import { cloneElement } from 'react';

// style
import * as S from './Badge.styled';

export const Badge = ({
  icon,
  text,
  color,
  xs,
  sm,
  md,
  customSizes,
  ...rest
}) => {
  let size = 30;
  let contentSize = 16;

  if (xs) {
    size = 12;
    contentSize = 8;
  } else if (sm) {
    size = 16;
    contentSize = 8;
  } else if (md) {
    size = 20;
    contentSize = 10;
  }

  const Icon = icon && cloneElement(icon, { color: '#fff', black: true });

  return (
    <S.Badge
      size={customSizes?.size ? customSizes.size : size}
      contentSize={
        customSizes?.contentSize ? customSizes?.contentSize : contentSize
      }
      color={color}
      {...rest}
    >
      {icon ? Icon : text}
    </S.Badge>
  );
};

export default Badge;
