import * as S from './SVG.styled';

export default ({
  color,
  width = 30,
  height = 30,
  bold,
  black,
  inCircle,
  ...rest
}) => {
  let weight;

  if (bold) {
    weight = 700;
  } else if (black) {
    weight = 900;
  }

  let content;

  switch (weight) {
    case 900:
      content = inCircle ? (
        <path
          d="M 15,0 C 6.728145,0 0,6.728145 0,15 0,23.2719 6.728145,30 15,30 23.2719,30 30,23.2719 30,15 30,6.728145 23.2719,0 15,0 Z m 6.8901,16.2228 H 8.1129 c -0.67557,0 -1.22274,-0.5472 -1.22274,-1.2228 0,-0.67557 0.54717,-1.22274 1.22274,-1.22274 h 13.7772 c 0.6756,0 1.2228,0.54717 1.2228,1.22274 0,0.6756 -0.5472,1.2228 -1.2228,1.2228 z"
          fill="currentcolor"
          strokeWidth="1.5"
        />
      ) : (
        <path
          d="M 28.23765,13.237673 H 1.76229 C 0.790092,13.237673 0,14.027768 0,14.999963 c 0,0.972165 0.790092,1.762365 1.76229,1.762365 h 26.47536 c 0.9723,0 1.76235,-0.7902 1.76235,-1.762365 0,-0.972195 -0.79005,-1.76229 -1.76235,-1.76229 z"
          fill="currentcolor"
          strokeWidth="1.5"
        />
      );

      break;
    case 700:
      content = inCircle ? (
        <>
          <path
            d="M 15,0 C 6.728145,0 0,6.728145 0,15 0,23.2719 6.728145,30 15,30 23.2719,30 30,23.2719 30,15 30,6.728145 23.2719,0 15,0 Z m 0,27.55755 C 8.076225,27.55755 2.44548,21.92685 2.44548,15.003 2.44548,8.07927 8.076225,2.44854 15,2.44854 c 6.92385,0 12.55455,5.63073 12.55455,12.55446 0,6.92385 -5.6307,12.55455 -12.55455,12.55455 z"
            fill="currentcolor"
            strokeWidth="1.5"
          />
          <path
            d="M 21.8901,13.777215 H 8.112915 c -0.67557,0 -1.222755,0.547185 -1.222755,1.222755 0,0.67563 0.547185,1.22268 1.222755,1.22268 H 21.8901 c 0.6756,0 1.2228,-0.54705 1.2228,-1.22268 0,-0.67557 -0.5472,-1.222755 -1.2228,-1.222755 z"
            fill="currentcolor"
            strokeWidth="1.5"
          />
        </>
      ) : (
        <path
          d="M 1.2227396,16.222727 H 28.777164 C 29.452822,16.222727 30,15.67555 30,15 30,14.32445 29.452822,13.777273 28.777164,13.777273 H 1.2227396 C 0.54717597,13.777273 0,14.32445 0,15 c 0,0.67555 0.54717597,1.222727 1.2227396,1.222727 z"
          fill="currentcolor"
          strokeWidth="1.56113"
        />
      );

      break;
    default:
      content = inCircle ? (
        <>
          <path
            d="M 15,30 C 23.2728,30 30,23.26965 30,15 30,6.730395 23.2728,0 15,0 6.727215,0 0,6.730395 0,15 0,23.26965 6.730395,30 15,30 Z M 15,1.274697 C 22.56855,1.274697 28.7253,7.43148 28.7253,15 28.7253,22.56855 22.56855,28.7253 15,28.7253 7.43148,28.7253 1.274697,22.56855 1.274697,15 1.274697,7.43148 7.43148,1.274697 15,1.274697 Z"
            fill="currentcolor"
            strokeWidth="1.5"
          />
          <path
            d="M 7.820295,15.63735 H 22.1829 c 0.35055,0 0.63735,-0.2868 0.63735,-0.637395 0,-0.350535 -0.2868,-0.63735 -0.63735,-0.63735 H 7.820295 c -0.350535,0 -0.637335,0.286815 -0.637335,0.63735 0,0.350595 0.2868,0.637395 0.637335,0.637395 z"
            fill="currentcolor"
            strokeWidth="1.5"
          />
        </>
      ) : (
        <path
          d="M 0.6373427,15.637316 H 29.362683 C 29.71324,15.637316 30,15.350557 30,15.000001 30,14.649492 29.71324,14.362683 29.362683,14.362683 H 0.6373427 C 0.2868072,14.362683 0,14.649492 0,15.000001 c 0,0.350556 0.2868072,0.637316 0.6373427,0.637315 z"
          fill="currentcolor"
          strokeWidth="1.62747"
        />
      );

      break;
  }

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
