import { useContext } from 'react';

// style
import * as S from './ServiceStats.styled';

// icons
import { Clock, PriceTag, RulerSVG } from 'common/icons';

// hooks
import { useNumber } from 'hooks/useNumber';
import { useTranslate } from 'hooks/useTranslate';
import { getTimeFromMinutes } from 'common/helpers/timeCalculations';
import { UserContext } from 'context/UserContext';

export const ServiceStats = (props) => {
  const { duration, volume, units, price, compServiceCount, totals } = props;

  const tr = useTranslate().use().global;
  const { locale } = useContext(UserContext);
  const { formatPrice, formatUnits } = useNumber({ price: true });

  return (
    <S.ServiceStats>
      {totals && `${tr['totals']}:`}

      {'duration' in props && !('volume' in props) && (
        <span>
          <Clock width={13} height={13} />
          <span>{duration ? getTimeFromMinutes(duration) : '-'}</span>
        </span>
      )}

      {'volume' in props && !('duration' in props) && (
        <span>
          <RulerSVG width={13} height={13} />
          <span>{`${formatUnits(volume)} ${units}`}</span>
        </span>
      )}

      {'price' in props && (
        <span>
          <PriceTag width={13} height={13} />
          <span>{formatPrice(price, { decimalPlaces: 4 }) || '-'}</span>
        </span>
      )}

      {'compServiceCount' in props && (
        <span>
          <span>{`${tr['amount']}: ${(+compServiceCount).toLocaleString(locale) || '-'} ${tr['pcs']}`}</span>
        </span>
      )}
    </S.ServiceStats>
  );
};

export default ServiceStats;
