import * as S from './SVG.styled';

export default ({ color, width = 30, height = 30, bold, ...rest }) => {
  const content = bold ? (
    <>
      <path
        d="M 28.7772,5.22264 H 22.76745 V 1.783668 c 0,-0.6755655 -0.5472,-1.222743 -1.22265,-1.222743 -0.6756,0 -1.2228,0.5471775 -1.2228,1.222743 V 5.22264 H 9.77277 V 1.783668 c 0,-0.6755655 -0.54717,-1.222743 -1.22274,-1.222743 -0.67557,0 -1.22274,0.5471775 -1.22274,1.222743 V 5.22264 H 1.222743 C 0.5471775,5.22264 0,5.76981 0,6.44538 v 21.770895 c 0,0.6756 0.5471775,1.2228 1.222743,1.2228 H 28.7772 c 0.6756,0 1.2228,-0.5472 1.2228,-1.2228 V 6.44538 C 30,5.76981 29.4528,5.22264 28.7772,5.22264 Z M 27.55455,9.022305 H 2.44548 V 7.67118 h 25.10907 z m -25.10907,2.44548 h 25.10907 v 15.52584 H 2.44548 Z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="M 9.73626,21.686775 H 6.245325 c -0.67557,0 -1.22274,0.54705 -1.22274,1.22265 0,0.6756 0.54717,1.2228 1.22274,1.2228 H 9.73626 c 0.67557,0 1.22274,-0.5472 1.22274,-1.2228 0,-0.6756 -0.54717,-1.22265 -1.22274,-1.22265 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="M 16.74555,21.686775 H 13.2546 c -0.675555,0 -1.22274,0.54705 -1.22274,1.22265 0,0.6756 0.547185,1.2228 1.22274,1.2228 h 3.49095 c 0.6756,0 1.2228,-0.5472 1.2228,-1.2228 0,-0.6756 -0.5472,-1.22265 -1.2228,-1.22265 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="M 23.75505,21.686775 H 20.2641 c -0.6756,0 -1.2228,0.54705 -1.2228,1.22265 0,0.6756 0.5472,1.2228 1.2228,1.2228 h 3.49095 c 0.67545,0 1.22265,-0.5472 1.22265,-1.2228 0,-0.6756 -0.5472,-1.22265 -1.22265,-1.22265 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="M 9.73626,18.033825 H 6.245325 c -0.67557,0 -1.22274,0.54705 -1.22274,1.22265 0,0.6756 0.54717,1.2228 1.22274,1.2228 H 9.73626 c 0.67557,0 1.22274,-0.5472 1.22274,-1.2228 0,-0.6756 -0.54717,-1.22265 -1.22274,-1.22265 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="M 16.74555,18.033825 H 13.2546 c -0.675555,0 -1.22274,0.54705 -1.22274,1.22265 0,0.6756 0.547185,1.2228 1.22274,1.2228 h 3.49095 c 0.6756,0 1.2228,-0.5472 1.2228,-1.2228 0,-0.6756 -0.5472,-1.22265 -1.2228,-1.22265 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="M 23.75505,18.033825 H 20.2641 c -0.6756,0 -1.2228,0.54705 -1.2228,1.22265 0,0.6756 0.5472,1.2228 1.2228,1.2228 h 3.49095 c 0.67545,0 1.22265,-0.5472 1.22265,-1.2228 0,-0.6756 -0.5472,-1.22265 -1.22265,-1.22265 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="M 9.73626,14.377875 H 6.245325 c -0.67557,0 -1.22274,0.547185 -1.22274,1.2228 0,0.67545 0.54717,1.22265 1.22274,1.22265 H 9.73626 c 0.67557,0 1.22274,-0.5472 1.22274,-1.22265 0,-0.675615 -0.54717,-1.2228 -1.22274,-1.2228 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="M 16.74555,14.377875 H 13.2546 c -0.675555,0 -1.22274,0.547185 -1.22274,1.2228 0,0.67545 0.547185,1.22265 1.22274,1.22265 h 3.49095 c 0.6756,0 1.2228,-0.5472 1.2228,-1.22265 0,-0.675615 -0.5472,-1.2228 -1.2228,-1.2228 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="M 23.75505,14.377875 H 20.2641 c -0.6756,0 -1.2228,0.547185 -1.2228,1.2228 0,0.67545 0.5472,1.22265 1.2228,1.22265 h 3.49095 c 0.67545,0 1.22265,-0.5472 1.22265,-1.22265 0,-0.675615 -0.5472,-1.2228 -1.22265,-1.2228 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
    </>
  ) : (
    <>
      <path
        d="M 0.6373485,29.415225 H 29.36265 c 0.35055,0 0.63735,-0.2868 0.63735,-0.63735 V 6.0819 C 30,5.731365 29.7132,5.444565 29.36265,5.444565 H 22.4601 V 1.2221235 c 0,-0.350541 -0.2868,-0.6373485 -0.63735,-0.6373485 -0.3504,0 -0.63735,0.2868075 -0.63735,0.6373485 V 5.444565 H 8.913315 V 1.2221235 c 0,-0.350541 -0.2868,-0.6373485 -0.63735,-0.6373485 -0.350535,0 -0.637335,0.2868075 -0.637335,0.6373485 V 5.444565 H 0.6373485 C 0.2868075,5.444565 0,5.731365 0,6.0819 v 22.695975 c 0,0.35055 0.2868075,0.63735 0.6373485,0.63735 z M 1.274697,10.680375 H 28.7253 v 17.46015 H 1.274697 Z M 28.7253,9.405675 H 1.274697 V 6.722445 H 28.7253 Z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="M 9.512505,22.608375 H 5.87325 c -0.350535,0 -0.63735,0.2868 -0.63735,0.63735 0,0.35055 0.286815,0.63735 0.63735,0.63735 h 3.639255 c 0.35055,0 0.63735,-0.2868 0.63735,-0.63735 0,-0.35055 -0.2868,-0.63735 -0.63735,-0.63735 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 16.81965,22.608375 h -3.63921 c -0.350535,0 -0.63735,0.2868 -0.63735,0.63735 0,0.35055 0.286815,0.63735 0.63735,0.63735 h 3.63921 c 0.35055,0 0.63735,-0.2868 0.63735,-0.63735 0,-0.35055 -0.2868,-0.63735 -0.63735,-0.63735 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 24.1269,22.608375 h -3.6393 c -0.35055,0 -0.63735,0.2868 -0.63735,0.63735 0,0.35055 0.2868,0.63735 0.63735,0.63735 h 3.6393 c 0.35055,0 0.63735,-0.2868 0.63735,-0.63735 0,-0.35055 -0.2868,-0.63735 -0.63735,-0.63735 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="M 9.512505,18.800175 H 5.87325 c -0.350535,0 -0.63735,0.2868 -0.63735,0.63735 0,0.3504 0.286815,0.63735 0.63735,0.63735 h 3.639255 c 0.35055,0 0.63735,-0.28695 0.63735,-0.63735 0,-0.35055 -0.2868,-0.63735 -0.63735,-0.63735 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 16.81965,18.800175 h -3.63921 c -0.350535,0 -0.63735,0.2868 -0.63735,0.63735 0,0.3504 0.286815,0.63735 0.63735,0.63735 h 3.63921 c 0.35055,0 0.63735,-0.28695 0.63735,-0.63735 0,-0.35055 -0.2868,-0.63735 -0.63735,-0.63735 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 24.1269,18.800175 h -3.6393 c -0.35055,0 -0.63735,0.2868 -0.63735,0.63735 0,0.3504 0.2868,0.63735 0.63735,0.63735 h 3.6393 c 0.35055,0 0.63735,-0.28695 0.63735,-0.63735 0,-0.35055 -0.2868,-0.63735 -0.63735,-0.63735 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="M 9.512505,14.988765 H 5.87325 c -0.350535,0 -0.63735,0.286815 -0.63735,0.63741 0,0.35055 0.286815,0.63735 0.63735,0.63735 h 3.639255 c 0.35055,0 0.63735,-0.2868 0.63735,-0.63735 0,-0.350595 -0.2868,-0.63741 -0.63735,-0.63741 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 16.81965,14.988765 h -3.63921 c -0.350535,0 -0.63735,0.286815 -0.63735,0.63741 0,0.35055 0.286815,0.63735 0.63735,0.63735 h 3.63921 c 0.35055,0 0.63735,-0.2868 0.63735,-0.63735 0,-0.350595 -0.2868,-0.63741 -0.63735,-0.63741 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 24.1269,14.988765 h -3.6393 c -0.35055,0 -0.63735,0.286815 -0.63735,0.63741 0,0.35055 0.2868,0.63735 0.63735,0.63735 h 3.6393 c 0.35055,0 0.63735,-0.2868 0.63735,-0.63735 0,-0.350595 -0.2868,-0.63741 -0.63735,-0.63741 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
    </>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
