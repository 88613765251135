import { useContext } from 'react';
import PropTypes from 'prop-types';

// icons
import { Columns, ListViewSVG, PersonOnTable, Row } from 'common/icons';

// components
import OnlineReservationsSettings from 'pages/Settings/Branches/OnlineReservations/OnlineReservationsSettings';
import GeneralSystemSettings from 'pages/Settings/System/General/GeneralSystemSettings/GeneralSystemSettings';
import BranchCreationSettings from 'pages/Settings/System/General/BranchCreationSettings/BranchCreationSettings';
import SMSSettings from 'pages/Settings/Branches/SMS/SMSSettings';
import GeneralCalendarSettings from 'pages/Settings/Branches/Calendar/General/GeneralCalendarSettings';
import DisplayMobileSettings from 'pages/Settings/Branches/Calendar/DisplayMobile/DisplayMobileSettings';
import DisplayPCSettings from 'pages/Settings/Branches/Calendar/DisplayPC/DisplayPCSettings';
import WorkingAreasSettings from 'pages/Settings/Branches/Calendar/WorkingAreas/WorkingAreasSettings';
import OperationDataSettings from 'pages/Settings/Branches/ContactDetails/OperationData/OperationDataSettings';
import LoginDataSettings from 'pages/Settings/Branches/ContactDetails/LoginData/LoginDataSettings';
import { TheButton } from 'components/Buttons/TheButton';

// contexts
import { UserContext } from 'context/UserContext';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useMediaQuery } from 'hooks/useMediaQuery';

const APP_ROOT = process.env.REACT_APP_APP_ROOT;

export const useSideBarMenu = (contents) => {
  const gl = useTranslate().use().global;
  const tr = useTranslate().use().main;
  const fl = useTranslate().use().filter;
  const isMobile = useMediaQuery('(max-width: 960px)');
  const { logoutUser, locale } = useContext(UserContext);

  const menuMyAccount = {
    id: 'my-account',
    name: tr['my-account'],
    route: APP_ROOT + 'dashboard/my-account',
    acl: 1,
    scope: 3,
    aclType: 3,
    subItems: [
      {
        id: 'my-projects',
        name: tr['my-projects'],
        acl: 1,
        scope: 3,
        aclType: 3,
        route: 'my-projects',
      },
      {
        id: 'my-settings',
        name: tr['settings'],
        acl: 1,
        scope: 3,
        aclType: 3,
        route: 'my-settings',
      },
      {
        id: 'logout',
        name: (
          <TheButton
            title={gl['logout']}
            primary
            width="170"
            height="30"
            action={logoutUser}
          />
        ),
        acl: 1,
        scope: 2,
        aclType: 3,
        route: 'my-settings',
      },
    ],
  };

  const menuServeProMinimal = {
    id: 'serve-pro',
    name: tr['serve-pro'],
    route: APP_ROOT + 'dashboard/serve-pro',
    acl: 1,
    scope: 3,
    aclType: 3,
    subItems: [
      {
        id: 'announcements-and-suggestions',
        name: gl['announcements-and-suggestions'],
        acl: 1,
        scope: 3,
        aclType: 3,
        globalRoute: 'announcements-and-suggestions',
      },
    ],
  };

  const menuServePro = {
    id: 'serve-pro',
    name: tr['serve-pro'],
    route: APP_ROOT + 'dashboard/serve-pro',
    acl: 1,
    scope: 3,
    aclType: 3,
    subItems: [
      {
        id: 'contact-us',
        name: tr['contact-us'],
        acl: 1,
        scope: 3,
        aclType: 3,
        route: 'contact-us',
      },
      {
        id: 'privacy-policy',
        name: tr['privacy-policy'],
        acl: 1,
        scope: 3,
        aclType: 3,
        route: 'privacy-policy',
      },
      {
        id: 'terms-and-conditions',
        name: tr['terms-and-conditions'],
        acl: 1,
        scope: 3,
        aclType: 3,
        route: 'terms-and-conditions',
      },
      {
        id: 'announcements-and-suggestions',
        name: gl['announcements-and-suggestions'],
        acl: 1,
        scope: 3,
        aclType: 3,
        globalRoute: 'announcements-and-suggestions',
      },
    ],
  };

  const menuDashboard = [menuMyAccount, menuServePro];

  const menuSystem = {
    id: 'system',
    name: tr['system-settings'],
    route: APP_ROOT + 'settings/system',
    acl: 1,
    scope: 3,
    aclType: 3,
    subItems: [
      {
        id: 'general',
        name: tr['common-settings'],
        acl: 1,
        scope: 3,
        aclType: 3,
        route: 'general',
        tabs: [
          {
            id: 'common-settings',
            name: tr['general'],
            route: 'common-settings',
            view: GeneralSystemSettings,
          },
          {
            id: 'branch-creation',
            name: tr['branch-creation'],
            route: 'branch-creation',
            view: BranchCreationSettings,
          },
        ],
      },
    ],
  };

  const menuBranches = {
    id: 'branches',
    name: tr['branch-settings'],
    route: APP_ROOT + 'settings/branches',
    acl: 1,
    scope: 3,
    aclType: 3,
    subItems: [
      {
        id: 'sector-details',
        name: tr['sector-details'],
        acl: 1,
        scope: 3,
        aclType: 3,
        route: 'sector-details',
        tabs: [
          {
            id: 'login-data',
            name: tr['login-data'],
            route: 'login-data',
            view: LoginDataSettings,
          },
          {
            id: 'operation-data',
            name: tr['operation-data'],
            route: 'operation-data',
            view: OperationDataSettings,
          },
        ],
      },
      {
        id: 'calendar',
        name: tr['calendar'],
        acl: 1,
        scope: 3,
        aclType: 3,
        route: 'calendar',
        tabs: [
          {
            id: 'general',
            name: tr['general'],
            route: 'general',
            view: GeneralCalendarSettings,
          },
          {
            id: 'display-pc',
            name: tr['display-pc'],
            route: 'display-pc',
            view: DisplayPCSettings,
          },
          {
            id: 'display-mobile',
            name: tr['display-mobile'],
            route: 'display-mobile',
            view: DisplayMobileSettings,
          },
          {
            id: 'working-areas',
            name: tr['working-areas'],
            route: 'working-areas',
            view: WorkingAreasSettings,
          },
        ],
      },
      {
        id: 'sms',
        name: tr['sms'],
        acl: 1,
        scope: 3,
        aclType: 3,
        route: 'sms',
        view: SMSSettings,
      },
      // {
      //   name: tr['working-areas'],
      //   acl: 1,
      //   route: APP_ROOT + '/settings/branches/working-areas',
      // },
      {
        id: 'online-reservations',
        name: tr['online-reservations'],
        acl: 1,
        scope: 3,
        aclType: 3,
        route: 'online-reservations',
        view: OnlineReservationsSettings,
      },
    ],
  };

  const menuSettings = [menuSystem, menuBranches, menuServeProMinimal];

  const menuDailyFunctions = {
    id: 'daily-functions',
    name: tr['daily-functions'],
    route: APP_ROOT + 'functions/daily-functions',
    acl: 1, // access control level for connected user
    scope: 3, // identifier for bitwise validation of usage scope: 1 - desktop, 2 - mobile, 3 - both
    aclType: 3, // identifier for bitwise validation of user type: 1 - individual, 2 - company, 3 - both

    subItems: [
      {
        id: 'calendar',
        name: tr.calendar,
        route: 'calendar',
        acl: 1,
        scope: 3,
        aclType: 3,
        cascade: true,

        subItems: [
          {
            id: 'list',
            name: tr.list,
            route: 'list',
            icon: <ListViewSVG />,
            acl: 1,
            scope: 1,
            aclType: 1,
          },

          {
            id: 'day',
            name: tr.day,
            route: 'day',
            icon: <Row />,
            acl: 1,
            scope: 3,
            aclType: 3,
          },
          {
            id: 'week',
            name: tr.week,
            route: 'week',
            icon: <Columns />,
            acl: 1,
            scope: 3,
            aclType: 3,
          },
          // {
          //   id: 'month',
          //   name: tr.month,
          //   route: 'month',
          //   icon: <Grid />,
          //   acl: 1,
          //   scope: 3,
          //   aclType: 3,
          // },
          {
            id: 'personal',
            name: tr.personal,
            route: 'personal',
            icon: <PersonOnTable />,
            acl: 1,
            scope: 3,
            aclType: 3,
          },
        ],
      },
      {
        id: 'client-search',
        name: tr['client-search'],
        route: 'client-search',
        acl: 1,
        scope: 3,
        aclType: 3,
      },
      {
        id: 'invoices',
        name: tr.invoices,
        route: 'invoices',
        acl: 2,
        scope: 3,
        aclType: 3,
      },
      {
        id: 'cash-register',
        name: tr['cash-register'],
        route: 'cash-register',
        acl: 2,
        scope: 3,
        aclType: 3,
      },
      {
        id: 'orders',
        name: tr.orders,
        route: 'orders',
        acl: 2,
        scope: 3,
        aclType: 3,
      },
      {
        id: 'supply-dispensary',
        name: tr['supply-dispensary'],
        route: 'supply-dispensary',
        acl: 2,
        scope: 3,
        aclType: 3,
      },
    ],
  };

  const menuTables = {
    id: 'tables',
    name: tr.tables,
    route: APP_ROOT + 'functions/tables',
    acl: 1, // access control level for connected user
    scope: 3, // identifier for bitwise validation of usage scope: 1 - desktop, 2 - mobile, 3 - both
    aclType: 3, // identifier for bitwise validation of user type: 1 - individual, 2 - company, 3 - both
    subItems: [
      {
        id: 'clients',
        name: tr['clients'],
        route: 'clients',
        acl: 1,
        scope: 3,
        aclType: 3,
        // filter: [
        //   {
        //     id: 'attended-branch',
        //     name: fl['attended-branch'],
        //     target: {
        //       slice: 'orders',
        //       field: ['orderDate', 'orderDate'],
        //     },
        //     type: 'switch',
        //     options: [
        //       { value: 1, label: fl.branch + ' 1' },
        //       { value: 2, label: fl.branch + ' 2' },
        //       { value: 3, label: fl.branch + ' 3' },
        //     ],
        //   },
        //   {
        //     id: 'order-date-period',
        //     name: fl['order-date-period'],
        //     target: {
        //       slice: 'orders',
        //       field: 'order-date',
        //       type: 'calendar',
        //       filterType: 'range',
        //     },
        //     type: 'switch',
        //     options: [
        //       { value: 'today', label: fl['today'] },
        //       { value: 'this-week', label: fl['this-week'] },
        //       { value: 'this-month', label: fl['this-month'] },
        //       { value: 'this-year', label: fl['this-year'] },
        //       { value: 'last-week', label: fl['last-week'] },
        //       { value: 'last-month', label: fl['last-month'] },
        //       { value: 'last-year', label: fl['last-year'] },
        //     ],
        //     update: (props) => updatePeriod(...props, locale),
        //   },
        //   {
        //     id: 'order-date-from',
        //     name: fl['order-date-from'],
        //     target: {
        //       slice: 'orders',
        //       field: 'order-date',
        //       type: 'calendar',
        //       filterType: 'range',
        //     },
        //     type: 'calendar',
        //     update: updateDate,
        //   },
        //   {
        //     id: 'order-date-to',
        //     name: fl['order-date-to'],
        //     target: {
        //       slice: 'orders',
        //       field: 'order-date',
        //       type: 'calendar',
        //       filterType: 'range',
        //     },
        //     type: 'calendar',
        //     update: updateDate,
        //   },
        // ],
      },
      {
        id: 'companies',
        name: tr.companies,
        route: 'companies',
        acl: 2,
        scope: 3,
        aclType: 3,
      },
      {
        id: 'suppliers',
        name: tr.suppliers,
        route: 'suppliers',
        acl: 2,
        scope: 3,
        aclType: 3,
      },
      {
        id: 'services',
        name: tr.services,
        route: 'services',
        acl: 1,
        scope: 3,
        aclType: 3,
        filter: [
          {
            id: 'service-activity',
            name: fl['service-activity'],
            level: 1,
            type: 'switch',
            options: [
              { value: true, label: gl['active'] },
              { value: false, label: gl['inactive'] },
            ],
            default: { value: true, label: gl['active'] },
          },
        ],
      },
      {
        id: 'products',
        name: tr.products,
        route: 'products',
        acl: 2,
        scope: 3,
        aclType: 3,
      },
      {
        id: 'employees',
        name: tr.employees,
        route: 'employees',
        acl: 1,
        scope: 3,
        aclType: 3,
      },
    ],
  };

  const menuFunctions = [menuDailyFunctions, menuTables, menuServeProMinimal];

  const menuFunctionsMobile = [
    menuDailyFunctions,
    menuTables,
    menuSystem,
    menuBranches,
    ...menuDashboard,
  ];

  if (isMobile) {
    return menuFunctionsMobile;
  } else {
    switch (contents) {
      case 'dashboard':
        return menuDashboard;

      case 'functions':
        return menuFunctions;

      case 'settings':
        return menuSettings;

      default:
        // console.log('contents:', contents);
        break;
    }
  }
};

useSideBarMenu.propTypes = {
  contents: PropTypes.string.isRequired,
};
