import { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router';

// components
import CustomerSearch from 'components/Day Functions/CustomerSearch/CustomerSearch';
import Bills from 'components/Day Functions/Bills/Bills';
import Case from 'components/Day Functions/Case/Case';
import Orders from 'components/Day Functions/Orders/Orders';
import MaterialExport from 'components/Day Functions/MaterialExport/MaterialExport';
import LegalEntities from 'components/Cards/LegalEntities/LegalEntities';
import Suppliers from 'components/Cards/Suppliers/Suppliers';
import Services from 'components/Cards/Services/Services';
import Products from 'components/Cards/Products/Products';

// contexts
import { FunctionsContextProvider } from 'context/FunctionsContext';

// pages
import Order from 'pages/Order/partials/Order/Order';
import ScheduleDay from 'pages/Schedule/Day/ScheduleDay';
import ScheduleWeek from 'pages/Schedule/Week/ScheduleWeek';
import SchedulePersonal from 'pages/Schedule/Personal/SchedulePersonal';
import { Employees } from 'pages/Employees/Employees';
import { Employee } from 'pages/Employee/Employee';
import OrderContainer from 'pages/Order/OrderContainer';
import OrderInformation from 'pages/Order/partials/OrderInformation/OrderInformation';
import { Clients } from 'pages/Clients/Clients';
import { Client } from 'pages/Client/Client';

// hooks
import { useRestoreAppUserCredentials } from 'hooks/useRestoreAppUserCredentials';

export const AppSpace = () => {
  const { user, companyId } = useRestoreAppUserCredentials();

  const location = useLocation();

  const [homeScreenPath, setHomeScreenPath] = useState();

  useEffect(() => {
    // console.log({ user });
    if (user) {
      switch (user.settings?.homeScreen) {
        case 'd':
          setHomeScreenPath('day');
          break;

        case 'w':
          setHomeScreenPath('week');
          break;

        case 'm':
          setHomeScreenPath('month');
          break;

        default:
          setHomeScreenPath('personal');
          break;
      }
    }
  }, [user]);

  return companyId ? (
    <FunctionsContextProvider>
      <Routes>
        <Route
          index
          element={
            <Navigate
              to={{ pathname: 'daily-functions', search: location.search }}
            />
          }
        />

        <Route path="daily-functions">
          <Route
            index
            element={
              <Navigate
                to={{ pathname: 'calendar', search: location.search }}
              />
            }
          />

          <Route path="calendar">
            <Route
              index
              element={
                <Navigate
                  to={{ pathname: homeScreenPath, search: location.search }}
                />
              }
            />

            {/* <Route path="register" element={<Register />}></Route> */}
            {/* <Route path="day/:branch" element={<ScheduleDay />} /> */}
            {/* <Route path="day/:branch" element={<Navigate to="1" />} /> */}
            {/* <Route path="day/:branch/:qtyPro" element={<ScheduleDay />} /> */}
            {/* <Route path="day-3/*" element={<ScheduleDay />} /> */}
            {/* <Route path="compare" element={<RegisterComparer />} /> */}

            <Route path="list" />
            <Route path="day/*" element={<ScheduleDay />} />
            <Route path="week/*" element={<ScheduleWeek />} />
            {/* <Route path="month" element={<ScheduleMonth />} /> */}
            <Route path="personal/*" element={<SchedulePersonal />} />
          </Route>
          <Route path="client-search/*" element={<CustomerSearch />} />

          <Route path="invoices" element={<Bills />} />
          <Route path="cash-register" element={<Case />} />
          <Route path="orders" element={<Orders />} />
          <Route path="supply-dispensary" element={<MaterialExport />} />
        </Route>

        <Route path="tables">
          <Route index element={<Navigate to="clients" />} />
          <Route path="clients/*" element={<Clients />} />
          <Route path="clients/:clientId?/*" element={<Client />} />
          <Route path="companies/*" element={<LegalEntities />} />
          <Route path="suppliers/*" element={<Suppliers />} />
          <Route path="services/*" element={<Services />} />
          <Route path="products/*" element={<Products />} />
          <Route path="employees/*" element={<Employees />} />
          <Route path="employees/:employeeId?/*" element={<Employee />} />
          <Route path="orders/*" element={<OrderContainer />}>
            <Route path=":orderId?" index element={<Order />} />
            <Route
              path=":orderId?/information"
              element={<OrderInformation />}
            />
          </Route>
        </Route>
      </Routes>
    </FunctionsContextProvider>
  ) : (
    <></>
  );
};
