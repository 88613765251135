import { StyledSVG } from './SVG.styled';

export default ({
  color = 'currentColor',
  width = 36.385,
  height = 34.005,
  ...rest
}) => (
  <StyledSVG
    width={width}
    height={height}
    viewBox="0 0 36.385 34.005"
    color={color}
    {...rest}
  >
    <g transform="translate(-1124 -138.849)">
      <g transform="translate(-1230.521 -2937.863)">
        <g transform="translate(2354.522 3076.712)">
          <path
            d="M2401.915,3127.419c-1.159,0-11.2.089-11.2,3.129v8.155c0,3.04,10.044,3.125,11.2,3.125s11.2-.085,11.2-3.125v-8.155C2413.1,3127.508,2403.062,3127.419,2401.915,3127.419Zm9.657,4.887v.9h0a.085.085,0,0,1-.031.035h0a.291.291,0,0,1-.05.046h0l-.081.058h-.023l-.116.073h-.023a1.618,1.618,0,0,1-.147.077h-.023l-.2.093-.05.02-.247.1h-.054c-.1.035-.2.073-.317.108a27.642,27.642,0,0,1-8.3,1.035,30.2,30.2,0,0,1-8.267-.962l-.3-.108h-.046c-.093-.031-.178-.066-.255-.1l-.039-.019a2.061,2.061,0,0,1-.2-.093h0l-.158-.081h0l-.12-.073h-.019l-.081-.058h0a.308.308,0,0,1-.054-.046h0l-.031-.039h0v-.97h.039l.216.093h0l.228.085.062.019.151.05h.05l.151.046a31.119,31.119,0,0,0,8.734,1.035,31.549,31.549,0,0,0,8.73-1.031l.151-.05h.054l.143-.046.07-.027.208-.077h.046l.2-.085Zm0,2.7v.9l-.027.027h0a.491.491,0,0,1-.066.062h0l-.085.062-.042.027-.1.062-.066.035-.135.069-.085.042-.232.12-.1.039c-.1.042-.216.085-.348.127l-.112.035a30.39,30.39,0,0,1-8.259.958,31.131,31.131,0,0,1-8.112-.9l-.232-.232-.209.089c-.116-.039-.216-.077-.305-.116l-.077-.035c-.062-.027-.12-.05-.17-.077l-.073-.035-.124-.066-.039-.042-.093-.058-.035-.023-.085-.046h0l-.058-.05h0v-.958h.039l.216.093h0l.228.081.062.023.151.05h.05l.151.046a37.348,37.348,0,0,0,17.464,0l.151-.046h.054l.143-.046.07-.027.209-.077h.046l.2-.085Zm-.07-4.47h0l-.039.035h0l-.062.046-.031.019-.07.046h-.031l-.12.07h-.039l-.12.058h-.046l-.186.081h-.039l-.189.073-.065.023-.271.089a26.453,26.453,0,0,1-8.282,1.051,29.605,29.605,0,0,1-8.267-.962l-.266-.089-.07-.023-.193-.073h-.031l-.193-.081h-.039l-.128-.062h-.031l-.124-.12h0l-.085-.054h0l-.073-.054h0l-.046-.039h0c.591-.607,4.041-1.576,9.6-1.576S2410.919,3129.934,2411.5,3130.54Zm.05,8.112c-.406.6-3.894,1.63-9.638,1.63s-9.236-1.035-9.657-1.634v-.9h.039l.2.089.066.023.174.07.081.027.127.042.066.023.143.046,1.008.324v-.039a35.5,35.5,0,0,0,7.726.773,31.657,31.657,0,0,0,8.622-.993l.12-.039.151-.046.054-.019.162-.062.07-.023.193-.073h.039c.073-.027.147-.062.22-.093h.035Z"
            transform="translate(-2376.732 -3107.831)"
            //   fill="#0f1d5b"
          />
          <path
            d="M2367.4,3109.2a15.792,15.792,0,1,1,20.25-15.154.773.773,0,0,0,1.545,0,17.337,17.337,0,1,0-22.228,16.634.677.677,0,0,0,.216.035.773.773,0,0,0,.216-1.514Z"
            transform="translate(-2354.522 -3076.712)"
            //   fill="#0f1d5b"
          />
          <path
            d="M2398.175,3084.509a.773.773,0,0,0-.773.773v14.324h10.044a.773.773,0,0,0,0-1.545h-8.5v-12.779A.773.773,0,0,0,2398.175,3084.509Z"
            transform="translate(-2380.837 -3081.497)"
            //   fill="#0f1d5b"
          />
        </g>
      </g>
    </g>
  </StyledSVG>
);
