import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import { shadow } from 'common/styles/shadows';

export const OrderNote = styled.div`
  .content {
    min-height: 38px;
    background-color: ${({ disabled }) =>
      disabled ? colors.bg_grey_4 : '#fff'};
    padding: 10px 0;
    margin: 0 5px;
    border-radius: 5px;
    display: flex;

    .buttons-container {
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      gap: 10px;
    }

    .icon-container {
      height: 20px;
      width: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 1px;

      svg {
        width: 16px;
        height: 16px;
        fill: ${colors.bg_grey_3};
      }
    }

    .input-container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;

      textarea {
        padding: 0;
        height: 100%;
        width: 100%;
        border: none;
        outline: none;
        font-size: 14px;
        font-family: ${fontFamilies.family_Secondary};
        color: ${colors.bg_Primary};
        margin-right: 5px;
        padding-top: 2px;

        &:disabled {
          background-color: ${colors.bg_grey_4};
        }

        ::placeholder {
          color: ${colors.bg_grey_3};
        }
      }
    }
  }

  @media screen and (max-width: 960px) {
    .content {
      margin: 0;
      border-radius: 3px;
      box-shadow: ${shadow.md};
      padding: 8px 0;
      min-height: 36px;

      .icon-container svg {
        fill: ${colors.canceled};
      }
      .input-container textarea::placeholder {
        color: ${colors.canceled};
      }
    }
  }
`;
