import { useEffect } from 'react';

// style
import * as S from './BranchCreationSettings.styled';

// icons
import { Check } from 'common/icons';

export const BranchCreationSettings = ({ setButtons }) => {
  // setup/cleanup header buttons
  useEffect(() => {
    setButtons &&
      setButtons({
        type: 'setup',
        value: [
          {
            id: 'save',
            icon: <Check bold />,
            action: () => console.log('Settings saved'),
            borderless: true,
            show: true,
          },
        ],
      });

    return () => setButtons && setButtons({ type: 'cleanup' });
  }, [setButtons]);

  return (
    <S.BranchCreationSettings>
      Branch creation settings contents
    </S.BranchCreationSettings>
  );
};

export default BranchCreationSettings;
