import { useState, useContext } from 'react';

// style
import * as S from './FunctionWidget.styled';

// icons
import { Ex, Plus, VerticalEllipsis } from 'common/icons';

// components
import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper';
import { Overlay } from 'components/Overlay/Overlay';
import { FunctionBarItem } from '../FunctionBarItem/FunctionBarItem';

// contexts
import { UserContext } from 'context/UserContext';

// hooks
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useModal } from 'hooks/useModal';

export const FunctionWidget = ({
  functions,
  plusAction,
  fabMarginY = 20,
  id,
}) => {
  // Hooks
  // --- global
  const { userAcl } = useContext(UserContext);
  const isMobile = useMediaQuery('(max-width: 960px)');

  // --- local
  const { isShowing, toggle } = useModal(false);
  const [selectedFunctionId, selectFunction] = useState();

  const filteredFunctions = functions.reduce((acc, func) => {
    const newFunc = { ...func };
    newFunc.title = newFunc.name;

    return newFunc.acl <= userAcl && (newFunc.hide ? newFunc.hide() : true)
      ? [...acc, newFunc]
      : acc;
  }, []);

  const [menuVisible, setMenuVisible] = useState();

  const hideMenu = () => {
    setMenuVisible(false);
    selectFunction(null);
  };

  const fabs = (
    <>
      {!!plusAction && (
        <S.FloatingActionButton
          marginY={fabMarginY}
          className="plus"
          secondary
          raised
          icon={<Plus black />}
          action={plusAction}
          isVisible={menuVisible}
          id={id}
        />
      )}
      <S.FloatingActionButton
        marginY={fabMarginY}
        secondary
        raised
        inverted
        icon={menuVisible ? <Ex black /> : <VerticalEllipsis />}
        action={() => setMenuVisible((old) => !old)}
        isVisible={menuVisible}
        id={id}
      />
    </>
  );

  return (
    filteredFunctions && (
      <S.FunctionWidget>
        <ConditionalWrapper
          condition={isMobile}
          wrapper={(children) => (
            <Overlay isVisible={menuVisible} hide={hideMenu} passThrough={fabs}>
              {children}
            </Overlay>
          )}
        >
          <S.FunctionList isVisible={menuVisible}>
            {filteredFunctions.map((item) => (
              <FunctionBarItem
                item={item}
                key={item.id}
                isActive={selectedFunctionId === item.id || item.isActive}
                setActive={selectFunction}
                closeMenu={hideMenu}
              />
            ))}
          </S.FunctionList>
        </ConditionalWrapper>
      </S.FunctionWidget>
    )
  );
};
