import { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { DateTime } from 'luxon';
import { Navigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

// Style
import * as S from './DataPanelColumn.styled';

// Helpers
import { datesAreOnSameDay, toIsoString } from 'common/helpers/dateOperations';
import { listOfExcuses } from 'common/helpers/tempData';
import { SCHEDULE_TYPE } from 'common/constants';

// Context/Redux
import { ScheduleContext } from 'context/ScheduleContext';
import { MainContext } from 'pages/Main/Main';
import { UserContext } from 'context/UserContext';
import { updateSchedule } from 'redux/actions/scheduleActions';
// import { addAppointment } from 'redux/actions/appointmentsAction';

// Components
import DataPanelCell from '../DataPanelCell/DataPanelCell';
import useObserver from 'hooks/useObserver';
import DataPanelHeader from '../DataPanelHeader/DataPanelHeader';
import AppointmentsContainer from '../AppointmentsContainer/AppointmentsContainer';
import TimeIndicator from '../../TimeIndicator/TimeIndicator';

const DataPanelColumn = ({
  employee,
  workSchedule,
  cellsData,
  loadingOrders,
  timeIndicator,
  pinCollumnToStart,
  columnDay,
  count,
  scrollIntoView,
  listOfExcusesWithAction,
  isInThePast,
  isWeekend,
  days,
  globalOrders,
  sectorId,
  refetchOrders,
  scrollToHour,
}) => {
  // Hooks
  const dispatch = useDispatch();
  const { locale } = useContext(UserContext);

  const [currentDate, setCurrentDate] = useState(columnDay);

  useEffect(() => {
    const same = datesAreOnSameDay(columnDay, currentDate);

    if (same) return;

    setCurrentDate(columnDay);
  }, [columnDay]);

  // -- state
  // --- local

  const [shouldNavigate, setShouldNavigate] = useState(false);

  const [activeCell, setActiveCell] = useState();
  const [appointmentsInRow, setAppointmentsInRow] = useState(0);

  const dateState = useSelector((state) => state.scheduleReducer.dateState);

  // -- context
  const {
    appointments,
    cache,
    compare,
    personalDays,
    numberOfDays,
    columnWidth,
    searchParams,
    personal,
    cellWidth,
    toggledEmployee,
    defaultColumnWidth,
    setScheduleScrollTargetNode,
    type,
  } = useContext(ScheduleContext);
  const { sectorData } = useContext(MainContext);

  // refs
  const containerRef = useRef(null);

  let container = useObserver({
    ref: containerRef,
    debounceDelay: 100,
  });

  // Variables
  const contextTreeItems = useCallback(
    (close) => [
      {
        id: '1',
        label: 'Naujas užsakymas',
        onClick: () => setShouldNavigate(true),
      },
      {
        id: '2',
        label: 'Nedarbo laikas',

        items: listOfExcusesWithAction(
          close,
          listOfExcuses,
          createNewExcuse,
          activeCell,
        ),
      },
    ],
    [activeCell],
  );

  // Functions
  // const updatePendingOrders = (id) => {
  //   const found = appointments.state.find((item) => item.id === id);
  //   if (!found) return;

  //   const saved = structuredClone(found);

  //   dispatch(addPendingOrder(found));
  //   dispatch(deleteAppointment({ id: id }));

  //   cache.setState((prev) => [
  //     ...prev,
  //     {
  //       type: 'fromSchedulerToPending',
  //       order: saved,
  //     },
  //   ]);
  // };

  const isWorkingHours = useCallback((hours, workSchedule) => {
    const filtered = workSchedule.find((interval) => {
      let startTime = interval.split(',')[0];
      let endTime = interval.split(',')[1];

      return hours >= startTime && hours < endTime;
    });

    return !!filtered;
  }, []);

  const selectCell = useCallback(
    (time) => {
      const newDate = addTimeToDate(columnDay, time);
      toggleCell({
        id: employee.id,
        time: newDate,
        dateTimeLocal: DateTime.now()
          .setZone(sectorData.settings.timezoneIanaId)
          .set({
            year: newDate.getFullYear(),
            month: newDate.getMonth() + 1,
            day: newDate.getDate(),
            hours: newDate.getHours(),
            minutes: newDate.getMinutes(),
          })
          .toISO(),
      });
    },
    [currentDate, employee, sectorData],
  );

  const isSelected = (time) => {
    if (!activeCell) return;
    if (employee.id !== activeCell.id) return;
    const thisDate = addTimeToDate(currentDate, time);
    return thisDate.getTime() === activeCell.time.getTime();
  };

  const addTimeToDate = useCallback((date, time) => {
    const newDate = new Date(date);

    const hours = time.split(':')[0];
    const minutes = time.split(':')[1];

    newDate.setHours(hours, minutes, 0, 0);
    return newDate;
  }, []);

  const handleClickAway = useCallback((e, selected) => {
    if (!selected) return;
    if (e.target.id === 'more' || e.target.id === 'overlay') return;
    if (e.button === undefined) return;

    // if (e.target.id !== 'context-tree' || e.target.id === 'header-img') {
    //   toggleCell(undefined);
    // } else {
    toggleCell(undefined, true);
    // }
  }, []);

  const createNewExcuse = (excuse, activeCell) => {
    const newExcuse = {
      id: excuse.id + Math.floor(Math.random() * 999),
      excuseId: excuse.id,
      title: excuse.label,
      member: activeCell.id,
      roomId: 1,
      status: 'excuse',
      category: excuse.category,
      orders: [
        {
          startDate: new Date(activeCell.time),
          duration: 3600000, // hour
        },
      ],
    };

    toggleCell(null);
    // dispatch(addAppointment(newExcuse));
  };

  const changeColumnWidth = useCallback((container, appointments) => {
    const calcWidth = appointments * 22 + 40;
    const width = calcWidth >= 220 ? calcWidth : 0;
    cellWidth.set(() => width);
  }, []);

  const checkForToday = () => datesAreOnSameDay(currentDate, new Date());

  const toggleCell = useCallback((obj, onlyLocalState) => {
    setActiveCell(obj);

    if (onlyLocalState) return;

    dispatch(
      updateSchedule({
        cell: obj,
        ...(!personal && { selectedProfessional: obj ? { id: obj.id } : null }),
      }),
    );
  }, []);

  const handleNavigation = useCallback(() => setShouldNavigate(true), []);

  useEffect(() => {
    if (!container.width || !appointmentsInRow) return;

    changeColumnWidth(container.width, appointmentsInRow);
  }, [appointmentsInRow, container.width, changeColumnWidth]);

  return (
    <>
      <S.DataPanelColumn
        toggled={toggledEmployee.value}
        ref={containerRef}
        expand={cellWidth.value}
        columnWidth={columnWidth}
        hiddenHeader={(numberOfDays.visible > 1 && count > 0) || personal}
        stickyHeader={numberOfDays.visible > 1}
        numberOfDays={days}
        locale={locale}
        isActive={scrollIntoView}
        isInThePast={isInThePast}
        isWeekend={isWeekend}
        defaultColumnWidth={defaultColumnWidth}
      >
        <div className="collumn-body">
          {cellsData.map((item, i) => (
            <div
              key={i}
              ref={(node) =>
                type === SCHEDULE_TYPE.PERSONAL &&
                scrollIntoView &&
                item.hour === scrollToHour &&
                setScheduleScrollTargetNode(node)
              }
            >
              <DataPanelCell
                key={i}
                id={employee.id + '-' + count + '-' + i}
                label={item.text}
                isWorkingHours={isWorkingHours(item.text, workSchedule)}
                select={selectCell}
                selected={isSelected(item.text)}
                onAway={handleClickAway}
                contextTreeItems={contextTreeItems}
                doubleClickAction={handleNavigation}
              />
            </div>
          ))}
          {timeIndicator && checkForToday() && (
            <TimeIndicator
              value={timeIndicator}
              showBubble={numberOfDays.visible > 1}
            />
          )}
          <AppointmentsContainer
            id={employee.id + '--' + toIsoString(columnDay).split('T')[0]}
            sectorId={sectorId}
            loadingAppointments={loadingOrders}
            headerMenu={{
              state: toggledEmployee.value,
              setState: toggledEmployee.set,
            }}
            appointmentsInRow={{
              state: appointmentsInRow,
              setState: setAppointmentsInRow,
            }}
            globalOrders={globalOrders}
            refetchOrders={refetchOrders}
            employeeId={employee.id}
            columnDay={columnDay}
          />
        </div>
      </S.DataPanelColumn>
      {shouldNavigate && (
        <Navigate
          to={'/functions/daily-functions/client-search'}
          state={{
            toOrder: true,
            numberOfDays: numberOfDays.visible,
            searchParams: Object.fromEntries([...searchParams]),
            time: activeCell.time,
            dateTimeLocal: activeCell.dateTimeLocal,
            employee,
          }}
        />
      )}
    </>
  );
};

export default DataPanelColumn;
