import { useCallback, useEffect, useState } from 'react';

// icons
import { ArrowRevert, Check } from 'common/icons';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useMediaQuery } from 'hooks/useMediaQuery';

export const useChangesStatus = ({
  setButtons,
  hasAnyChanges,
  hasAnyErrors,
  setIsGoBackEnabled,
  onReset,
  onSubmit,
}) => {
  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 960px)');

  const handleReset = useCallback(() => {
    console.log('reset requested');
    onReset();
  }, [onReset]);

  const handleSubmit = useCallback(() => {
    console.log('submit requested');
    onSubmit();
  }, [onSubmit]);

  useEffect(() => {
    if (setButtons) {
      setButtons({
        type: 'setup',
        value: [
          {
            id: 'reset',
            action: handleReset,
            secondary: true,
            outline: true,
            inverted: true,
            icon: <ArrowRevert bold />,
            ...(isMobile ? { title: undefined } : { title: tr['reset'] }),
          },
          {
            id: 'save',
            action: handleSubmit,
            icon: <Check bold />,
            ...(isMobile ? { title: undefined } : { title: tr['save'] }),
          },
        ],
      });

      setButtons({ type: 'show', id: 'reset', value: hasAnyChanges() });
      setButtons({ type: 'show', id: 'save', value: hasAnyChanges() });
    }

    return () => setButtons({ type: 'cleanup' });
  }, [setButtons, isMobile, tr, handleReset, handleSubmit, hasAnyChanges]);

  // TODO: uncomment if "disabling 'Save' button on errors" functionality is necessary
  // useEffect(() => {
  //   if (setButtons && hasAnyErrors) {
  //     setButtons({
  //       type: 'update',
  //       id: 'save',
  //       value: { disabled: !!hasAnyErrors() },
  //     });
  //   }
  // }, [setButtons, hasAnyErrors]);
};
