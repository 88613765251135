import { useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// style
import * as S from './ServiceDetails.styled';

// components
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs';
import { SectorSelector } from '../SectorSelector/SectorSelector';
import { GeneralInfo } from '../GeneralInfo/GeneralInfo';
import { SetContents } from '../SetContents/SetContents';
import { ServiceGeneralData } from './ServiceGeneralData/ServiceGeneralData';
import { Loader } from 'components/Loader/Loader';
import { Overlay } from 'components/Overlay/Overlay';

// services
import { useSystemGetUnits } from 'services/systemService';

// hooks
import { MainContext } from 'pages/Main/Main';
import { useTranslate } from 'hooks/useTranslate';
import { useFormState } from 'hooks/useFormState';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { areArraysIdentical } from 'common/helpers/arrayFunctions';
import { areObjectsIdentical } from 'common/helpers/objectFunctions';
import {
  useServicesGetServiceById,
  useServicesUpdate,
} from 'services/serviceService';
import {
  getAncestors,
  getItem,
  getPath,
  hydrateServiceData,
  updateTree,
} from 'common/helpers/serviceFunctions';

export const ServiceDetails = ({
  service,
  activeItem,
  setActiveItem,
  treeData,
  setCategories,
  setButtons,
  sectors,
}) => {
  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 960px)');

  // in case of new service
  const item = service || getItem(treeData, activeItem.id, activeItem.type);

  // identifiers
  const isNew = activeItem.id === 'new';
  const isService = activeItem.type === 'service';
  const isSet = activeItem.type === 'set';
  const isNewSet = isNew && isSet;
  const isParentNone = [item?.groupId, item?.categoryId].includes(0);

  // data load handler
  const { data, isSuccess } = useServicesGetServiceById({
    id: activeItem.id,
    queryParams: {
      enabled: !isNew,
      refetchOnWindowFocus: false,
    },
  });
  // data save handler
  const {
    isSuccess: isMutationSuccess,
    isError: isMutationError,
    error: mutationError,
    isLoading: isMutationLoading,
    data: db,
    mutate,
  } = useServicesUpdate('service', {
    id: activeItem.id,
  });

  // units load handler
  const {
    data: units,
    isSuccess: isUnitsSuccess,
    getUnitsById,
  } = useSystemGetUnits();

  // state
  const [disabled, setDisabled] = useState(!isNew);
  const [changed, setChanged] = useState(false);

  const parsePeriod = (data) => [
    data?.replace(/[^0-9]/g, ''),
    data?.replace(/(\d)*(\.)?(\d)*/gm, ''),
  ];

  // configure required fields
  const setupFields = useCallback(() => {
    return [
      {
        id: 'name',
        level: 1,
        type: 'search',
        default: isSuccess && 'name' in data ? data.name : item.name,
      },
      {
        id: 'composition',
        level: 1,
        type: 'array',
        ...(isSuccess && 'composition' in data
          ? { default: { value: data.composition, label: data.composition } }
          : {}),
      },
      {
        id: 'sectors',
        level: 1,
        type: 'array',
        ...(isSuccess && data && 'sectors' in data
          ? { default: { value: data.sectors, label: data.sectors } }
          : isNew
            ? { default: { value: item.sectors, label: item.sectors } }
            : {}),
      },
      {
        id: 'type',
        level: 1,
        type: 'switch',
        options: [
          { value: 1, label: tr['duration'] },
          { value: 2, label: tr['volume'] },
        ],
        default:
          (isSuccess && 'baseDurationInMinutes' in data) ||
          !data ||
          activeItem?.id === 'new'
            ? { value: 1, label: tr['duration'] }
            : { value: 2, label: tr['volume'] },
      },
      {
        id: 'baseDurationInMinutes',
        level: 1,
        type: 'minutes',
        ...(isSuccess && 'baseDurationInMinutes' in data
          ? { default: data.baseDurationInMinutes }
          : { default: 0 }),
      },
      {
        id: 'baseBreakInMinutes',
        level: 1,
        type: 'minutes',
        ...(isSuccess && 'baseBreakInMinutes' in data
          ? { default: data.baseBreakInMinutes }
          : {}),
      },
      {
        id: 'unitId',
        level: 1,
        type: 'select',
        ...(isUnitsSuccess && { options: units }),
        ...(isUnitsSuccess && isSuccess && 'unitId' in data
          ? { default: getUnitsById(data.unitId) }
          : { default: { value: 0, label: tr['unit-0'] } }),
      },
      {
        id: 'unitVolume',
        level: 1,
        type: 'search',
        ...(isSuccess && 'unitVolume' in data
          ? { default: { value: +data.unitVolume, label: data.unitVolume } }
          : { default: { value: 1, label: 1 } }),
      },
      {
        id: 'smsPeriodUnitId',
        level: 1,
        type: 'switch',
        options: [
          { value: 'd', label: tr['days'] },
          { value: 'w', label: tr['weeks'] },
          { value: 'm', label: tr['months'] },
          { value: 'y', label: tr['years'] },
        ],
        default:
          isSuccess && 'smsPeriod' in data
            ? {
                value: parsePeriod(data.smsPeriod)[1],
                label:
                  tr[
                    ['years', 'months', 'weeks', 'days'].find(
                      (item) => item[0] === parsePeriod(data.smsPeriod)[1],
                    )
                  ],
              }
            : { value: 'd', label: tr['days'] },
      },
      {
        id: 'smsPeriod',
        level: 1,
        type: 'search',
        ...(isSuccess && 'smsPeriod' in data
          ? { default: parsePeriod(data.smsPeriod)[0] }
          : {}),
      },
      {
        id: 'isActive',
        level: 1,
        type: 'switch',
        options: [
          { value: true, label: tr['active'] },
          { value: false, label: tr['inactive'] },
        ],
        default:
          (isSuccess && data?.isActive) || item?.isActive
            ? { value: true, label: tr['active'] }
            : { value: false, label: tr['inactive'] },
      },
      {
        id: 'explodeOnAdd',
        level: 1,
        type: 'switch',
        options: [
          { value: 0, label: tr['add-as-set'] },
          { value: 1, label: tr['add-exploded'] },
          { value: 2, label: tr['ask-during-addition'] },
        ],
        default:
          isSuccess && 'explodeOnAdd' in data
            ? {
                value: data.explodeOnAdd || 0,
                label:
                  tr[
                    data.explodeOnAdd === 0 || !data.explodeOnAdd
                      ? 'add-as-set'
                      : data.explodeOnAdd === 1
                        ? 'add-exploded'
                        : 'ask-during-addition'
                  ],
              }
            : { value: 2, label: tr['ask-during-addition'] },
      },
    ];
  }, [
    isSuccess,
    data,
    tr,
    item,
    isNew,
    units,
    isUnitsSuccess,
    getUnitsById,
    activeItem,
  ]);

  // create form state
  const { state, options, resetState, resetOptions } =
    useFormState(setupFields());

  // update options after fetching
  useEffect(() => {
    isUnitsSuccess && resetOptions(setupFields());
  }, [isUnitsSuccess, resetOptions, setupFields]);

  // check if there are actual changes in state and set identifier accordingly
  useEffect(() => {
    if (!disabled && data) {
      const separateUnits = (data) => data?.match(/[a-zA-Z]+|[0-9]+/g);

      if (isSuccess) {
        const isChanged = Object.entries(state)
          .sort((a, b) => {
            const idA = a[0].toUpperCase();
            const idB = b[0].toUpperCase();
            if (idA > idB) return 1;
            if (idA < idB) return -1;
            return 0;
          })
          .some((entry) => {
            switch (true) {
              case entry[0] === 'smsPeriod':
                return (
                  separateUnits(data['smsPeriod'])?.[0] !==
                  entry[1].value?.value
                );

              case entry[0] === 'smsPeriodUnitId':
                if (!('smsPeriod' in data)) {
                  return 'd' !== entry[1].value?.value;
                } else {
                  return (
                    separateUnits(data['smsPeriod'])?.[1] !==
                    state['smsPeriodUnitId']?.value?.value
                  );
                }

              case entry[0] === 'type':
                const serviceType = 'unitId' in data ? 'material' : 'service';

                switch (entry[1].value?.value) {
                  case 1:
                    return serviceType !== 'service';
                  case 2:
                    return serviceType !== 'material';
                  default:
                    return false;
                }

              case entry[0] === 'composition':
                if (!data.hasComp) return false;

                return !areArraysIdentical(
                  entry[1].value?.value,
                  data[entry[0]],
                );

              case entry[0] === 'explodeOnAdd':
                if (!data.hasComp) return false;

                return String(data[entry[0]]) !== String(entry[1].value?.value);

              case entry[0] === 'baseDurationInMinutes':
              case entry[0] === 'baseBreakInMinutes':
                return (
                  state['type']?.value?.value === 1 &&
                  data[entry[0]] !== entry[1].value?.value
                );

              case entry[0] === 'unitId':
              case entry[0] === 'unitVolume':
                return (
                  state['type']?.value?.value === 2 &&
                  data[entry[0]] !== entry[1].value?.value
                );

              case !['type'].includes(entry[0]):
                if (Array.isArray(entry[1].value?.value)) {
                  return !areArraysIdentical(
                    entry[1].value?.value,
                    data[entry[0]],
                  );
                } else if (
                  typeof entry[1].value?.value === 'object' &&
                  entry[1].value?.value !== (null || undefined)
                ) {
                  return !areObjectsIdentical(
                    entry[1].value?.value,
                    data[entry[0]],
                  );
                } else {
                  return (
                    String(data[entry[0]]) !== String(entry[1].value?.value)
                  );
                }

              default:
                return false;
            }
          });

        setChanged(isChanged);
      }
    }
  }, [state, isNew, data, disabled, activeItem, isSuccess]);

  // if mutation was a success, update service
  useEffect(() => {
    if (isMutationSuccess) {
      const updatedObject = hydrateServiceData(db.data['service']);

      // update isEmpty status of parent
      const parent = getItem(
        treeData,
        updatedObject.subgroupId || updatedObject.groupId,
        Object.keys(updatedObject)
          .find((prop) => ['subgroupId', 'groupId'].includes(prop))
          .slice(0, -2),
      );
      if (parent) parent.isEmpty = false;

      // update item (assign id)
      updateTree(
        activeItem.id,
        activeItem.type,
        updatedObject,
        null,
        setCategories,
      );

      setActiveItem(
        isMobile ? undefined : { id: updatedObject?.id, type: activeItem.type },
      );
    }
  }, [isMutationSuccess, db, isMobile]);

  const exportObject = useCallback(() => {
    // validate SMSPeriod
    if (
      state['smsPeriod']?.value?.value &&
      !/^\d+$/.test(state['smsPeriod']?.value?.value)
    ) {
      toast.error(tr['service-smsperiod-invalid-range']);
      return undefined;
    }

    // validate unitVolume
    if ([undefined, null].includes(state['unitVolume']?.value?.value)) {
      toast.error(tr['unit-id-and-volume-are-required-for-material']);
      return undefined;
    }

    const processedObj = structuredClone({
      ...(item.id === 'new' ? item : data ? data : item),
    });

    console.log({ processedObj, item, data, state });

    processedObj['smsPeriod'] = state['smsPeriod']?.value?.value
      ? state['smsPeriod']?.value?.value +
        state['smsPeriodUnitId']?.value?.value
      : '0d';

    Object.entries(state).forEach((entry) => {
      if (
        entry[1].value?.value !== entry[1].default?.value &&
        !['smsPeriod', 'smsPeriodUnitId'].includes(entry[0])
      ) {
        processedObj[entry[0]] = entry[1].value?.value;
      }
    });

    if (processedObj.id === 'new') {
      delete processedObj.id;
    }

    if (
      processedObj.hasComp &&
      (!processedObj.composition || processedObj.composition.length < 2)
    ) {
      toast.error(tr['err-set-composition']);
      return undefined;
    }

    if (state['type']?.value?.value === 1) {
      if (!processedObj.baseDurationInMinutes) {
        processedObj.baseDurationInMinutes = 0;
      }
      delete processedObj.unitId;
      delete processedObj.unitVolume;
    } else if (state['type']?.value?.value === 2) {
      if (!('unitVolume' in processedObj) || !('unitId' in processedObj)) {
        processedObj.unitVolume = 1.0;
        processedObj.unitId = 0;
      } else processedObj.unitVolume = +processedObj.unitVolume;
      delete processedObj.baseDurationInMinutes;
      delete processedObj.baseBreakInMinutes;
    }
    delete processedObj.type;

    if (
      !!processedObj.smsPeriod &&
      !['y', 'm', 'w', 'd'].some((item) =>
        processedObj.smsPeriod.includes(item),
      )
    ) {
      processedObj.smsPeriod += processedObj.smsPeriodUnitId;
      delete processedObj.smsPeriodUnitId;
    }

    return processedObj;
  }, [data, state, item, tr]);

  const confirmChanges = useCallback(() => {
    const processedObject = exportObject();

    processedObject &&
      mutate(processedObject)?.then(() => {
        // disable Edit mode and reset change status only if saved to B/E
        setDisabled(true);
        setChanged(false);
      });
  }, [exportObject, mutate]);

  const resetChanges = useCallback(() => {
    resetState(setupFields());
    setDisabled(!isNew);
    setChanged(false);
  }, [resetState, setupFields, isNew]);

  // |- BUTTONS ->

  // 'edit' button show/hide logic
  useEffect(() => {
    if (setButtons) {
      setButtons({
        type: 'update',
        id: 'edit',
        value: { action: () => setDisabled(false) },
      });
      setButtons({
        type: 'update',
        id: 'cancel',
        value: { action: resetChanges },
      });
      setButtons({
        type: 'update',
        id: 'save',
        value: { action: confirmChanges },
      });
    }
  }, [setButtons, state, confirmChanges, resetChanges]);

  // 'edit' button show/hide logic
  useEffect(() => {
    if (setButtons) {
      setButtons({ type: 'show', id: 'edit', value: !isNew && disabled });
    }
  }, [setButtons, disabled, isNew]);

  // 'save'/'cancel' button show/hide logic
  useEffect(() => {
    if (setButtons) {
      setButtons({ type: 'show', id: 'cancel', value: changed });
      setButtons({ type: 'show', id: 'save', value: isNew ? true : changed });
    }
  }, [setButtons, changed, isNew]);

  // <- BUTTONS -|

  // reset state values if another group was selected
  const { setSearchQuery } = useContext(MainContext);
  useEffect(() => {
    (isSuccess || isNew) && resetChanges();
    isNewSet && setSearchQuery(undefined, 'services-modal'); // clear search phrase of set formation dialog in case of new set
  }, [activeItem, isNew, isNewSet, resetChanges, isSuccess, setSearchQuery]);

  const extractPath = (data) => {
    return data
      ? 'categoryName' in data && [
          ...[data?.categoryName],
          ...('groupName' in data ? [data.groupName] : []),
          ...('subgroupName' in data ? [data.subgroupName] : []),
        ]
      : undefined;
  };

  const breadcumbsFromObject = getPath(treeData, data || item);
  const breadcrumbsFromTree = extractPath(data);

  const breadcrumbs = (
    breadcumbsFromObject ||
    breadcrumbsFromTree || [
      tr['unassigned-to-category'],
      ...(service?.groupId === 0 ? [tr['ungrouped']] : []),
    ]
  )?.map((node, index) => {
    if (node === 'NONE') {
      if (index === 0) return tr['unassigned-to-category'];
      else if (index === 1) return tr['ungrouped'];
    }
    return node;
  });

  // logs
  // useEffect(() => console.log({ state }), [state]);
  // useEffect(() => console.log({ breadcrumbs }), [breadcrumbs]);
  useEffect(() => {
    isMutationError && console.log({ mutationError });
  }, [isMutationError, mutationError]);

  return (
    <S.ServiceDetails>
      <S.Container>
        <Breadcrumbs path={breadcrumbs} />

        <GeneralInfo
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          state={state?.['name']}
          disabled={{ state: disabled, setState: setDisabled }}
          changed={changed}
          confirmChanges={confirmChanges}
          resetChanges={resetChanges}
        />

        {isSet && (
          <SetContents
            state={state?.['composition']}
            disabled={disabled}
            breadcrumbs={breadcrumbs}
            name={state?.['name'].value?.value}
            getUnitsById={getUnitsById}
          />
        )}

        {(isService || isSet) && (
          <ServiceGeneralData
            state={state}
            options={options}
            disabled={disabled}
            isSet={isSet}
          />
        )}

        <SectorSelector
          data={state?.['sectors']}
          disabled={disabled}
          ancestors={getAncestors(item, treeData)}
          isService
          activeItem={activeItem}
          sectorDb={sectors}
          isParentNone={isParentNone}
        />

        <Overlay isVisible={isMutationLoading}>
          <Loader />
        </Overlay>
      </S.Container>
    </S.ServiceDetails>
  );
};
