import { fontFamilies } from 'common/fonts/fonts';
import styled from 'styled-components/macro';

export const Badge = styled.div`
  width: ${({ size }) => size + 'px'};
  height: ${({ size }) => size + 'px'};
  border-radius: 50%;
  background-color: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ contentSize }) => contentSize + 'px'};
  font-family: ${fontFamilies.family_SecondaryCondensed};
  font-weight: 700;

  svg {
    width: ${({ contentSize }) => contentSize + 'px !important'};
    height: ${({ contentSize }) => contentSize + 'px !important'};
  }
`;
