import * as S from './SVG.styled';

export default ({
  color,
  width = 30,
  height = 30,
  bold,
  black,
  inCircle,
  ...rest
}) => {
  let weight;

  if (bold) {
    weight = 700;
  } else if (black) {
    weight = 900;
  }

  let content;

  switch (weight) {
    case 900:
      content = inCircle ? (
        <path
          d="M 15,0 C 6.728145,0 0,6.728145 0,15 0,23.2719 6.728145,30 15,30 23.2719,30 30,23.2719 30,15 30,6.728145 23.2719,0 15,0 Z m 6.78315,20.05605 c 0.47685,0.47685 0.47685,1.25025 0,1.7271 -0.23835,0.2385 -0.5502,0.3576 -0.86505,0.3576 -0.31485,0 -0.6267,-0.1191 -0.86505,-0.3576 L 15,16.73025 9.94395,21.78315 c -0.238425,0.2385 -0.55023,0.3576 -0.86202,0.3576 -0.311805,0 -0.626655,-0.1191 -0.865095,-0.3576 -0.476865,-0.47685 -0.476865,-1.25025 0,-1.7271 L 13.269825,15 8.216835,9.94701 C 7.73997,9.470145 7.73997,8.6937 8.216835,8.216835 8.6937,7.73997 9.467085,7.73997 9.94395,8.216835 L 15,13.27287 20.05305,8.216835 c 0.47685,-0.476865 1.25325,-0.476865 1.7301,0 0.47685,0.476865 0.47685,1.25331 0,1.730175 L 16.73025,15 Z"
          fill="currentcolor"
          strokeWidth="1.5"
        />
      ) : (
        <path
          d="m 29.484513,0.51729349 c -0.687317,-0.68727573 -1.803495,-0.68727573 -2.490812,0 L 15.001054,12.509392 3.008438,0.51729349 c -0.687302,-0.69021267 -1.8034451,-0.69021267 -2.49075307,0 -0.69024658,0.68727721 -0.69024658,1.80336501 0,2.49064971 L 12.510348,14.999967 0.51768493,26.992201 c -0.6873095,0.687285 -0.6873095,1.80326 0,2.490544 0.34365399,0.343643 0.79598567,0.516889 1.24537727,0.516889 0.4493961,0 0.9017323,-0.173246 1.2453758,-0.516889 L 15.001054,17.490662 26.993701,29.482745 c 0.343658,0.343643 0.79607,0.516889 1.245481,0.516889 0.449411,0 0.901672,-0.173246 1.245331,-0.516889 0.687317,-0.687284 0.687317,-1.803259 0,-2.490544 L 17.491866,14.999967 29.484513,3.0079432 c 0.687317,-0.6872847 0.687317,-1.8033725 0,-2.49064971 z"
          fill="currentcolor"
          strokeWidth="1.5"
        />
      );

      break;
    case 700:
      content = inCircle ? (
        <>
          <path
            d="m 25.61115,4.3882642 c -5.8533,-5.8502279 -15.373245,-5.8502279 -21.223485,0 -5.850222,5.8502248 -5.850222,15.3702298 0,21.2235298 5.85024,5.85015 15.370185,5.85015 21.223485,0 5.8533,-5.8503 5.8503,-15.370245 0,-21.2235298 z M 6.118575,23.880794 c -4.899141,-4.896 -4.899141,-12.86553 0,-17.7616197 4.89609,-4.8960885 12.865575,-4.8960885 17.761725,0 4.896,4.8960897 4.896,12.8656197 0,17.7616197 -4.89615,4.89615 -12.865635,4.89615 -17.761725,0 z"
            fill="currentcolor"
            strokeWidth="1.5"
            transform="translate(0,-5.9327501e-4)"
          />
          <path
            d="m 20.73645,19.006244 -4.0092,-4.00929 4.0092,-4.00923 c 0.47715,-0.47706 0.47715,-1.2507747 0,-1.7308947 -0.477,-0.480135 -1.2507,-0.477075 -1.73085,0 L 14.99637,13.266044 10.98714,9.2568293 c -0.477075,-0.477075 -1.25079,-0.477075 -1.73091,0 -0.480135,0.47706 -0.477075,1.2507747 0,1.7308947 l 4.00923,4.00923 -4.00923,4.00929 c -0.477075,0.477 -0.477075,1.2507 0,1.73085 0.477075,0.48015 1.250775,0.477 1.73091,0 l 4.00923,-4.0092 4.00923,4.0092 c 0.477,0.477 1.2507,0.477 1.73085,0 0.48015,-0.47715 0.47715,-1.25085 0,-1.73085 z"
            fill="currentcolor"
            strokeWidth="1.5"
            transform="translate(0.00364314,0.00245629)"
          />
        </>
      ) : (
        <path
          d="M 16.73025,15 29.6424,2.087835 c 0.47685,-0.476865 0.47685,-1.250256 0,-1.7301825 -0.47685,-0.47686995 -1.2534,-0.47686995 -1.73025,0 L 15,13.269825 2.087835,0.3576525 c -0.476865,-0.47686995 -1.253313,-0.47686995 -1.7301825,0 -0.47686995,0.4768695 -0.47686995,1.2533175 0,1.7301825 L 13.269825,15 0.3576525,27.91215 c -0.47686995,0.47685 -0.47686995,1.25025 0,1.73025 C 0.5960865,29.88075 0.907887,30 1.222743,30 1.537605,30 1.849395,29.88075 2.087835,29.6424 L 15,16.73025 27.91215,29.6424 C 28.15065,29.88075 28.46235,30 28.7772,30 c 0.31485,0 0.6267,-0.11925 0.8652,-0.3576 0.47685,-0.47685 0.47685,-1.25025 0,-1.73025 z"
          fill="currentcolor"
          strokeWidth="1.5"
        />
      );

      break;
    default:
      content = inCircle ? (
        <>
          <path
            d="m 4.3878582,25.6122 c 5.8504658,5.85045 15.3738908,5.85045 21.2243418,0 5.85045,-5.850451 5.85045,-15.373876 0,-21.2243418 -5.850451,-5.8504774 -15.373876,-5.8504774 -21.2243418,0 -5.8504774,5.8504658 -5.8504774,15.3705908 0,21.2243418 z M 24.7098,5.2901382 c 5.3532,5.3530958 5.3532,14.0666108 0,19.4196618 -5.353051,5.3532 -14.066566,5.3532 -19.4196618,0 -5.35310868,-5.353051 -5.35310868,-14.063386 0,-19.4196618 5.3530958,-5.35310868 14.0666108,-5.35310868 19.4196618,0 z"
            fill="currentcolor"
            strokeWidth="1.5"
          />
          <path
            d="m 9.4699635,10.372244 4.6293605,4.629405 -4.6293605,4.6293 c -0.248685,0.2487 -0.248685,0.65355 0,0.90225 0.248685,0.2487 0.6535955,0.2487 0.9022805,0 l 4.629405,-4.6293 4.6293,4.6293 c 0.2487,0.2487 0.65355,0.2487 0.90225,0 0.2487,-0.2487 0.2487,-0.65355 0,-0.90225 l -4.6293,-4.6293 4.6293,-4.629405 c 0.2487,-0.248685 0.2487,-0.6535955 0,-0.9022805 -0.2487,-0.248685 -0.65355,-0.248685 -0.90225,0 l -4.6293,4.6293605 -4.629405,-4.6293605 c -0.248685,-0.248685 -0.6535955,-0.248685 -0.9022805,0 -0.248685,0.248685 -0.248685,0.6535955 0,0.9022805 z"
            fill="currentcolor"
            strokeWidth="1.5"
          />
        </>
      ) : (
        <path
          d="m 0.18761398,29.813719 c 0.12427816,0.12435 0.28679587,0.1881 0.44931357,0.1881 0.16251771,0 0.32503541,-0.06375 0.44931355,-0.1881 L 14.999029,15.901051 28.911786,29.813719 c 0.124351,0.12435 0.286801,0.1881 0.449401,0.1881 0.16245,0 0.3249,-0.06375 0.449251,-0.1881 0.24855,-0.248551 0.24855,-0.653251 0,-0.901801 L 15.89762,14.99916 29.813588,1.0863814 c 0.24855,-0.24855634 0.24855,-0.65325835 0,-0.90181467 -0.248551,-0.24855586 -0.653251,-0.24855586 -0.901802,0 L 14.999029,14.097359 1.0862411,0.18775424 c -0.24855629,-0.25174337 -0.6500708,-0.25174337 -0.89862712,0 -0.24855646,0.25174232 -0.25174307,0.65007082 0,0.89862716 L 14.100393,14.99916 0.18761398,28.911918 c -0.24855646,0.24855 -0.24855646,0.65325 0,0.901801 z"
          fill="currentcolor"
          strokeWidth="1.5"
        />
      );

      break;
  }

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
