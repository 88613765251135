import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import {
  dropdownRollIn,
  rotate180,
  rotate180Back,
} from 'common/styles/animations';

import styled from 'styled-components/macro';

export const CategoryTree = styled.ul`
  font-family: ${fontFamilies.family_Secondary};
  margin-block: 0;
  overflow: auto;
  padding: 0;

  ::-webkit-scrollbar {
    display: none;
  }

  .button-container {
    padding: 8.305px 10px;
  }

  .button-container button {
    background-color: ${colors.bg_grey_1};
    justify-content: left;
    font-family: ${fontFamilies.family_Secondary};
    font-size: 0.875rem;
    font-weight: 400;
    padding-inline: 20px;
    width: fit-content;
  }
`;

// export const CategoryTreeItem = styled.li`
//   animation: ${dropdownRollIn} 0.3s ease-in-out;
//   border-radius: 5px;
//   display: flex;
//   flex-shrink: 0;
//   font-size: 1rem;
//   gap: 10px;
//   justify-content: space-between;
//   /* padding: 10px 18px; */
//   padding: 10px;
//   transform-origin: top;

//   @media (max-width: 960px) {
//     padding-inline: 10px;
//   }

//   &.open .icon svg {
//     transform-origin: 60% 44%;
//     animation: ${rotate180} ease-in-out 0.3s both;
//   }

//   &.closed .icon svg {
//     animation: ${rotate180Back} ease-in-out 0.3s both;
//   }

//   &:hover {
//     background-color: lightgrey;
//   }

//   &:active,
//   &.active {
//     background-color: ${colors.bg_Primary};
//     color: ${({ isNew }) =>
//       isNew ? colors.disabledGrey : colors.text_Primary};

//     svg {
//       fill: ${({ isNew }) =>
//         isNew ? colors.disabledGrey : colors.text_Primary};
//     }

//     > div > div:first-child {
//       cursor: ${({ isNew, isSet, isEmpty }) =>
//         !(isNew || isSet || isEmpty) && 'pointer'};
//     }
//   }

//   &:not(.active) {
//     > div > div:first-child {
//       cursor: ${({ isEmpty, isSet }) => !(isSet || isEmpty) && 'pointer'};
//     }

//     > div > div:only-child,
//     > div > div:nth-child(2) {
//       cursor: pointer;
//     }
//   }

//   &.inactive {
//     color: ${colors.disabledGrey};
//   }

//   > div {
//     /* align-items: center; */
//     align-items: start;
//     display: flex;
//     gap: 10px;

//     > div > span:first-child {
//       font-weight: ${({ search }) => search && 'bold'};
//     }

//     > div:first-child {
//     }

//     > div:nth-child(2),
//     > div:only-child {
//       /* cursor: ${({ isNew }) => !isNew && 'pointer'}; */
//       display: -webkit-box;
//       overflow: hidden;
//       -webkit-box-orient: vertical;
//       -webkit-line-clamp: 2;
//     }

//     > div:last-child {
//       /* flex-shrink: 0; */
//     }
//   }

//   .icon {
//     -webkit-transform: rotate(90deg);
//     transform: rotate(90deg);
//     align-items: center;
//     /* cursor: ${({ isEmpty }) => !isEmpty && 'pointer'}; */
//     color: ${({ isEmpty }) => isEmpty && colors.disabledGrey};
//     display: flex;
//   }

//   .icon,
//   .functions {
//     padding-top: 2px;
//   }

//   svg {
//     flex-shrink: 0;
//     height: 16px;
//     width: 16px;
//     /* padding-bottom: 2px;
//     padding-left: 1px; */
//   }

//   &.category {
//     @media screen and (min-width: 959px) {
//       font-size: 1.125rem;
//     }
//     font-weight: 700;
//   }

//   &.group {
//     font-weight: 500;
//     /* margin-left: 26px; */
//     margin-left: ${({ noIndent }) => !noIndent && '26px'};
//   }

//   &.subgroup,
//   &.service {
//     /* margin-left: 52px; */
//     margin-left: ${({ noIndent }) => !noIndent && '52px'};
//   }

//   &.service,
//   &.set {
//     border: ${({ search, serviceAction }) =>
//       (search || serviceAction) && `1px solid ${colors.bg_grey_2}`};
//     margin-bottom: ${({ search, serviceAction }) =>
//       (search || serviceAction) && '5px'};

//     .item-info {
//       display: block;
//       overflow: unset;

//       > span:first-child {
//         font-weight: 600;
//       }

//       .path {
//         margin-left: 3px;
//       }
//     }

//     .item-info {
//       display: flex;
//       flex-direction: column;
//       gap: 5px;
//     }

//     .item-details {
//       align-items: center;
//       color: ${colors.bg_grey_3};
//       display: flex;
//       font-size: 0.688rem;
//       gap: 10px;
//       margin-top: 3px;

//       &-wrapper {
//         align-items: center;
//         display: flex;
//         gap: 5px;

//         &-label {
//           display: flex;

//           svg {
//             width: 13px;
//             height: 13px;
//           }
//         }

//         &-value {
//           align-items: center;
//           display: flex;
//           line-height: 1;
//         }
//       }
//     }
//   }

//   .context-menu-icon-container {
//     flex-shrink: 0;
//     height: 20px;
//     width: 20px;
//     /* align-items: center; */
//   }

//   &.action {
//     .functions {
//       align-self: center;
//       height: 20px;
//       padding: 0;
//       width: 20px;

//       svg {
//         height: 20px;
//         width: 20px;
//       }
//     }
//   }
// `;

export const CategoryTreeItem = styled.li`
  align-items: center;
  animation: ${dropdownRollIn} 0.3s ease-in-out;
  border-radius: 5px;
  display: flex;
  flex-shrink: 0;
  font-size: 1rem;
  gap: 10px;
  justify-content: space-between;
  padding: 10px;
  transform-origin: top;

  @media (max-width: 960px) {
    padding-inline: 10px;
  }

  &.open .icon svg {
    /* transform-origin: 60% 44%; */
    animation: ${rotate180} ease-in-out 0.3s both;
  }

  &.closed .icon svg {
    animation: ${rotate180Back} ease-in-out 0.3s both;
  }

  &:hover {
    background-color: lightgrey;
  }

  &:active,
  &.active {
    background-color: ${colors.bg_Primary};
    color: ${({ isNew }) =>
      isNew ? colors.disabledGrey : colors.text_Primary};

    svg {
      fill: ${({ isNew }) =>
        isNew ? colors.disabledGrey : colors.text_Primary};
    }

    > div > div:first-child {
      cursor: ${({ isNew, isSet, isEmpty }) =>
        !(isNew || isSet || isEmpty) && 'pointer'};
    }
  }

  &:not(.active) {
    > div > div:first-child {
      cursor: ${({ isEmpty, isSet }) => !(isSet || isEmpty) && 'pointer'};
    }

    > div > div:only-child,
    > div > div:nth-child(2) {
      cursor: pointer;
    }
  }

  &.inactive {
    color: ${colors.disabledGrey};
  }

  &.category {
    font-size: 1.125rem;
  }

  &.category,
  &.group {
    font-weight: 700;
  }

  &.group,
  &.subgroup {
    font-size: 1rem;
  }

  &.group {
    margin-left: ${({ noIndent }) => !noIndent && '26px'};
  }

  &.subgroup,
  &.service {
    margin-left: ${({ noIndent }) => !noIndent && '52px'};
  }

  &.service,
  &.set {
    border: ${({ search, serviceAction }) =>
      (search || serviceAction) && `1px solid ${colors.bg_grey_2}`};
    font-size: 0.875rem;
    margin-bottom: ${({ search, serviceAction }) =>
      (search || serviceAction) && '5px'};
  }

  > div {
    /* border: 1px solid green; */
  }

  .control-description-container {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .title-container {
      display: flex;
      gap: 10px;

      .icon,
      .set-icon {
        flex-shrink: 0;
        height: 16px;
        width: 16px;
      }

      .icon {
        align-items: center;
        color: ${({ isEmpty }) => isEmpty && colors.disabledGrey};
        display: flex;
        transform: rotate(90deg);
        transform-origin: center;
      }

      .icon svg,
      .set-icon svg {
        height: 16px;
        width: 16px;
      }

      .title {
      }
    }

    .title,
    .subtitle {
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: ${({ search }) => (search ? 1 : 2)};
    }

    .subtitle {
      font-size: 0.75rem;
      color: ${colors.bg_grey_3};
    }
  }

  &.category .title-container .icon {
    margin-top: 1px;
  }

  .context-menu-icon-container {
    flex-shrink: 0;
    /* height: 16px; */
    width: ${({ serviceAction }) => (serviceAction ? 20 : 16)}px;

    svg {
      height: 16px;
      width: 16px;
    }
  }

  // TODO: maybe possible to remove? refactor moving to .context-menu-icon-container
  &.action {
    /* align-items: center; */

    .functions {
      align-self: center;
      height: 20px;
      padding: 0;
      width: 20px;

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
`;
