import { useState, useEffect, useRef, useContext } from 'react';

// style
import * as S from './PendingOrderCard.styled';
import { colors } from 'common/colors/colors';

// icons
import { Copy, PaperPlaneSVG, TrashCan } from 'common/icons';

// components
import AppointmentsDetails from 'components/AppointmentCard/AppointmentsDetails/AppointmentsDetails';
import AppointmentsDetailsMobile from 'components/AppointmentCard/AppointmentsDetails/AppointmentsDetailsMobile/AppointmentsDetailsMobile';
import ContextTree from 'components/ContextTree/ContextTree';
import Avatar from 'components/Avatar/Avatar';
import Overlay from 'components/Overlay/Overlay';

// contexts
import { UserContext } from 'context/UserContext';

// hooks
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useStatusList } from 'hooks/useStatusList';
import { useObserver } from 'hooks/useObserver';
import useAppointmentCard from 'hooks/schedule/useAppointmentCard';
import useTranslate from 'hooks/useTranslate';
import { useContextMenuItems } from 'hooks/useContextMenuItems';
import { getDurationFromMinutes } from 'common/helpers/dateOperations';
import { fillImgPath } from 'common/helpers/imagesFunctions';

const PendingOrderCard = ({
  data,
  drag,
  id,
  index,
  hover,
  allowHover,
  width,
  isDragging,
}) => {
  const isMobile = useMediaQuery('(max-width: 960px)');
  const { locale } = useContext(UserContext);
  const currStatus = useStatusList(data.completionStatus.toLowerCase());
  const containerRef = useRef(null);
  let container = useObserver({
    ref: containerRef,
    debounceDelay: 10,
  });

  const [hoverTime, setHoverTime] = useState(hover);
  const [selected, setSelected] = useState(false);
  const [displayContextMenu, setDisplayContextMenu] = useState(false);
  const [displayDetails, setDisplayDetails] = useState(false);

  const handleClickAway = (e) => {
    if (isMobile) return;

    // functions.raiseHead(false);
    setDisplayContextMenu(false);
    // setFocusing(0);
    // setDraggable(false);
  };

  const {
    getBalanceActions,
    getStatusActions,
    getAppointemtFunctions,
    getFunctionList,
    isModalShowing,
    modalToggle,
    modalContent,
  } = useAppointmentCard({
    isPending: true,
    appointment: data,
    handleClickAway,
    setDisplayDetails,
  });

  useEffect(() => {
    setHoverTime(hover);
  }, [hover]);

  const handleClick = () => {
    if (!isMobile) return;

    setDisplayDetails(true);
  };

  const getTimeStamp = (utc) => {
    const utcDate = new Date(utc);

    return utcDate.toLocaleTimeString(locale, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
  };

  const getAppointmentDetailsData = () => ({
    id: data.sequenceNo,
    uuid: data.id,
    duration: getDurationFromMinutes(data.durationInMinutes),
    services: data.services,
    splits: data.splits,
    wantedEmployees: data.wantedEmployees || [],
    customer: {
      name: data.customer?.name,
      lastName: data.customer?.lastName,
    },
    balance: {
      amountCovered: data.amountCovered,
      toPay: data.finalAmountWithVat - data.amountCovered,
    },
    note: data?.note,
  });

  // comp
  const TooltipWrapper = (
    <S.Tooltip onMouseDown={(e) => e.stopPropagation()}>
      <AppointmentsDetails
        status={data.completionStatus}
        data={getAppointmentDetailsData()}
        type={'pending'}
        statusItems={getStatusActions}
        functionItems={getFunctionList}
        balanceItems={getBalanceActions}
      />
    </S.Tooltip>
  );

  const handleContextMenu = (e) => {
    e.preventDefault();

    // functions.raiseHead(true);
    setDisplayContextMenu(true);
    // setFocusing(1);
    // setDraggable(false);
  };

  return (
    <S.PendingOrderCard
      id={`pending-${id}`}
      onMouseDown={(e) =>
        !isMobile &&
        e.button !== 2 &&
        drag &&
        drag(
          e,
          index,
          id,
          setHoverTime,
          containerRef.current,
          getDurationFromMinutes(data.durationInMinutes),
          data,
        )
      }
      onClick={handleClick}
      data-index={index}
      ref={containerRef}
      index={index}
      width={width}
      height={container.height}
      color={currStatus?.background}
      selected={selected || isDragging}
      onContextMenu={handleContextMenu}
    >
      <div className="pendingOrder_info">
        <span className="pendingOrder_info-name">{`${
          data.customer?.name || 'Vardenis'
        } ${data.customer?.lastName || 'Pavardenis'}`}</span>

        <span className="pendingOrder_info-duration">
          {getDurationFromMinutes(data.durationInMinutes)}
        </span>

        <span className="pendingOrder_info-timeStamp">
          {getTimeStamp(data.lastUpdateTimeUtc)}
        </span>
      </div>
      <div className="pendingOrder_professionals">
        {data.wantedEmployees?.map((item, i) => {
          return i > 1 ? null : (
            <div className="pendingOrder_professionals-head" key={i}>
              <Avatar imagePath={fillImgPath(item.photoUrl)} />
            </div>
          );
        })}
      </div>
      {hoverTime && (
        <div className="hover-time">
          <span>{hoverTime}</span>
        </div>
      )}

      {!isMobile && (
        <ContextTree
          key={id}
          parentId={'schedule'}
          containerId={`pending-${id}`}
          openOnContext
          fixedWidth={300}
          content={TooltipWrapper}
          setCardSelected={setSelected}
          type="schedule-body-item"
          open={displayContextMenu}
        />
      )}

      {isMobile && displayDetails && (
        <AppointmentsDetailsMobile
          status={data.completionStatus}
          data={getAppointmentDetailsData()}
          type="pending"
          close={() => setDisplayDetails(false)}
          functions={getAppointemtFunctions()}
        />
      )}
      <Overlay isVisible={isModalShowing} hide={modalToggle}>
        {modalContent}
      </Overlay>
    </S.PendingOrderCard>
  );
};

export default PendingOrderCard;
