// style
import * as S from './SetService.styled';

// icons
import { Cube } from 'common/icons';

// components
import { ServiceStats } from '../ServiceStats/ServiceStats';

export const SetService = (props) => {
  const { service, getUnitsById, control, ...rest } = props;

  return (
    <S.SetService {...rest}>
      <div className="info">
        <div className="info__title">
          {service.hasComp && <Cube />}

          <div className="name">
            {service?.subgroupName && <span>{service.subgroupName}. </span>}
            {service?.name}
          </div>
        </div>

        {service && (
          <ServiceStats
            {...('baseDurationInMinutes' in service
              ? { duration: service.baseDurationInMinutes }
              : 'unitId' in service
                ? {
                    volume: service.unitVolume,
                    units: getUnitsById(service.unitId)?.label,
                  }
                : {})}
            price={service.avgUnitPriceWithVat}
            {...('compServiceCount' in service && {
              compServiceCount: service.compServiceCount,
            })}
          />
        )}
      </div>

      {control && control()}
    </S.SetService>
  );
};
