import * as S from './SVG.styled';

export default ({ color = 'currentColor', width = 20, height = 20 }) => {
  const content = (
    <path
      d="M 14.999985,0 C 6.72729,0 0,6.730275 0,15 0,23.26965 6.73026,30 14.999985,30 23.26965,30 30,23.26965 30,15 30,6.730275 23.26965,0 14.999985,0 Z M 26.62155,13.37157 H 21.4515 C 21.30315,9.98418 20.69505,6.69468 19.64505,4.22682 23.3349,5.822625 26.046,9.26043 26.62155,13.37157 Z M 14.999985,26.7402 c -0.86613,0 -2.89203,-3.5298 -3.185685,-10.1058 h 6.3744 C 17.892,23.2104 15.8691,26.7402 15.003,26.7402 M 11.81133,13.37157 c 0.293655,-6.576045 2.319555,-10.1058 3.185685,-10.1058 0.866085,0 2.891985,3.529755 3.185685,10.1058 H 11.80836 Z M 10.354965,4.22385 C 9.304935,6.69171 8.696835,9.98418 8.54853,13.3686 H 3.378495 c 0.57543,-4.11411 3.28653,-7.548945 6.97647,-9.14475 z M 3.378495,16.6344 H 8.54853 c 0.148305,3.3873 0.756405,6.6768 1.806435,9.14475 -3.68994,-1.59585 -6.40104,-5.0337 -6.97647,-9.14475 z m 16.266555,9.14475 c 1.05,-2.46795 1.6581,-5.75745 1.80645,-9.14475 h 5.17005 c -0.57555,4.11405 -3.28665,7.5489 -6.9765,9.14475 z"
      fill="currentcolor"
      strokeWidth="1.5"
    />
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
    >
      {content}
    </S.StyledSVG>
  );
};
