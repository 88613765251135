import { useContext, useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router';
import { DateTime } from 'luxon';

// icons
import { PaperPlaneSVG, TrashCan } from 'common/icons';

// components
import DialogLayout from 'components/DialogLayout/DialogLayout';
import FunctionBar from 'components/FunctionBar/FunctionBar';
import OrderItemsMobile from './partials/OrderMobile/OrderMobile';
import OrderLayout from 'layouts/OrderLayout/OrderLayout';
import Overlay from 'components/Overlay/Overlay';
import DialogBox from 'components/DialogBox/DialogBox';
import SelectProfessionals from 'components/DialogLayout/SelectProfessionals/SelectProfessionals';
import SelectionPanel from 'components/Overlay/SelectionPanel/SelectionPanel';
import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper';
import DialogPresets from 'components/DialogBox/DialogPresets/DialogPresets';

// contexts
import { OrderContext } from 'context/OrderContext';
import { MainContext } from 'pages/Main/Main';

// hooks
import { useOrderFunctions } from 'hooks/useOrderFunctions';
import useMediaQuery from 'hooks/useMediaQuery';
import useTranslate from 'hooks/useTranslate';
import useOrder from 'hooks/order/useOrder';
import useModal from 'hooks/useModal';
import {
  useEmployeesGet,
  useEmployeesGetMultiple,
} from 'services/employeeService';
import {
  useOrderDelete,
  useServicesGetOders,
  useServicesGetPrices,
} from 'services/orderService';
import {
  useServicesGetServiceById,
  useServicesGetServiceComposition,
} from 'services/serviceService';
import {
  generateNewService,
  modifyOrderCopy,
} from 'common/helpers/orderFunctions';
import {
  areObjectsIdentical,
  filterObjectByKeys,
} from 'common/helpers/objectFunctions';
import { ORDER_COMPLETION_STATUS } from 'common/constants';
import { colors } from 'common/colors/colors';

const objectEntriesToCompareForChange = [
  'id',
  'version',
  'sectorId',
  'startTimeUtc',
  'sendVisitReminder',
  'employeeId',
  'customerId',
  'durationInMinutes',
  'status',
  'wantedEmployeeIds',
  'savedProducts',
];

const SERVICE_MENU_HIDDING_BREAKPOINT = 1455;

const OrderContainer = () => {
  const isMobile = useMediaQuery('(max-width: 960px)');
  const tr = useTranslate().use().global;
  const { sectorData, requireSectorData } = useContext(MainContext);
  const { orderId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const getPrices = useServicesGetPrices();
  const { toggle, isShowing } = useModal();
  const orderBeingDeleted = useRef();
  const isNewOrder = orderId === 'new';
  const [showMenu, setShowMenu] = useState(
    window.innerWidth > SERVICE_MENU_HIDDING_BREAKPOINT,
  );
  const [emerging, setEmerging] = useState(false);
  const [searchProfessionalInput, setSearchProfessionalInput] = useState('');
  const [employeeId, setEmployeeId] = useState();
  const [wantedEmployeeIds, setWantedEmployeeIds] = useState([]);
  const [isWantedEmployeesDialogDisabled, setIsWantedEmployeesDialogDisabled] =
    useState(true);
  const [wantedEmployees, setWantedEmployees] = useState(null);
  const [employees, setEmployees] = useState();
  const [sectorId, setSectorId] = useState();
  const [client, setClient] = useState();
  const [selectedId, setSelectedId] = useState(null);
  const [footerData, setFooterData] = useState({
    totalDuration: 0,
    totalPrice: 0,
    priceToPay: 0,
    totalVat: 0,
    discount: 0,
  });
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [productsSaveCondition, setProductsSaveCondition] = useState(false);
  const [contextSaveFunc, setContextSaveFunc] = useState(
    () => () => console.log('default'),
  );
  const [contextSaveCond, setContextSaveCond] = useState(false);
  const [order, setOrder] = useState({
    id: '0000',
    savedProducts: [],
    status: ORDER_COMPLETION_STATUS.ORDERED,
    toggled: true,
    startTimeUtc: DateTime.now()
      .set({ seconds: 0, milliseconds: 0 })
      .toUTC()
      .toISO(),
    endTimeUtc: DateTime.now()
      .set({ seconds: 0, milliseconds: 0 })
      .toUTC()
      .toISO(),
    durationInMinutes: 0,
    amountBeforeDiscountWithVat: 0,
    finalAmountVat: 0,
    discountWithVat: 0,
    preciseDiscountWithVat: 0,
    finalAmountWithVat: 0,
    sendVisitReminder: true,
    note: null,
  });
  const [isFormDisabled, setIsFormDisabled] = useState(!isNewOrder);
  const [isDirty, setIsDirty] = useState(false);
  const [defaultFormValues, setDefaultFormValues] = useState({});
  const [lastOrderPricesWithDiscount, setLastOrderPricesWithDiscount] =
    useState({ services: {} });
  const [displayModal, setDisplayModal] = useState(null);
  const [modalSwitch, setModalSwitch] = useState('');
  const [wantedEmployeeSearchInput, setWantedEmployeeSearchInput] =
    useState('');
  const [compositionToSplit, setCompositionToSplit] = useState();
  const [plusFabAction, setPlusFabAction] = useState();

  const multiSelect = location.state?.multi === 'multi-selects';

  const {
    data: orderData,
    refetch,
    isFetchedAfterMount,
  } = useServicesGetOders(true, {
    id: orderId,
    queryParams: {
      retry: false,
      enabled: orderId !== 'new' && !orderBeingDeleted.current,
    },
  });

  const { saveOrder } = useOrder({
    sectorId,
    selectedEmployees: employees,
    order,
    multiSelect,
    // numberOfDays: location.state?.numberOfDays,
    location,
    orderId,
    refetch,
    setIsFormDisabled,
    setIsWantedEmployeesDialogDisabled,
  });
  const selectedServiceBE = useServicesGetServiceById({
    id: selectedId?.id,
    queryParams: { enabled: !!selectedId, refetchOnWindowFocus: false },
  });
  const { refetch: refetchCompositionToSplit, data: compositionToSplitData } =
    useServicesGetServiceComposition({
      compositionId: compositionToSplit?.id,
      searchParams: {
        sectorId,
        ...(employeeId && { employeeId }),
        ...(client?.id && { customerId: client.id }),
      },
      queryParams: {
        enabled: false,
      },
    });

  const { data: currentEmloyeeData, refetch: refetchEmployee } =
    useEmployeesGet({
      id: employeeId,
      searchParams: {
        sectorId: sectorId,
      },
      queryParams: {
        retry: false,
        enabled: !!employeeId && sectorId !== undefined,
      },
    });

  const wantedEmployeesData = useEmployeesGetMultiple(
    wantedEmployeeIds?.map((id) => ({
      id: id,
      queryParams: {
        retry: false,
        refetchOnWindowFocus: false,
      },
    })),
  );
  const {
    mutate: deleteOrderMutation,
    isSuccess: isOrderDeletionSuccess,
    isError: isOrderDeletionError,
  } = useOrderDelete();

  const modalButtons = {
    send: [
      {
        title: tr['send'],
        icon: <PaperPlaneSVG bold color={colors.bg_Secondary} />,
        action: () => {
          console.log('sms');
        },
      },
    ],
    delete: [
      {
        title: tr['delete'],
        icon: <TrashCan bold />,
        action: () => {
          orderBeingDeleted.current = true;
          deleteOrderMutation({
            id: orderId,
            searchParams: {
              version: order?.version,
            },
          });
        },
      },
    ],
  };

  const menuVisibilityListener = () => {
    if (showMenu && window.innerWidth < SERVICE_MENU_HIDDING_BREAKPOINT) {
      setShowMenu(false);
    } else if (
      !showMenu &&
      window.innerWidth > SERVICE_MENU_HIDDING_BREAKPOINT
    ) {
      setShowMenu(true);
    }
  };

  useEffect(() => {
    requireSectorData();
  }, []);

  useEffect(() => {
    window.removeEventListener('resize', menuVisibilityListener);
    window.addEventListener('resize', menuVisibilityListener);
  }, [showMenu]);

  useEffect(() => {
    setIsDirty(() => {
      if (isNewOrder) {
        return !!order.savedProducts.length;
      } else if (
        Object.keys(order).length &&
        Object.keys(defaultFormValues).length
      ) {
        return !areObjectsIdentical(
          filterObjectByKeys(
            defaultFormValues,
            objectEntriesToCompareForChange,
          ),
          filterObjectByKeys(order, objectEntriesToCompareForChange),
        );
      }
    });
  }, [order]);

  const fetchingAtLeastOne =
    wantedEmployeesData.find((item) => item.isFetching) !== undefined;

  useEffect(() => {
    if (!wantedEmployeeIds.length) {
      setWantedEmployees(null);
    } else if (wantedEmployeesData[0]?.isSuccess) {
      if (wantedEmployeesData.length) {
        const newWantedEmployees = [];

        for (let context of wantedEmployeesData) {
          if (context?.data?.employee) {
            newWantedEmployees.push(context.data.employee);
          }
        }

        if (newWantedEmployees?.length) {
          setWantedEmployees(newWantedEmployees);
        }
      }
    }
  }, [wantedEmployeesData.length, fetchingAtLeastOne]);

  useEffect(() => {
    setOrder((prev) => ({
      ...prev,
      wantedEmployeeIds: wantedEmployees?.map((employee) => employee.id),
    }));
  }, [wantedEmployees]);

  useEffect(() => {
    if (order.status === ORDER_COMPLETION_STATUS.CONFIRMED) {
      setIsWantedEmployeesDialogDisabled(true);
    }
  }, [order.status]);

  // Set employeeId in case of new order creation
  useEffect(() => {
    if (orderId === 'new') {
      setEmployeeId(location?.state?.employee?.id ?? null);
      setWantedEmployees(location?.state?.wantedEmployees ?? []);
      setIsWantedEmployeesDialogDisabled(false);

      if (location?.state?.searchParams?.sectorId) {
        setSectorId(location.state.searchParams.sectorId);
      }

      if (location?.state?.selectedClient) {
        const customer = location?.state?.selectedClient;
        setClient({
          ...customer,
          firstName: customer?.name,
        });

        setOrder((prev) => {
          return {
            ...prev,
            customer,
          };
        });
      }

      if (
        sectorData?.settings?.timezoneIanaId &&
        location?.state?.dateTimeLocal
      ) {
        const startDateTime = DateTime.fromISO(location.state.dateTimeLocal, {
          zone: sectorData.settings.timezoneIanaId,
        });

        setOrder((prev) => {
          return {
            ...prev,
            startTimeUtc: startDateTime
              .set({ seconds: 0, milliseconds: 0 })
              .toUTC()
              .toISO(),
            endTimeUtc: startDateTime
              .set({ seconds: 0, milliseconds: 0 })
              .toUTC()
              .toISO(),
            durationInMinutes: 0,
          };
        });
      }
    }
  }, [sectorData?.settings?.timezoneIanaId]);

  useEffect(() => {
    if (sectorData?.settings?.timezoneIanaId) {
      setOrder((prev) => ({
        ...prev,
        timezoneIanaId: sectorData.settings.timezoneIanaId,
      }));
    }
  }, [sectorData?.settings?.timezoneIanaId]);

  // Set employeeId in case of existing order edit
  useEffect(() => {
    if (
      orderData &&
      sectorData?.settings?.timezoneIanaId &&
      isFetchedAfterMount
    ) {
      setEmployeeId(orderData?.order?.employeeId ?? null);
      setWantedEmployees(orderData?.order?.wantedEmployees ?? []);
      setIsWantedEmployeesDialogDisabled(false);

      if (orderData?.order?.sectorId) {
        setSectorId(orderData.order.sectorId);
      }

      if (orderData?.order?.customer) {
        const customer = orderData?.order?.customer;
        setClient({
          ...customer,
          firstName: customer?.name,
        });
      }

      if (orderData?.order) {
        const actualOrder = orderData.order;
        const startDateTime = DateTime.fromISO(actualOrder.startTimeLocal, {
          zone: sectorData.settings.timezoneIanaId,
        });

        const order = {
          ...actualOrder,
          endTimeUtc: startDateTime
            .plus({ minutes: actualOrder.durationInMinutes })
            .toUTC()
            .toISO(),
          id: actualOrder.sequenceNo,
          wantedEmployeeIds: orderData?.order?.wantedEmployees.map(
            (employee) => employee.id,
          ),
          savedProducts: [],
          status: actualOrder.completionStatus.toLowerCase(),
          toggled: true,
          preciseDiscountWithVat: 0,
          timezoneIanaId: sectorData.settings.timezoneIanaId,
        };

        const updatedOrder = actualOrder.services.reduce(
          (accumulatorOrder, service) => {
            accumulatorOrder.preciseDiscountWithVat += service.discountWithVat;

            return addToOrder(service, false, accumulatorOrder);
          },
          order,
        );

        setOrder(updatedOrder);
        setDefaultFormValues(updatedOrder);
        setIsFormDisabled(false);
      }
    }
  }, [orderData, sectorData, isFetchedAfterMount]);

  useEffect(() => {
    if (employeeId) {
      refetchEmployee();
    }
  }, [employeeId]);

  useEffect(() => {
    if (currentEmloyeeData?.employee) {
      setEmployees([currentEmloyeeData.employee]);
    }
  }, [currentEmloyeeData]);

  useEffect(() => {
    if (!selectedServiceBE.data) return;

    addToOrder(selectedServiceBE.data);
  }, [selectedServiceBE.data]);

  useEffect(() => {
    if (sectorData?.settings?.timezoneIanaId) {
      const startDateTime = DateTime.fromISO(order.startTimeUtc, {
        zone: sectorData.settings.timezoneIanaId,
      });
      const startOfAnotherDay = startDateTime.plus({ days: 1 }).startOf('day');
      let durationInMinutes = 0;

      if (order.savedProducts.length) {
        durationInMinutes = getDuration(order.savedProducts);
      }

      const endDateTime = startDateTime.plus({ minutes: durationInMinutes });

      setOrder((prev) => ({
        ...prev,
        multipleDaysSpanWarning: endDateTime > startOfAnotherDay,
      }));
    }
  }, [
    order.durationInMinutes,
    sectorData?.settings?.timezoneIanaId,
    orderData?.order,
  ]);

  useEffect(() => {
    if (getPrices.isSuccess) {
      const orderCopy = structuredClone(order);
      const servicesCopy = structuredClone(order.savedProducts);
      const data = getPrices.data.data;
      const calcPrices = data.services;

      // setLastOrderPricesWithDiscount((prev) => ({
      //   ...prev,
      //   discountWithVat: data.discountWithVat,
      // }));
      let orderPricesServiceItemIndex = 0;
      setOrder(() => {
        orderCopy.savedProducts = servicesCopy.map((service, i) => {
          if (service.serviceId === 1) {
            return service;
          } else {
            setLastOrderPricesWithDiscount((prev) => ({
              ...prev,
              services: {
                ...prev.services,
                [service.id]: service.discountWithVat,
              },
            }));

            return {
              ...service,
              ...calcPrices[orderPricesServiceItemIndex++],
            };
          }
        });

        const startDateTime = DateTime.fromISO(orderCopy.startTimeUtc, {
          zone: sectorData.settings.timezoneIanaId,
        });

        const servicesForDurationCalculation = structuredClone(
          orderCopy.savedProducts,
        );
        let durationInMinutes = 0;

        if (servicesForDurationCalculation.length) {
          const lastIsBreak =
            servicesForDurationCalculation.at(-1).serviceId === 1;

          if (lastIsBreak) servicesForDurationCalculation.pop();

          durationInMinutes = getDuration(servicesForDurationCalculation);
          if (durationInMinutes < 0) {
            durationInMinutes = 0;
          }
        }

        let endDateTime = startDateTime.plus({ minutes: durationInMinutes });
        const startOfAnotherDay = startDateTime
          .plus({ days: 1 })
          .startOf('day');

        if (endDateTime > startOfAnotherDay) {
          endDateTime = startOfAnotherDay;

          durationInMinutes = startOfAnotherDay
            .diff(startDateTime, ['minutes'])
            .toObject()
            .minutes.truncate(0);
        }

        const updatedFields = {
          endTimeUtc: endDateTime.toUTC().toISO(),
          durationInMinutes,
          amountBeforeDiscountWithVat: data.amountBeforeDiscountWithVat,
          finalAmountVat: data.finalAmountVat,
          discountWithVat: data.discountWithVat,
          preciseDiscountWithVat: data.services.reduce(
            (accumulatorPreciseDiscount, service) =>
              accumulatorPreciseDiscount + service.discountWithVat,
            0,
          ),
          finalAmountWithVat: data.finalAmountWithVat,
        };

        return { ...orderCopy, ...updatedFields };
      });
    } else if (getPrices.isError) {
      setOrder((prev) => {
        const savedProducts = structuredClone(prev.savedProducts);
        savedProducts.map((item) => {
          if (item.id in lastOrderPricesWithDiscount.services) {
            item.discountWithVat =
              lastOrderPricesWithDiscount.services[item.id];
          }

          return item;
        });

        return { ...prev, savedProducts };
      });
    }
  }, [getPrices.isSuccess, getPrices.isError, getPrices.data]);

  useEffect(() => {
    if (isOrderDeletionSuccess) {
      exitOrderCreation();
    }
  }, [isOrderDeletionSuccess]);

  useEffect(() => {
    if (isOrderDeletionError) {
      orderBeingDeleted.current = false;
    }
  }, [isOrderDeletionError]);

  useEffect(() => {
    const orderCopy = structuredClone(order);

    setFooterData({
      totalDuration: orderCopy.durationInMinutes,
      totalPrice: orderCopy.amountBeforeDiscountWithVat,
      priceToPay: orderCopy.finalAmountWithVat,
      totalVat: orderCopy.finalAmountVat,
      discount: orderCopy.discountWithVat,
    });
  }, [order]);

  useEffect(() => {
    setSearchProfessionalInput('');

    switch (modalSwitch) {
      case 'select-employee':
        setDisplayModal(
          <ConditionalWrapper
            condition={isMobile}
            wrapper={(children) => (
              <SelectionPanel
                key={'select-professional'}
                closeAction={handleCloseAction}
                headerTitle={tr['choose-specialist']}
                searchTitle={tr['search']}
                arrayFilter={setWantedEmployeeSearchInput}
              >
                {children}
              </SelectionPanel>
            )}
            secondaryWrapper={(children) => (
              <DialogBox
                headerText={tr['choose-specialist']}
                close={toggle}
                cancelOverflow
              >
                {children}
              </DialogBox>
            )}
          >
            <SelectProfessionals
              select={(employees) => {
                setWantedEmployees(employees);
                handleCloseAction();
              }}
              selectedEmployess={wantedEmployees}
              calendarView={'i'} //TODO: should be by data from calendar?
              sectorId={sectorId}
              multi
              confirmationText={tr['choose']}
              searchInput={wantedEmployeeSearchInput}
            />
          </ConditionalWrapper>,
        );

        break;
      case 'send-sms-link':
        setDisplayModal(
          <DialogPresets
            className="modal-wrapper"
            key="send-sms-link"
            preset="smsLink"
            close={handleCloseAction}
            buttons={modalButtons.send}
          />,
        );
        break;
      case 'send-sms-reminder':
        setDisplayModal(
          <DialogPresets
            className="modal-wrapper"
            key="send-sms-reminder"
            preset="smsReminder"
            close={handleCloseAction}
            buttons={modalButtons.send}
          />,
        );
        break;
      case 'delete-order':
        setDisplayModal(
          <DialogPresets
            className="modal-wrapper"
            key="delete-order"
            preset="deleteOrder"
            close={handleCloseAction}
            buttons={modalButtons.delete}
          />,
        );
        break;
      default:
        return;
    }
  }, [modalSwitch, wantedEmployeeSearchInput, wantedEmployees]);

  useEffect(() => {
    if (compositionToSplit) {
      refetchCompositionToSplit();
    }
  }, [compositionToSplit]);

  useEffect(() => {
    if (!!compositionToSplitData?.services?.length) {
      addToOrder(compositionToSplitData?.services, null, null, false);
      setCompositionToSplit(undefined);
    }
  }, [compositionToSplitData]);

  const addToOrder = (service, override, orderFromBE) => {
    if (!service) return;
    if (service?.toSplit) {
      setCompositionToSplit(service);

      return;
    }

    const breakId = 1;
    const orderToModify = orderFromBE || order;
    let updatedOrder;

    if (Array.isArray(service)) {
      const arr = [];
      service.forEach((item) => {
        const el = item.id === 1 ? item : generateNewService(item);
        if (el) arr.push(el);
      });
      updatedOrder = modifyOrderCopy(orderToModify, arr, override);
    } else {
      const newService = generateNewService(service);
      updatedOrder = modifyOrderCopy(orderToModify, newService, override);
    }

    setIsFormDisabled(false);

    if (!updatedOrder) return;

    setOrder(updatedOrder);

    setLastOrderPricesWithDiscount((prev) => {
      const services = structuredClone(prev.services);

      services[service.serviceId] = service.discountWithVat;

      return { ...prev, services: services };
    });

    if (!orderFromBE) {
      calculateOrderPrices(updatedOrder.savedProducts);
    }

    setSelectedId(null);

    return updatedOrder;
  };

  const getDuration = (services) =>
    services.reduce(
      (acc, curr) => acc + (curr.unitDurationInMinutes * curr.quantity || 0),
      0,
    );

  const calculateOrderPrices = (services) => {
    const updatedServices = services
      .filter((item) => item.serviceId !== 1)
      .map((service) => {
        const keyNeeded = [
          'serviceId',
          'quantity',
          'unitPriceWithVat',
          'discountWithVat',
        ];

        return {
          ...Object.fromEntries(
            Object.entries(service).filter(([key]) => keyNeeded.includes(key)),
          ),
          ...(service.vatPercent && { vatPercent: service.vatPercent }),
        };
      });

    getPrices.mutate(updatedServices);
  };

  const updateServiceKey = (keyValue, index, skipPriceCalculation) => {
    const decomposedKeyValue = Object.entries(keyValue)[0];
    const key = decomposedKeyValue[0];
    const value = decomposedKeyValue[1];
    if (order.savedProducts[index][key] === value) {
      return;
    }

    const orderCopy = structuredClone(order);
    const serviceId = orderCopy.savedProducts.find(
      (service, i) => i === index,
    ).id;
    const newServices = orderCopy.savedProducts.map((service, i) => {
      if (
        i === index ||
        (key === 'smsPeriodCustomer' && service.id === serviceId)
      ) {
        return { ...service, ...keyValue };
      }
      return service;
    });
    setOrder(() => {
      return { ...orderCopy, savedProducts: newServices };
    });

    if (!skipPriceCalculation) {
      calculateOrderPrices(newServices);
    }
  };

  const saveDiscount = (discount) => {
    const orderCopy = structuredClone(order);
    orderCopy.savedProducts = orderCopy.savedProducts.map((item, index) => ({
      ...item,
      customItemIndex: index,
    }));
    const seriviceDiscounsts = {};
    const precentageDiscountForEveryItem = discount.divideBy(
      orderCopy.amountBeforeDiscountWithVat,
    );

    const servicesWithPrice = orderCopy.savedProducts
      ?.filter((service) => service.amountBeforeDiscountWithVat)
      ?.sort((a, b) => {
        if (
          a.discountWithVat.multiply(a.quantity) >
          b.discountWithVat.multiply(b.quantity)
        ) {
          return -1;
        } else if (
          a.discountWithVat.multiply(a.quantity) <
          b.discountWithVat.multiply(b.quantity)
        ) {
          return 1;
        } else {
          return 0;
        }
      });

    let serviceDiscountAmountsSum = 0;
    let leftoverDiscount = 0;

    for (let i in servicesWithPrice) {
      const service = servicesWithPrice[i];

      if (servicesWithPrice.length - 1 === Number(i)) {
        const serviceDiscount = discount.minus(serviceDiscountAmountsSum);
        const truncatedServiceDiscount = serviceDiscount.truncate(4);

        if (truncatedServiceDiscount > service.amountBeforeDiscountWithVat) {
          seriviceDiscounsts[service.customItemIndex] =
            service.amountBeforeDiscountWithVat;
          leftoverDiscount = truncatedServiceDiscount.minus(
            service.amountBeforeDiscountWithVat,
          );
        } else {
          seriviceDiscounsts[service.customItemIndex] =
            truncatedServiceDiscount;
        }
      } else {
        const serviceDiscount = service.amountBeforeDiscountWithVat.multiply(
          precentageDiscountForEveryItem,
        );

        seriviceDiscounsts[service.customItemIndex] =
          serviceDiscount.truncate(4);
        serviceDiscountAmountsSum +=
          seriviceDiscounsts[service.customItemIndex];
      }
    }

    if (leftoverDiscount) {
      for (let service of servicesWithPrice) {
        const serviceDiscount = seriviceDiscounsts[service.customItemIndex];

        if (
          serviceDiscount + leftoverDiscount >
          service.amountBeforeDiscountWithVat
        ) {
          let avilableLeftoverDiscount =
            service.amountBeforeDiscountWithVat.minus(serviceDiscount);
          leftoverDiscount = leftoverDiscount.minus(avilableLeftoverDiscount);
          seriviceDiscounsts[service.customItemIndex] =
            service.amountBeforeDiscountWithVat;
        } else {
          seriviceDiscounsts[service.customItemIndex] +=
            leftoverDiscount.truncate(4);
          leftoverDiscount = 0;
        }
      }
    }

    const updatedServices = orderCopy.savedProducts.map((service) => {
      if (service.unitPriceWithVat === undefined) return service;

      const updatedService = {
        ...service,
        discountWithVat: seriviceDiscounsts[service.customItemIndex],
      };

      delete updatedService.customItemIndex;

      return updatedService;
    });

    setOrder({
      ...orderCopy,
      savedProducts: updatedServices,
      preciseDiscountWithVat: Number(discount),
    });

    calculateOrderPrices(updatedServices);
  };

  const deleteService = (index) => {
    const orderCopy = structuredClone(order);

    const serviceToDelete = orderCopy.savedProducts.find(
      (el, i) => i === index,
    );

    setLastOrderPricesWithDiscount((prev) => {
      delete prev.services[serviceToDelete.id];

      return prev;
    });
    let hasBreak = !!serviceToDelete.breakInMinutes;

    orderCopy.savedProducts.map((service, index) => {
      const nextService = orderCopy.savedProducts[index + 1];
      if (
        nextService &&
        service.id === serviceToDelete.id &&
        nextService.id === 1
      ) {
        hasBreak = true;
      }
    });

    console.log(serviceToDelete);
    console.log(hasBreak);

    const filteredServices = orderCopy.savedProducts.filter((serv, i) => {
      return hasBreak ? i !== index && i !== index + 1 : i !== index;
    });

    orderCopy.savedProducts = filteredServices;

    if (orderCopy.savedProducts.length && orderCopy.savedProducts[0].id === 1) {
      orderCopy.savedProducts.shift();
    }

    if (!orderCopy.savedProducts.length) {
      setIsFormDisabled(true);
    }
    // console.log(orderCopy.savedProducts);

    setOrder(orderCopy);
    calculateOrderPrices(orderCopy.savedProducts);
  };

  const selectEmployee = (employee) => {
    if (multiSelect) {
      setEmployees((prev) => {
        const alreadyExist = prev.find((obj) => obj.id === employee.id);

        return alreadyExist
          ? prev.filter((obj) => obj.id !== employee.id)
          : employees.length === 3
            ? prev
            : [...prev, employee];
      });
    } else {
      setEmployees([employee]);
      setEmerging(false);
    }

    setSearchProfessionalInput('');
  };

  const employeeSelected = (id) => {
    return employees?.find((obj) => obj.id === id);
  };

  const exitOrderCreation = () => {
    let pathname = '/functions/daily-functions/calendar/day';

    switch (location.state?.numberOfDays) {
      case 0:
        pathname = '/functions/daily-functions/calendar/day';

        break;
      case 7:
        pathname = '/functions/daily-functions/calendar/week';

        break;
      // case 30:
      // pathName = '/functions/daily-functions/calendar/month';
    }

    const navigateObj = { pathname };

    const params = new URLSearchParams(location?.state?.searchParams);

    if (params.size) {
      navigateObj.search = params.toString();
    }

    navigate(navigateObj);
  };

  const handleCloseAction = () => {
    setWantedEmployeeSearchInput('');
    setModalSwitch(null);
    setDisplayModal(null);
    toggle();

    return;
  };

  const toggleWantedEmployeesSelection = isWantedEmployeesDialogDisabled
    ? null
    : () => {
        setModalSwitch('select-employee');
        toggle();
      };

  const orderContext = {
    currOrder: { order, setOrder },
    order: {
      state: order,
      setState: setOrder,
    },
    saveFunc: { contextSaveFunc, setContextSaveFunc },
    saveCond: { contextSaveCond, setContextSaveCond },
    products: {
      selectedProducts,
      setSelectedProducts,
      productsSaveCondition,
      setProductsSaveCondition,
    },
    professional: employees?.[0],
    footerData,
    orderId,
    client,
    sectorId,
    isDirty,
    isFormDisabled,
    employeeId,
    wantedEmployees,
    isNewOrder,
    setIsFormDisabled,
    saveOrder,
    saveDiscount,
    deleteService,
    updateServiceKey,
    addToOrder,
    refetch,
    toggleWantedEmployeesSelection,
    setPlusFabAction,
  };

  const { functionBarFunctions } = useOrderFunctions({
    orderId,
    order,
    isDirty,
    isNewOrder,
    setOrder,
    setModalSwitch,
    modalToggle: toggle,
    setDefaultFormValues,
  });

  return (
    <OrderContext.Provider value={orderContext}>
      <OrderLayout
        isMobile={isMobile}
        showMenu={{ state: showMenu, setState: setShowMenu }}
        emerging={{ state: emerging, setState: setEmerging }}
        employees={employees}
        customerId={order?.customer?.id ?? null}
        employeeId={employeeId}
        wantedEmployees={wantedEmployees}
        search={{
          state: searchProfessionalInput,
          setState: setSearchProfessionalInput,
        }}
        employeeSelected={employeeSelected}
        selectEmployee={selectEmployee}
        multiSelect={multiSelect}
        toggleWantedEmployeesSelection={toggleWantedEmployeesSelection}
      >
        <DialogLayout
          headerText={`${client?.firstName || ''} ${client?.lastName || ''}`}
          exitFunc={exitOrderCreation}
          darkTheme
          {...(!isMobile && {
            // getBack: () =>
            //   navigate('../', {
            //     state: location.state,
            //   }),
            navSticky: true,
            menuBurger: {
              state: !showMenu,
              toggle: () => setShowMenu(!showMenu),
            },
          })}
        >
          <FunctionBar
            functions={functionBarFunctions}
            plusAction={plusFabAction}
            fixed
            {...(order.savedProducts.length && { fabMarginY: 120 })}
          />
          {isMobile ? (
            <OrderItemsMobile
              setDisplayModal={setDisplayModal}
              modalToggle={toggle}
              modalIsShowing={isShowing}
            />
          ) : (
            <Outlet
              context={{
                setDisplayModal: setDisplayModal,
                modalToggle: toggle,
                modalIsShowing: isShowing,
              }}
            />
          )}
        </DialogLayout>
      </OrderLayout>
      <Overlay isVisible={isShowing} hide={handleCloseAction}>
        {displayModal}
      </Overlay>
    </OrderContext.Provider>
  );
};

export default OrderContainer;
