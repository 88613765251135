import { useContext, useLayoutEffect } from 'react';
import { ClickAwayListener } from 'react-advanced-click-away';

// style
import * as S from './OrderNote.styled';

// icons
import { Check, Ex, MessageSVG } from 'common/icons';

// images

// components
import ButtonSquare from 'components/Buttons/ButtonSquare';

// contexts
import { OrderContext } from 'context/OrderContext';

// hooks
import { useOrderNoteStatus } from 'hooks/useOrderNoteStatus';
import useTranslate from 'hooks/useTranslate';

const OrderNote = () => {
  const tr = useTranslate().use().global;
  const {
    inputRef,
    isNoteDisabled,
    isEditingNote,
    inputValue,
    setInputValue,
    setIsEditingNote,
    handleClickAway,
    handleSubmit,
    handleReset,
  } = useOrderNoteStatus();
  const { order, isNewOrder } = useContext(OrderContext);

  useLayoutEffect(() => {
    if (inputRef?.current) {
      inputRef.current.style.height = 'inherit';
      inputRef.current.style.height = inputRef.current.scrollHeight + 1 + 'px';
    }
  }, [inputRef.current?.scrollHeight, inputValue, isEditingNote]);

  return (
    <S.OrderNote disabled={isNoteDisabled} editing={isEditingNote}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className="content">
          {(isNewOrder || !isEditingNote) && (
            <div className="icon-container">
              <MessageSVG />
            </div>
          )}
          {!isNewOrder && isEditingNote && (
            <div className="buttons-container">
              <ButtonSquare
                onClick={handleReset}
                Icon={<Ex black width={10} height={10} />}
              />
              <ButtonSquare
                onClick={handleSubmit}
                Icon={<Check black width={10} height={10} />}
              />
            </div>
          )}
          <div className="input-container">
            <textarea
              rows={1}
              disabled={isNoteDisabled}
              ref={inputRef}
              onFocus={() => setIsEditingNote(true)}
              value={isNewOrder ? order.note : inputValue}
              type={'text'}
              placeholder={tr['note']}
              onChange={(e) => {
                const value = e.target.value;

                if (!isNewOrder) {
                  setInputValue(value);
                }

                order.setState((prev) => ({
                  ...prev,
                  note: value.trim().length ? value : null,
                }));
              }}
            />
          </div>
        </div>
      </ClickAwayListener>
    </S.OrderNote>
  );
};

export default OrderNote;
