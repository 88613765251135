import styled, { css } from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { dropdownRollIn, dropdownRollOut } from 'common/styles/animations';
import { fontFamilies } from 'common/fonts/fonts';

import { components } from 'react-select';
const { Menu: OriginalMenu } = components;

export const Menu = styled(OriginalMenu)`
  border: 1px solid ${colors.table_borders_Primary};
  border-radius: 5px !important;
  border-top: none;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  box-shadow: none !important;
  font-family: ${fontFamilies.family_Secondary};
  margin-top: 0 !important;
  margin-bottom: 0;
  transform-origin: top;

  ${({ stayOpen }) =>
    !stayOpen &&
    css`
      animation: ${dropdownRollIn} 0.2s ease-in-out;
    `}

  &.select__menu--close {
    animation: ${dropdownRollOut} 0.1s ease-in-out;
  }

  .select__menu-list {
    max-height: ${(props) => props.menuHeight};
    padding-bottom: 5px;
    padding-top: 5px;

    @media screen and (max-width: 960px) {
      padding-bottom: 3px;
      padding-top: 3px;
    }

    ::-webkit-scrollbar {
      display: none;
    }

    ::-webkit-scrollbar-track {
      border-radius: 4px;
    }
  }

  .select__option {
    background-color: transparent;
    border-radius: 5px;
    color: ${colors.text_Secondary};
    margin-inline: 5px;
    overflow: hidden;
    padding: 10px 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: auto;

    :active {
      background-color: ${colors.sidebar_bg_selected};
    }

    &--is-focused {
      background-color: ${colors.sidebar_bg_selected};

      @media screen and (max-width: 960px) {
        background-color: revert;
      }
    }

    :active,
    &--is-selected {
      font-weight: 900;
    }
  }

  .select__option span:not(:only-child) {
    margin-right: 10px;
    vertical-align: middle;
  }

  ${({ customStyle }) => customStyle}
`;

export const InputField = styled.div`
  font-family: ${fontFamilies.family_Secondary};
  width: ${({ width }) => width};

  ${({ labelPosition }) =>
    labelPosition === 'inline' &&
    css`
      align-items: center;
      align-self: start;
      display: flex;
    `}

  label {
    color: ${colors.bg_grey_3};
    display: ${(props) =>
      props.extendOnOpen && props.isMenuOpen.current ? 'none' : 'block'};
    font-size: 0.875rem;
    margin: ${({ noLabel, labelPosition }) =>
      noLabel
        ? '0 10px'
        : labelPosition === 'inline'
          ? '0 10px 0 0'
          : '0 10px 5px'};
    white-space: nowrap;
  }

  label::after {
    color: ${colors.error};
    content: '*';
    display: ${({ required }) => (required ? 'block' : 'none')};
    margin-left: 5px;
  }

  .input-wrapper {
    animation: ${dropdownRollIn} 0.2s ease-in-out;
    transform-origin: top;
    width: ${(props) => props.extendOnOpen && '100%'};
  }

  .select {
    animation: ${dropdownRollIn} 0.2s ease-in-out;
    transform-origin: top;
    width: ${(props) => props.extendOnOpen && '100%'};
    flex: ${({ labelPosition }) => labelPosition === 'inline' && '1 0 100%'};
  }

  .select__control {
    border: ${({ extendOnOpen }) => extendOnOpen && 'none'};
    border-color: ${({ disabled, extendOnOpen }) =>
      !extendOnOpen && (disabled ? 'transparent' : colors.filterGrey)};
    border-radius: 5px;
    box-shadow: none;
    min-height: unset;

    &--is-disabled {
      background-color: transparent;
    }

    &--is-focused {
      border-color: ${colors.bg_grey_3};
      font-weight: bold;

      .select__indicator {
        color: ${colors.bg_grey_3};

        svg {
          fill: ${colors.bg_grey_3};
        }
      }

      :hover {
        border-color: ${colors.bg_grey_3};

        .select__indicator {
          color: ${colors.bg_grey_3};
        }
      }
    }

    .select__indicator {
      svg {
        fill: ${colors.bg_grey_4};
      }

      &:hover {
        color: ${colors.text_Secondary};

        svg {
          fill: ${colors.text_Secondary};
        }
      }
    }

    &--menu-is-open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      ${Menu} {
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        ${(props) =>
          props.stayOpen &&
          css`
            z-index: unset;
          `}
      }

      /* & .select__value-container {
        padding-left: ${(props) => props.extendOnOpen && '0'};
      }

      & .select__placeholder {
        margin-left: ${(props) => props.extendOnOpen && '0'};
      } */
    }
  }

  .select__placeholder {
    color: ${({ noValue, disabled }) =>
      noValue && disabled ? colors.text_Secondary : colors.filterGrey};
  }

  .select__value-container {
    align-items: center;
    cursor: ${({ isSearchable }) => isSearchable && 'text'};
    display: ${(props) => props.prepend && 'flex'};
    height: ${({ disabled, notClearable, noOptions }) =>
      (disabled || notClearable || noOptions) && '36px'};
    min-height: 36px;

    .wrapper {
      align-items: center;
      display: grid;
    }

    .prepend_icon {
      align-items: center;
      color: ${colors.bg_grey_3};
      display: flex;
      margin-right: 10px;
    }

    .prepend_label {
      color: ${colors.bg_grey_3};
      margin-right: 10px;
    }

    @media screen and (max-width: 960px) {
      .prepend_icon {
        display: flex;
        height: unset;
        margin-right: 10px;
        width: 14px;
      }
    }
  }

  .select__indicators {
    display: ${({ disabled }) => disabled && 'none'};
  }

  .select__indicator-separator {
    display: ${({ disabled, notClearable, noOptions, noValue }) =>
      (disabled ||
        (notClearable && !noOptions) ||
        (!notClearable && noOptions) ||
        noValue) &&
      'none'};
  }

  .select__indicator {
    align-items: center;
    display: ${({ disabled }) => (disabled ? 'none' : 'flex')};
    height: 36px;
    justify-content: center;
    width: 36px;
  }

  .select__input-container {
    cursor: text;
    grid-template-columns: 0 100%;
  }

  .select__input {
    color: ${colors.text_Secondary} !important;
    opacity: 1 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .select__single-value {
    color: ${colors.text_Secondary};

    > span:not(:only-child) {
      margin-right: 10px;
      vertical-align: middle;
    }
  }

  .select__multi-value__label {
    color: ${colors.text_Secondary};
  }

  .select__control--menu-is-open .prepend_icon {
    color: inherit;
  }

  .select__dropdown-indicator {
    transition: transform 300ms ease-in-out;
  }

  .select__dropdown-indicator.open {
    transform: rotate(180deg);
  }

  .select__dropdown-indicator.close {
    transform: rotate(0deg);
  }

  .select__dropdown-indicator .arrow {
    margin-top: -2px;
    transform: rotate(90deg);
  }

  ${({ customStyle }) => customStyle}
`;

export const ValueContainer = styled.div`
  align-items: center;
  display: flex;

  .prepend_icon {
    color: ${colors.bg_grey_3};
    display: flex;
    height: 25px;
    margin-right: 15px;
    width: 25px;
  }

  .prepend_label {
    color: ${colors.bg_grey_3};
    margin-right: 10px;
  }

  @media screen and (max-width: 960px) {
    .prepend_icon {
      display: flex;
      height: unset;
      margin-right: 10px;
      width: 14px;
    }
  }
`;

export const TableFilterMenu = styled.div`
  border: none;
  background-color: ${colors.bg_grey_1};
  border-radius: 0 0 5px 5px;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 4px;
  }

  .select__option {
    overflow-x: hidden;
    text-overflow: ellipsis;
    padding: 8px 5px;
    color: ${colors.text_Secondary};
    border-radius: 0;
    margin: 0;
    font-size: 12px;

    :active,
    &--is-focused {
      background-color: ${colors.bg_grey_2};
    }

    :active,
    &--is-selected {
      font-weight: bold;
    }
  }
`;
