import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// icons
import { Star } from 'common/icons';

// components
import { Table } from 'components/Tables/Table/Table';
import { EmployeeCard } from '../EmployeeCard/EmployeeCard';

// contexts
import { TableContext } from 'context/TableContext';
import { UserContext } from 'context/UserContext';
import { MainContext } from 'pages/Main/Main';

// hooks
import {
  useEmployeesBrowsePost,
  useEmployeesDelete,
} from 'services/employeeService';
import useTranslate from 'hooks/useTranslate';
import useNumber from 'hooks/useNumber';

// helpers
import { TABLE_FILTER_TYPE } from 'common/constants';
import { useTable } from 'hooks/useTable';

export const EmployeesTable = () => {
  const { state, dispatch } = useContext(TableContext);
  const { employeeIds, user } = useContext(UserContext);
  const { tableState } = useContext(MainContext);
  const tr = useTranslate().use().global;
  const [searchParams] = useSearchParams();
  const sectorId = searchParams.get('sectorId');
  const dataRequestEnabled = useRef();
  const { setFilter, setData } = useTable();
  const { format } = useNumber();
  const {
    mutate,
    isSuccess: isDeletionSuccess,
    isError: isDeletionError,
  } = useEmployeesDelete();
  const {
    data: { data } = {},
    isLoading,
    mutate: getEmployees,
  } = useEmployeesBrowsePost({
    searchParams: {
      ...state.pagination,
      ...(sectorId && {
        sectorId: sectorId,
      }),
      withSectorSettings: true,
    },
  });
  const [action, setAction] = useState();

  useEffect(() => {
    if (dataRequestEnabled.current === true || state.filters?.applied.length) {
      getEmployees({
        sort: state.sort,
        filter: state.filters.applied,
      });

      dataRequestEnabled.current = true;
    }
  }, [
    sectorId,
    state.pagination,
    state.sort,
    state.filters.applied,
    isDeletionSuccess,
    isDeletionError,
  ]);

  if (data?.employees?.length) {
    const employees = data?.employees?.map((item) => {
      if (item?.phonePrefix && item?.phoneNumber) {
        item.phone = item.phonePrefix + item.phoneNumber;
      }

      item.professionId = null;

      if (item.sectorSettings?.priority) {
        item.sectorSettings.priority = format(item.sectorSettings.priority, {
          decimalPlaces: 1,
          roundingMethod: 'D',
        });
      }

      return item;
    });

    data.employees = employees;
  }

  const isEditingSelf =
    (action?.record?.id && employeeIds?.includes(action.record.id)) ||
    action?.record?.email === user?.email;

  const handleRecordDelete = useCallback(() => {
    mutate({
      id: action.record.id,
      searchParams: {
        version: action.record.version,
      },
    });
  }, [isEditingSelf, action?.record]);

  useEffect(() => {
    if (tableState?.employees) {
      dispatch({
        type: 'SET_STATE',
        payload: tableState?.employees,
      });
      dataRequestEnabled.current = true;
    }
  }, []);

  useEffect(() => {
    if (!tableState?.employees) {
      dispatch({
        type: 'SET_TABLE_NAME',
        payload: 'employees',
      });

      setFilter(true, 'IsActive');

      let columns = [
        {
          id: 'sectorSettings.priority',
          label: tr['priority'],
          // cellClassNames: 'center',
          filter: 'Priority',
          filterType: TABLE_FILTER_TYPE.INPUT,
          inputProps: {
            type: 'number',
            decimalPlaces: 1,
            allowDecimalPadding: true,
          },
          sortable: true,
          cellClassNames: 'text-right',
          isSectorSpecific: true,
          icon: <Star bold width={16} height={16} />,
          // minWidth: 54,
          minWidth: 68,
        },
        {
          id: 'createTimeUtc',
          label: tr['registerDate'] + ' (UTC)',
          type: 'DATETIME',
          filter: 'CreateTime',
          filterType: TABLE_FILTER_TYPE.DATE_RANGE,
          sortable: true,
          minWidth: 138,
          maxWidth: 138,
        },
        {
          id: 'photoUrl',
          label: tr['picture'],
          type: 'AVATAR',
          options: [
            { label: tr['has-picture'], value: '1' },
            { label: tr['has-no-picture'], value: '0' },
          ],
          cellClassNames: 'center',
          filter: 'HasPhoto',
          filterType: TABLE_FILTER_TYPE.SELECT,
          sortable: true,
          width: 160,
          minWidth: 160,
        },
        {
          id: 'firstName',
          label: tr['firstName'],
          filter: 'Name',
          filterType: TABLE_FILTER_TYPE.INPUT,
          sortable: true,
          maxWidth: 120,
          minWidth: 120,
          mobileHidden: true,
        },
        {
          id: 'lastName',
          label: tr['lastName'],
          filter: 'LastName',
          filterType: TABLE_FILTER_TYPE.INPUT,
          sortable: true,
          minWidth: 120,
          maxWidth: 120,
          mobileHidden: true,
        },
        {
          id: 'birthday',
          label: tr['dob'],
          type: 'DATE',
          filter: 'Birthday',
          filterType: TABLE_FILTER_TYPE.DATE_RANGE,
          sortable: true,
          width: 138,
          minWidth: 138,
        },
        {
          id: 'phone',
          label: tr['phone2'],
          filter: 'PhoneNumber',
          filterType: TABLE_FILTER_TYPE.INPUT,
          sortable: true,
          minWidth: 120,
          maxWidth: 120,
        },
        {
          id: 'email',
          label: tr['email'],
          filter: 'Email',
          filterType: TABLE_FILTER_TYPE.INPUT,
          sortable: true,
          minWidth: 200,
        },
        {
          id: 'professionId',
          label: tr['occupation'],
          type: 'OPTION',
          // options: [],
          isSectorSpecific: true,
          minWidth: 120,
          maxWidth: 120,
          mobileHidden: true,
          options: ['aukurakurys', 'arkliavagis'],
        },
        {
          id: 'sectorSettings.isShownInCalendar',
          label: tr['visibilitySelf'],
          type: 'OPTION',
          options: [
            { label: tr['show'], value: true },
            { label: tr['dont-show'], value: false },
          ],
          isSectorSpecific: true,
        },
        {
          id: 'sectorSettings.roleId',
          label: tr['role-for-common-permissions'],
          type: 'OPTION',
          options: [],
          minWidth: 120,
          maxWidth: 120,
          // options: ['aukurakurys', 'arkliavagis'],
        },
        {
          id: 'sectorRole',
          label: tr['role-for-sector-permissions'],
          type: 'OPTION',
          options: [],
          isSectorSpecific: true,
          minWidth: 120,
          maxWidth: 120,
          // options: ['aukurakurys', 'arkliavagis'],
        },
        {
          id: 'sectorSettings.serveOnlineId',
          label: tr['serve-online-system-id'],
          isSectorSpecific: true,
          minWidth: 200,
        },
        {
          id: 'isActive',
          label: tr['activity'],
          type: 'OPTION',
          options: [
            { label: tr['active'], value: true },
            { label: tr['inactive'], value: false },
          ],
          filter: 'IsActive',
          filterType: TABLE_FILTER_TYPE.SELECT,
          sortable: true,
          maxWidth: 120,
          minWidth: 120,
        },
      ];

      columns.map((column) => {
        column.isVisible = true;
      });

      dispatch({
        type: 'SET_COLUMNS',
        payload: columns,
      });
    }
  }, []);

  useEffect(() => {
    if (data?.employees) {
      setData(data.employees, data.totalCount);
    }
  }, [data]);

  useEffect(() => {
    if (state.columns.length) {
      const visibleColumns = state.columns.filter((column) => {
        if (!column.isVisible || (!sectorId && column?.isSectorSpecific)) {
          return false;
        }

        return true;
      });

      if (!visibleColumns.length) {
        const toBeVisibleColumn = sectorId
          ? state.columns[0]
          : state.columns.find((column) => !column?.isSectorSpecific);

        toBeVisibleColumn.isVisible = true;

        dispatch({
          type: 'SET_COLUMN',
          payload: { toBeVisibleColumn },
        });
      }
    }
  }, [sectorId]);

  const isRecordInactive = (record) => !record.isActive;

  const recordDeleteWarning =
    tr[
      isEditingSelf
        ? 'confirmation-of-deleting-self-card'
        : 'are-you-sure-you-want-to-delete-the-employee-card'
    ];

  const activeRecordDeleteMessage = tr['can-only-delete-inactive-cards'];

  return (
    <Table
      actions={{
        action,
        setAction,
        navigation: true,
        delete: {
          action: handleRecordDelete,
          warning: recordDeleteWarning,
          activeRecordDeleteMessage,
        },
      }}
      onRecordDelete={handleRecordDelete}
      mobileElement={EmployeeCard}
      isLoading={isLoading}
      isRecordInactive={isRecordInactive}
    />
  );
};
