import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';

// icons
import { Like, Check, Unlike } from 'common/icons';

// contexts
import { OrderContext } from 'context/OrderContext';

// hooks
import useMediaQuery from './useMediaQuery';
import useTranslate from './useTranslate';
import { useServicesModifyOrderStatus } from 'services/orderService';
import { ORDER_COMPLETION_STATUS } from 'common/constants';
import { toSentenceCase } from 'common/helpers/stringOperations';
import { colors } from 'common/colors/colors';

export const useOrderFormStatus = ({ setButtons }) => {
  const navigate = useNavigate();
  const {
    orderId,
    order,
    saveOrder,
    professional,
    isDirty,
    isFormDisabled,
    setIsFormDisabled,
    refetch,
  } = useContext(OrderContext);
  const isMobile = useMediaQuery('(max-width: 960px)');
  const tr = useTranslate().use().global;
  const isNewOrder = orderId === 'new';
  const { mutate, isSuccess, isError, error } = useServicesModifyOrderStatus();

  let isOrderConfirmed = !isNewOrder;

  if (order?.state?.completionStatus && !isNewOrder) {
    isOrderConfirmed =
      order.state.completionStatus ===
      toSentenceCase(ORDER_COMPLETION_STATUS.CONFIRMED);
  }

  useEffect(() => {
    setButtons({
      type: 'setup',
      value: [
        {
          id: 'cancel-accomplishment',
          show: order?.state?.completionStatus ? isOrderConfirmed : false,
          disabled: true,
          customBackgroundColor: colors.ordered,
          customTextColor: colors.bg_Button_Secondary,
          icon: (
            <Unlike
              bold
              {...(!isMobile && {
                style: {
                  margin: 0,
                  minWidth: 16,
                  marginLeft: 3,
                  transform: 'translate3d(-5px, 1px, 0)',
                },
              })}
            />
          ),
          ...(!isMobile && {
            title: tr['cancel-accomplished'],
          }),
        },
        {
          id: 'accomplished',
          show: !isNewOrder && !isOrderConfirmed,
          disabled: true,
          customBackgroundColor: colors.cancelAccomplished,
          customTextColor: colors.bg_Button_Secondary,
          icon: (
            <Like
              bold
              {...(!isMobile && {
                style: {
                  margin: 0,
                  minWidth: 16,
                  marginLeft: 3,
                  transform: 'translate3d(-5px, 1px, 0)',
                },
              })}
            />
          ),
          ...(!isMobile && {
            title: tr['accomplished'],
          }),
        },
        {
          id: 'save',
          borderless: false,
          show: !isOrderConfirmed,
          secondary: true,
          disabled: true,
          icon: (
            <Check
              bold
              {...(!isMobile && {
                style: {
                  margin: 0,
                  minWidth: 16,
                  marginLeft: 3,
                  transform: 'translate3d(-5px, 1px, 0)',
                },
              })}
            />
          ),
          ...(!isMobile && {
            title: tr['save'],
          }),
        },
      ],
    });
  }, [isMobile]);

  useEffect(() => {
    if (order.state) {
      setButtons({
        type: 'update',
        id: 'cancel-accomplishment',
        value: {
          show: order?.state?.completionStatus ? isOrderConfirmed : false,
          secondary: isFormDisabled,
          disabled: isFormDisabled,
          action: () => {
            setIsFormDisabled(true);
            mutate({
              id: orderId,
              completionStatus: toSentenceCase(ORDER_COMPLETION_STATUS.ORDERED),
              version: order.state.version,
            });
          },
        },
      });
      setButtons({
        type: 'update',
        id: 'save',
        value: {
          show: !isOrderConfirmed,
          secondary: !isDirty || isFormDisabled,
          disabled: !isDirty || isFormDisabled,
          action: saveOrder,
        },
      });
      setButtons({
        type: 'update',
        id: 'accomplished',
        value: {
          show: !isNewOrder && !isOrderConfirmed && professional,
          secondary: isDirty || isFormDisabled,
          disabled: isDirty || isFormDisabled,
          action: () => {
            setIsFormDisabled(true);
            mutate({
              id: orderId,
              completionStatus: toSentenceCase(
                ORDER_COMPLETION_STATUS.CONFIRMED,
              ),
              version: order.state.version,
            });
          },
        },
      });
    }
  }, [order.state, professional, isDirty, isFormDisabled, isOrderConfirmed]);

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }

    if (isError) {
      if (error?.response?.status === 412) {
        refetch();
      }
      if (error?.response?.status === 404) {
        navigate('/functions/daily-functions/calendar/');
      }
    }
  }, [isSuccess, isError]);
};
