import styled from 'styled-components/macro';

import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import { shadow } from 'common/styles/shadows';

export const OrderFooterMobile = styled.div`
  position: sticky;
  bottom: 0;
  background-color: ${colors.bg_Primary};
  box-shadow: ${shadow.negative};
  padding: 10px;
  width: 100%;
  z-index: 6;

  .orderFooterInfo {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    direction: rtl;
    place-items: center;
  }

  .footerCell_card {
    direction: ltr;

    height: 100%;

    display: flex;
    justify-content: space-evenly;
    flex-direction: column;

    font-size: 14px;
    font-family: ${fontFamilies.family_Secondary};
    gap: 2px;
  }

  @media screen and (min-width: 961px) {
    border-radius: 0 0 10px 10px;
    z-index: 1;

    .orderFooterInfo {
      direction: ltr;
      place-items: center;

      display: flex;
      justify-content: space-between;

      > div:first-child {
        order: 4;
      }

      > div:nth-child(2) {
        order: 2;
      }

      > div:nth-child(3) {
        order: 1;
      }

      > div:nth-child(4) {
        order: 5;
      }

      > div:nth-child(5) {
        order: 3;
      }
    }

    .footerCell_card {
      gap: 4px;
    }
  }
`;
