import { useRef } from 'react';

// style
import * as S from './Search.styled';

// icons
import { ClearSearchSVG, MagnifyingGlass } from 'common/icons';

export const Search = ({
  darkTheme,
  placeholder,
  state,
  raised,
  autofocus,
}) => {
  const ref = useRef(null);

  return (
    <S.Search darkTheme={darkTheme} raised={raised}>
      {state?.input ? (
        <div
          className="search-icon clear-input"
          onClick={() => {
            state?.setInput('');
            ref.current.focus();
          }}
        >
          <ClearSearchSVG />
        </div>
      ) : (
        <div className="search-icon">
          <MagnifyingGlass black />
        </div>
      )}

      <input
        className="search-input"
        type="text"
        placeholder={placeholder}
        value={state?.input}
        onChange={(e) => state?.setInput(e.target.value)}
        autoFocus={autofocus}
        ref={ref}
      />
    </S.Search>
  );
};

export default Search;
