import {
  useEffect,
  useReducer,
  useRef,
  useState,
  useContext,
  forwardRef,
} from 'react';
import ClickAwayListener from 'react-click-away-listener';

// style
import * as S from './ScheduleDayCard.styled';

// components
import { InputFieldTime } from 'components/InputFieldTime/InputFieldTime';
import { OfficeSelector } from '../OfficeSelector/OfficeSelector';
import { ContextMenu } from 'components/ContextMenu/ContextMenu';
import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper';

// context
import { UserContext } from 'context/UserContext';

// hooks
import { useMediaQuery } from 'hooks/useMediaQuery';
import { applyStartTime, getDay } from 'common/helpers/dateOperations';
import { useFunctionList } from 'hooks/useFunctionList';
import { useTime } from 'hooks/useTime';
import { useToggler } from 'hooks/useToggler';
import { useSystemGetCultureById } from 'services/systemService';
import { useScheduleHelpers } from 'hooks/employee-schedule/useScheduleHelpers';

export const ScheduleDayCard = forwardRef((props, ref) => {
  const {
    isTemplate,
    date: dateProp,
    isSelected,
    changeHandler,
    options,
    selectRange,
    copyRange,
    pasteRange,
    clearRange,
    pasteable,
    clearable,
    clearSelection,
    hasHourChanged,
    hasDateErrors,
    ...otherProps
  } = props;
  const { locale } = useContext(UserContext);
  const isMobile = useMediaQuery('(max-width: 960px)');

  // get culture settings
  const { data: culture } = useSystemGetCultureById({
    id: locale,
    queryParams: { enabled: !isTemplate },
  });

  const { getTime } = useTime();

  // date value
  let date, cardCheckupId, cardUniId;
  const now = new Date();
  now.setHours(0, 0, 0, 0);

  if (dateProp?.date) {
    date = dateProp.date;
    cardCheckupId = date;
    cardUniId = dateProp.date?.getTime();
  } else {
    cardUniId = dateProp.index;
    cardCheckupId = cardUniId;
  }

  const isCurrentDay = !isTemplate && date?.getTime() === now.getTime();
  const isCurrentMonth = !isTemplate && date?.getMonth() === now.getMonth();

  // hour values
  const initialEmpty = ['', '', '', ''];

  const validate = (state, action) => {
    const { id, value } = action;

    const newState = [...state];
    newState[id] = value || '';

    return newState;
  };

  const reducer = (state, action) => {
    switch (action.id) {
      case 'setup':
        return [
          getTime(dateProp.hours?.[0]) || '',
          getTime(dateProp.hours?.[1]) || '',
          getTime(dateProp.hours?.[2]) || '',
          getTime(dateProp.hours?.[3]) || '',
        ];
      case 'clear':
        return initialEmpty;
      default:
        return validate(state, action);
    }
  };

  const [hours, dispatch] = useReducer(reducer, initialEmpty);

  // set initial hours
  useEffect(() => {
    if (dateProp) {
      dispatch({ id: 'setup' });
    }
  }, [dateProp]);

  // office value
  const office = dateProp?.office;

  // value presence status
  const hasHours = () => {
    let result = 0;
    if (hours[0] || hours[1]) result |= 1;
    if (hours[2] || hours[3]) result |= 2;
    return result; // returns: if no values - 0; if 1st row has values - 1; if 2nd row has values - 2; if both rows have values - 3
  };
  const hasOffice = office !== undefined;
  const hasValues = hasHours() || hasOffice;

  const officeSelector = useToggler(false); // officeSelector's status/hide/show handler

  const isChangedFirst =
    hasHourChanged(cardCheckupId, 0) || hasHourChanged(cardCheckupId, 1);
  const isChangedSecond =
    hasHourChanged(cardCheckupId, 2) || hasHourChanged(cardCheckupId, 3);
  const hasErrorsFirst =
    hasDateErrors(cardCheckupId, 0) || hasDateErrors(cardCheckupId, 1);
  const hasErrorsSecond =
    hasDateErrors(cardCheckupId, 2) || hasDateErrors(cardCheckupId, 3);

  const validateValue = (e) => {
    const handler = e.target;
    const cardHandler = handler?.closest('.react-time-picker');
    const fullId = cardHandler?.id;
    const cardId = Number(fullId?.split('-')[0]);
    const fieldId = Number(fullId?.split('-')[1]);

    if (!cardHandler) return; // click outside the scope

    const rHandler = e.relatedTarget;
    const rFullId = rHandler?.closest('.react-time-picker')?.id;
    const rCardId = Number(rFullId?.split('-')[0]);
    const rFieldId = Number(rFullId?.split('-')[1]);

    // const liveValue = new Date(date);
    const liveValue = new Date(Number(cardUniId));
    liveValue.setHours(
      hours[fieldId]?.split(':')[0],
      hours[fieldId]?.split(':')[1],
    );

    if (fieldId === rFieldId && cardId === rCardId) {
      // console.log("esu tame pačiame field'e, validacijos nereikia");
      return;
    } else if (dateProp?.hours?.[fieldId]?.getTime() === liveValue?.getTime()) {
      // console.log('reikšmė nepasikeitė, nereikia siųsti cache validacijai');
      return;
    } else {
      // console.log("pakeičiau field'ą, reikia užsaugoti reikšmę");
      let currentHoursObj = new Date(Number(cardId));

      if (hours[fieldId]) {
        currentHoursObj.setHours(
          hours[fieldId]?.split(':')[0],
          hours[fieldId]?.split(':')[1],
        );

        changeHandler(
          applyStartTime(currentHoursObj),
          currentHoursObj,
          fieldId,
        );
      } else {
        changeHandler(applyStartTime(currentHoursObj), undefined, fieldId);
      }
    }
  };

  const clearTimeValues = (ids) => {
    const updatedObj = { ...dateProp };

    if (Array.isArray(ids)) {
      ids.forEach((id) => {
        updatedObj['hours' + id] = undefined;
      });
    } else {
      updatedObj['hours' + ids] = undefined;
    }

    changeHandler(updatedObj);
  };

  const focusCardOnClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.shiftKey) {
      selectRange && selectRange(e, dateProp);
    } else {
      // const isFieldDisabled = (context) =>
      //   !!context?.current?.querySelector('.react-time-picker--disabled');

      const focusField = (context) => {
        context?.current
          ?.querySelector('.react-time-picker__inputGroup__hour')
          .focus();
      };

      // focus to last empty time input field, if the card is not currently selected
      if (!isSelected && !isTemplate) {
        if (
          !hours.find((value, index) => {
            if (!value) {
              focusField(refs[index]);
              return true;
            }
            return false;
          })
        ) {
          selectRange && selectRange(e, dateProp);
        }
      }
    }
  };

  // downstream refs for focusing
  const refs = [useRef(), useRef(), useRef(), useRef()];

  const [showMenu, setShowMenu] = useState(false);
  const [mouseXPos, setMouseXPos] = useState();
  const [mouseYPos, setMouseYPos] = useState();

  const showContextMenu = (e) => {
    if (!isTemplate) {
      e.preventDefault(); // prevent browser's context menu

      if (!isSelected) {
        selectRange && selectRange(e, dateProp);
      }

      setMouseXPos(e.pageX);
      setMouseYPos(e.pageY);

      setShowMenu(true);
    }
  };

  const contextFunctions = useFunctionList([
    'copy-schedule-record',
    'paste-schedule-record',
    'clear-schedule-record',
  ]);

  // config function visibility
  contextFunctions.find((fn) => fn.id === 'paste-schedule-record').hide =
    pasteable;
  contextFunctions.find((fn) => fn.id === 'clear-schedule-record').hide = () =>
    !clearable();
  // config function action
  contextFunctions.find((fn) => fn.id === 'copy-schedule-record').action =
    copyRange;
  contextFunctions.find((fn) => fn.id === 'paste-schedule-record').action =
    pasteRange;
  contextFunctions.find((fn) => fn.id === 'clear-schedule-record').action =
    clearRange;

  const isNewMonth = (date) => date?.getDate() === 1;
  const isNewYear = (date) => date?.getMonth() === 0 && isNewMonth(date);
  const { weekendIndexArray } = useScheduleHelpers();

  return (
    <S.ScheduleDayCardContainer
      className={'card-container'}
      isOfficeSelectorVisible={officeSelector.active}
      currentDay={isCurrentDay}
      currentMonth={isCurrentMonth}
      {...(ref ? { ref } : {})}
      // onClick={() => console.log({ refas: ref?.current })}
    >
      {!isTemplate && date && (
        <label htmlFor={cardUniId}>
          <ConditionalWrapper
            condition={isNewMonth(date)}
            wrapper={(children) => <b>{children}</b>}
          >
            {`${date?.getDate()} ${culture?.dateTimeFormatInfo.abbreviatedMonthNames[date?.getMonth()] || ''}`}
            {isNewYear(date) && `, ${date?.getFullYear()}`}
          </ConditionalWrapper>
        </label>
      )}

      <ClickAwayListener
        onClickAway={(e) => {
          if (
            // !isTemplate &&
            !isMobile &&
            (e.target.classList.contains('schedule__data') ||
              e.target.classList.contains('card-container')) && // clicked between day cards...
            !document.getElementsByClassName('office-selector').length && // no officeSelector is currently open...
            !document.getElementsByClassName('context-menu').length // no officeSelector is currently open...
          ) {
            // ... clear selection
            clearSelection();
          }
        }}
      >
        <S.Selector
          isSelected={isSelected}
          isTemplate={isTemplate}
          locale={locale}
        >
          <S.ScheduleDayCard
            id={cardUniId}
            isWeekend={weekendIndexArray?.includes(getDay(date) + 1)}
            onContextMenu={showContextMenu}
            onClick={focusCardOnClick}
            tabIndex={-1}
            {...otherProps} // for card autoselection if inner field is focussed/selected
          >
            <S.Range
              hasValues={hasValues}
              isChanged={isChangedFirst}
              hasErrors={hasErrorsFirst}
              isSelected={isSelected}
              onBlur={validateValue}
            >
              <S.Value
                hasValue={!!hours?.[0]}
                isSelected={isSelected || isTemplate}
              >
                <InputFieldTime
                  ref={refs[0]}
                  isSelected={isSelected || isTemplate}
                  id={cardUniId + '-0'}
                  value={hours?.[0]}
                  onChange={(value) => dispatch({ id: 0, value })}
                  onFocus={(e) => selectRange && selectRange(e, dateProp)}
                  required={hours?.[1]}
                />
              </S.Value>

              <span>-</span>

              <S.Value
                hasValue={!!hours?.[1]}
                isSelected={isSelected || isTemplate}
              >
                <InputFieldTime
                  ref={refs[1]}
                  isSelected={isSelected || isTemplate}
                  id={cardUniId + '-1'}
                  value={hours?.[1]}
                  onChange={(value) => dispatch({ id: 1, value })}
                  onFocus={(e) => selectRange && selectRange(e, dateProp)}
                  // disabled={!hours?.[0]}
                  required={hours?.[0]}
                />
              </S.Value>
            </S.Range>

            <S.Range
              hasValues={hasValues}
              isChanged={isChangedSecond}
              hasErrors={hasErrorsSecond}
              isSelected={isSelected}
              onBlur={validateValue}
            >
              <S.Value
                hasValue={!!hours?.[2]}
                isSelected={isSelected || isTemplate}
              >
                <InputFieldTime
                  ref={refs[2]}
                  isSelected={isSelected || isTemplate}
                  id={cardUniId + '-2'}
                  value={hours?.[2]}
                  onChange={(value) => dispatch({ id: 2, value })}
                  onFocus={(e) => selectRange && selectRange(e, dateProp)}
                  // disabled={!hours?.[1] || !hours?.[0]}
                  // disabled={!hours?.[1] || !hours?.[0]}
                  required={hours?.[3]}
                />
              </S.Value>

              <span>-</span>

              <S.Value
                hasValue={!!hours?.[3]}
                isSelected={isSelected || isTemplate}
              >
                <InputFieldTime
                  ref={refs[3]}
                  isSelected={isSelected || isTemplate}
                  id={cardUniId + '-3'}
                  value={hours?.[3]}
                  onChange={(value) => dispatch({ id: 3, value })}
                  onFocus={(e) => selectRange && selectRange(e, dateProp)}
                  // disabled={!hours?.[2] || !hours?.[1] || !hours?.[0]}
                  // disabled={!hours?.[1] || !hours?.[0]}
                  required={hours?.[2]}
                />
              </S.Value>
            </S.Range>
          </S.ScheduleDayCard>

          {/* {((!isTemplate && hasOffice) || isSelected) && (
            <ClickAwayListener
              onClickAway={(e) => {
                if (officeSelector.active) officeSelector.off();
              }}
            >
              <S.Badge
                // className="office"
                onClick={officeSelector.on}
                hasValue={hasOffice}
              >
                {office?.label}
                {officeSelector.active && (
                  <OfficeSelector
                    state={options['office-number'].find(
                      (option) => option.value === office?.value,
                    )}
                    onChange={(value) => {
                      console.log({ value, dateProp });
                      changeHandler(dateProp.date, {
                        ...dateProp,
                        office: value,
                      });
                    }}
                    options={options['office-number']}
                    close={officeSelector.off}
                  />
                )}
              </S.Badge>
            </ClickAwayListener>
          )} */}

          {showMenu && (
            <ContextMenu
              setShowMenu={setShowMenu}
              xPos={mouseXPos}
              yPos={mouseYPos}
              functions={contextFunctions}
            />
          )}
        </S.Selector>
      </ClickAwayListener>
    </S.ScheduleDayCardContainer>
  );
});

ScheduleDayCard.displayName = 'ScheduleDayCard';
