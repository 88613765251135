import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import styled from 'styled-components/macro';

export const SetContents = styled.div`
  width: 100%;

  > label {
    color: ${colors.bg_grey_3};
    display: block;
    font-size: 0.875rem;
    margin-bottom: 20px;
  }

  .totals-control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${colors.bg_grey_3};
    font-size: 0.75rem;
  }

  button {
    background-color: ${colors.bg_Secondary};
    border: none;
    border-radius: 50%;
    line-height: 0;
    margin-left: auto;
    padding: 0;
  }
`;

export const SetServiceContainer = styled.div`
  align-items: ${({ hasContents }) => !hasContents && 'center'};
  background-color: ${({ disabled }) => !disabled && colors.bg_grey_1};
  border-radius: 5px;
  display: flex;
  flex-direction: ${({ hasContents }) => hasContents && 'column'};
  font-family: ${fontFamilies.family_Secondary};
  font-size: 0.875rem;
  gap: 10px;
  justify-content: space-between;
  padding: ${({ disabled }) => !disabled && '20px'};

  @media (max-width: 960px) {
    margin-inline: -10px;
    padding: ${({ disabled }) => !disabled && '10px'};
  }

  .empty {
    font-size: 1rem;
  }
`;
