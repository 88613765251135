import { useContext, useRef } from 'react';

// style
import * as S from './AppointmentsDetails.styled';

// icons
import { LinkUrlSVG, Arrow, VerticalEllipsis } from 'common/icons';

// components
import Avatar from 'components/Avatar/Avatar';
import ContextTree from 'components/ContextTree/ContextTree';
import { Link } from 'components/Overrides';

// contexts
import { ScheduleContext } from 'context/ScheduleContext';
import { MainContext } from 'pages/Main/Main';
import { UserContext } from 'context/UserContext';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import useStatusList from 'hooks/useStatusList';
import useTooltipWhenOverflows from 'hooks/useTooltipWhenOverflows';
import { useAppointment } from 'hooks/useAppointment';
import useNumber from 'hooks/useNumber';
import {
  getDurationString,
  getTimeString,
} from 'common/helpers/dateOperations';
import { fillImgPath } from 'common/helpers/imagesFunctions';

const AppointmentsDetails = ({
  status,
  data,
  type,
  statusItems,
  functionItems,
  balanceItems,
}) => {
  const {
    id,
    uuid,
    customer,
    duration,
    services,
    time,
    wantedEmployees,
    balance,
    note,
  } = data;
  const tr = useTranslate().use().global;
  const { searchParams, numberOfDays } = useContext(ScheduleContext);
  const { sectorData } = useContext(MainContext);
  const { locale } = useContext(UserContext);
  const currentStatus = useStatusList(status.toLowerCase());
  const { formatPrice } = useNumber({ price: true });
  const { icons } = useAppointment();
  const clientNameRef = useRef();
  const orderTitleRef = useRef();
  const clientName =
    customer?.name || customer?.lastName
      ? `${customer?.name} ${customer?.lastName}`
      : `\u2013`;
  const orderId = tr['order'] + ' ' + id;
  useTooltipWhenOverflows(clientNameRef, clientName);
  useTooltipWhenOverflows(orderTitleRef, orderId);
  const startTimeString = getTimeString(
    time?.start,
    sectorData?.settings?.timezoneIanaId,
    locale,
  );
  const endTimeString = getTimeString(
    time?.end,
    sectorData?.settings?.timezoneIanaId,
    locale,
  );
  const durationString =
    duration ||
    getDurationString(
      time?.start,
      time?.end,
      sectorData?.settings?.timezoneIanaId,
    );
  const finalPrice = formatPrice(
    services.reduce((prev, curr) => prev + +curr.finalAmountWithVat, 0),
  );

  return (
    <S.AppointmentsDetails statusColor={currentStatus?.background}>
      <div className="head">
        <Link
          to={`/functions/tables/clients/${customer.id}/general-information`}
          className="section__link"
        >
          <div className="full-name">
            <span ref={clientNameRef}>{clientName}</span>
            <div className="section__link__icon">
              <LinkUrlSVG bold />
            </div>
          </div>
        </Link>
        {customer?.phoneNumber && (
          <span className="phone">
            {customer?.phonePrefix + customer?.phoneNumber}
          </span>
        )}
      </div>
      <div className="body">
        <div className="section display__row appopintment-id">
          <Link
            to={'/functions/tables/orders/' + uuid}
            className="section__link"
            state={{
              toOrder: true,
              numberOfDays: numberOfDays.visible,
              searchParams: Object.fromEntries([...searchParams]),
            }}
          >
            <span ref={orderTitleRef}>{orderId}</span>
            <div className="section__link__icon">
              <LinkUrlSVG bold />
            </div>
          </Link>
          <div className="section__functions" id="functions-button">
            <VerticalEllipsis />
            <ContextTree
              items={functionItems}
              containerId="functions-button"
              openOnClick
              fixedWidth={200}
            />
          </div>
        </div>
        {type === 'pending' && !!wantedEmployees.length && (
          <div className="section display__row preferation">
            <span>{tr['prefer-to-see']}:</span>
            <div className="display__row avatars">
              {wantedEmployees?.map(({ photoUrl, firstName, lastName }, i) => (
                <div
                  key={i}
                  data-tooltip-id="tooltip"
                  data-tooltip-html={
                    firstName || lastName
                      ? `${firstName} ${lastName}`
                      : `\u2013`
                  }
                >
                  <Avatar imagePath={fillImgPath(photoUrl)} raised />
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="section appointment-status" id="appointment-status">
          <div className="status">
            <div className="status__color" />
            <span className="status__label">{currentStatus?.title}</span>
          </div>
          <Arrow className="section__arrow" black />
          <ContextTree
            items={statusItems}
            containerId="appointment-status"
            openOnClick
            fixedWidth={200}
          />
        </div>
        <div className="section display__row">
          {type !== 'pending' && (
            <div className="display__column">
              <span className="section__label">{tr['time']}:</span>{' '}
              {startTimeString} - {endTimeString}
            </div>
          )}
          <div className="display__column">
            <span className="section__label">{tr['duration']}:</span>{' '}
            {durationString}
          </div>
        </div>
        {!!services?.length && (
          <div className="section services">
            <div className="list">
              <span className="sections__label">{tr['services']}:</span>
              <ul>
                {services.map(({ serviceName }, i) => (
                  <li key={i}>{serviceName}</li>
                ))}
              </ul>
            </div>
            <span className="price">{finalPrice}</span>
          </div>
        )}
        <div className="section balance" id="balance">
          <div className="status display__row">
            <div className="display__column">
              <span className="section__label">{tr['paid']}</span>
              <span>{formatPrice(balance.amountCovered)}</span>
            </div>
            <div className="display__column">
              <span className="section__label">{tr['pay']}</span>
              <span className="section__label status__debt">
                {formatPrice(balance.toPay)}
              </span>
            </div>
          </div>
          <Arrow className="section__arrow" black />
          <ContextTree
            items={balanceItems}
            containerId="balance"
            openOnClick
            fixedWidth={200}
          />
        </div>
        <div className="section note">
          <span>{tr['note']}:</span>
          <p>{note || tr['none']} </p>
        </div>
        <div className="section additional-info">
          {icons.map(({ icon, label }, i) => (
            <div
              key={i}
              className="icon-wrapper"
              data-tooltip-id="tooltip"
              data-tooltip-html={label}
            >
              {icon}
            </div>
          ))}
        </div>
      </div>
    </S.AppointmentsDetails>
  );
};

export default AppointmentsDetails;
