import { useContext } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useLocation } from 'react-router';

// style
import * as S from './OrderHeaderMobile.styled';

// icons
import { Euro } from 'common/icons';

// components
import TimeAndDateChild from 'components/DialogLayout/CustomSelect/children/TimeAndDateChild/TimeAndDateChild';
import Checkbox from 'components/Checkbox/Checkbox';
import Badge from 'components/Badge/Badge';

// contexts
import { OrderContext } from 'context/OrderContext';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import useStatusList from 'hooks/useStatusList';
import { colors } from 'common/colors/colors';

const OrderHeaderMobile = ({ id, status }) => {
  const tr = useTranslate().use().global;
  const location = useLocation();
  const { order } = useContext(OrderContext);
  const currStatus = useStatusList([status])[0];
  const multiSelect = location.state?.multi === 'multi-selects';

  return (
    <S.OrderHeaderMobile backgroundColor={currStatus?.background}>
      <div className="row">
        <Badge
          data-tooltip-id="tooltip"
          data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
            tr[order.state.isPaid ? 'paid' : 'debt'],
          )}
          icon={<Euro />}
          md
          color={colors[order.state.isPaid ? 'paidStatus' : 'debtStatus']}
        />
        <span className="status-title">{currStatus?.title}</span>
        <span># {id}</span>
        <Checkbox
          text={`${tr.sms}:`}
          value={{
            state: order.state.sendVisitReminder,
            action: () =>
              order.setState((prev) => ({
                ...prev,
                sendVisitReminder: !prev.sendVisitReminder,
              })),
          }}
        />
      </div>

      {!multiSelect && (
        <div className="row">
          <TimeAndDateChild
            className="row"
            darkTheme
            // arrow={
            //   order.state.savedProducts?.length
            //     ? () => {
            //         setModalSwitch({content: 'select-dateAndTime'});
            //         toggle();
            //       }
            //     : null
            // }
          />
        </div>
      )}
    </S.OrderHeaderMobile>
  );
};

export default OrderHeaderMobile;
