import { createElement, useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';

// style
import * as S from './BusinessDetails.styled';

// components
import { InputField } from 'components/InputField/InputField';

// icons
import AestheticMedicineSVG from 'common/icons/business-fields/AestheticMedicineSVG';
import BeautySVG from 'common/icons/business-fields/BeautySVG';
import CarServiceSVG from 'common/icons/business-fields/CarServiceSVG';
import CleaningSVG from 'common/icons/business-fields/CleaningSVG';
import ContentCreationSVG from 'common/icons/business-fields/ContentCreationSVG';
import DentistrySVG from 'common/icons/business-fields/DentistrySVG';
import DrivingInstructorsSVG from 'common/icons/business-fields/DrivingInstructorsSVG';
import OpticsSVG from 'common/icons/business-fields/OpticsSVG';
import OtherServicesSVG from 'common/icons/business-fields/OtherServicesSVG';
import ParenthoodSVG from 'common/icons/business-fields/ParenthoodSVG';
import PhotographySVG from 'common/icons/business-fields/PhotographySVG';
import PsychologySVG from 'common/icons/business-fields/PsychologySVG';
import RehabilitationSVG from 'common/icons/business-fields/RehabilitationSVG';
import SpaSVG from 'common/icons/business-fields/SpaSVG';
import SportsSVG from 'common/icons/business-fields/SportsSVG';
import TutorsSVG from 'common/icons/business-fields/TutorsSVG';
import VeterinarySVG from 'common/icons/business-fields/VeterinarySVG';

// context
import { UserContext } from 'context/UserContext';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useFormState } from 'hooks/useFormState';
import {
  useSystemGetBusinessAreaOptions,
  useSystemGetCultureById,
  useSystemGetTimezoneOptions,
} from 'services/systemService';
import { InputFieldPhone } from 'components/InputFieldPhone/InputFieldPhone';
import { useRestoreAppUserCredentials } from 'hooks/useRestoreAppUserCredentials';

export const BusinessDetails = ({ setStage, setNext, auto }) => {
  const APP_ROOT = process.env.REACT_APP_APP_ROOT;
  const tr = useTranslate().use().global;
  const navigate = useNavigate();

  useRestoreAppUserCredentials();

  const { user, token, updateUser } = useContext(UserContext);

  const { data: businessFields, isSuccess: isBusinessFieldsSuccess } =
    useSystemGetBusinessAreaOptions();

  // useEffect(() => {
  //   isBusinessFieldsSuccess && console.log({ businessFields });
  // }, [businessFields, isBusinessFieldsSuccess]);

  // const importAll = (source) => {
  //   let images = {};
  //   source
  //     .keys()
  //     .map((item) => (images[item.replace('./', '')] = source(item)));
  //   return images;
  // };

  // const images = importAll(
  //   require.context('icons/business-fields/svg', false, /\.(png|jpe?g|svg)$/)
  // );

  const reqSvgs = require.context(
    'common/icons/business-fields/source',
    false,
    /^\.\/.*\.svg$/,
  );

  const images = reqSvgs.keys().reduce((imgs, path) => {
    // if (path[0] !== '.') imgs[path] = reqSvgs(path);
    // if (path[0] !== '.') {
    //   const svgContent = require('../../../' + path).default;
    //   console.log({ svgContent });
    // }
    imgs[path.replace(/^\.\/(.*)(\.svg)$/, '$1')] = reqSvgs(path);
    return imgs;
  }, {});

  const Image = createElement('div');

  // console.log({ reqSvgs, images, length: reqSvgs.keys().length });

  // const { data, isSuccess } = useCountriesGetAll(['flag', 'name']);
  const { data: timezones, isSuccess } = useSystemGetTimezoneOptions({
    labelFormat: ['displayName'],
  });

  const { data: culture, isSuccess: isCultureSuccess } =
    useSystemGetCultureById({
      id: user?.settings?.culture,
      queryParams: {
        enabled: !auto,
        refetchOnWindowFocus: false,
      },
    });

  useEffect(() => {
    isCultureSuccess && console.log({ culture });
  }, [culture, isCultureSuccess]);

  const selectDefaultCulture = useCallback(() => {
    if (timezones) {
      const suggestedTimezoneId = (
        auto
          ? user.suggestedCultures?.find(
              (culture) => culture.id === user.settings.culture,
            )
          : culture
      )?.suggestedTimezoneId;

      return timezones.find(
        (timezone) => timezone.value === suggestedTimezoneId,
      );
    }
  }, [
    timezones,
    user?.settings?.culture,
    user?.suggestedCultures,
    auto,
    culture,
  ]);

  // configure required fields
  const setupFields = useCallback(() => {
    return [
      {
        id: 'business-field',
        level: 1,
        type: 'select',
        options: [
          {
            value: 1,
            label: 'Artistai, animatoriai',
            icon: <ContentCreationSVG />,
          },
          {
            value: 2,
            label: 'Auto instruktoriai',
            icon: <DrivingInstructorsSVG />,
          },
          {
            value: 3,
            label: 'Autoservisų paslaugos',
            icon: <CarServiceSVG />,
          },
          { value: 4, label: 'Fotografai', icon: <PhotographySVG /> },
          {
            value: 5,
            label: 'Grožio centrai, kirpyklos, kosmetologija',
            icon: <BeautySVG />,
          },
          {
            value: 6,
            label: 'Klinikos ir estetinės medicinos įstaigos',
            icon: <AestheticMedicineSVG />,
          },
          {
            value: 7,
            label: 'Korepetitoriai, mokytojai',
            icon: <TutorsSVG />,
          },
          { value: 8, label: 'Mama ir vaikas', icon: <ParenthoodSVG /> },
          {
            value: 9,
            label: 'Odontologijos klinikos',
            icon: <DentistrySVG />,
          },
          { value: 10, label: 'Optikos centrai', icon: <OpticsSVG /> },
          {
            value: 11,
            label: 'Patalpų valymo paslaugos',
            icon: <CleaningSVG />,
          },
          {
            value: 12,
            label: 'Psichologai',
            icon: <PsychologySVG />,
          },
          {
            value: 13,
            label: 'Reabilitacijos centrai',
            icon: <RehabilitationSVG />,
          },
          {
            value: 14,
            label: 'SPA ir masažo centrai',
            icon: <SpaSVG />,
          },
          { value: 15, label: 'Sporto centrai', icon: <SportsSVG /> },
          {
            value: 16,
            label: 'Veterinarijos klinikos',
            icon: <VeterinarySVG />,
          },
          {
            value: 999,
            label: 'Kitos paslaugos',
            icon: <OtherServicesSVG />,
          },
        ],
        // options: businessFields?.map((field) => ({
        //   value: field.id,
        //   label: field.englishName,
        //   icon: field.imageUrl,
        // })),
      },
      {
        id: 'business-field-other',
        level: 1,
        type: 'search',
      },
      {
        id: 'time-zone',
        level: 1,
        type: 'select',
        options: timezones,
        default: selectDefaultCulture(),
      },
      {
        id: 'name',
        level: 1,
        type: 'search',
      },
      {
        id: 'manager',
        level: 1,
        type: 'search',
      },
      {
        id: 'country',
        level: 1,
        type: 'search',
      },
      {
        id: 'city',
        level: 1,
        type: 'search',
      },
      {
        id: 'street',
        level: 1,
        type: 'search',
      },
      {
        id: 'building-number',
        level: 1,
        type: 'search',
      },
      {
        id: 'office-number',
        level: 1,
        type: 'search',
      },
      {
        id: 'phone-number',
        level: 1,
        type: 'search',
      },
      {
        id: 'email',
        level: 1,
        type: 'search',
      },
    ];
    // }, [user, data, isSuccess]);
  }, [timezones, selectDefaultCulture]);

  // create form state
  const { state, options, resetState, resetOptions } =
    useFormState(setupFields());

  // useEffect(() => console.log({ isSuccess }), [isSuccess]);

  useEffect(() => {
    if (isSuccess) {
      resetState(setupFields());
      resetOptions(setupFields());
    }
  }, [isSuccess, setupFields]);

  useEffect(
    () =>
      setNext(() => () => {
        console.log('BusinessDetails');
        // TODO: POST to B/E, receive UserDetails, updateUser, navigate to dashboard

        console.log({ state });
        const newCompany = {
          name: state['name']?.value?.value,
          businesAreaId: state['businesa-field']?.value?.value,
          ...(state['business-field']?.value === 999 && {
            businessAreaOther: state['business-field-other']?.value?.value,
          }),
          ...(state['time-zone']?.value && {
            timezoneId: state['time-zone']?.value?.value,
          }),
        };

        axios
          .post(
            `${process.env.REACT_APP_API}newCompany`,
            {
              newCompany,
              ...(auto && { newUser: user }),
            },
            { headers: { Authorization: 'Bearer ' + token } },
          )
          .then((apiUserResponse) => {
            console.log({ apiUserResponse });

            if (apiUserResponse.status === 200) {
              updateUser(apiUserResponse.data);

              navigate(APP_ROOT + 'dashboard', { replace: true });
            }
          })
          .catch((err) => {
            console.log({ err });
            if (err.response?.status === 401) {
              navigate(APP_ROOT + 'login');
            }
          });
      }),
    [
      state,
      // updateUser,
      setNext,
      setStage,
      // user,
      navigate,
      APP_ROOT,
      user,
      token,
      auto,
      updateUser,
    ],
  );

  // useEffect(() => console.log({ state, options }), [state, options]);
  // useEffect(() => console.log({ timezones }), [timezones]);

  return (
    <S.BusinessDetails>
      <div className="field-group">
        <InputField
          id={'name'}
          label={tr['company-name']}
          state={state?.['name']}
          options={options?.['name']}
          required
        />

        <InputField
          id={'business-field'}
          label={tr['business-field']}
          state={state?.['business-field']}
          options={options?.['business-field']}
          required
          // stayOpen
        />

        {state?.['business-field']?.value?.value === 999 && (
          <InputField
            id={'business-field-other'}
            label={tr['business-field-other']}
            state={state?.['business-field-other']}
            options={options?.['business-field-other']}
            required
          />
        )}

        <InputField
          id={'time-zone'}
          label={tr['business-time-zone']}
          state={state?.['time-zone']}
          options={options?.['time-zone']}
          required
          isSearchable
        />

        <InputField
          id={'manager'}
          label={tr['manager']}
          state={state?.['manager']}
          options={options?.['manager']}
        />

        <InputField
          id={'country'}
          label={tr['country']}
          state={state?.['country']}
          options={options?.['country']}
        />

        <InputField
          id={'city'}
          label={tr['city']}
          state={state?.['city']}
          options={options?.['city']}
        />

        <InputField
          id={'street'}
          label={tr['street']}
          state={state?.['street']}
          options={options?.['street']}
        />

        <div className="field-group field-group--horizontal">
          <InputField
            id={'building-number'}
            label={tr['building-number']}
            state={state?.['building-number']}
            options={options?.['building-number']}
          />

          <InputField
            id={'office-number'}
            label={tr['office-number']}
            state={state?.['office-number']}
            options={options?.['office-number']}
          />
        </div>

        <InputFieldPhone
          id="phone-number"
          label={tr['phone-number']}
          // disabled={disabled}
          state={state?.['phone-number']}
        />

        <InputField
          id={'email'}
          label={tr['email']}
          state={state?.['email']}
          options={options?.['email']}
        />
      </div>
    </S.BusinessDetails>
  );
};

export default BusinessDetails;
