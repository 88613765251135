import * as S from './SVG.styled';

export default ({ color, width = 30, height = 30, bold, ...rest }) => {
  const content = bold ? (
    <>
      <path
        d="m 29.105337,20.784385 c -1.84713,-2.065574 -4.777612,-4.530876 -8.237547,-4.530876 -3.459935,0 -6.390399,2.465302 -8.237547,4.530876 -1.192866,1.333333 -1.192866,3.347066 0,4.683493 1.847129,2.068573 4.777612,4.530875 8.237547,4.530875 3.459935,0 6.390398,-2.465302 8.237547,-4.530875 1.192884,-1.333333 1.192884,-3.347066 0,-4.683493 z m -1.813663,3.05102 c -1.241557,1.388248 -3.730857,3.719233 -6.426959,3.719233 -2.696102,0 -5.182215,-2.33106 -6.423959,-3.719233 -0.362118,-0.405803 -0.362118,-1.019063 0,-1.424866 1.241556,-1.388248 3.72767,-3.719233 6.423959,-3.719233 2.696102,0 5.182215,2.33106 6.426959,3.719233 0.362118,0.405803 0.362118,1.016007 0,1.42181 z"
        strokeWidth="0.304708"
      />
      <ellipse
        cx="20.867039"
        cy="23.126131"
        rx="3.5420551"
        ry="3.5514297"
        strokeWidth="0.304708"
      />
      <path
        d="M 2.8648423,6.7196585 H 27.002265 c 1.579338,0 2.86353,-1.2875667 2.86353,-2.8710297 V 2.8722763 c 0,-1.5835192 -1.284173,-2.87102965 -2.86353,-2.87102965 H 2.8648423 C 1.2855042,0.00429767 0.00131227,1.2918507 0.00131227,2.8754636 v 0.9763526 c 0,1.5835191 1.28417313,2.8710296 2.86353003,2.8710296 z M 2.4357721,2.8721825 c 0,-0.2379806 0.1917083,-0.4301951 0.4290702,-0.4301951 H 27.002265 c 0.237362,0 0.42907,0.1922145 0.42907,0.4301951 v 0.9763526 c 0,0.2379807 -0.191708,0.4301951 -0.42907,0.4301951 H 2.8648423 c -0.2373619,0 -0.4290702,-0.1922144 -0.4290702,-0.4301951 z"
        strokeWidth="0.304708"
      />
      <path
        d="M 27.001703,8.129206 H 2.86053 c -1.5763008,0 -2.86053015783,1.2875666 -2.86053015783,2.87103 v 0.976352 c 0,1.583519 1.28417315783,2.87103 2.86353005783,2.87103 H 27.000953 c 1.579338,0 2.86353,-1.287567 2.86353,-2.87103 v -0.976352 c 0,-1.5835196 -1.284174,-2.87103 -2.86353,-2.87103 z m 0.42907,3.844289 c 0,0.23798 -0.191709,0.430195 -0.42907,0.430195 H 2.86053 c -0.2373619,0 -0.4290701,-0.192215 -0.4290701,-0.430195 v -0.976353 c 0,-0.237981 0.1917082,-0.430195 0.4290701,-0.430195 h 24.141173 c 0.237361,0 0.42907,0.192214 0.42907,0.430195 z"
        strokeWidth="0.304708"
      />
      <path
        d="M 8.6252755,16.254259 H 2.8648423 c -1.5793381,0 -2.86353003,1.287567 -2.86353003,2.87103 v 0.976352 c 0,1.58352 1.28417313,2.87103 2.86353003,2.87103 h 5.7604332 c 1.5793385,0 2.8635305,-1.287567 2.8635305,-2.87103 v -0.976352 c 0,-1.583519 -1.284174,-2.87103 -2.8635305,-2.87103 z m 0.4290702,3.844289 c 0,0.23798 -0.1917082,0.430214 -0.4290702,0.430214 H 2.8648423 c -0.2373619,0 -0.4290702,-0.192215 -0.4290702,-0.430214 v -0.976353 c 0,-0.23798 0.1917083,-0.430195 0.4290702,-0.430195 h 5.7604332 c 0.237362,0 0.4290702,0.192215 0.4290702,0.430195 z"
        strokeWidth="0.304708"
      />
    </>
  ) : (
    <>
      <path
        d="m 29.23398,21.39525 c -1.8183,-2.0325 -4.7111,-4.4716 -8.0608,-4.4716 -3.3498,0 -6.2331,2.4391 -8.064,4.4716 a 2.9842,2.9842 0 0 0 0,3.9926 c 1.8309,2.0388 4.7111,4.4716 8.064,4.4716 3.3529,0 6.2426,-2.4328 8.0608,-4.4716 a 2.9842,2.9842 0 0 0 0,-3.9926 z m -0.94537,3.1512 c -1.355,1.5126 -4.0966,4.0525 -7.1218,4.0525 -3.0252,0 -5.7668,-2.5399 -7.1249,-4.0525 a 1.73,1.73 0 0 1 0,-2.3099 c 1.3582,-1.5126 4.0966,-4.0525 7.1249,-4.0525 3.0283,0 5.7668,2.5399 7.1218,4.0525 a 1.7237,1.7237 0 0 1 0.0063,2.3099 z"
        strokeWidth="0.31512"
      />
      <path
        d="m 21.17298,20.35625 a 3.0378,3.0378 0 1 0 3.0346,3.0346 3.0378,3.0378 0 0 0 -3.0346,-3.0346 z"
        strokeWidth="0.31512"
      />
      <path
        d="M 2.7163802,6.83695 H 27.34038 a 2.521,2.521 0 0 0 2.521,-2.521 v -1.6544 a 2.521,2.521 0 0 0 -2.521,-2.521 H 2.7163802 a 2.521,2.521 0 0 0 -2.52099996,2.521 v 1.6544 a 2.521,2.521 0 0 0 2.52099996,2.521 z m -1.2605,-4.1754 a 1.2605,1.2605 0 0 1 1.2605,-1.2605 H 27.34038 a 1.2605,1.2605 0 0 1 1.2605,1.2605 v 1.6544 a 1.2605,1.2605 0 0 1 -1.2605,1.2605 H 2.7163802 a 1.2605,1.2605 0 0 1 -1.2605,-1.2605 z"
        strokeWidth="0.31512"
      />
      <path
        d="M 2.7163802,15.34225 H 27.34038 a 2.521,2.521 0 0 0 2.521,-2.521 v -1.6512 a 2.521,2.521 0 0 0 -2.521,-2.521 H 2.7163802 a 2.521,2.521 0 0 0 -2.52099996,2.521 v 1.6544 a 2.521,2.521 0 0 0 2.52099996,2.5178 z m -1.2605,-4.1722 a 1.2605,1.2605 0 0 1 1.2605,-1.2605 H 27.34038 a 1.2605,1.2605 0 0 1 1.2605,1.2605 v 1.6544 a 1.2605,1.2605 0 0 1 -1.2605,1.2605 H 2.7163802 a 1.2605,1.2605 0 0 1 -1.2605,-1.2605 z"
        strokeWidth="0.31512"
      />
      <path
        d="m 9.0597802,17.15725 h -6.5388 a 2.521,2.521 0 0 0 -2.520999956059,2.521 v 1.6544 A 2.521,2.521 0 0 0 2.5209802,23.85365 h 6.5388 a 2.521,2.521 0 0 0 2.5209998,-2.521 v -1.6544 a 2.521,2.521 0 0 0 -2.5209998,-2.521 z m 1.2604998,4.1754 a 1.2605,1.2605 0 0 1 -1.2604998,1.2605 h -6.5388 a 1.2605,1.2605 0 0 1 -1.2605,-1.2605 v -1.6544 a 1.2605,1.2605 0 0 1 1.2605,-1.2605 h 6.5388 a 1.2605,1.2605 0 0 1 1.2604998,1.2605 z"
        strokeWidth="0.31512"
      />
    </>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
