import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';

export const SetListItem = styled.div`
  display: flex;
  gap: 10px;
  min-height: 60px;

  .setListItem__info-container {
    flex: 1;
  }

  .setListItem__action-container {
    align-items: center;
    display: flex;
    gap: 10px;
    width: 125px;
  }

  .setListItem__amount {
    align-items: center;
    border: 1px solid ${colors.bg_grey_2};
    border-radius: 5px;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  .setListItem__amount > div,
  .setListItem__amount .input-wrapper {
    height: calc(100% + 2px);
    /* width: calc(100% + 2px); */
  }

  .setListItem__amount > div {
    margin: -1px;
  }

  .setListItem__trash-wrapper {
    color: ${colors.bg_grey_3};
    cursor: pointer;
    height: 20px;
    width: 20px;

    svg {
      height: 20px;
      width: 20px;
    }

    :hover .setListItem__trash-icon svg {
      color: ${colors.mandyRed};
    }
  }
`;
