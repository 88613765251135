import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateSchedule } from 'redux/actions/scheduleActions';

// style
import * as S from './TimeOff.styled';

// icons
import { Check } from 'common/icons';

// components
import SelectProfessionals from '../../SelectProfessionals/SelectProfessionals';
import SelectExcuses from '../../SelectExcuses/SelectExcuses';
import CustomSelect from '../../CustomSelect/CustomSelect';
import TimeAndDateChild from '../../CustomSelect/children/TimeAndDateChild/TimeAndDateChild';
import SimpleSelectChild from '../../CustomSelect/children/SimpleSelectChild/SimpleSelectChild';
import ProfessionalSelectChild from '../../CustomSelect/children/ProfessionalSelectChild/ProfessionalSelectChild';
import Overlay from 'components/Overlay/Overlay';
import SelectionPanel from 'components/Overlay/SelectionPanel/SelectionPanel';
import FunctionBar from 'components/FunctionBar/FunctionBar';

// contexts
import { CreateOrderContext } from 'context/SchedulerCreatingEventContext';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import useModal from 'hooks/useModal';
import useObserver from 'hooks/useObserver';
import { listOfExcuses } from 'common/helpers/tempData';

const TimeOff = ({ closeAction, setButtons, saveFunc, data, functions }) => {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tr = useTranslate().use().global;
  const { isShowing, toggle } = useModal();
  const displayRef = useRef();
  let { width } = useObserver({
    ref: displayRef,
    debounceDelay: 10,
  });

  let professionalIds =
    JSON.parse(sessionStorage.getItem('selectedProfessionalIds')) ||
    data?.professionals;

  let cellTime = JSON.parse(sessionStorage.getItem('selectedProfessionalTime'));

  cellTime = cellTime ? new Date(cellTime) : new Date();

  // -- state
  // --- local
  const [professional, setProfessional] = useState([]);
  const [excuse, setExcuse] = useState();
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );

  const [modalSwitch, setModalSwitch] = useState('');
  const [displayModal, setDisplayModal] = useState();
  const [openCalendar, setOpenCalendar] = useState(false);

  const [selectedTime, setSelectedTime] = useState(
    data?.start ? new Date(data?.start) : null,
  );

  const [functionBarItems, setFunctionBarItems] = useState(functions);

  // --- global
  // const { listOfExcuses } = useContext(ScheduleContext);

  const { employees } = useSelector((state) => state.calendarView);
  const dateState = useSelector((state) => state.scheduleReducer.dateState);

  // -- side effects
  useEffect(() => {
    switch (modalSwitch) {
      case 'select-professional':
        setDisplayModal(
          <SelectionPanel
            key="footer-discount"
            closeAction={() => {
              sessionStorage.getItem('selectedProfessionalIds') === null
                ? navigate(-1)
                : toggle();
            }}
            headerTitle={tr.professionals}
            searchTitle={tr.professionalSearch}
            array={employees}
            arrayFilter={selectProfessionalsFilter}
            childProps={{
              selectedProf: getProfesionalsIds(professional),
              select: selectProfessional,
            }}
            Child={SelectProfessionals}
          />,
        );
        break;
      case 'select-reason':
        setDisplayModal(
          <SelectionPanel
            closeAction={handleCloseAction}
            headerTitle={tr.reason}
            childProps={{
              arr: listOfExcuses,
              select: setExcuse,
              currentlySelected: excuse,
            }}
            Child={SelectExcuses}
          />,
        );
        break;
      default:
        return;
    }
  }, [modalSwitch]);

  useEffect(() => {
    if (professionalIds) {
      const filteredProf = employees.filter((obj) => {
        return professionalIds.find((item) => item === obj.id);
      });
      setProfessional(filteredProf);
    } else if (professional.length === 0 && window.innerWidth < 960) {
      toggle();
      setModalSwitch('select-professional');
    }

    return () => {
      sessionStorage.removeItem('selectedProfessionalIds');
      sessionStorage.removeItem('selectedProfessionalTime');
      dispatch(
        updateSchedule({
          cell: null,
          selectedProfessional: null,
        }),
      );
    };
  }, []);

  useEffect(() => {
    if (selectedTime) {
      setTime(selectedTime);
    } else {
      const currTime = cellTime ? cellTime : dateState;

      setSelectedTime(currTime);
      setTime(currTime);
    }
  }, []);

  useEffect(() => {
    if (!selectedTime) return;

    setStartTime(() => {
      const x = new Date(selectedTime);
      const start = new Date(startTime);

      x.setHours(start.getHours(), start.getMinutes(), 0, 0);
      return x;
    });

    setEndTime(() => {
      const x = new Date(selectedTime);
      const end = new Date(endTime);

      x.setHours(end.getHours(), end.getMinutes(), 0, 0);
      return x;
    });
  }, [selectedTime]);

  useEffect(() => {
    if (!!data?.excuseId) {
      const found = listOfExcuses.find((item) => item.id === data.excuseId);

      if (found) setExcuse(found);
    } else if (data?.title) {
      setExcuse({
        label: data?.title,
        type: 'other',
        category: 'excuse',
      });
    }
  }, [data]);

  useEffect(() => {
    if (modalSwitch && width && displayRef.current.offsetWidth !== width) {
      setModalSwitch(null);
      toggle();
    }
  }, [width]);

  useEffect(() => {
    if (selectedTime) {
      let hours = selectedTime.getHours();
      let minutes = selectedTime.getMinutes();
      if (hours.toString().length === 1) hours = `0${hours}`;
      if (minutes.toString().length === 1) minutes = `0${minutes}`;
      setStartTime(selectedTime);

      setEndTime(() => {
        if (data?.duration) {
          const hours = +data.duration.split(':')[0];
          const minutes = +data.duration.split(':')[1];

          return new Date(
            new Date(selectedTime).getTime() +
              (hours * 60 + minutes) * 60 * 1000,
          );
        } else {
          return new Date(new Date(selectedTime).getTime() + 60 * 60 * 1000);
        }
      });
    }
  }, [data]);

  // setup header button
  useEffect(() => {
    // setButtons({ type: 'setup', value: [{ id: 'save', title: tr['save'] }] });
    setButtons({
      type: 'setup',
      value: [{ id: 'save', icon: <Check bold /> }],
    });
    return () => setButtons({ type: 'show', id: 'save', value: false });
  }, [setButtons, tr]);

  // update header button props
  useEffect(() => {
    let endHours = endTime.getHours();
    let endMinutes = endTime.getMinutes();

    // check if all fiels selected
    if (data) {
      if (!professional[0]) return;

      let [hours, minutes] = data.duration.split(':');
      hours = +hours * 60 * 60 * 1000;
      minutes = +minutes * 60 * 1000;

      const dataEndTime = selectedTime.getTime() + hours + minutes;

      if (
        data.professionals[0] !== professional[0]?.id ||
        selectedTime.getTime() !== startTime.getTime() ||
        dataEndTime !== endTime.getTime() ||
        (excuse.id && data.excuseId !== excuse.id) ||
        data.title !== excuse.label
      ) {
        setButtons({
          type: 'update',
          id: 'save',
          value: {
            show: true,
            action: saveTimeOff,
          },
        });
      } else {
        setButtons({ type: 'show', id: 'save', value: false });
      }
    } else {
      if (
        Object.keys(professional).length !== 0 &&
        (endHours !== 0 || endMinutes !== 0) &&
        excuse
      ) {
        setButtons({
          type: 'update',
          id: 'save',
          value: {
            show: true,
            action: saveTimeOff,
          },
        });
      } else {
        setButtons({ type: 'show', id: 'save', value: false });
      }
    }
  }, [
    startTime,
    endTime,
    excuse,
    setButtons,
    professional,
    selectedTime,
    data,
  ]);

  useEffect(() => {
    if (!excuse) return;

    const hours = endTime.getHours();
    const minutes = endTime.getMinutes();

    if (!hours && !minutes)
      setEndTime(new Date(startTime.getTime() + 60 * 60 * 1000));
  }, [excuse]);

  useEffect(() => {
    const btn = document.getElementById('timeOff-fab');
    if (!btn) return;
    btn.style.zIndex = 1001;
  }, []);

  useEffect(() => {
    if (!functions) return;
    setFunctionBarItems((prev) => {
      const copy = prev.filter((func) => func.id !== 'edit');

      return adaptFunctionItems(copy);
    });
  }, []);

  // Functions
  const saveTimeOff = () => {
    const newExcuse = {
      id: data?.id || excuse.id + Math.floor(Math.random() * 999),
      excuseId: excuse.id,
      title: excuse.label,
      member: professional[0].id,
      roomId: 1,
      status: 'excuse',
      category: excuse.category,
      orders: [
        {
          startDate: new Date(startTime),
          duration: endTime.getTime() - startTime.getTime(),
        },
      ],
    };

    if (saveFunc) {
      saveFunc(newExcuse);
      closeAction();
    }
  };

  const handleCloseAction = (action) => {
    switch (action) {
      default:
        setModalSwitch(null);
        toggle();
        return;
    }
  };

  const currentDataChange = (data) => {
    setSelectedTime(new Date(data));
    setOpenCalendar(false);
  };

  const selectProfessionalsFilter = (input, array, setState) => {
    if (!input) {
      setState(array);
    } else {
      let filteredInput = array.filter((item) => {
        return item.fieldName.toLowerCase().includes(input.toLowerCase());
      });
      setState(filteredInput);
    }

    // if (input.length < 3) {
    //   setState(array);
    // } else {
    //   let filteredInput = array.filter((item) => {
    //     return item.fieldName.toLowerCase().includes(input.toLowerCase());
    //   });
    //   setState(filteredInput);
    // }
  };

  const inputToDateObj = (input, type) => {
    const currentDate = selectedTime ? new Date(selectedTime) : new Date();

    let hour = input.split(':')[0];
    let minute = input.split(':')[1];

    switch (type) {
      case 'start':
        currentDate.setHours(hour, minute, 0, 0);
        handleStartInput(currentDate);
        break;
      case 'end':
        currentDate.setHours(hour, minute, 0, 0);
        if (startTime.getTime() + 60 * 60 * 1000 <= currentDate.getTime()) {
          setEndTime(currentDate);
        } else {
          setEndTime(new Date(startTime.getTime() + 60 * 60 * 1000));
        }
        break;
      default:
        return;
    }
  };

  const handleStartInput = (currentDate) => {
    let diff = Math.ceil((endTime.getTime() - startTime.getTime()) / 60 / 1000);
    const diffMinutes = diff % 60;
    const diffHours = Math.floor(diff / 60);
    setStartTime(currentDate);
    if (diff > 60) {
      let newEndtime = new Date(currentDate);
      newEndtime.setHours(
        newEndtime.getHours() + diffHours,
        newEndtime.getMinutes() + diffMinutes,
        0,
        0,
      );
      setEndTime(newEndtime);
    } else {
      setEndTime(new Date(currentDate.getTime() + 60 * 60 * 1000));
    }
  };

  const selectProfessional = (prof) => {
    sessionStorage.setItem(
      'selectedProfessionalIds',
      JSON.stringify([prof.id]),
    );
    setProfessional([prof]);
    closeModal();
  };

  const closeModal = () => {
    toggle();
    setModalSwitch(null);
  };

  const getProfesionalsIds = (array) => {
    const ids = [];
    array.forEach((item) => ids.push(item.id));

    return ids;
  };

  const setTime = (timeObj) => {
    let hours = timeObj.getHours();
    let minutes = timeObj.getMinutes();
    if (hours.toString().length === 1) hours = `0${hours}`;
    if (minutes.toString().length === 1) minutes = `0${minutes}`;
    setStartTime(timeObj);
    // setEndTime(new Date(new Date(timeObj).getTime() + 60 * 60 * 1000));
  };

  const adaptFunctionItems = (items) => {
    if (!items) return;

    const newForm = items.map((item) => {
      return {
        id: item.id,
        acl: 1,
        icon: item.icon,
        iconTitle: item.label,
        tooltip: item.label,
        scope: 3,
        ...(item.fontColor && { color: item.fontColor }),
        ...(item.onClick && { action: item.onClick }),
        closeOnClick: true,
        color: item.fontColor,
      };
    });

    return newForm;
  };

  return (
    <CreateOrderContext.Provider
      value={{
        professional: { currSelectedProfessional: professional },
        excuse: { excuse, setExcuse },
      }}
    >
      <S.TimeOff openCalendar={openCalendar} excuse={excuse} ref={displayRef}>
        {data && (
          <FunctionBar fixed functions={functionBarItems} id="timeOff-fab" />
        )}

        <div className="time-event__wrapper">
          {professional?.length ? (
            <ProfessionalSelectChild
              setShowSelect={() => {
                setModalSwitch('select-professional');
                toggle();
              }}
              currentProfessional={professional[0]}
            />
          ) : null}
          <div className="proffesionalTimeAndDate">
            <CustomSelect
              label={`${tr.date} | ${tr.time}`}
              paddingSize="even"
              child={
                <TimeAndDateChild
                  info={{ startTime, endTime }}
                  // selectedTime={selectedTime}
                  currentDataChange={currentDataChange}
                  inputToDateObj={inputToDateObj}
                  showCalendar={{
                    openCalendar: openCalendar,
                    setOpenCalendar: setOpenCalendar,
                  }}
                />
              }
              openInput={openCalendar}
            />
          </div>

          <div className="excusesSelect">
            <CustomSelect
              label={tr.reason}
              action={() => {
                setModalSwitch('select-reason');
                toggle();
              }}
              selected={excuse}
              arrow={true}
              paddingSize="even"
            >
              <SimpleSelectChild selected={excuse} placeholder={tr.select} />
            </CustomSelect>
          </div>
        </div>
      </S.TimeOff>
      <Overlay isVisible={isShowing} hide={toggle}>
        {displayModal}
      </Overlay>
    </CreateOrderContext.Provider>
  );
};

export default TimeOff;
