import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const SetList = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-family: ${fontFamilies.family_Secondary};
  max-width: 660px;
  padding: 40px;
  position: relative;

  svg {
    height: 100%;
    width: 100%;
  }

  .list__header {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .list__title-wrapper {
      display: flex;

      h2 {
        font-size: 1.875rem;
        font-weight: 500;
        margin: 0;
        padding: 0;
      }
    }

    .list__setIcon-wrapper {
      flex-shrink: 0;
      height: 30px;
      margin-right: 25px;
      margin-top: 3px;
      width: 30px;
    }

    .list__exitIcon-wrapper {
      cursor: pointer;
      padding: 4px 6px;
      position: absolute;
      right: 24px;
      top: 24px;

      svg {
        height: unset;
        width: unset;
      }
    }
  }

  .list__path-wrapper {
    display: flex;
    font-size: 0.875rem;
    padding-top: 30px;

    > div {
      margin-right: 3px;
    }

    .last {
      font-weight: 600;
    }
  }

  .list__list-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    padding-top: 30px;

    .list__label-container {
      border-bottom: 1px solid ${colors.bg_grey_2};
      color: ${colors.bg_grey_3};
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;

      span {
        display: block;
        font-size: 0.875rem;

        &:last-child {
          width: 110px;
        }
      }
    }

    .list__list-container {
      flex: 1;
      margin-bottom: 10px;
      overflow: auto;
      padding-top: 10px;

      ::-webkit-scrollbar {
        height: 0;
        width: 0;
      }

      > div {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &-empty {
        border: 1px solid ${colors.bg_grey_2};
        border-radius: 5px;
        color: ${colors.bg_grey_2};
        padding: 10px;
        text-align: center;
      }
    }
  }

  .list__save-button {
    height: 40px;
    flex-shrink: 0;
    width: 100%;

    button {
      width: 100%;
    }
  }
`;
