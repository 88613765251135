import { useContext } from 'react';
import ClickAwayListener from 'react-click-away-listener';

// style
import * as S from './ProfessionalHeader.styled';

// icons
import { VerticalEllipsis, PinSVG } from 'common/icons';

// components
import Overlay from 'components/Overlay/Overlay';
import WorkerFunctions from 'components/Overlay/WorkerFunctions/WorkerFunctions';
import ContextTree from 'components/ContextTree/ContextTree';
import Avatar from 'components/Avatar/Avatar';

// contexts
import { UserContext } from 'context/UserContext';

// hooks
import useMediaQuery from 'hooks/useMediaQuery';
import useModal from 'hooks/useModal';
import { useContextMenuItems } from 'hooks/useContextMenuItems';
import { useTranslate } from 'hooks/useTranslate';
import { fillImgPath } from 'common/helpers/imagesFunctions';

const ProfessionalHeader = ({
  id,
  pic,
  firstName,
  lastName,
  headerMenu,
  position,
  pinToStart,
  prioritize,
  select,
  selected,
  openPersonal,
  openMonthly,
  variant = 'single',
}) => {
  // Hooks
  const tr = useTranslate().use().global;
  const { locale } = useContext(UserContext);
  const isMobile = useMediaQuery('(max-width: 960px)');
  const { isShowing, toggle } = useModal();
  const contextMenuItems = useContextMenuItems([
    !prioritize && 'pin',
    openPersonal && 'individual-scheduler',
    openMonthly && 'monthly-scheduler',
  ]);

  const changeToggleState = () =>
    // isMobile ? toggle() : headerMenu.setState(!headerMenu.state);
    isMobile ? toggle() : headerMenu.setState(true);

  const getContextItems = (action) => {
    if (!contextMenuItems) return [];

    return contextMenuItems.map((item) => {
      const newAction = () => {
        if (item.id === 'pin') {
          pinToStart && pinToStart(id);
        }

        if (item.id === 'individual-scheduler') {
          openPersonal && openPersonal();
        }

        if (item.id === 'monthly-scheduler') {
          openMonthly && openMonthly();
        }
      };
      return {
        ...item,
        onClick: () => {
          newAction();
          action && action();
        },
      };
    });
  };

  return (
    <>
      <S.Wrapper variant={variant} locale={locale}>
        <S.ImgContainer selected={selected} variant={variant}>
          <div id="header-img" className="img-wrapper" onClick={select}>
            <Avatar
              imagePath={fillImgPath(pic)}
              fullName={`${firstName} ${lastName}`}
            />
          </div>
          <S.OfficeWrapper>
            <S.OfficeNumber variant={variant}>2</S.OfficeNumber>
          </S.OfficeWrapper>
          <ClickAwayListener onClickAway={() => headerMenu.setState(false)}>
            <div className="actions-wrapper">
              <div
                id={`context-${id}`}
                className="actions-wrapper__context hover"
                onClick={() => changeToggleState()}
              >
                <VerticalEllipsis />
                {!isMobile && (
                  <ContextTree
                    containerId={`context-${id}`}
                    parentId={'schedule'}
                    openOnClick
                    // openOnContext
                    items={getContextItems}
                    fixedWidth={200}
                  />
                )}
              </div>
            </div>
          </ClickAwayListener>
          {prioritize && pinToStart && (
            <div className="pin hover" onClick={pinToStart}>
              <PinSVG />
            </div>
          )}
        </S.ImgContainer>
        <div className="details">
          <h4>
            {firstName} {!isMobile && lastName}
          </h4>
          <span>{position}</span>
        </div>
      </S.Wrapper>

      <Overlay isVisible={isShowing} hide={toggle}>
        <WorkerFunctions id={id} prioritize={prioritize} />
      </Overlay>
    </>
  );
};

export default ProfessionalHeader;
