import * as S from './SVG.styled';

export default ({ bold, ...rest }) => {
  const content = bold ? (
    <>
      <path
        d="M 19.21845,22.44645 19.03515,22.4067 C 19.01985,21.2298 19.0137,20.0529 19.0137,19.2918 v -3.6132 l 0.0825,-4.07784 c 0.0092,-0.43407 -0.2139,-0.84063 -0.5808,-1.066845 L 18.02325,10.23129 C 17.7267,10.047885 17.3661,10.00203 17.0328,10.099845 l -6.349035,1.889145 c -0.51966,0.152835 -0.87426,0.6297 -0.87426,1.170765 v 1.121865 c 0,0.5961 0.431025,1.10658 1.02099,1.20753 l 0.50439,0.0855 c 0.02445,0.68175 0.03668,1.3848 0.03668,2.4027 v 1.31445 c 0,0.75195 -0.0061,1.9197 -0.02139,3.09045 l -0.29346,0.06735 c -0.55329,0.1284 -0.944565,0.62355 -0.944565,1.1922 v 1.39695 c 0,0.67545 0.54717,1.22265 1.22274,1.22265 h 7.63291 c 0.6756,0 1.2228,-0.5472 1.2228,-1.22265 V 23.6418 c 0,-0.5778 -0.4065,-1.0791 -0.97215,-1.19835 z m -5.526735,1.3725 c 0.05502,-0.1344 0.08559,-0.2811 0.08865,-0.4371 0.0214,-1.2105 0.03669,-2.81535 0.03669,-4.09005 v -1.31445 c 0,-1.54065 -0.03057,-2.421 -0.08254,-3.50619 -0.01529,-0.284295 -0.125325,-0.544125 -0.302625,-0.745875 L 16.6293,12.7746 16.57125,15.65415 v 3.63765 c 0,0.9813 0.0092,2.65335 0.03675,4.13295 0,0.13755 0.02745,0.26895 0.0702,0.3912 h -2.986485 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 15.1377,10.13652 c 1.9716,0 3.5154,-1.54371 3.5154,-3.515385 0,-1.971675 -1.5438,-3.515385 -3.5154,-3.515385 -1.971735,0 -3.515445,1.54371 -3.515445,3.515385 0,1.971675 1.54371,3.515385 3.515445,3.515385 z m 0,-4.58529 c 0.6297,0 1.0698,0.44019 1.0698,1.069905 0,0.6297 -0.4401,1.06989 -1.0698,1.06989 -0.629775,0 -1.069965,-0.44019 -1.069965,-1.06989 0,-0.629715 0.44019,-1.069905 1.069965,-1.069905 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="M 15,0 C 6.728145,0 0,6.728145 0,15 0,23.2719 6.728145,30 15,30 23.2719,30 30,23.2719 30,15 30,6.728145 23.2719,0 15,0 Z m 0,27.55455 C 8.076225,27.55455 2.44548,21.92385 2.44548,15 2.44548,8.076225 8.076225,2.44548 15,2.44548 c 6.92385,0 12.55455,5.630745 12.55455,12.55452 0,6.92385 -5.6307,12.55455 -12.55455,12.55455 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
    </>
  ) : (
    <>
      <path
        d="m 10.75845,14.885265 1.03569,0.172035 c 0.04141,0.89865 0.06054,1.71765 0.06054,3.04665 v 1.37025 c 0,0.8922 -0.0096,2.34855 -0.02868,3.7317 l -0.790305,0.1848 c -0.289995,0.0669 -0.49395,0.32505 -0.49395,0.62145 v 1.45635 c 0,0.35055 0.2868,0.63735 0.63735,0.63735 H 19.1364 c 0.35055,0 0.63735,-0.2868 0.63735,-0.63735 v -1.45635 c 0,-0.30285 -0.2103,-0.56085 -0.5067,-0.6246 l -0.6915,-0.1434 c -0.02235,-1.3926 -0.02865,-2.8617 -0.02865,-3.7668 v -3.7794 l 0.08595,-4.25118 C 18.636,11.22051 18.5214,11.010195 18.33015,10.8891 l -0.513,-0.315495 C 17.661,10.47801 17.4729,10.45251 17.30085,10.50669 l -6.618885,1.969395 c -0.27087,0.07968 -0.455715,0.328245 -0.455715,0.611865 v 1.169535 c 0,0.3123 0.22626,0.576795 0.5322,0.62778 z m 0.7425,-1.32249 5.8509,-1.73997 -0.07965,3.862395 v 3.79215 c 0,1.0197 0.0094,2.7597 0.03825,4.299 0.0063,0.29625 0.2166,0.55125 0.5067,0.61185 l 0.6819,0.1434 v 0.3027 H 11.816445 V 24.522 l 0.78075,-0.18165 c 0.28362,-0.0669 0.487575,-0.31875 0.49395,-0.6087 0.02231,-1.2588 0.03824,-2.92545 0.03824,-4.25115 v -1.37025 c 0,-1.5933 -0.03186,-2.50155 -0.08604,-3.623325 -0.01593,-0.29955 -0.235815,-0.548115 -0.532185,-0.59592 l -1.010205,-0.1689 v -0.15615 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 15.1464,9.292485 c 1.6986,0 3.02745,-1.32888 3.02745,-3.027405 0,-1.69854 -1.32885,-3.027405 -3.02745,-3.027405 -1.69851,0 -3.027375,1.328865 -3.027375,3.027405 0,1.698525 1.328865,3.027405 3.027375,3.027405 z m 0,-4.78011 c 1.00065,0 1.75275,0.75525 1.75275,1.752705 0,0.997455 -0.75525,1.752705 -1.75275,1.752705 -0.997425,0 -1.75269,-0.75207 -1.75269,-1.752705 0,-1.000635 0.75207,-1.752705 1.75269,-1.752705 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 15.00315,30 c 8.2728,0 15,-6.73035 15,-15 0,-8.269605 -6.73035,-15 -15,-15 C 6.73359,0 0,6.730395 0,15 0,23.26965 6.730395,30 15,30 Z m 0,-28.725303 c 7.56855,0 13.7253,6.156783 13.7253,13.725303 0,7.56855 -6.15675,13.7253 -13.7253,13.7253 C 7.434675,28.7253 1.274697,22.56855 1.274697,15 1.274697,7.43148 7.434675,1.274697 15.00315,1.274697 Z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
    </>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={30}
      version="1.1"
      viewBox="0 0 30 30"
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
