import { useState, useRef, useEffect, useContext } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { ScheduleContext } from 'context/ScheduleContext';
import { toast } from 'react-toastify';

// style
import * as S from './AppointmentWrapper.styled';

import AppointmentCard from 'components/AppointmentCard/AppointmentCard';
import AppointmentsDetails from 'components/AppointmentCard/AppointmentsDetails/AppointmentsDetails';
import ContextTree from 'components/ContextTree/ContextTree';
import AppointmentsDetailsMobile from 'components/AppointmentCard/AppointmentsDetails/AppointmentsDetailsMobile/AppointmentsDetailsMobile';
import Overlay from 'components/Overlay/Overlay';
import MultiAppointments from 'components/AppointmentCard/MultiAppointmentsCard/MultiAppointmentsCard';

// hooks
import useMediaQuery from 'hooks/useMediaQuery';
import useTranslate from 'hooks/useTranslate';
import useAppointmentCard from 'hooks/schedule/useAppointmentCard';
import { formatToIsoWithoutMilliseconds } from 'common/helpers/dateOperations';
import { SCHEDULE_CELL_HEIGHT } from 'common/constants';
import {
  getAppointmentHeight,
  getAppointmentPosition,
  getColumnOrders,
  getOrderTimeIntervals,
  willOrderOverlap,
} from 'common/helpers/scheduleFunctions';

const AppointmentWrapper = ({
  order,
  selected,
  multiBooking,
  parentHeight,
  updateAppointmentPosition,
  updateAppointmentHeight,
  updateToPendingOrders,
  dragging,
  widths,
  getHoverTime,
  timeOff,
  functions,
  globalOrders,
  hourInterval,
  startHour,
  notOverlapingStatuses,
}) => {
  const {
    id,
    sequenceNo,
    topPosition,
    height,
    completionStatus,
    services,
    splits,
    employeeId,
    customer,
    amountCovered,
    finalAmountWithVat,
    startTimeUtc,
    durationInMinutes,
    note,
  } = order;
  const isMobile = useMediaQuery('(max-width: 960px)');
  const tr = useTranslate().use().global;
  const { dataSource, setScheduleScrollTargetNode } =
    useContext(ScheduleContext);
  // const timeOffMenuItems = useContextMenuItems(['edit', 'delete-timeOff']);

  const handleClickAway = (e) => {
    if (isMobile) return;

    functions.raiseHead(false);
    setDisplayContextMenu(false);
    setFocusing(0);
    setDraggable(false);
  };

  const [containerTop, setContainerTop] = useState(topPosition);
  const [containerHeight, setContainerHeight] = useState(height);
  const [containerLeft, setContainerLeft] = useState(null);
  const [focusing, setFocusing] = useState(0);
  const [displayContextMenu, setDisplayContextMenu] = useState(false);
  const [displayDetails, setDisplayDetails] = useState(false);
  const [draggable, setDraggable] = useState(selected);
  const [hoverTime, setHoverTime] = useState(null);
  const containerRef = useRef(null);
  const scrollTimer = useRef(null);

  const {
    getBalanceActions,
    getStatusActions,
    getAppointemtFunctions,
    getFunctionList,
    isModalShowing,
    modalToggle,
    modalContent,
  } = useAppointmentCard({
    appointment: order,
    handleClickAway,
    setDisplayDetails,
  });

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const hasBreak = !!services.find((item) => item.serviceId === 1);
  let isAppointmentValid = true;

  useEffect(() => {
    setContainerTop(topPosition);
    setContainerHeight(height);
  }, [topPosition, height]);

  // Functions
  const setOrderIndication = (isOverlaping, elements) => {
    const indicationClass = isAppointmentValid ? 'warning' : 'danger';

    elements.forEach((element) => {
      element.classList.remove('warning', 'danger');

      if (isOverlaping) {
        element.classList.add(indicationClass);
      }
    });
  };

  const resizeAppointment = (e, height, direction) => {
    document.body.style.cursor = 'ns-resize';

    const startPosition = e.pageY;

    handleResizeHeight(startPosition, height, direction);
  };

  const handleResizeHeight = (startPosition, height, direction) => {
    let newHeight = calculateNewDivHeight(parentHeight, height, 0);
    let newTop = containerTop;

    const excludeOverlaping = notOverlapingStatuses.includes(completionStatus);

    const ordersElements = document.querySelectorAll(`#order-${id}`);

    setFocusing(1);

    const cells = document.querySelectorAll('.dataPanelCell');

    if (cells) {
      for (const cell of cells) {
        cell.classList.add('dragging');
      }
    }

    const initialContainerHeight = containerHeight;
    const initialContainerTop = containerTop;
    const initialContainerLeft = containerLeft;

    const onMouseMove = (e) => {
      const columnDiv =
        e.target.closest('#collumn') ||
        e.target.closest('.collumn-body')?.querySelector('#collumn');

      const diff =
        direction === 'up' ? startPosition - e.pageY : e.pageY - startPosition;
      newHeight = calculateNewDivHeight(parentHeight, height, diff);

      setContainerHeight((prev) => {
        if (newHeight > 0) {
          if (direction === 'up' && prev !== newHeight) {
            setContainerTop((x) => {
              newTop = x - (newHeight - prev) / 2;
              return newTop;
            });
          }

          return newHeight;
        }

        return prev;
      });

      if (columnDiv && !excludeOverlaping) {
        const newOrder = getAppointmentHeight(
          order,
          newHeight,
          newTop,
          hourInterval,
          startHour,
        );
        if (newOrder) {
          let columnOrders = getColumnOrders(
            globalOrders,
            newOrder.updatedOrder.employeeId,
            formatToIsoWithoutMilliseconds(
              new Date(newOrder.updatedOrder.startTimeUtc),
            ).split('T')[0],
          );

          columnOrders = columnOrders.filter(
            (item) =>
              item.id !== newOrder.updatedOrder.id &&
              !notOverlapingStatuses.includes(item.completionStatus),
          );

          const isOverlaping = willOrderOverlap(
            columnOrders,
            newOrder.newIntervals,
          );

          const targetEmployeeSchedule = dataSource.find(
            (schedule) => schedule.employee.id === employeeId,
          );

          isAppointmentValid =
            !isOverlaping ||
            (isOverlaping &&
              targetEmployeeSchedule.employee.sectorSettings
                .allowOrdersOverlap);

          setOrderIndication(isOverlaping, ordersElements);
        }
      }
    };

    const onMouseUp = () => {
      document.body.style.cursor = 'auto';
      document.body.removeEventListener('mousemove', onMouseMove);

      console.log('changing height');

      if (isAppointmentValid) {
        updateAppointmentHeight(order, newHeight, newTop);
      } else {
        setContainerHeight(initialContainerHeight);
        setContainerTop(initialContainerTop);
        setContainerLeft(initialContainerLeft);
        toast.error(`${tr['order']} ${tr['overplaps'].toLowerCase()}`);
      }

      // updateAppointmentPosition(id, newHeight, newTop, undefined, direction);
      setFocusing(0);

      if (cells) {
        for (const cell of cells) {
          cell.classList.remove('dragging');
        }
      }
    };

    document.body.addEventListener('mousemove', onMouseMove);
    document.body.addEventListener('mouseup', onMouseUp, { once: true });
  };

  const calculateNewDivHeight = (
    containerHeight,
    divHeight,
    heightIncrease,
  ) => {
    const diff = divHeight + heightIncrease;

    return (
      ((Math.round(
        (diff > 0 ? diff : SCHEDULE_CELL_HEIGHT) / SCHEDULE_CELL_HEIGHT,
      ) *
        SCHEDULE_CELL_HEIGHT) /
        containerHeight) *
      100
    );
  };

  const dragAppointment = (event, elementID) => {
    if (event.button !== 0) return;

    document.body.style.cursor = 'grab';

    let newTop;
    let newLeft;
    let newWidth;
    let dataId;

    const excludeOverlaping = notOverlapingStatuses.includes(completionStatus);

    const checkColumn = saveToOtherCollumn(event);
    dataId = checkColumn ? checkColumn : dataId;

    const draggableElement = document.getElementById(elementID);
    const startColumn =
      event.target.closest('#collumn') ||
      event.target.closest('.collumn-body')?.querySelector('#collumn');

    const ordersElements = document.querySelectorAll(`#order-${id}`);

    // multiBooking.width = 100;
    // multiBooking.left = 0;

    const mousePositionInElement = {
      top: event.clientY - containerRef.current.getBoundingClientRect().top,
      bottom:
        containerRef.current.getBoundingClientRect().bottom - event.clientY,
    };

    setFocusing(1);

    dragging.setState((prev) => {
      return {
        ...prev,
        moving: true,
        appId: id,
        ...(!!timeOff && { timeOff: true }),
      };
    });

    const cells = document.querySelectorAll('.dataPanelCell');

    if (cells) {
      for (const cell of cells) {
        cell.classList.add('dragging');
      }
    }

    const scheduleDiv =
      event.target.closest('#schedule') ||
      event.target.closest('.collumn-body')?.querySelector('#schedule');

    const scrollSchedule = (diffToScheduler, tillBottom) => {
      if (diffToScheduler <= 20) {
        if (!scrollTimer.current) {
          scrollTimer.current = setInterval(() => {
            scheduleDiv.scrollBy({
              top: -20,
              behavior: 'smooth',
            });

            setContainerTop(100 * (scheduleDiv.scrollTop / parentHeight));
          }, 100);
        }
      } else if (tillBottom <= 20) {
        if (!scrollTimer.current) {
          scrollTimer.current = setInterval(() => {
            scheduleDiv.scrollBy({
              top: +20,
              behavior: 'smooth',
            });

            setContainerTop((prev) => {
              const x = (SCHEDULE_CELL_HEIGHT / parentHeight) * 100;

              if (prev + x + height > 100) {
                return prev;
              } else {
                return prev + x;
              }
            });
          }, 120);
        }
      } else if (scrollTimer.current) {
        clearInterval(scrollTimer.current);
        scrollTimer.current = null;
      }
    };

    const initialContainerTop = containerTop;
    const initialContainerLeft = containerLeft;

    const onMouseMove = (e) => {
      const isParentPosition = event.target
        .closest('#collumn')
        .getBoundingClientRect();

      // const cellX = (widths.parentWidth * 100) / widths.appointmentWidth;

      // const mousePositionX = e.clientX - isParentPosition.left + 41;
      const mousePositionY = e.clientY - isParentPosition.top;

      const elementPositionY =
        containerRef.current.getBoundingClientRect().top - isParentPosition.top;

      const queueDiv = e.target.closest('#pending');
      const columnDiv =
        e.target.closest('#collumn') ||
        e.target.closest('.collumn-body')?.querySelector('#collumn');

      if (queueDiv && !timeOff) {
        isAppointmentValid = true;
        ordersElements.forEach((element) => {
          element.classList.remove('warning');
          element.classList.remove('danger');
        });

        setFocusing(5);

        const x =
          Math.floor(
            (mousePositionY - mousePositionInElement.top) /
              SCHEDULE_CELL_HEIGHT,
          ) * SCHEDULE_CELL_HEIGHT;

        newTop = 100 * (x / parentHeight);

        const diffToCenter =
          queueDiv.getBoundingClientRect().width / 2 -
          isParentPosition.width / 2;

        const divDiff =
          queueDiv.getBoundingClientRect().left -
          isParentPosition.left +
          diffToCenter;

        newLeft = (100 * divDiff) / widths.appointmentWidth;

        draggableElement.style.left =
          queueDiv.getBoundingClientRect().left -
          startColumn.getBoundingClientRect().left +
          5 +
          'px';
        draggableElement.style.width =
          queueDiv.getBoundingClientRect().width - 10 + 'px';
      } else newLeft = null;

      // handling hover effect by elements top position
      if (columnDiv) {
        draggableElement.style.left =
          columnDiv.getBoundingClientRect().left -
          startColumn.getBoundingClientRect().left +
          'px';

        draggableElement.style.width =
          columnDiv.getBoundingClientRect().width + 'px';

        // ----
        setHoverTime(getHoverTime(elementPositionY));
        setFocusing(1);

        // NEW TOP
        const x =
          Math.floor(
            (mousePositionY - mousePositionInElement.top) /
              SCHEDULE_CELL_HEIGHT,
          ) * SCHEDULE_CELL_HEIGHT;

        newTop = 100 * (x / parentHeight);
        if (newTop + height > 100) newTop = 100 - height;

        const diffToScheduler =
          e.clientY -
          scheduleDiv.getBoundingClientRect().top -
          // 160 -
          110 -
          mousePositionInElement.top;

        const diffToBottom =
          scheduleDiv.getBoundingClientRect().bottom -
          e.clientY -
          mousePositionInElement.bottom;

        scrollSchedule(diffToScheduler, diffToBottom);

        if (!excludeOverlaping) {
          const columnData = columnDiv.dataset.id;
          const columnEmployeeId = columnData?.split('--')[0];
          const columnDate = columnData?.split('--')[1];

          const newPosition = getAppointmentPosition(
            order,
            newTop,
            columnEmployeeId,
            new Date(columnDate),
            hourInterval,
            startHour,
          );

          if (newPosition) {
            const newIntervals = getOrderTimeIntervals(
              newPosition.updatedOrder,
              newPosition.updatedOrder.durationInMinutes,
            );

            let columnOrders = getColumnOrders(
              globalOrders,
              columnEmployeeId,
              columnDate,
            );

            columnOrders = columnOrders.filter(
              (item) =>
                item.id !== id &&
                !notOverlapingStatuses.includes(item.completionStatus),
            );

            const isOverlaping = willOrderOverlap(columnOrders, newIntervals);
            const targetEmployeeSchedule = dataSource.find(
              (schedule) => schedule.employee.id === columnEmployeeId,
            );

            isAppointmentValid =
              !isOverlaping ||
              (isOverlaping &&
                targetEmployeeSchedule.employee.sectorSettings
                  .allowOrdersOverlap);

            setOrderIndication(isOverlaping, ordersElements);
          } else {
            isAppointmentValid = true;
            ordersElements.forEach((element) => {
              element.classList.remove('warning');
              element.classList.remove('danger');
            });
          }
        }
      } else {
        setHoverTime(null);
      }

      if (columnDiv || queueDiv) setContainerLeft(newLeft);
      // if (newTop >= 0 && newTop + height <= 100 && !scrollTimer.current) {
      if (newTop >= 0 && newTop + height <= 100) {
        draggableElement.style.top = `calc( ${newTop}% + 1px)`;
      } else if (newTop < 0) {
        draggableElement.style.top = 0;
      }

      const checkColumn = saveToOtherCollumn(e);
      dataId = checkColumn ? checkColumn : dataId;
    };

    const onMouseUp = (e) => {
      if (newTop) setContainerTop(newTop);

      const queueDiv = e.target.closest('#pending');

      setHoverTime(null);

      dragging.setState((prev) => {
        return { moving: false };
      });

      if (cells) {
        for (const cell of cells) {
          cell.classList.remove('dragging');
        }
      }

      if (scrollTimer.current) {
        clearInterval(scrollTimer.current);
        scrollTimer.current = null;
      }

      if (isAppointmentValid) {
        if (queueDiv && !timeOff) {
          updateToPendingOrders(order);
        } else if (dataId) {
          updateAppointmentPosition(order, newTop, dataId);
        } else {
          updateAppointmentPosition(order, newTop < 0 ? 0 : newTop);
        }
      } else {
        toast.error(`${tr['order']} ${tr['overplaps'].toLowerCase()}`);
        setContainerTop(initialContainerTop);
        setContainerLeft(initialContainerLeft);
      }

      draggableElement.style.left = '';
      draggableElement.style.width = '';
      draggableElement.style.top = '';

      setContainerLeft(null);
      setFocusing(0);
      setDraggable(false);

      document.body.style.cursor = 'auto';
      document.body.removeEventListener('mousemove', onMouseMove);
    };

    document.body.addEventListener('mousemove', onMouseMove);
    document.body.addEventListener('mouseup', onMouseUp, { once: true });
  };

  const saveToOtherCollumn = (e) => {
    const isParent = e.target.closest('#collumn');
    const isSibling = e.target
      .closest('.collumn-body')
      ?.querySelector('#collumn');

    if (!isParent && !isSibling) return null;

    return isParent ? isParent.dataset.id : isSibling.dataset.id;
  };

  const handleCardClick = () => {
    toogleService();

    if (!isMobile) return;

    setDisplayDetails(true);
  };

  // const timeOffItems = (close) => {
  //   return timeOffMenuItems.map((item) => {
  //     switch (item.id) {
  //       case 'edit':
  //         return { ...item, items: listOfExcusesWithAction(close) };
  //       case 'delete-timeOff':
  //         return {
  //           ...item,
  //           icon: isMobile && item.icon,
  //           onClick: () => {
  //             setModal('delete-timeOff', id);
  //             close && close();
  //             handleClickAway();
  //           },
  //         };
  //       default:
  //         return item;
  //     }
  //   });
  // };

  // const listOfExcusesWithAction = (close) => {
  //   const arr = listOfExcuses.map((item) => {
  //     return {
  //       ...item,
  //       onClick: () => {
  //         cache.setState([]);
  //         toast.success(tr['order-updated-successfully']);

  //         console.log('update order');
  //         // dispatch(
  //         //   updateAppointment({ id, excuseId: item.id, title: item.label })
  //         // );

  //         close();
  //         handleClickAway();
  //       },
  //     };
  //   });

  //   arr.push({
  //     id: 22,
  //     label: 'Kita...',
  //     onClick: () => {},
  //     items: [
  //       {
  //         id: 222,
  //         input: true,
  //         onKeyPress: (e) => {
  //           if (e.key === 'Enter') {
  //             cache.setState([]);
  //             toast.success(tr['order-updated-successfully']);
  //             console.log('update order');
  //             // dispatch(
  //             //   updateAppointment({ id, excuseId: null, title: e.target.value })
  //             // );
  //             close();
  //             handleClickAway();
  //           }
  //         },
  //       },
  //     ],
  //   });

  //   return arr;
  // };

  const handleContextMenu = (e) => {
    e.preventDefault();

    functions.raiseHead(true);
    setDisplayContextMenu(true);
    setFocusing(1);
    setDraggable(false);
  };

  const getTimeForDetails = () => ({
    start: startTimeUtc,
    end: new Date(
      new Date(startTimeUtc).getTime() + durationInMinutes * 60000,
    ).toISOString(),
  });

  const toogleService = () => {
    if (!!isMobile) return;

    setDraggable((prev) => !prev);
    if (!draggable) {
      setDisplayContextMenu(false);
    }
  };

  const resizeWithHandle = (e, direction) => {
    e.stopPropagation();
    resizeAppointment(
      e,
      containerRef.current.getBoundingClientRect().height + 4,
      direction,
    );
  };

  // Comp
  const TooltipWrapper = (
    <S.Tooltip>
      <AppointmentsDetails
        status={completionStatus}
        data={{
          id: sequenceNo,
          uuid: id,
          time: getTimeForDetails(),
          services,
          note,
          customer,
          balance: {
            amountCovered,
            toPay: finalAmountWithVat - amountCovered,
          },
        }}
        statusItems={getStatusActions}
        functionItems={getFunctionList}
        balanceItems={getBalanceActions}
      />
    </S.Tooltip>
  );

  return (
    <>
      <ClickAwayListener mouseEvent="mousedown" onClickAway={handleClickAway}>
        <S.AppointmentWrapper
          id={id + '-id'}
          ref={(node) => {
            containerRef.current = node;

            if (selected) {
              setScheduleScrollTargetNode(node);
            }
          }}
          topPosition={containerTop}
          height={containerHeight}
          multiBooking={multiBooking}
          left={containerLeft}
          focusing={focusing + 1}
          moving={dragging.state?.moving && dragging.state?.appId === id}
          onContextMenu={handleContextMenu}
          onMouseDown={(e) =>
            draggable && !displayContextMenu && dragAppointment(e, id + '-id')
          }
          drag={draggable}
          isSafari={isSafari}
          onClick={handleCardClick}
          contextMenuVisible={displayContextMenu}
        >
          {!isMobile && (
            <ContextTree
              key={id}
              // items={timeOff && timeOffItems}
              containerId={id + '-id'}
              parentId={'schedule'}
              openOnContext
              openOnDoubleClick
              fixedWidth={timeOff ? 210 : 310}
              open={displayContextMenu}
              content={TooltipWrapper}
              setCardSelected={(value) => !value && handleClickAway()}
              type="schedule-body-item"
            />
          )}

          {hasBreak ? (
            <MultiAppointments
              id={id}
              timeOff={timeOff}
              displayData={{
                status: completionStatus,
                firstName: customer?.name,
                lastName: customer?.lastName,
                services,
                splits,
              }}
              hoverDisplay={draggable}
              order={order}
            />
          ) : (
            <AppointmentCard
              id={id}
              timeOff={timeOff}
              displayData={{
                status: completionStatus,
                firstName: customer?.name,
                lastName: customer?.lastName,
                services,
              }}
              hoverDisplay={draggable}
            />
          )}

          {(draggable || displayContextMenu) && (
            <>
              <S.DragHandle onMouseDown={(e) => resizeWithHandle(e, 'up')} />
              <S.DragHandle
                bottom
                onMouseDown={(e) => resizeWithHandle(e, 'down')}
              />
            </>
          )}

          {
            isMobile && displayDetails && (
              // (!timeOff ? (
              <AppointmentsDetailsMobile
                status={completionStatus}
                data={{
                  id: sequenceNo,
                  uuid: id,
                  time: getTimeForDetails(),
                  services,
                  customer,
                  employeeId,
                  balance: {
                    amountCovered,
                    toPay: finalAmountWithVat - amountCovered,
                  },
                  note,
                }}
                functions={getAppointemtFunctions()}
                close={() => setDisplayDetails(false)}
              />
            )
            // ) : (
            //   <Overlay isVisible={true}>
            //     <DialogLayout
            //       headerText={'Nedarbo laikas'}
            //       exitFunc={() => setDisplayDetails(false)}
            //       darkTheme
            //       Child={TimeOff}
            //       childProps={{
            //         data: {
            //           ...timeOff,
            //           id: id,
            //           // professionals: [currentProfessionals[0].id],
            //         },
            //         saveFunc: (x) => dispatch(updateAppointment(x)),
            //         functions: timeOffItems(),
            //       }}
            //     />
            //   </Overlay>
            // ))
          }

          {hoverTime && (
            <div className="hover-time">
              <span>{hoverTime}</span>
            </div>
          )}
        </S.AppointmentWrapper>
      </ClickAwayListener>

      <Overlay isVisible={isModalShowing} hide={modalToggle}>
        {modalContent}
      </Overlay>
    </>
  );
};

export default AppointmentWrapper;
