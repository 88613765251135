import * as S from '../icons/SVG.styled';

export default ({ color, width = 154, height = 30, singleColor, ...rest }) => {
  return (
    <S.StyledSVG
      width={width}
      height={height}
      viewBox="0 0 154 30"
      color={color}
      {...rest}
    >
      <path
        d="M 4.1472655,19.749916 C 4.1629915,20.188959 4.0034442,20.616192 3.7037381,20.937738 3.404032,21.259134 2.9887276,21.448683 2.5491616,21.46438 2.109606,21.48008 1.6818076,21.320723 1.3598787,21.02145 1.0379499,20.722029 0.84826306,20.307352 0.8325467,19.868309 c 0,-0.03887 0,-0.07833 0,-0.118393 -0.002001,-0.441136 0.1714308,-0.865081 0.482193,-1.178554 0.3107637,-0.313624 0.7334392,-0.491065 1.1751653,-0.493606 0.4417336,0.0025 0.8644076,0.179982 1.1751758,0.493606 0.3107532,0.313473 0.4841878,0.737418 0.4821847,1.178554 z"
        fill={singleColor ? color : '#ffa35f'}
        strokeWidth="1.49487"
      />
      <path
        d="m 29.129939,1.2418736 c -6.098913,0 -11.033324,4.9769427 -11.033324,11.1149914 0,6.138109 4.934411,11.113031 11.033324,11.113031 2.78464,-4.49e-4 5.46434,-1.061357 7.492877,-2.966567 v -3.588431 c -0.788991,1.329536 -1.918215,2.425424 -3.271669,3.175101 -1.353453,0.749526 -2.882255,1.125784 -4.429444,1.089907 -1.547188,-0.03573 -3.057006,-0.482244 -4.374283,-1.293508 -1.317278,-0.811266 -2.394481,-1.958278 -3.121135,-3.322944 L 37.271888,6.4048228 38.080761,5.8768802 C 37.067839,4.44665 35.726642,3.2795316 34.169289,2.4731398 32.612085,1.666748 30.884016,1.2445509 29.129939,1.2418736 Z M 20.374644,12.362501 c -0.0058,-1.153709 0.215709,-2.297284 0.652361,-3.3654271 0.436651,-1.0681581 1.079593,-2.039972 1.892205,-2.8599371 0.81261,-0.8199802 1.779042,-1.4720716 2.844135,-1.9190372 1.064945,-0.4469657 2.207622,-0.6800605 3.362856,-0.6859652 1.964257,-4.335e-4 3.870663,0.6653061 5.40664,1.888198 L 20.594838,14.355907 c -0.147543,-0.654214 -0.22124,-1.322824 -0.220194,-1.993406 z"
        fill={color || '#0f1d5b'}
        strokeWidth="1.49487"
      />
      <path
        d="M 88.708363,6.4048228 89.522917,5.8768802 C 88.50805,4.4446618 87.164014,3.2763474 85.60367,2.469881 84.043327,1.6634145 82.31212,1.2423161 80.555201,1.2418736 c -6.091438,0 -11.029735,4.9769427 -11.029735,11.1149914 0,6.138109 4.932616,11.113031 11.029735,11.113031 2.78494,0.0016 5.465388,-1.059564 7.492878,-2.966567 v -3.588431 c -0.789739,1.327294 -1.918663,2.421088 -3.271071,3.169121 -1.352407,0.748032 -2.879714,1.123393 -4.425259,1.087666 -1.545544,-0.03588 -3.053866,-0.481348 -4.370097,-1.291118 -1.316381,-0.80977 -2.393434,-1.95454 -3.120687,-3.317113 z M 71.803644,12.362501 c -0.01002,-2.328363 0.906339,-4.5653878 2.547406,-6.2191755 1.641216,-1.6538027 3.872755,-2.5889773 6.204151,-2.5999198 1.963809,-0.00383 3.870812,0.658624 5.408434,1.8787954 L 72.020101,14.361543 C 71.87196,13.705595 71.79931,13.034892 71.803644,12.362501 Z"
        fill={color || '#0f1d5b'}
        strokeWidth="1.49487"
      />
      <path
        d="M 42.368193,5.2004972 V 1.7735713 H 40.009142 V 23.19185 h 2.359051 V 10.844417 c 0.249344,-1.8296139 1.069878,-3.5341824 2.344701,-4.8714915 1.274973,-1.3373242 2.939359,-2.2393576 4.75682,-2.5779602 l 0.536209,-2.386073 c -1.492027,0.1416449 -2.939209,0.5865654 -4.25275,1.307391 -1.313392,0.7208404 -2.465337,1.702356 -3.38498,2.8842139 z"
        fill={color || '#0f1d5b'}
        strokeWidth="1.49487"
      />
      <path
        d="M 68.114608,1.4372633 60.544595,18.055332 53.019578,1.4372633 50.809266,2.5645361 60.501244,23.475576 70.185898,2.4612107 Z"
        fill={color || '#0f1d5b'}
        strokeWidth="1.49487"
      />
      <path
        d="M 10.658179,11.227701 C 8.0545672,10.476186 6.2203638,10.220668 5.3907568,8.8998626 4.5611347,7.5790718 4.5554841,5.5292934 6.2880963,4.3437732 8.0207085,3.1582529 11.437007,3.0887265 14.085778,5.5725101 L 15.570063,3.6824284 C 14.189253,2.5683031 12.392675,1.2982361 9.3845964,1.2982361 7.6030422,1.2904434 5.8660501,1.8542046 4.4294368,2.9064871 c 0,0 -2.1464724,1.6552229 -2.1464724,4.4151832 -0.011144,0.7971984 0.1554274,1.5869074 0.4876321,2.3118737 0.3322045,0.724966 0.821729,1.367057 1.4331003,1.879737 1.0248518,0.678342 2.1496206,1.192441 3.3335264,1.523704 2.0147984,0.580548 4.5488098,1.100986 5.2768408,2.442391 0.728045,1.341495 0.797646,3.735078 -0.630206,4.79479 -1.427855,1.059563 -2.146483,1.183637 -3.2545238,1.183637 -1.5222244,-0.03259 -2.9949088,-0.546375 -4.2064248,-1.467363 -0.043904,0.448012 -0.221106,0.872704 -0.5087784,1.219215 -0.2876725,0.34651 -0.672631,0.599143 -1.1053056,0.725309 0.4082186,0.351294 2.3910418,1.890112 5.8223924,1.890112 1.3530052,0.01405 2.6817192,-0.359366 3.8286872,-1.076306 1.146952,-0.716938 2.064099,-1.747201 2.642733,-2.968807 0.588753,-1.315186 0.812685,-3.45509 0.04326,-5.033267 -0.769409,-1.578193 -2.184092,-2.771231 -4.787719,-3.518995 z"
        fill={color || '#0f1d5b'}
        strokeWidth="1.49487"
      />
      <path
        d="m 117.19577,11.299097 c 0,3.321716 -0.71679,5.960817 -2.15022,7.9173 -1.43343,1.956484 -3.38498,2.934576 -5.85435,2.934576 -1.501,0.0287 -2.9863,-0.309288 -4.3269,-0.984371 -1.21637,-0.617231 -2.23857,-1.558101 -2.95341,-2.718716 V 29.95 H 100.8461 V 0.44713281 h 1.06479 V 4.2479521 c 0.68136,-1.2950792 1.71312,-2.3730736 2.97793,-3.1112993 1.29755,-0.73562622 2.76909,-1.11000208 4.26096,-1.08406851 2.49584,0 4.461,0.95505681 5.89577,2.86517411 1.43478,1.9101129 2.15156,4.5924149 2.15022,8.0469056 z m -1.08542,-0.413347 c 0,-3.0599353 -0.60827,-5.4529055 -1.82479,-7.1788954 -1.21652,-1.7259898 -2.92217,-2.5896157 -5.11694,-2.5908684 -1.78726,0 -3.30529,0.4628038 -4.55441,1.3884412 -1.24926,0.9256225 -2.15036,2.2551882 -2.70332,3.9886823 v 9.9576313 c 0.50153,1.40712 1.45541,2.608695 2.71258,3.417568 1.2542,0.807827 2.78241,1.21189 4.58462,1.21189 2.18221,0 3.8753,-0.866874 5.07926,-2.600323 1.20412,-1.73345 1.81163,-4.26489 1.823,-7.594126 z"
        fill={color || '#0f1d5b'}
        strokeWidth="1.49487"
      />
      <path
        d="m 132.39993,1.234353 -1.3865,-0.1183638 c -1.81477,0 -3.33355,0.5874429 -4.55636,1.7623197 -1.2228,1.1748768 -2.0242,2.8175727 -2.40419,4.9280875 V 21.756477 h -1.0648 V 0.4471358 h 1.0648 v 4.4302156 c 0.51662,-1.3986884 1.42177,-2.6213413 2.6093,-3.5246333 1.15746,-0.8667531 2.60794,-1.30013055 4.35125,-1.30013055 0.5108,-0.0178342 1.0207,0.05663461 1.50504,0.21981906 z"
        fill={color || '#0f1d5b'}
        strokeWidth="1.49487"
      />
      <path
        d="m 134.49184,10.688473 c -0.0311,-1.8938043 0.36968,-3.7698639 1.17198,-5.4860923 0.72217,-1.5518527 1.87098,-2.8667389 3.31278,-3.7914226 1.44838,-0.91141072 3.13055,-1.383297 4.84232,-1.35837267 1.70117,-0.03474576 3.37586,0.42519666 4.82005,1.32383827 1.44435,0.8986461 2.59524,2.1972232 3.31428,3.7376551 0.8147,1.700308 1.22878,3.5637958 1.21383,5.4485262 v 0.93939 c 0,2.047895 -0.38717,3.879766 -1.163,5.495569 -0.71754,1.554364 -1.8647,2.871194 -3.3071,3.795171 -1.45899,0.885262 -3.13264,1.35495 -4.83978,1.358238 -1.70699,0.0033 -3.38244,-0.459971 -4.84488,-1.339551 -1.44255,-0.911571 -2.59509,-2.215096 -3.32219,-3.757501 -0.80842,-1.693985 -1.21951,-3.54964 -1.20218,-5.426043 z m 1.06465,0.826677 c -0.0214,1.686121 0.33874,3.355306 1.05358,4.882972 0.6401,1.401887 1.65736,2.598529 2.93847,3.457032 1.28034,0.833838 2.78149,1.265705 4.30985,1.239994 2.43305,0 4.41525,-0.912468 5.94658,-2.737403 1.5309,-1.824935 2.29627,-4.177828 2.29627,-7.058648 v -0.610624 c 0.0164,-1.6746565 -0.3483,-3.3311052 -1.06435,-4.8454218 -0.64428,-1.4068952 -1.66408,-2.609442 -2.94758,-3.475778 -1.26496,-0.8369199 -2.75324,-1.2730328 -4.27039,-1.2512855 -2.43304,0 -4.41838,0.9256285 -5.956,2.7768733 -1.53762,1.851245 -2.30643,4.182208 -2.30643,6.992889 z"
        fill={color || '#0f1d5b'}
        strokeWidth="1.49487"
      />
    </S.StyledSVG>
  );
};
