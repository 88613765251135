// style
import * as S from './ScheduleTemplate.styled';

// icons
import { Check, Pencil, TrashCan } from 'common/icons';

// components
import { TheButton } from 'components/Buttons/TheButton';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useTime } from 'hooks/useTime';
import { toSentenceCase } from 'common/helpers/stringOperations';
import { useScheduleHelpers } from 'hooks/employee-schedule/useScheduleHelpers';

export const ScheduleTemplate = (props) => {
  const {
    state,
    templateType,
    calendarPickerDimensions,
    setTemplateDialog,
    editTemplate,
    deleteTemplate,
    chooseUpdateOption,
    sectorId,
  } = props;
  const tr = useTranslate().use().global;
  const { getTime } = useTime();
  const { getWeekDaysArray } = useScheduleHelpers();

  const TemplateButton = ({ title, icon, action }) => (
    <TheButton
      title={title}
      secondary
      outline
      height="35"
      width="fit-content"
      icon={icon}
      action={action}
      flat
    />
  );

  // day template
  const getDayTemplate = (dayData) => {
    let dayTemplate = '';

    if (dayData?.hours0 || dayData?.hours1) {
      dayTemplate += getTime(dayData.hours0) + ' - ' + getTime(dayData.hours1);
    }

    if (dayData?.hours2 || dayData?.hours3) {
      dayTemplate +=
        ' | ' + getTime(dayData.hours2) + ' - ' + getTime(dayData.hours3);
    }

    return dayTemplate || '-';
  };

  const officeFromRange = (range) => {
    return range?.every(
      (day, index, arr) =>
        index === 0 || day.office?.value === arr[index - 1].office?.value,
    )
      ? range[0].office?.label || '-'
      : '(...)';
  };

  return (
    <S.TemplateSection>
      <h3>{toSentenceCase(tr['template'])}</h3>

      {templateType && (
        <S.ControlsSection>
          <TemplateButton
            title={tr['fill-schedule']}
            icon={<Check bold inCircle />}
            action={chooseUpdateOption}
          />

          <TemplateButton
            title={tr['edit']}
            icon={<Pencil bold width={16} height={16} />}
            action={editTemplate}
          />

          <TemplateButton
            title={toSentenceCase(tr['remove'])}
            icon={<TrashCan bold width={16} height={16} />}
            action={deleteTemplate}
          />
        </S.ControlsSection>
      )}

      {templateType ? (
        <div className="schedule-setup-details">
          <label>{tr['office-number']}:</label>
          <div>{officeFromRange(state['schedule-template'].value)}</div>

          {templateType === 'daily' ? (
            <label>{tr['work-schedule-daily']}:</label>
          ) : templateType === 'weekly' ? (
            <label>{tr['work-schedule-weekly']}:</label>
          ) : (
            <label>{tr['work-schedule-continuous']}:</label>
          )}
          <div>
            {state['schedule-template']?.value?.map((day, index) => {
              return (
                <div className="day" key={index}>
                  {templateType !== 'daily' && (
                    <span className="day__label">
                      {templateType === 'weekly'
                        ? getWeekDaysArray({ sectorId })[index % 7]
                        : index + 1}
                    </span>
                  )}
                  <span>{getDayTemplate(day)}</span>
                </div>
              );
            })}
          </div>

          <label>{tr['repetition-during-selected-period']}:</label>
          <div>
            {templateType === 'daily'
              ? state['repetition-daily']?.value?.label
              : templateType === 'weekly'
                ? state['repetition-weekly']?.value?.label
                : tr['continuous']}
          </div>

          <label>{tr['application']}:</label>
          {state['apply-workdays']?.value?.value && (
            <div>{state['apply-workdays']?.value?.label}</div>
          )}
          {state['apply-weekends']?.value?.value && (
            <div>{state['apply-weekends']?.value?.label}</div>
          )}
          {state['apply-holidays']?.value?.value && (
            <div>{state['apply-holidays']?.value?.label}</div>
          )}
          {!state['apply-workdays']?.value?.value &&
            !state['apply-weekends']?.value?.value &&
            !state['apply-holidays']?.value?.value && <div>{'-'}</div>}
        </div>
      ) : (
        <div className="template-type-selector">
          <TemplateButton
            title={tr['repeat-daily']}
            action={() => setTemplateDialog('daily')}
          />

          <TemplateButton
            title={tr['repeat-weekly']}
            action={() => setTemplateDialog('weekly')}
          />

          <TemplateButton
            title={tr['repeat-continuously']}
            action={() => setTemplateDialog('continuous')}
          />
        </div>
      )}
    </S.TemplateSection>
  );
};
