import * as S from './SVG.styled';

export default ({
  color,
  width = 30,
  height = 30,
  bold,
  black,
  number,
  ...rest
}) => {
  let weight;

  if (bold) {
    weight = 700;
  } else if (black) {
    weight = 900;
  }

  let content;

  switch (weight) {
    case 900:
      content = (
        <>
          <path
            d="m 21.513157,7.58487 c 0,4.186815 -2.9154,7.58148 -6.513149,7.58148 -3.597735,0 -6.5131955,-3.394665 -6.5131955,-7.58148 C 8.4868125,3.398055 11.402273,0 15.000008,0 c 3.597749,0 6.513149,3.394725 6.513149,7.58487 z"
            fill="currentcolor"
            strokeWidth="1.5"
          />
          <path
            d="M 26.279107,23.9793 V 30 H 3.7208925 v -6.0207 c 0,-3.21165 2.040165,-6.0672 5.075445,-7.11225 l 0.32283,-0.1098 c 1.6640705,1.30125 3.6909305,2.07015 5.8808405,2.07015 2.189999,0 4.216799,-0.7689 5.880899,-2.07015 l 0.3228,0.1098 c 3.03525,1.04505 5.0754,3.9006 5.0754,7.11225 z"
            fill="currentcolor"
            strokeWidth="1.5"
          />
        </>
      );

      break;
    case 700:
      switch (number) {
        case 1:
          content = (
            <>
              <path
                d="m 18.008125,16.6833 c -3.88335,0 -7.040775,-3.587625 -7.040775,-7.99965 0,-4.412025 3.157425,-8.0025885 7.040775,-8.0025885 3.8832,0 7.0407,3.5905635 7.0407,8.0025885 0,4.412025 -3.1575,7.99965 -7.0407,7.99965 z m 0,-13.612515 c -2.56305,0 -4.651049,2.51817 -4.651049,5.612865 0,3.09468 2.085,5.609865 4.651049,5.609865 2.5659,0 4.6509,-2.515185 4.6509,-5.609865 0,-3.094695 -2.085,-5.612865 -4.6509,-5.612865 z"
                fill="currentcolor"
                strokeWidth="1.5"
              />
              <path
                d="M 28.131625,29.997 H 7.8846405 c -0.66015,0 -1.194855,-0.53475 -1.194855,-1.1949 v -5.40375 c 0,-3.39045 2.15373,-6.4104 5.3619445,-7.5126 l 0.29274,-0.0987 c 0.38235,-0.1284 0.803535,-0.0597 1.12017,0.19125 1.34124,1.0485 2.912535,1.6041 4.543485,1.6041 1.63095,0 3.1992,-0.5556 4.5435,-1.6041 0.31665,-0.24795 0.7377,-0.31965 1.12005,-0.19125 l 0.2898,0.0987 c 3.2112,1.1052 5.3649,4.12515 5.3649,7.5156 v 5.40375 c 0,0.66015 -0.5346,1.1949 -1.19475,1.1949 z M 9.0795105,27.6072 H 26.936725 v -4.20885 c 0,-2.26425 -1.37715,-4.29555 -3.45615,-5.1438 -1.64295,1.12305 -3.52185,1.7145 -5.47245,1.7145 -1.9506,0 -3.829545,-0.59145 -5.47248,-1.7145 -2.07906,0.84825 -3.4561345,2.87655 -3.4561345,5.1438 z"
                fill="currentcolor"
                strokeWidth="1.5"
              />
              <path
                d="M 7.1677005,13.773765 H 4.7779755 V 3.85044 L 1.7340705,5.358945 0.6736245,3.21717 7.1677005,0 Z"
                fill="currentcolor"
                strokeWidth="1.5"
              />
            </>
          );

          break;
        case 2:
          content = (
            <>
              <path
                d="m 18.41685,16.372236 c -3.97308,0 -7.20351,-3.67053 -7.20351,-8.1845858 C 11.21334,3.6735952 14.44377,0 18.41685,0 c 3.9732,0 7.2036,3.6735952 7.2036,8.1876502 0,4.5140558 -3.2304,8.1845858 -7.2036,8.1845858 z m 0,-13.9272458 c -2.6253,0 -4.758525,2.5764 -4.758525,5.74266 0,3.1662608 2.133225,5.7396158 4.758525,5.7396158 2.6253,0 4.7586,-2.573355 4.7586,-5.7396158 0,-3.16626 -2.1333,-5.74266 -4.7586,-5.74266 z"
                fill="currentcolor"
                strokeWidth="1.5"
              />
              <path
                d="M 28.7745,29.993886 H 8.05929 c -0.675435,0 -1.2225,-0.54705 -1.2225,-1.2225 v -5.52885 c 0,-3.46875 2.203545,-6.5586 5.48595,-7.6863 l 0.302565,-0.10095 c 0.3912,-0.1314 0.82212,-0.06105 1.14609,0.1956 1.372305,1.0728 2.979855,1.6413 4.648455,1.6413 1.66875,0 3.2733,-0.5685 4.64865,-1.6413 0.32385,-0.25365 0.75795,-0.327 1.146,-0.1956 l 0.29655,0.10095 C 27.7965,16.686936 30,19.776786 30,23.245686 v 5.5287 c 0,0.67545 -0.54705,1.2225 -1.2225,1.2225 z m -19.492725,-2.445 H 27.552 v -4.30635 c 0,-2.3166 -1.40895,-4.39485 -3.5361,-5.26275 -1.6809,1.14915 -3.6033,1.75425 -5.59905,1.75425 -1.99575,0 -3.918075,-0.6051 -5.599005,-1.75425 -2.127135,0.8679 -3.53607,2.94315 -3.53607,5.26275 z"
                fill="currentcolor"
                strokeWidth="1.5"
              />
              <path
                d="M 8.39853,13.380276 H 0.1680945 L 5.89548,5.6846752 c 0.547065,-0.809895 0.687645,-1.72371 0.369795,-2.393025 -0.226155,-0.47373 -0.64791,-0.73962 -1.25916,-0.78852 -0.064185,-0.0061 -0.12837,-0.00916 -0.1956,-0.00916 -1.305015,0 -2.36553,1.060515 -2.36553,2.36553 H 0 c 0,-2.65281 2.157705,-4.81051444 4.810515,-4.81051444 0.131415,0 0.26283,0.006111 0.3912,0.01527975 1.476165,0.11919315 2.668095,0.91381515 3.270165,2.17603969 0.693765,1.45782 0.473715,3.31296 -0.574575,4.84413 L 7.86981,7.1241652 5.033625,10.935291 H 8.39547 v 2.444985 z"
                fill="currentcolor"
                strokeWidth="1.5"
              />
            </>
          );

          break;
        case 3:
          content = (
            <>
              <path
                d="m 18.415594,16.37385 c -3.973455,0 -7.20423,-3.670845 -7.20423,-8.185365 C 11.211364,3.673965 14.442139,0 18.415594,0 c 3.9735,0 7.20435,3.673965 7.20435,8.188485 0,4.51452 -3.23085,8.185365 -7.20435,8.185365 z m 0,-13.92861 c -2.62545,0 -4.75899,2.57667 -4.75899,5.743245 0,3.166575 2.13354,5.740185 4.75899,5.740185 2.6256,0 4.75905,-2.57361 4.75905,-5.740185 0,-3.166575 -2.13345,-5.743245 -4.75905,-5.743245 z"
                fill="currentcolor"
                strokeWidth="1.5"
              />
              <path
                d="M 28.774294,29.997 H 8.0570286 c -0.675495,0 -1.22262,-0.5472 -1.22262,-1.22265 v -5.5293 c 0,-3.4692 2.20377,-6.55935 5.4865054,-7.6872 l 0.299535,-0.10095 c 0.391245,-0.1314 0.82221,-0.06105 1.14621,0.19575 1.372335,1.0728 2.980185,1.6413 4.648935,1.6413 1.6689,0 3.2736,-0.5685 4.6491,-1.6413 0.324,-0.2538 0.75795,-0.32715 1.14615,-0.19575 l 0.29655,0.10095 c 3.28575,1.13085 5.48955,4.22115 5.48955,7.6902 v 5.5293 c 0,0.6756 -0.5472,1.22265 -1.22265,1.22265 z M 9.2796486,27.5517 H 27.551644 v -4.30665 c 0,-2.3169 -1.4091,-4.3953 -3.5364,-5.26335 -1.68105,1.1493 -3.6036,1.7544 -5.59965,1.7544 -1.9959,0 -3.91842,-0.6051 -5.599515,-1.7544 -2.12736,0.86805 -3.5364304,2.94345 -3.5364304,5.26335 z"
                fill="currentcolor"
                strokeWidth="1.5"
              />
              <path
                d="M 4.8048936,13.31733 C 2.1548586,13.31733 0,11.550645 0,9.377445 h 2.4452286 c 0,0.706065 0.968925,1.494645 2.359665,1.494645 1.390725,0 2.35965,-0.78858 2.35965,-1.494645 0,-0.706065 -0.968925,-1.494645 -2.35965,-1.494645 h -0.0642 L 3.4844586,7.916415 V 5.41617 l 1.320435,0.02139 c 1.390725,0 2.35965,-0.788595 2.35965,-1.494645 0,-0.706065 -0.968925,-1.49466 -2.35965,-1.49466 -1.39074,0 -2.359665,0.788595 -2.359665,1.49466 H 0 C 0,1.7697 2.1548586,0.00302124 4.8048936,0.00302124 c 2.65002,0 4.80489,1.76667876 4.80489,3.93989376 0,1.05144 -0.50739,2.0112 -1.3296,2.717265 0.82221,0.706065 1.3296,1.66581 1.3296,2.717265 0,2.1732 -2.15487,3.939885 -4.80489,3.939885 z"
                fill="currentcolor"
                strokeWidth="1.5"
              />
            </>
          );

          break;
        default:
          content = (
            <>
              <path
                d="m 15.000008,16.37385 c -3.9735,0 -7.2042755,-3.670845 -7.2042755,-8.185365 C 7.7957325,3.673965 11.026508,0 15.000008,0 c 3.973514,0 7.204214,3.673965 7.204214,8.188485 0,4.51452 -3.2307,8.185365 -7.204214,8.185365 z m 0,-13.92861 c -2.62251,0 -4.759035,2.57667 -4.759035,5.743245 0,3.166575 2.133465,5.740185 4.759035,5.740185 2.625615,0 4.759065,-2.57361 4.759065,-5.740185 0,-3.166575 -2.13345,-5.743245 -4.759065,-5.743245 z"
                fill="currentcolor"
                strokeWidth="1.5"
              />
              <path
                d="M 25.358722,29.997 H 4.6413975 c -0.675495,0 -1.22262,-0.5472 -1.22262,-1.22265 v -5.5293 c 0,-3.4692 2.20377,-6.55935 5.486505,-7.6872 l 0.299535,-0.10095 c 0.391245,-0.1314 0.8222255,-0.06105 1.1462105,0.19575 1.372395,1.0728 2.980125,1.6413 4.648995,1.6413 1.668899,0 3.273599,-0.5685 4.648949,-1.6413 0.324,-0.2538 0.7551,-0.32715 1.1463,-0.19575 l 0.296401,0.10095 c 3.285899,1.13085 5.48955,4.22115 5.48955,7.6902 v 5.5293 c 0,0.6756 -0.54705,1.22265 -1.222501,1.22265 z M 5.8640175,27.5517 H 24.136072 v -4.30665 c 0,-2.3169 -1.4091,-4.3953 -3.5364,-5.26335 -1.6812,1.1493 -3.60375,1.7544 -5.59965,1.7544 -1.995915,0 -3.91848,-0.6051 -5.5995745,-1.7544 -2.12736,0.86805 -3.53643,2.94345 -3.53643,5.26335 z"
                fill="currentcolor"
                strokeWidth="1.5"
              />
            </>
          );
      }

      break;
    default:
      switch (number) {
        case 1:
          content = (
            <>
              <path
                d="m 18.344977,15.9549 c -3.746804,0 -6.79722,-3.504225 -6.79722,-7.80885 0,-4.304625 3.047265,-7.8119745 6.79722,-7.8119745 3.75,0 6.797251,3.5041995 6.797251,7.8119745 0,4.307775 -3.050401,7.80885 -6.797251,7.80885 z m 0,-14.360325 c -3.053549,0 -5.536724,2.940135 -5.536724,6.551475 0,3.61134 2.483175,6.54831 5.536724,6.54831 3.05355,0 5.536801,-2.93697 5.536801,-6.54831 0,-3.61134 -2.483251,-6.551475 -5.536801,-6.551475 z"
                fill="currentcolor"
                strokeWidth="1.5"
              />
              <path
                d="M 29.024677,30 H 7.6654125 c -0.346635,0 -0.630255,-0.28365 -0.630255,-0.6303 v -5.7006 c 0,-3.30885 2.10189,-6.25215 5.2311005,-7.3299 l 0.308819,-0.10395 c 0.201691,-0.0693 0.422266,-0.0315 0.59244,0.1008 1.528366,1.1943 3.31821,1.8246 5.18061,1.8246 1.8624,0 3.65235,-0.6303 5.1807,-1.8246 0.1671,-0.1323 0.390751,-0.16695 0.5925,-0.1008 l 0.3057,0.10395 c 3.129151,1.07775 5.23425,4.0242 5.23425,7.3299 v 5.7006 c 0,0.34665 -0.283649,0.6303 -0.6303,0.6303 z M 8.2956675,28.7394 H 28.394377 v -5.0703 c 0,-2.76375 -1.7553,-5.22795 -4.367549,-6.1323 -1.689151,1.23525 -3.6492,1.88445 -5.681851,1.88445 -2.0325,0 -3.992594,-0.65235 -5.681669,-1.88445 -2.612385,0.90435 -4.3676405,3.36855 -4.3676405,6.1323 z"
                fill="currentcolor"
                strokeWidth="1.5"
              />
              <path
                d="M 6.2788425,13.51575 H 5.0183475 V 2.03256 L 0.8996475,4.07142 0.3387225,2.94327 6.2788425,0 Z"
                fill="currentcolor"
                strokeWidth="1.5"
              />
            </>
          );

          break;
        case 2:
          content = (
            <>
              <path
                d="m 18.5601,15.79665 c -3.78786,0 -6.87165,-3.543615 -6.87165,-7.896705 C 11.68845,3.54684 14.769045,0 18.5601,0 c 3.79095,0 6.87165,3.54366 6.87165,7.899945 0,4.35627 -3.08385,7.896705 -6.87165,7.896705 z m 0,-14.5219515 c -3.087,0 -5.59737,2.9732265 -5.59737,6.6252465 0,3.652005 2.51037,6.62205 5.59737,6.62205 3.087,0 5.59725,-2.970045 5.59725,-6.62205 0,-3.65202 -2.51025,-6.6252465 -5.59725,-6.6252465 z"
                fill="currentcolor"
                strokeWidth="1.5"
              />
              <path
                d="M 29.3565,30 H 7.76361 C 7.41318,30 7.12647,29.7132 7.12647,29.36265 v -5.7648 c 0,-3.34605 2.12487,-6.3225 5.288295,-7.41225 l 0.31221,-0.1053 c 0.20388,-0.0669 0.426885,-0.0318 0.59892,0.102 1.545075,1.2078 3.354555,1.84515 5.237355,1.84515 1.8828,0 3.69225,-0.63735 5.23725,-1.84515 0.1689,-0.1338 0.3951,-0.17205 0.59895,-0.102 l 0.309,0.1053 C 27.8751,17.27535 30,20.25495 30,23.59785 v 5.7648 C 30,29.7132 29.71335,30 29.3628,30 Z M 8.40075,28.7253 H 28.7193 v -5.12745 c 0,-2.7948 -1.77435,-5.28675 -4.4154,-6.2013 -1.7076,1.2492 -3.68595,1.9056 -5.7438,1.9056 -2.058,0 -4.03638,-0.6597 -5.74392,-1.9056 -2.640975,0.91455 -4.41543,3.4065 -4.41543,6.2013 z"
                fill="currentcolor"
                strokeWidth="1.5"
              />
              <path
                d="M 8.117235,12.67689 H 0.8059905 L 6.033765,5.64693 C 6.72825,4.633545 6.900285,3.425775 6.467025,2.520735 6.135705,1.826025 5.48583,1.4053785 4.632045,1.3384575 c -0.08601,-0.00637 -0.16884,-0.00956 -0.25485,-0.00956 -1.710735,0 -3.1029015,1.3926135 -3.1029015,3.1038885 H 0 C 0,2.01723 1.962405,0.0541992 4.377195,0.0541992 c 0.12105,0 0.242115,0.0031867 0.359985,0.01593375 C 6.04014,0.175296 7.09143,0.870006 7.62027,1.9758 8.247855,3.29193 8.0376,4.984095 7.07232,6.39264 l -3.727305,5.012745 h 4.77222 v 1.2747 z"
                fill="currentcolor"
                strokeWidth="1.5"
              />
            </>
          );

          break;
        case 3:
          content = (
            <>
              <path
                d="m 18.55875,15.793301 c -3.78816,0 -6.872265,-3.542865 -6.872265,-7.8950404 0,-4.35216 3.084105,-7.8982556000742 6.872265,-7.8982556000742 3.78825,0 6.8724,3.5429006000742 6.8724,7.8982556000742 0,4.3553544 -3.08415,7.8950404 -6.8724,7.8950404 z m 0,-14.5188694 c -3.0873,0 -5.59785,2.972604 -5.59785,6.623829 0,3.6512394 2.51055,6.6206554 5.59785,6.6206554 3.0873,0 5.598,-2.969415 5.598,-6.6206554 0,-3.651225 -2.5107,-6.623829 -5.598,-6.623829 z"
                fill="currentcolor"
                strokeWidth="1.5"
              />
              <path
                d="M 29.35635,29.99365 H 7.761255 c -0.350475,0 -0.637215,-0.2868 -0.637215,-0.6372 v -5.7636 c 0,-3.34545 2.12511,-6.32115 5.288865,-7.4109 l 0.31224,-0.105 c 0.20391,-0.0702 0.42693,-0.03195 0.59898,0.10185 1.54524,1.2075 3.354975,1.8447 5.237925,1.8447 1.88295,0 3.69255,-0.6372 5.23785,-1.8447 0.1689,-0.1338 0.3951,-0.16875 0.59895,-0.10185 l 0.30915,0.105 C 27.87165,17.2717 30,20.2507 30,23.59285 v 5.7636 c 0,0.3504 -0.2868,0.6372 -0.6372,0.6372 z M 8.39847,28.71925 h 20.32068 v -5.1264 c 0,-2.7942 -1.77465,-5.2857 -4.41585,-6.2001 -1.70775,1.2489 -3.6894,1.9053 -5.7444,1.9053 -2.05515,0 -4.036815,-0.659549 -5.74455,-1.9053 -2.641245,0.9144 -4.41588,3.4059 -4.41588,6.2001 z"
                fill="currentcolor"
                strokeWidth="1.5"
              />
              <path
                d="M 4.371285,12.604091 C 1.95942,12.604091 0,11.046101 0,9.1344556 h 1.274427 c 0,1.2107104 1.389123,2.1952054 3.096858,2.1952054 1.70772,0 3.096855,-0.984495 3.096855,-2.1952054 0,-1.210695 -1.389135,-2.19519 -3.096855,-2.19519 l -0.73917,0.012735 v -1.29672 l 0.73917,0.00955 c 1.70772,0 3.096855,-0.984495 3.096855,-2.195205 0,-1.21071 -1.389135,-2.195199 -3.096855,-2.195199 -1.707735,0 -3.096858,0.984489 -3.096858,2.195199 H 0 C 0,1.5548006 1.962615,0 4.371285,0 6.77994,0 8.74257,1.5579806 8.74257,3.4696256 c 0,1.169295 -0.729615,2.20476 -1.847925,2.83242 1.11831,0.627645 1.847925,1.663125 1.847925,2.832405 0,1.9148244 -1.96263,3.4696344 -4.371285,3.4696344 z"
                fill="currentcolor"
                strokeWidth="1.5"
              />
            </>
          );

          break;
        default:
          content = (
            <>
              <path
                d="m 14.99685,15.79665 c -3.78903,0 -6.87381,-3.543615 -6.87381,-7.896705 C 8.12304,3.54684 11.20782,0 14.99685,0 c 3.78903,0 6.87378,3.54366 6.87378,7.899945 0,4.35627 -3.08475,7.896705 -6.87378,7.896705 z m 0,-14.521953 c -3.087945,0 -5.59911,2.973228 -5.59911,6.625248 0,3.652005 2.511165,6.62205 5.59911,6.62205 3.08793,0 5.59908,-2.970045 5.59908,-6.62205 0,-3.65202 -2.51115,-6.625248 -5.59908,-6.625248 z"
                fill="currentcolor"
                strokeWidth="1.5"
              />
              <path
                d="M 25.79673,30 H 4.19697 C 3.846435,30 3.55962,29.7132 3.55962,29.36265 v -5.7648 c 0,-3.3462 2.12556,-6.3225 5.290005,-7.4124 l 0.3123,-0.10515 c 0.20394,-0.0702 0.42702,-0.03195 0.5991,0.102 1.54557,1.2078 3.35565,1.84515 5.239005,1.84515 1.8834,0 3.69345,-0.63735 5.23905,-1.84515 0.1689,-0.13395 0.3951,-0.1689 0.5991,-0.102 l 0.30915,0.10515 c 3.1644,1.0899 5.29305,4.0695 5.29305,7.4124 v 5.7648 C 26.44038,29.7132 26.15358,30 25.80303,30 Z M 4.83432,28.7253 h 20.32506 v -5.12745 c 0,-2.7948 -1.77495,-5.2869 -4.4169,-6.20145 -1.70805,1.2492 -3.69015,1.9056 -5.74563,1.9056 -2.05545,0 -4.03761,-0.65955 -5.745705,-1.9056 -2.6418,0.91455 -4.416825,3.40665 -4.416825,6.20145 z"
                fill="currentcolor"
                strokeWidth="1.5"
              />
            </>
          );
      }
  }

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
