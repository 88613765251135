import styled from 'styled-components/macro';
import { colors } from '../../common/colors/colors';
import { shadow } from '../../common/styles/shadows';

export const Avatar = styled.div`
  align-items: center;
  background-color: ${(props) =>
    props.anonymous || props.initials ? colors.bg_grey_1 : colors.bg_grey_2};
  border-radius: 50%;
  box-shadow: ${(props) =>
    props.raised &&
    (typeof props.raised === 'boolean' ? shadow.avatar : shadow[props.raised])};
  color: ${(props) =>
    props.anonymous ? colors.bg_grey_3 : colors.header_logo_and_text_Primary};
  display: flex;
  height: ${(props) => (props.size ? props.size : '100%')};
  justify-content: center;
  overflow: hidden;
  width: ${(props) => (props.size ? props.size : '100%')};

  img {
    height: inherit;
    object-fit: cover;
    -webkit-user-drag: none;
    width: inherit;
  }

  svg {
    align-self: flex-end;
    height: 75%;
    width: 75%;
  }

  .initials {
    align-items: center;
    color: ${colors.text_Secondary};
    display: flex;
    font-weight: 600;
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  :hover {
    .tooltip-container {
      display: unset;

      > span {
        background-color: ${colors.text_Secondary};
        border-radius: 10px;
        display: inline-block;
        font-size: 0.6875rem;
        line-height: 1;
        margin-left: 26px;
        padding: 5px 10px;
        position: relative;
        top: -16px;
      }
    }
  }
`;
