import { cloneElement } from 'react';

// style
import * as S from './FunctionBarSubItem.styled';

// hooks
import { useMediaQuery } from 'hooks/useMediaQuery';

export const FunctionBarSubItem = ({ item, setActive, closeMenu }) => {
  const { icon, color, background, isDisabled, action, name, title } = item;
  const isMobile = useMediaQuery('(max-width: 960px)');
  const processedIcon = icon && cloneElement(icon, { sm: isMobile }); // add prop for bold icon if isMobile

  return (
    <S.FunctionBarSubItem
      itemColor={color}
      itemBackground={background}
      isDisabled={isDisabled}
      onClick={(e) => {
        e.stopPropagation();
        if (isDisabled) return false;
        action();
        setActive(null);
        closeMenu();
      }}
    >
      {background ? (
        <span>
          <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" />
          </svg>
        </span>
      ) : (
        <span>{processedIcon}</span>
      )}
      <span>{name || title}</span>
    </S.FunctionBarSubItem>
  );
};

export default FunctionBarSubItem;
