import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { components } from 'react-select';

// style
import * as S from './InputFieldNumber.styled';

// components
import { ReactNumeric } from 'components/ReactNumeric/ReactNumeric';

// context
import { UserContext } from 'context/UserContext';

// hooks
import { useSystemGetCultureById } from 'services/systemService';

export const InputFieldNumber = ({
  id,
  headerId,
  label,
  state,
  decimalPlaces,
  disabled,
  placeholder,
  minimumValue,
  type,
  ...restProps
}) => {
  const { locale } = useContext(UserContext);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);

  // if no headerId supplied
  if (!headerId) headerId = id;

  // get culture settings
  const { isSuccess: isCultureSuccess, data: culture } =
    useSystemGetCultureById({ id: locale });

  useEffect(
    // set values asynchronously
    () =>
      setInputValue(
        !(
          [undefined, null].includes(state?.value) ||
          state?.value?.value === undefined
        )
          ? state.value?.value?.toString()
          : '',
      ),
    [state, state?.value, headerId],
  );

  const updateValue = useCallback(
    (value) => state?.setValue && state.setValue(headerId, value),
    [state, headerId],
  );

  const validateInput = (e, value) => {
    // if value didn't change
    if (e.target?.value === inputValue) return;

    updateValue(
      e.target.value === ''
        ? undefined
        : { value: value, label: e.target.value },
    );
  };

  const clearInput = (e) => {
    // set visual confirmation immediately
    inputRef.current.autonumeric.set('');
    // outsource to validation logic
    validateInput({ target: { value: '' } }, '');
    inputRef.current.input.focus();
  };

  const typeSettings = useMemo(() => {
    if (type && isCultureSuccess) {
      switch (type) {
        case 'currency':
          return {
            currencySymbol: culture.numberFormatInfo?.currencySymbol, // TODO: fetch from '/sectors', not from '/cultures'
            emptyInputBehavior: 'null',
          };
        case 'percentage':
          return {
            suffixText:
              (disabled && state?.value !== undefined) || !disabled ? '%' : '',
          };
        default:
          return {};
      }
    } else return {};
  }, [type, isCultureSuccess, culture, state, disabled]);

  // log
  // useEffect(() => {
  //   isCultureSuccess && console.log({ culture });
  // }, [isCultureSuccess, culture]);

  return (
    <S.InputFieldNumber hasValue={!!state.value}>
      {label && <label htmlFor={id}>{label}</label>}

      <div className="input-wrapper">
        <ReactNumeric
          id={id}
          ref={inputRef}
          placeholder={
            !state.value && disabled
              ? '-'
              : placeholder ||
                (0).toLocaleString(locale, {
                  minimumFractionDigits:
                    decimalPlaces?.toString() ||
                    (isCultureSuccess &&
                      culture?.numberFormatInfo?.numberDecimalDigits),
                  maximumFractionDigits:
                    decimalPlaces?.toString() ||
                    (isCultureSuccess &&
                      culture?.numberFormatInfo?.numberDecimalDigits),
                })
          }
          value={inputValue}
          onBlur={validateInput}
          modifyValueOnWheel={false}
          {...(minimumValue !== undefined && {
            minimumValue: minimumValue || '0',
          })}
          {...(isCultureSuccess &&
            culture?.numberFormatInfo && {
              decimalPlaces: +culture.numberFormatInfo.numberDecimalDigits,
              decimalCharacter: culture.numberFormatInfo.numberDecimalSeparator,
              decimalCharacterAlternative:
                culture.numberFormatInfo.numberDecimalSeparator === ','
                  ? '.'
                  : ',',
              digitGroupSeparator:
                culture.numberFormatInfo.numberGroupSeparator,
              digitalGroupSpacing:
                culture.numberFormatInfo.numberGroupSizes[0].toString(),
            })}
          {...(decimalPlaces !== undefined && { decimalPlaces })}
          disabled={disabled}
          {...typeSettings}
          unformatOnHover={true}
          {...restProps}
        />

        {inputValue !== '' && !disabled && (
          <div className="input-wrapper__icon" onClick={clearInput}>
            <components.ClearIndicator
              getStyles={() => ({})}
              getClassNames={() => {}}
              cx={() => []}
            />
          </div>
        )}
      </div>
    </S.InputFieldNumber>
  );
};

export default InputFieldNumber;
