import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import {
  EmployeesSelector,
  EmptyEmployee,
} from '../EmployeesSelector/EmployeesSelector.styled';
import { ProfessionalSelect } from 'components/DialogLayout/CustomSelect/children/ProfessionalSelectChild/ProfessionalSelectChild.styled';
export { EmptyEmployee };

export const OrderMobile = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: calc(100dvh - 54px);

  .order-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 10px 80px 10px;
    height: 100%;
  }

  ::-webkit-scrollbar {
    width: 0px;
  }

  ${EmployeesSelector} {
    background-color: ${colors.bg_Secondary};
    padding: 20px;

    .row-title {
      font-size: 12px;
    }

    .row .row-content ${ProfessionalSelect} {
      background-color: ${colors.bg_grey_1};

      .proffesionalSelect_info {
        .proffesionalSelect_info_name {
          font-size: 12px;
        }

        .proffesionalSelect_info_profession {
          font-size: 10px;
        }
      }
    }

    ${EmptyEmployee} {
      background-color: ${colors.bg_grey_2};
    }
  }

  .client_basic_info {
    align-items: center;
    display: flex;
  }

  .pickClientAgain {
    padding-right: 10px;

    svg {
      fill: ${colors.bg_Primary};
      height: 15.94px;
      width: 20px;
    }
  }

  .client_fullName {
    color: ${colors.bg_Primary};
    font-family: ${fontFamilies.family_Secondary};
    font-size: 18px;
    font-weight: 600;
  }

  .client_orders_info {
    display: flex;
    margin-top: 20px;

    > div {
      margin-right: 5px;
    }
  }
`;

export const Text = styled.div`
  font-family: ${fontFamilies.family_Secondary};
  max-width: 460px;
  padding: 10px 0px;

  a {
    color: ${colors.link};
  }
`;
