import { useCallback, useContext, useState } from 'react';
import { Navigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateSchedule } from 'redux/actions/scheduleActions';

// components
import ProfessionalHeader from '../ProfessionalHeader/ProfessionalHeader';
import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper';

// contexts
import { ScheduleContext } from 'context/ScheduleContext';

export const DataPanelHeader = ({
  numberOfDays,
  personalDays,
  headerData,
  headerMenu,
  pinToStart,
  searchParams,
}) => {
  const dispatch = useDispatch();
  const sectorId = searchParams.get('sectorId');

  const APP_ROOT = process.env.REACT_APP_APP_ROOT;

  // local
  const [shouldNavigateToPersonal, setShouldNavigateToPersonal] =
    useState(false);
  const [shouldNavigateToMonthly, setShouldNavigateToMonthly] = useState(false);
  const { selectedProfessional } = useContext(ScheduleContext);

  const toggleSelectedProfessionals = useCallback(
    (employee) => {
      const newVal =
        selectedProfessional?.value?.id === employee?.id ? null : employee;
      selectedProfessional.set(newVal);
      dispatch(
        updateSchedule({
          selectedProfessional: newVal,
        }),
      );
    },
    [selectedProfessional?.value?.id, sectorId],
  );

  const selected = selectedProfessional?.value?.id === headerData.id;
  // const checktIfHeadToggled = (id) => selectedProfessional?.value?.id === id;

  return (
    // <ClickAwayListener
    //   mouseEvent="mousedown"
    //   onClickAway={() => dispatch(setPendingOrdersProfesional(null))}
    // >
    <div className="column-head">
      <ConditionalWrapper
        condition={numberOfDays !== 1}
        wrapper={(children) => <div className="sticky-head">{children}</div>}
      >
        <ProfessionalHeader
          id={headerData.id}
          pic={headerData.photoUrl}
          firstName={headerData.firstName}
          lastName={headerData.lastName}
          position={headerData.profession}
          prioritize={headerData.pinned}
          headerMenu={headerMenu}
          pinToStart={pinToStart}
          select={() => {
            toggleSelectedProfessionals(headerData);
          }}
          selected={selected}
          // selected={checktIfHeadToggled(headerData.id)}
          openPersonal={() => setShouldNavigateToPersonal(true)}
          // openMonthly={() => setShouldNavigateToPersonal(true)}
          {...(numberOfDays !== 1 && {
            variant: 'multi',
          })}
        />
      </ConditionalWrapper>
      {shouldNavigateToPersonal && (
        <Navigate
          to={{
            pathname: APP_ROOT + 'functions/daily-functions/calendar/personal',
            search: createSearchParams({
              ...Object.fromEntries(new URLSearchParams(searchParams)),
              employeeId: headerData.id,
            }).toString(),
          }}
          state={{ numberOfDays }}
        />
      )}
      {shouldNavigateToMonthly && (
        <Navigate
          to={{
            pathname: APP_ROOT + 'functions/daily-functions/calendar/month',
            search: createSearchParams({
              ...Object.fromEntries(new URLSearchParams(searchParams)),
              employeeId: headerData.id,
            }).toString(),
          }}
          state={{ numberOfDays }}
        />
      )}
    </div>
    // </ClickAwayListener>
  );
};

export default DataPanelHeader;
