import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import { Avatar } from 'components/Avatar/Avatar.styled';

export const AppointmentsDetails = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${fontFamilies.family_Secondary};

  .section {
    padding: 10px 0;
    border-bottom: 1px solid ${colors.bg_grey_5};

    &__link {
      display: flex;
      width: 95%;
      align-items: center;
      gap: 5px;

      &__icon {
        width: 15px;
        height: 15px;
        margin-top: 3px;
        display: flex;
        align-items: center;
      }
    }

    &__functions {
      width: 15px;
      height: 15px;
      display: flex;
      align-items: center;
      position: relative;
    }

    &__arrow {
      height: 12px;
    }

    &__label {
      font-weight: 600;
    }

    :last-of-type {
      border-bottom: none;
    }
  }

  .display {
    &__row {
      display: flex;
      gap: 20px;
    }
    &__column {
      display: flex;
      flex-direction: column;
    }
  }

  .head {
    display: flex;
    flex-direction: column;
    background-color: ${colors.bg_grey_1};
    padding: 10px;
    border-radius: 5px;

    .full-name {
      font-size: 1rem;
      font-family: ${fontFamilies.family_Primary};
      font-weight: 900;
      display: flex;
      align-items: center;
      gap: 5px;
      width: 100%;
      color: ${colors.bg_Primary};

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        height: 20px;
        margin-bottom: -1.6px;
      }
    }

    .phone {
      font-weight: 400;
      margin-top: 3px;
      font-size: 0.875rem;
    }
  }

  .body {
    background-color: white;
    font-size: 14px;
    line-height: 1.3;
    letter-spacing: 0px;
    width: 100%;
    padding: 0 10px;

    .appopintment-id {
      font-size: 1rem;
      font-family: ${fontFamilies.family_Primary};
      font-weight: 900;
      color: ${colors.bg_Primary};
    }

    .preferation {
      font-weight: 900;
      gap: 10px;

      .avatars {
        gap: 5px;

        ${Avatar} {
          width: 20px;
          height: 20px;
        }
      }
    }

    .appointment-status {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      cursor: pointer;

      .status {
        display: flex;
        align-items: center;
        width: 95%;

        &__color {
          background-color: ${(props) => props.statusColor};
          width: 20px;
          height: 20px;
          border-radius: 50%;
          flex-shrink: 0;
          transition: background-color 300ms ease-in-out;
        }

        &__label {
          margin-left: 5px;
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .time {
      display: flex;
      gap: 20px;

      .block {
        display: flex;
        flex-direction: column;
      }
    }

    .services {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .list ul {
        margin: 0px 15px;
        padding: 0;
        white-space: pre-wrap;
        font-size: 0.75rem;
        list-style-type: disc;
      }

      .price {
        display: block;
        font-weight: 600;
      }
    }

    .balance {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      .status {
        display: flex;
        gap: 20px;

        &__debt {
          font-weight: 600;
          color: ${colors.discount};
        }
      }
    }

    .note {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;

      span {
        font-weight: 900;
      }

      p {
        margin: 0;
        padding: 0;
        white-space: pre-wrap;
      }
    }

    .additional-info {
      display: flex;
      gap: 5px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
`;
