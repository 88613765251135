import { getOptions } from 'common/helpers/modelFunctions';
import { useStore } from 'hooks/headers-store/store';
import { useEffect, useState } from 'react';
import { useFetch } from './useApi';
import {
  useDelete,
  useFetchMulti,
  useFetch as useNewUseFetch,
  useUpdate,
} from './useApi2';

import { usePost } from './useApi2';

const DB_SLICE = 'orders';

export const useOrdersGetAll = () => {
  const context = useFetch(DB_SLICE, null, { retry: true });

  if (context.isError) {
    console.log(context.error.stack);
  }

  return { ...context, data: context.data };
};

export const useOrdersOptionsGetAll = () => {
  const { data, isSuccess, ...context } = useOrdersGetAll();

  const [{ [DB_SLICE]: headers }] = useStore(false);

  const [options, setOptions] = useState();

  useEffect(() => {
    if (isSuccess) {
      setOptions(() =>
        Object.keys(data[0])?.reduce((acc, headerId) => {
          const header = headers.find((header) => header.id === headerId);
          return {
            ...acc,
            [headerId]: Array.isArray(header?.options)
              ? header.options
              : typeof header?.options === 'function'
                ? header.options(data, header.id)
                : getOptions(data, header?.id),
          };
        }, {}),
      );
    }
  }, [isSuccess, data, headers]);

  // useEffect(() => {
  //   if (isSuccess)
  //     setOptions(() =>
  //       Object.keys(data[0])?.reduce(
  //         (acc, headerId) => ({
  //           ...acc,
  //           [headerId]: data.reduce((acc, record) => {
  //             return [
  //               ...acc,
  //               ...(!acc.some((valueObj) => valueObj.value === record[headerId])
  //                 ? [{ value: record[headerId], label: record[headerId] }]
  //                 : []),
  //             ];
  //           }, []),
  //         }),
  //         {}
  //       )
  //     );
  // }, [isSuccess, data]);

  return {
    ...context,
    isSuccess: isSuccess && options !== undefined,
    options: options,
  };
};

export const useOrdersGetOne = (id) => {
  // get specific client data from B/E
  const context = useFetch(DB_SLICE, { id }, { retry: true });

  // get headers
  const [{ [DB_SLICE]: headers }] = useStore(false);

  return { ...context, data: context.data };
};

// ------------------------------

export const useServicesGetPrices = () => {
  return usePost({
    endpoint: 'orderprices',
    reqObjName: 'services',
  });
};

export const useServicesSellOrders = () => {
  const DB_SLICE = 'sellorders';

  return usePost({
    endpoint: DB_SLICE,
    reqObjName: 'order',
    searchParams: {
      allowOverlap: 1,
      serviceListMode: 'list',
    },
  });
};

export const useServiceGetOrder = (params) => {
  const { id, ...restParams } = params;
  const DB_SLICE = `sellorders/${id}`;

  return useNewUseFetch({
    endpoint: DB_SLICE,
    ...restParams,
  });
};

export const useServicesGetOders = (single, params) => {
  const DB_SLICE = 'sellorders';

  const useFetchCond = single ? useNewUseFetch : useFetchMulti;

  const getParams = () => {
    return single
      ? {
          endpoint: DB_SLICE,
          ...params,
        }
      : params.map((el) => {
          return {
            endpoint: DB_SLICE,
            ...el,
          };
        });
  };

  return useFetchCond(getParams());
};

export const useServicesModifyOrderExecutionDetails = (params = {}) => {
  const DB_SLICE = 'sellorders';

  const { searchParams } = params;

  const search = {
    serviceListMode: 'both',
    ...searchParams,
  };

  return useUpdate({
    endpoint: DB_SLICE,
    reqObjName: 'order',
    searchParams: search,
  });
};

export const useServicesModifyOrderStatus = (params = {}) => {
  const DB_SLICE = 'sellorders/status';

  const { searchParams } = params;

  const search = {
    useVersioning: 1,
    serviceListMode: 'both',
    ...searchParams,
  };

  return useUpdate({
    endpoint: DB_SLICE,
    reqObjName: 'order',
    searchParams: search,
  });
};

export const useServicesGetPendingOrders = (params = {}) => {
  const DB_SLICE = 'sellorders/waiting';

  return useNewUseFetch({
    endpoint: DB_SLICE,
    ...params,
  });
};

export const useOrderPaymentStatusPatch = (params = {}) => {
  const DB_SLICE = 'sellorders/payment';

  return useUpdate({
    endpoint: DB_SLICE,
    reqObjName: 'order',
    ...params,
  });
};

export const useOrderNotePatch = () => {
  const DB_SLICE = 'sellorders/note';

  return useUpdate({
    endpoint: DB_SLICE,
    reqObjName: 'order',
  });
};

export const useOrderDelete = (params = {}) =>
  useDelete({
    endpoint: 'sellorders',
    ...params,
  });
