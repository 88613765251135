import { useState } from 'react';
import Select from 'react-select';

// style
import * as S from './Pagination.styled';
import { colors } from 'common/colors/colors';

// icons
import { Arrow, ArrowEnd } from 'common/icons';

// components
import { BaseSelect } from 'components/Inputs';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useMediaQuery } from 'hooks/useMediaQuery';

export const Pagination = ({
  recordsPerPage,
  recordsPerPageHandler,
  totalRecords,
  page,
  changePage,
}) => {
  const pageNumbers = [];
  // const [currentPage, setCurrentPage] = useState(1);

  for (let i = 1; i <= Math.ceil(totalRecords / recordsPerPage); i++) {
    pageNumbers.push(i);
  }

  const lastPage = () => pageNumbers.slice(-1)[0] || 1;

  const tr = useTranslate().use().global;

  const isMobile = useMediaQuery('(max-width: 960px)');

  const pageQtyOptions = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ];

  const handleToFirstPage = () => changePage(1);

  const hanleToPreviusPage = () => page > 1 && changePage((prev) => prev - 1);

  const hanleToNextPage = () =>
    page < lastPage(pageNumbers) && changePage((prev) => prev + 1);

  const handleToLastPage = () => changePage(lastPage(pageNumbers));

  return (
    <S.Container>
      <S.ControlSection>
        <ArrowEnd
          bold
          width={20}
          className="arrow first-page"
          onClick={handleToFirstPage}
        />
        <Arrow
          bold
          width={20}
          className="arrow previus-page"
          onClick={hanleToPreviusPage}
        />
        <div className="page-indication">
          {page}&nbsp;/&nbsp;{lastPage()}
        </div>
        <Arrow
          bold
          width={20}
          className="arrow next-page"
          onClick={hanleToNextPage}
        />
        <ArrowEnd
          bold
          width={20}
          className="arrow"
          onClick={handleToLastPage}
        />
      </S.ControlSection>

      <S.InfoSection>
        {!isMobile && <label htmlFor="select-page-qty">{tr.show}</label>}
        <BaseSelect
          options={pageQtyOptions}
          customStyles={S.CustomFilterSelect}
          value={recordsPerPage}
          onChange={(value) => recordsPerPageHandler(value)}
        />
        {!isMobile && (
          <span>
            {tr['total']}: <b>{totalRecords}</b>
          </span>
        )}
      </S.InfoSection>
    </S.Container>
  );
};

export default Pagination;
