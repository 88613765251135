import { useEffect, useReducer, useState } from 'react';

// style
import * as S from './GeneralSystemSettings.styled';

// icons
import { Check } from 'common/icons';

// components
import { InputField } from 'components/InputField/InputField';
import { InputFieldGroup } from 'components/InputFieldGroup/InputFieldGroup';
import { Loader } from 'components/Loader/Loader';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useStore } from 'hooks/headers-store/store';
import { useUsersGetOne } from 'services/userService';
import { useSystemGetTimezoneOptions } from 'services/systemService';

export const GeneralSystemSettings = ({ setButtons }) => {
  const {
    data,
    isSuccess: isDataSuccess,
    isLoading: isDataLoading,
  } = useUsersGetOne('1'); // TODO:
  // const { options, isSuccess: isOptionsSuccess } = useClientsOptionsGetAll(id); // TODO:

  // useEffect(() => {
  //   console.log('data:', data);
  // }, [data]);

  // translation engine
  const tr = useTranslate().use().global;

  // setup data state
  let [{ users: allHeaders }] = useStore(false);

  // console.log({ allHeaders });

  const requiredHeaders = [
    'currency',
    'country',
    'language',
    'city',
    'vat',
    'vats',
    'professions',
    'timezone',
    'weekStart',
  ];

  let headers = requiredHeaders.map((headerId) =>
    allHeaders.find((header) => header.id === headerId),
  );

  const reducer = (state, action) => {
    if (action.id === 'setup') {
      return action.value;
    }
    let newState = { ...state };
    newState[action.id].value = action.value;
    // console.log({ newState });
    return newState;
  };

  // console.log('headers:', headers);

  const [state, dispatch] = useReducer(reducer, {
    // currency: {
    //   type: 'search',
    //   valueType: 'text',
    //   value: { value: '€', label: '€' },
    // },
    // currency: {},
    // language: {
    //   type: 'select',
    //   valueType: 'text',
    //   value: { value: 'lt', label: 'Lietuvių' },
    // },
    // country: null,
    // city: null,
    // vat: {
    //   type: 'select',
    //   valueType: 'number',
    //   value: { value: 21.0, label: '21.00%' },
    // },
    // vats: { type: 'search', valueType: 'number', value: [21.0, 0.0] },
    // professions: {
    //   type: 'search',
    //   valueType: 'text',
    //   value: ['Administratorė', 'Vadovas'],
    // },
  });

  // console.log('RENDERING');

  // setup initial state
  useEffect(() => {
    if (data) dispatch({ id: 'setup', value: data });
  }, [data]);

  // change value function
  const setValue = (id, value) => {
    // console.log({ id, value });
    dispatch({ id, value });
  };

  // setup/cleanup header buttons
  useEffect(() => {
    setButtons &&
      setButtons({
        type: 'setup',
        value: [
          {
            id: 'save',
            icon: <Check bold />,
            action: () => console.log('Settings saved'),
            borderless: true,
            show: true,
          },
        ],
      });

    return () => setButtons && setButtons({ type: 'cleanup' });
  }, [setButtons]);

  const [professions, setProfessions] = useState({
    type: 'select',
    values: [],
    setValue,
  });

  const addItemHandler = (id) => {
    let newValue = { ...state };
    newValue[id].value.push('');
    // console.log({ id, newValue: newValue[id] });
    setValue(id, newValue[id].value);
  };

  const removeItemHandler = (id, position) => {
    let newValue = { ...state };
    newValue[id].value.splice(position, 1);
    setValue(id, newValue[id].value);
  };

  useEffect(() => {
    // console.log('outer state:', state);
  }, [state]);

  const [vatOptions, setVatOptions] = useState(
    state['vats']?.value?.map((vat) => ({
      value: vat,
      label: String(vat),
    })),
  );

  useEffect(() => {
    if (state['vats']) {
      // console.log({ state });
      setVatOptions(
        state['vats']?.value?.map((vat) => ({
          value: vat,
          label: String(vat),
        })),
      );
    }
  }, [state]);

  const { data: timezones } = useSystemGetTimezoneOptions({
    labelFormat: ['displayName'],
  });

  return (
    <S.GeneralSystemSettings>
      <S.Form>
        <InputField
          id={'timezone'}
          label={'Laiko juosta'}
          state={{
            type: state['timezone']?.type,
            // value: timezones?.filter(item => item.value == state['timezone']?.value?.value)[0],
            value: state['timezone']?.value,
            setValue: setValue,
          }}
          options={timezones}
        />
        <InputField
          id={'weekStart'}
          label={'Savaitės pradžia'}
          state={{
            type: state['weekStart']?.type,
            value: state['weekStart']?.value,
            setValue: setValue,
          }}
          options={headers.find((header) => header.id === 'weekStart').options}
        />
      </S.Form>
      <S.Form>
        {/* {headers?.map((header, key) => {
          console.log('header.options', header.options);
          return (
            isDataSuccess && (
              <InputField
                key={header.id}
                id={header.id}
                label={tr[header.id]}
                state={{
                  type: state[header.id]?.type,
                  value: state[header.id]?.value,
                  setValue: setValue,
                }}
                // options={options[header.id]}
              />
            )
          );
        })} */}
        <InputField
          id={'currency'}
          label={'Valiutos simbolis'}
          state={{
            type: state['currency']?.type,
            value: state['currency']?.value,
            setValue: setValue,
          }}
          // options={[
          //   { value: '€', label: '€' },
          //   { value: '$', label: '$' },
          //   { value: '£', label: '£' },
          //   { value: '₽', label: '₽' },
          // ]}
          options={headers.find((header) => header.id === 'currency').options}
        />
        <InputField
          id={'country'}
          label={tr['country']}
          state={{
            type: state['country']?.type,
            value: state['country']?.value,
            setValue: setValue,
          }}
          // options={headers.find((header) => header.id === 'country').options}
          options={[{ value: 'Netherlands', label: 'Netherlands' }]}
        />
        <InputField
          id={'language'}
          label={'Programos kalba'}
          state={{
            type: state['language']?.type,
            value: state['language']?.value,
            setValue: setValue,
          }}
          // options={[
          //   { value: 'lt', label: 'Lietuvių' },
          //   { value: 'en', label: 'English' },
          //   { value: 'ru', label: 'Русский' },
          // ]}
          options={headers.find((header) => header.id === 'language').options}
        />
        <InputField
          id={'city'}
          label={tr['city']}
          state={{
            type: state['city']?.type,
            value: state['city']?.value,
            setValue: setValue,
          }}
          options={[{ value: 'North Nakiaton', label: 'North Nakiaton' }]}
          // options={headers.find((header) => header.id === 'city').options}
        />
      </S.Form>

      <S.Form>
        <InputField
          id="vat"
          label={tr['default-vat']}
          state={{
            type: state['vat']?.type,
            value: state['vat']?.value,
            setValue: setValue,
          }}
          // options={state['vats']?.value.reduce(
          //   (acc, item) =>
          //     item !== ''
          //       ? [
          //           ...acc,
          //           {
          //             value: item,
          //             label: item,
          //           },
          //         ]
          //       : acc,
          //   []
          // )}
          // options={headers.find((header) => header.id === 'vat').options}
          options={vatOptions}
        />
        <InputFieldGroup
          id="vats"
          label={tr['vats']}
          placeholder="%"
          type={state['vats']?.type}
          valueType={state['vats']?.valueType}
          state={state['vats']?.value}
          setState={setValue}
          addItemHandler={addItemHandler}
          removeItemHandler={removeItemHandler}
        />
      </S.Form>

      <S.Form>
        <InputFieldGroup
          id="professions"
          label="Profesijų pavadinimai"
          placeholder={tr['input']}
          type={state['professions']?.type}
          valueType={state['professions']?.valueType}
          state={state['professions']?.value}
          setState={setValue}
          addItemHandler={addItemHandler}
          removeItemHandler={removeItemHandler}
        />
      </S.Form>

      {isDataLoading && <Loader />}
    </S.GeneralSystemSettings>
  );
};

export default GeneralSystemSettings;
