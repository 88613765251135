// style
import * as S from './OrderRowBreak.styled';

// icons
import { Clock } from 'common/icons';

// components
import { InputFieldDuration } from 'components/InputFieldDuration/InputFieldDuration';

// hooks
import useTranslate from 'hooks/useTranslate';
import { getTimeFromMinutes } from 'common/helpers/timeCalculations';

const OrderRowBreak = ({ colSpan, index, updateBreak, data }) => {
  const tr = useTranslate().use().global;
  const handleInput = (value) =>
    updateBreak({ unitDurationInMinutes: value?.value }, index);

  return (
    <S.OrderRowBreak>
      <td colSpan={colSpan}>
        <div className="content">
          <div className="icon-container">
            <Clock />
          </div>
          <span className="text-container">{tr['break']}</span>
          <InputFieldDuration
            id={'unitDurationInMinutes'}
            state={{
              ...(data?.unitDurationInMinutes
                ? {
                    default: {
                      value: data?.unitDurationInMinutes,
                      label: getTimeFromMinutes(data?.unitDurationInMinutes),
                    },
                  }
                : {}),
              setValue: (id, value) => handleInput(value),
            }}
          />
        </div>
      </td>
    </S.OrderRowBreak>
  );
};

export default OrderRowBreak;
