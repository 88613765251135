import { StyledSVG } from './SVG.styled';

export default ({
  color = 'currentColor',
  width = 15.971,
  height = 16,
  ...rest
}) => (
  <StyledSVG
    width={width}
    height={height}
    viewBox="0 0 15.971 16"
    color={color}
    {...rest}
  >
    <path
      d="M36.181,16.059l-.842-.842a2.136,2.136,0,0,0-3.015,0L26.053,21.49a.227.227,0,0,0-.024.031c-.016.018-.033.037-.047.057s-.021.034-.033.052-.021.037-.031.057-.016.044-.024.067l-.015.039L24.9,25.707a.651.651,0,0,0,.79.79l3.915-.978c.015,0,.029-.011.044-.016a.4.4,0,0,0,.062-.023c.021-.01.041-.021.06-.033s.034-.02.05-.031a.483.483,0,0,0,.055-.046c.01-.01.021-.016.031-.026l6.272-6.272a2.133,2.133,0,0,0,0-3.015Zm-1.763.078.842.842a.83.83,0,0,1,0,1.174l-.291.291-2.015-2.015.291-.291A.83.83,0,0,1,34.418,16.137Zm-2.385,1.211,2.015,2.015-4.6,4.6-2.015-2.015Zm-5.168,5.873,1.31,1.31-1.747.436Z"
      transform="translate(-20.832 -12.219)"
      // fill="#0f1d5b"
    />
    <path
      d="M11.128,12.616a.651.651,0,0,0-.651.651V14.7H1.311V5.12H5.3a.651.651,0,0,0,.651-.651V1.3h4.529v.3a.651.651,0,0,0,1.3,0V.651A.651.651,0,0,0,11.128,0H5.281A.713.713,0,0,0,5.2.007L5.165.013c-.023,0-.046.011-.068.018a.192.192,0,0,0-.033.011A.611.611,0,0,0,5,.073L4.97.088a1.006,1.006,0,0,0-.086.059L.246,3.965a.6.6,0,0,0-.09.093l-.02.026a.675.675,0,0,0-.057.093l-.01.018a.641.641,0,0,0-.041.119.189.189,0,0,1-.007.029.569.569,0,0,0-.011.106c0,.007,0,.011,0,.018V15.349A.651.651,0,0,0,.661,16H11.128a.651.651,0,0,0,.651-.651V13.267A.651.651,0,0,0,11.128,12.616Zm-6.482-8.8H2.475l2.17-1.786V3.817Z"
      transform="translate(-0.01)"
      // fill="#0f1d5b"
    />
  </StyledSVG>
);
