import * as S from './SVG.styled';

export default ({ color, width = 30, height = 30, bold, ...rest }) => {
  const content = bold ? (
    <>
      <path
        d="m 27.017603,4.725135 -6.3012,-4.09068894 c -1.29015,-0.837705 -2.9655,-0.8468775 -4.26795,-0.0214005 L 10.477508,4.41024 C 10.003628,4.71291 9.5908883,5.11953 9.2882183,5.587305 L 1.4309034,17.5965 c -0.220125,0.3363 -0.293499,0.7368 -0.210945,1.13115 0.082545,0.39435 0.314895,0.73065 0.651195,0.94785 l 15.5281496,10.083 c 0.25065,0.162 0.53505,0.2415 0.8133,0.2415 0.49215,0 0.97215,-0.2415 1.25955,-0.67875 l 7.83585,-11.98155 c 0.32715,-0.50145 0.53505,-1.0824 0.6084,-1.6755 l 0.86835,-7.15716 c 0.1803,-1.485855 -0.5136,-2.96865 -1.77015,-3.784965 z m -0.6603,3.49146 -0.86835,7.157205 c -0.02745,0.2262 -0.10695,0.4464 -0.22935,0.63285 L 17.937353,27.1995 3.9929334,18.1467 11.333558,6.926415 c 0.11619,-0.17733 0.2721,-0.333255 0.45249,-0.44943 l 5.971005,-3.79719 c 0.49515,-0.31491 1.13115,-0.31185 1.6233,0.00916 l 6.3012,4.090695 c 0.477,0.30879 0.73995,0.874395 0.6726,1.44 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 21.866003,5.880795 c -0.7551,-0.48918 -1.65405,-0.65733 -2.53455,-0.470835 -0.8805,0.186495 -1.6356,0.706245 -2.12475,1.461405 -1.01205,1.556175 -0.56865,3.647385 0.9906,4.659345 0.5655,0.366885 1.2015,0.541155 1.8282,0.541155 1.1037,0 2.18595,-0.541155 2.8311,-1.531725 1.0119,-1.556175 0.56565,-3.64737 -0.9906,-4.659345 z m -1.06095,3.329415 c -0.2781,0.424965 -0.8499,0.54726 -1.27785,0.2721 -0.4281,-0.27822 -0.54735,-0.84993 -0.2721,-1.277955 0.13455,-0.2079 0.3393,-0.34854 0.5808,-0.400515 0.0642,-0.012225 0.1284,-0.02139 0.1926,-0.02139 0.17745,0 0.3486,0.05196 0.50145,0.149805 0.4281,0.278205 0.5472,0.84993 0.2721,1.277955 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
    </>
  ) : (
    <>
      <path
        d="m 27.179731,4.82394 -6.56895,-4.26453607 c -1.13775,-0.73944151 -2.61345,-0.74581651 -3.75765,-0.0191235 L 10.628386,4.498845 C 10.204486,4.76976 9.8507106,5.120355 9.5797806,5.53788 L 1.3853705,18.05745 C 1.108085,18.48765 1.229201,19.0614 1.6594805,19.3419 L 17.844331,29.85015 c 0.153,0.09885 0.3282,0.14985 0.50355,0.14985 0.06375,0 0.13065,-0.0063 0.1944,-0.01905 0.24225,-0.051 0.4527,-0.19455 0.5865,-0.4017 l 8.16885,-12.48765 c 0.28695,-0.4398 0.47175,-0.95295 0.5355,-1.4757 l 0.90525,-7.461285 c 0.1593,-1.30677 -0.4527,-2.616735 -1.55865,-3.33387 z m 0.2901,3.18087 -0.90525,7.46139 c -0.0414,0.3315 -0.15615,0.6534 -0.3378,0.9306 L 18.245881,28.5945 2.6443355,18.46215 10.644331,6.232695 c 0.17211,-0.26772 0.3984,-0.487635 0.666135,-0.65976 l 6.224715,-3.9585601 c 0.72345,-0.462144 1.6605,-0.455769 2.3808,0.01275 l 6.56895,4.2645451 c 0.70125,0.455775 1.0869,1.284465 0.98805,2.10996 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 21.806131,6.028755 c -1.32915,-0.863745 -3.114,-0.484455 -3.97455,0.84462 -0.8637,1.32909 -0.4845,3.11394 0.84465,3.977685 0.4812,0.312345 1.02315,0.46215 1.56165,0.46215 0.94035,0 1.86465,-0.46215 2.41605,-1.30995 0.8637,-1.32909 0.48435,-3.11394 -0.84465,-3.977685 z m -0.2232,3.28287 c -0.23265,0.35697 -0.58965,0.605565 -1.01025,0.694815 -0.4176,0.08925 -0.84465,0.0096 -1.2048,-0.22311 -0.7395,-0.481275 -0.9498,-1.472505 -0.4686,-2.211945 0.306,-0.47172 0.81915,-0.72669 1.34505,-0.72669 0.29955,0 0.59925,0.08286 0.867,0.258165 0.73935,0.481275 0.9498,1.472505 0.4716,2.211945 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
    </>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
