// style
import * as S from './CustomSelect.styled';

// icons
import { Arrow } from 'common/icons';

const CustomSelect = ({
  label,
  action,
  selected,
  darkTheme,
  arrow,
  child,
  openInput,
  paddingSize,
  children,
}) => {
  return (
    <S.CustomSelect
      selected={selected}
      darkTheme={darkTheme}
      openInput={openInput}
      animation={openInput !== undefined}
      paddingSize={paddingSize}
    >
      <div className="label">{label}</div>
      <div className="container" onClick={action}>
        {child}
        {children}
        {arrow && (
          <div className="icon">
            <Arrow black height={18} width={14} />
          </div>
        )}
      </div>
    </S.CustomSelect>
  );
};

export default CustomSelect;
