import styled, { css } from 'styled-components/macro';
import { colors } from 'common/colors/colors';

export const Container = styled.div`
  color: ${colors.footer_Secondary};
  display: flex;
  flex: 1;
  font-family: var(--font-family-secondary);
  font-size: 1.125rem;
  justify-content: space-between;
  margin-bottom: 8px;
  min-width: max-content;

  @media (max-width: 960px) {
    font-size: 0.875rem;
  }
`;

export const ControlSection = styled.div`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  font-size: 1.125rem;
  gap: 18px;

  @media (max-width: 960px) {
    font-size: 0.875rem;
    gap: 14px;
  }

  .arrow {
    cursor: pointer;
  }

  .first-page {
    transform: rotate(180deg);
  }

  .previus-page {
    transform: rotate(180deg);
  }

  .page-indication {
    font-weight: bold;
  }
`;

export const InfoSection = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  margin-left: 40px;
  gap: 10px;
`;

export const CustomFilterSelect = {
  InputField: css`
    .select__control {
      border-color: ${colors.bg_Primary};
      cursor: pointer;
      border-radius: ${({ menuIsOpen }) =>
        menuIsOpen ? '10px 10px 0 0' : '18px'};
      padding: 0 10px;
      height: 36px;

      .select__input-container {
        grid-template-columns: 0 0;
        cursor: pointer;
      }

      &:hover {
        border-color: currentColor;
      }

      &--is-focused:hover .select__indicator {
        color: ${colors.text_Secondary};
      }

      .select__value-container {
        font-weight: bold;
      }

      .select__indicator {
        color: ${colors.text_Secondary};
        padding: 0;
        width: 20px;

        svg {
          width: 12px;
          height: 12px;
        }
      }
    }

    @media (max-width: 960px) {
      .select__control {
        height: 26px;
        padding: 0 5px;

        .select__value-container {
          min-height: unset;
          padding: 0 6px;
        }

        .select__indicator {
          height: 26px;
        }
      }
    }
  `,
  Menu: css`
    box-shadow: 0 2px 3px rgba(0, 0, 0, 16%) !important;
    margin-top: -1px !important;
    padding-top: 2px;
    border: 1px solid currentColor;
    border-top: none;
    border-radius: 10px !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;

    .select__menu-list {
      padding-top: 0;
      padding-bottom: 10px;

      .select__option {
        cursor: pointer;
        margin: 0;
        padding: 8px 12px 8px 20px;
        border-radius: 0;
        font-size: 1.125rem;

        &:active {
          background-color: ${colors.bg_grey_2};
        }

        &--is-focused {
          background-color: ${colors.bg_grey_2};
        }
      }
    }

    @media (max-width: 960px) {
      .select__menu-list .select__option {
        padding-left: 10px;
        font-size: 0.875rem;
      }
    }
  `,
};
