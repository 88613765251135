import { useContext } from 'react';
import AutoNumeric from 'autonumeric';

// style
import * as S from './ServiceGeneralData.styled';

// components
import { InputField } from 'components/InputField/InputField';
import { InputFieldDuration } from 'components/InputFieldDuration/InputFieldDuration';
import { InputFieldNumber } from 'components/InputFieldNumber/InputFieldNumber';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { UserContext } from 'context/UserContext';
import { useSystemGetCultureById } from 'services/systemService';

export const ServiceGeneralData = ({ state, options, disabled, isSet }) => {
  const tr = useTranslate().use().global;
  const { locale } = useContext(UserContext);
  const { data: culture } =
    useSystemGetCultureById({ id: locale });

  return (
    <S.ServiceGeneralData>
      <label>{tr['settings']}</label>

      <div className="settings-container">
        {'type' in state && (
          <S.InputFieldGroup disabled={disabled}>
            {!disabled && (
              <InputField
                id={'type'}
                label={tr['type']}
                state={state?.['type']}
                options={options?.['type']}
                notClearable
              />
            )}

            {state['type'].value.value === 1 ? (
              <>
                <InputFieldDuration
                  id={'baseDurationInMinutes'}
                  label={tr['duration']}
                  state={state['baseDurationInMinutes']}
                  disabled={disabled}
                />

                <InputFieldDuration
                  id={'baseBreakInMinutes'}
                  label={tr['break']}
                  state={state['baseBreakInMinutes']}
                  disabled={disabled}
                />
              </>
            ) : (
              <>
                <InputFieldNumber
                  id={'unitVolume'}
                  label={tr['amount']}
                  state={state?.['unitVolume']}
                  disabled={disabled}
                  allowDecimalPadding={false}
                  showWarnings={false}
                  placeholder={AutoNumeric.format(1, {
                    decimalPlaces: 3,
                    ...(culture && {
                      decimalCharacter:
                        culture?.numberFormatInfo?.currencyDecimalSeparator,
                    }),
                  })}
                />

                <InputField
                  id={'unitId'}
                  label={tr['units']}
                  state={state?.['unitId']}
                  options={options?.['unitId']}
                  disabled={disabled}
                  // className={'width-free'}
                  placeholder={'-'}
                  notClearable
                />
              </>
            )}
          </S.InputFieldGroup>
        )}

        {'smsPeriod' in state && state['type'].value.value === 1 && (
          <S.InputFieldGroup disabled={disabled}>
            <InputFieldNumber
              id={'smsPeriod'}
              label={tr['periodic-sms']}
              state={state?.['smsPeriod']}
              disabled={disabled}
              decimalPlaces={0}
            />

            {(state['smsPeriod'].value || !disabled) && (
              <InputField
                id={'smsPeriodUnitId'}
                label={tr['units']}
                state={state?.['smsPeriodUnitId']}
                options={options?.['smsPeriodUnitId']}
                notClearable
                disabled={disabled}
                // className={'width-free'}
              />
            )}
          </S.InputFieldGroup>
        )}

        <S.InputFieldGroup disabled={disabled}>
          <InputField
            id={'isActive'}
            label={tr['activity']}
            state={state?.['isActive']}
            options={options?.['isActive']}
            notClearable
            disabled={disabled}
          />
        </S.InputFieldGroup>

        {isSet && (
          <S.InputFieldGroup disabled={disabled}>
            <InputField
              id={'explodeOnAdd'}
              label={tr['orderCard'] + ' | ' + tr['service-set']}
              state={state?.['explodeOnAdd']}
              options={options?.['explodeOnAdd']}
              notClearable
              disabled={disabled}
              width={'max-content'}
            />
          </S.InputFieldGroup>
        )}
      </div>
    </S.ServiceGeneralData>
  );
};
