import { useCallback, useEffect } from 'react';

// style
import * as S from './DisplayPCSettings.styled';

// icons
import { Check } from 'common/icons';

// components
import { InputField } from 'components/InputField/InputField';
import { ServiceCardDisplaySettings } from 'components/ServiceCardDisplaySettings/ServiceCardDisplaySettings';

// hooks
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useTranslate } from 'hooks/useTranslate';
import { useFormState } from 'hooks/useFormState';

export const DisplayPCSettings = ({ setButtons }) => {
  const tr = useTranslate().use().global;

  const setupFields = useCallback(() => {
    return [
      {
        id: 'calendar-time-interval',
        type: 'select',
        default: { label: '00:15', value: 2 },
        options: [
          { label: '00:10', value: 1 },
          { label: '00:15', value: 2 },
          { label: '00:20', value: 3 },
          { label: '00:30', value: 4 },
          { label: '01:00', value: 5 },
        ],
      },
    ];
  }, []);

  const { state, options } = useFormState(setupFields());

  const isMobile = useMediaQuery('(max-width: 960px)');

  // setup/cleanup header buttons
  useEffect(() => {
    setButtons &&
      setButtons({
        type: 'setup',
        value: [
          {
            id: 'save',
            icon: <Check bold />,
            action: () => console.log('Settings saved'),
            borderless: true,
            show: true,
          },
        ],
      });

    return () => setButtons && setButtons({ type: 'cleanup' });
  }, [setButtons]);

  return (
    <S.DisplayPCSettings>
      <div className="form-container">
        <div className="form__time-input">
          <InputField
            id="calendar-time-interval"
            label={tr['calendar-time-interval']}
            state={state?.['calendar-time-interval']}
            options={options?.['calendar-time-interval']}
            notClearable
            isSearchable={false}
          />
        </div>

        <div className="form__card-input-wrapper">
          <ServiceCardDisplaySettings />
        </div>
      </div>
    </S.DisplayPCSettings>
  );
};

export default DisplayPCSettings;
