// style
import * as S from './TitleAndValueChild.styled';

// icons
import { Arrow } from 'common/icons';

export const TitleAndValueChild = ({
  title,
  children,
  editable,
  arrow,
  action,
  bold,
  inputSize,
  icon,
  textType,
  justifyValue,
  warning,
  ...rest
}) => {
  return (
    <S.TitleAndValue
      editable={editable}
      bold={bold}
      inputSize={inputSize}
      textType={textType}
      justifyValue={justifyValue}
      isWarning={!!warning}
      {...rest}
    >
      <div className="titleContainer">
        {icon && <div className="iconContainer">{icon}</div>}
        <span>{title}</span>
      </div>
      <div
        className={`valueContainer${editable ? ' editable' : ''}`}
        onClick={() => {
          if (action) action();
        }}
      >
        {children}
        {arrow && (
          <div className="arrow">
            <Arrow black height={8} width={10} />
          </div>
        )}
      </div>
    </S.TitleAndValue>
  );
};

export default TitleAndValueChild;
