import { useNavigate } from 'react-router';

// icons
import {
  Ellipsis,
  ArrowRevert,
  CheckTargetSVG,
  Print,
  PersonFilter,
  Hand,
  Coins,
  PersonAdd,
  Person,
  ArrowBack,
  LightBulbSVG,
  ShareScreenSVG,
  ShowCollumnsSVG,
  FilterSVG,
  OpenFolderSVG,
  InterchangeSVG,
  RowsWithEyeSVG,
  PersonArrow,
} from 'common/icons';

// hooks
import { useTranslate } from 'hooks/useTranslate';

export const useFunctionBar = (contents) => {
  const navigate = useNavigate();

  const tr = useTranslate().use().global;

  const functions = [
    {
      id: 'back',
      icon: <ArrowBack />,
      tooltip: tr.back,
      action: () => navigate(-1),
      scope: 3,
      acl: 2,
    },
    {
      id: 'new-client',
      icon: <PersonAdd />,
      tooltip: tr['new-client'],
      action: () =>
        navigate('/functions/tables/clients/new/general-information'),
      scope: 3, // (identifier for bitwise validation: 1 - desktop, 2 - mobile, 3 - both)
      iconTitle: tr['new-client'],
      acl: 1,
    },
    {
      id: 'new-employee',
      icon: <PersonAdd />,
      tooltip: tr['new-employee'],
      action: () => navigate('new'),
      scope: 3,
      iconTitle: tr['new-employee'],
      acl: 1,
    },
    {
      id: 'go-to-client',
      icon: <PersonArrow />,
      tooltip: tr['client-card'],
      action: () => console.log('open client card'),
      scope: 3,
      iconTitle: tr['client-card'],
      acl: 1,
      closeOnClick: true,
    },
    {
      id: 'table-function',
      icon: <Ellipsis inCircle />,
      tooltip: tr.functions,
      action: null,
      scope: 3,
      iconTitle: tr.functions,
      acl: 1,
      // hide: ifNoRecordsSelected,
    },
    {
      id: 'hide-column',
      icon: <ShowCollumnsSVG />,
      tooltip: tr.showColumns,
      action: null,
      scope: 1,
      iconTitle: tr.showColumns,
      acl: 1,
      closeOnClick: false,
    },
    {
      id: 'print',
      icon: <Print />,
      tooltip: tr.print,
      action: () => window.print(),
      closeOnClick: true,
      scope: 3,
      iconTitle: tr.print,
      acl: 1,
    },
    {
      id: 'help',
      icon: <LightBulbSVG />,
      tooltip: tr.userManual,
      scope: 3,
      acl: 2,
    },
    {
      id: 'share-screen',
      icon: <ShareScreenSVG />,
      tooltip: tr.shareScreen,
      scope: 3,
      acl: 2,
    },
    {
      id: 'search-by-selected-professionals',
      icon: <PersonFilter />,
      tooltip: tr.searchBySelectedProfessionals,
      iconTitle: tr.search,
      action: null,
      closeOnClick: true,
      scope: 3,
      acl: 1,
    },
    {
      id: 'show-hidden-list',
      icon: <RowsWithEyeSVG />,
      tooltip: tr.showHidden,
      iconTitle: tr.waitingBar,
      closeOnClick: true,
      action: null,
      scope: 2,
      acl: 1,
    },
    {
      id: 'cancel',
      icon: <ArrowRevert />,
      tooltip: tr.cancelAction,
      iconTitle: tr.cancel,
      action: () => {},
      closeOnClick: true,
      scope: 3,
      acl: 1,
    },
    {
      id: 'show-canceled-orders',
      icon: <Hand />,
      tooltip: tr.showCanceledOrder,
      iconTitle: tr.showCanceled,
      action: () => {},
      closeOnClick: true,
      scope: 3,
      acl: 1,
    },
    {
      id: 'change-payment-status',
      icon: <Coins />,
      tooltip: tr['change-payment-status'],
      iconTitle: tr['change-payment-status'],
      action: () => console.log('change payment status'),
      scope: 3,
      acl: 1,
      active: false,
    },
    {
      id: 'change-status',
      icon: <InterchangeSVG />,
      tooltip: tr['change-order-status'],
      iconTitle: tr['change-order-status'],
      action: () => console.log('change status'),
      // closeOnClick: true,
      scope: 3,
      acl: 1,
      active: false,
    },
    {
      id: 'functions',
      icon: <Ellipsis inCircle />,
      tooltip: tr.functions,
      iconTitle: tr.functions,
      action: () => {
        console.log('Atidaryk galimas funkcijas pls');
      },
      scope: 3,
      acl: 1,
      // closeOnClick: true,
    },
    // {
    //   id: 'change-status',
    //   icon: <InterchangeSVG />,
    //   tooltip: tr['change-order-status'],
    //   iconTitle: tr['change-order-status'],
    //   action: () => {
    //     console.log('Pakeisk būseną');
    //   },
    //   scope: 3,
    //   acl: 1,
    // },
    // {
    //   id: 'new-sheet',
    //   icon: <NewSheetSVG />,
    //   tooltip: tr['new-service-type'],
    //   iconTitle: tr['new-service-type'],
    //   action: () => {
    //     console.log(tr['new-service-type']);
    //   },
    //   closeOnClick: true,
    //   scope: 3,
    //   acl: 1,
    // },
    // {
    //   id: 'add-category',
    //   icon: <Plus black />,
    //   tooltip: tr['new-category'],
    //   iconTitle: tr['new-category'],
    //   action: () => {
    //     console.log('default add');
    //   },
    //   closeOnClick: true,
    //   scope: 2,
    //   acl: 1,
    // },
    // {
    //   id: 'add-group',
    //   <Plus black />,
    //   tooltip: tr['new-group'],
    //   iconTitle: tr['new-group'],
    //   action: () => {
    //     console.log('default add');
    //   },
    //   closeOnClick: true,
    //   scope: 2,
    //   acl: 1,
    // },
    // {
    //   id: 'add-set',
    //   <Plus black />,
    //   tooltip: tr['new-set'],
    //   iconTitle: tr['new-set'],
    //   action: () => {
    //     console.log('default add');
    //   },
    //   closeOnClick: true,
    //   scope: 2,
    //   acl: 1,
    // },
    // {
    //   id: 'add-service',
    //   <Plus black />,
    //   tooltip: tr['new-service'],
    //   iconTitle: tr['new-service'],
    //   action: () => {
    //     console.log('default add');
    //   },
    //   closeOnClick: true,
    //   scope: 2,
    //   acl: 1,
    // },
    {
      id: 'filter',
      icon: <FilterSVG />,
      tooltip: tr['filters'],
      iconTitle: tr['filters'],
      action: () => {
        console.log('default filter');
      },
      closeOnClick: true,
      scope: 2,
      acl: 1,
    },
    {
      id: 'cleanUp',
      icon: <ArrowRevert />,
      tooltip: tr.cancel,
      iconTitle: tr.cancel,
      action: () => {},
      closeOnClick: true,
      scope: 3,
      acl: 1,
    },
    {
      id: 'show-number-of-professionals',
      icon: <Person number={1} bold />,
      tooltip: tr['show-one-professional'],
      iconTitle: tr['show-one-professional'],
      action: () => console.log('change status'),
      scope: 2,
      acl: 1,
      active: false,
    },
    {
      id: 'workingDays',
      icon: <CheckTargetSVG />,
      tooltip: tr['display-working-days'],
      iconTitle: 'Display working days',
      action: null,
      closeOnClick: true,
      scope: 3,
      acl: 1,
    },
  ];

  const functionsClients = [
    'new-client',
    'hide-column',
    // 'print',
    'help',
    'share-screen',
    // 'table-function',
    'back',
  ].map((item) => functions.find((func) => func.id === item));

  const functionsEmployees = ['new-employee', 'hide-column'].map((item) =>
    functions.find((func) => func.id === item),
  );

  const functionsCalendarDaily = [
    'cancel',
    'workingDays',
    // 'search-by-selected-professionals',
    'show-hidden-list',
    'show-canceled-orders',
    'print',
    'show-number-of-professionals',
  ].map((item) => functions.find((func) => func.id === item));

  const functionsCalendarMonthly = [
    'cancel',
    'show-hidden-list',
    'show-canceled-orders',
    'print',
    'show-number-of-professionals',
  ].map((item) => functions.find((func) => func.id === item));

  const functionsSelectClient = ['new-client'].map((item) =>
    functions.find((func) => func.id === item),
  );

  const functionsOrders = ['print', 'table-function'].map((item) =>
    functions.find((func) => func.id === item),
  );

  const functionsCreateNewOrder = [
    'change-status',
    'client-card',
    'functions',
  ].map((item) => functions.find((func) => func.id === item));

  if (Array.isArray(contents)) {
    // in case the function id array is supplied as a configurator
    return contents.map((item) => functions.find((func) => func.id === item));
  } else {
    // sets of functions according to current endpoint
    switch (contents) {
      case 'orders':
        return functionsOrders;
      case 'clients':
        return functionsClients;
      case 'employees':
        return functionsEmployees;
      case 'calendar-day':
      case 'calendar-compare':
      case 'calendar-week':
        return functionsCalendarDaily;
      case 'calendar-month':
        return functionsCalendarMonthly;
      case 'select-client':
        return functionsSelectClient;
      case 'new-order':
        return functionsCreateNewOrder;
      default:
        return null;
    }
  }
};

export default useFunctionBar;
