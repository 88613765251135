import { useContext } from 'react';

// style
import * as S from './Footer.styled';

// icons
import { GlobeSVG } from 'common/icons';

// components
import { Link } from 'components/Overrides';

// contexts
import { UserContext } from 'context/UserContext';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { colors } from 'common/colors/colors';

export const Footer = ({ content }) => {
  // Hooks
  // -- translations
  // const tr = useTranslate().use().global;
  // const { locale } = useContext(UserContext);

  // Functions
  // const changeLanguage = (e) => {
  //   localStorage.setItem('lng', e.target.value);
  //   window.location.reload(false);
  // };

  return (
    <S.Footer className="footer">
      {/* <S.MainContainer>
        <S.LogoContainer>
           <ServeProLogo />
        </S.LogoContainer>
        <S.Links>
          {content === 1 && (
            <>
              <Link to="/privacy-policy">{tr.privacyPolicy}</Link>
              <Link to="/legal-provisions">{tr.legalProvisions}</Link>
              <Link to="/support-and-contacts">{tr.helpAndContacts}</Link>
            </>
          )}
          {content === 2 && (
            <Link to="/announcements-and-suggestions">
              {tr['announcements-and-suggestions']}
            </Link>
          )}
        </S.Links>
      </S.MainContainer>
      {content === 1 && (
        <S.LanguageBar>
          <div style={{ paddingRight: '10px' }}>
            <GlobeSVG color={colors.footer_logo_Primary} />
          </div>

          <select
            value={locale}
            onChange={(e) => changeLanguage(e)}
          >
            <option value="lt">Lietuvių</option>
            <option value="en">English</option>
            <option value="ru">Русский</option>
          </select>
        </S.LanguageBar>
      )} */}
    </S.Footer>
  );
};

export default Footer;
