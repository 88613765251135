import { useCallback, useEffect, useState } from 'react';

// icons
import { Pencil, ArrowRevert, Check } from 'common/icons';

// hooks
import useMediaQuery from './useMediaQuery';
import useTranslate from './useTranslate';

export const useFormStatus = ({
  resourceContext,
  mutationContext,
  setButtons,
  hasInitialResourse = true,
  methods = {},
  resetForm,
  setIsGoBackEnabled,
  submit,
  confirmationModal,
  bypassUnsavedChangesRef,
}) => {
  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 960px)');
  const { formState: { isDirty, isValid } = {} } = methods;
  const { isError: isMutationError, error: mutationError } = mutationContext;
  const {
    data: resourceData,
    isSuccess: isResourceSuccess,
    isError: isResourceError,
    isFetching: isResourceFetching,
    refetch: refetchResource,
  } = resourceContext;
  const { isFetched: resourseFetched } = resourceContext;
  const [isFormDisabled, setIsFormDisabled] = useState(hasInitialResourse);
  const [isButtonsDisabled, setIsButtonsDisabled] = useState(true);

  const onFormReset = useCallback(() => {
    resetForm();

    if (hasInitialResourse) {
      setIsFormDisabled(true);
    }
  }, [resetForm]);

  useEffect(() => {
    if (setButtons) {
      setButtons({
        type: 'setup',
        value: [
          {
            id: 'edit',
            action: () => {
              setIsFormDisabled(false);
            },
            icon: <Pencil bold />,
            ...(!isMobile && { title: tr['edit'] }),
            secondary: isResourceError,
            disabled: isResourceError,
          },
          {
            id: 'cancel',
            action: () => {
              setIsFormDisabled(true);
            },
            primary: true,
            outline: true,
            inverted: true,
            icon: <ArrowRevert bold />,
            ...(!isMobile && { title: tr['cancel'] }),
          },
          {
            id: 'reset',
            action: onFormReset,
            primary: true,
            outline: true,
            inverted: true,
            icon: <ArrowRevert bold />,
            ...(!isMobile && { title: tr['reset'] }),
          },
          {
            id: 'save',
            action: () => {
              if (!confirmationModal || confirmationModal()) {
                handleOnSave();

                return;
              }
            },
            icon: <Check bold />,
            ...(!isMobile && { title: tr['save'] }),
          },
        ],
      });

      setButtons({ type: 'show', id: 'edit', value: isFormDisabled });
      setIsButtonsDisabled(false);
    }

    return () => setButtons && setButtons({ type: 'cleanup' });
  }, [resourseFetched, isMobile]);

  useEffect(() => {
    if (setButtons) {
      setButtons({
        type: 'update',
        id: 'save',
        value: {
          secondary: isButtonsDisabled,
          disabled: isButtonsDisabled,
        },
      });
      setButtons({
        type: 'update',
        id: 'edit',
        value: {
          secondary: isButtonsDisabled,
          disabled: isButtonsDisabled,
        },
      });
    }
  }, [isButtonsDisabled]);

  useEffect(() => {
    if (setButtons) {
      setButtons({ type: 'show', id: 'edit', value: isFormDisabled });
      setButtons({
        type: 'show',
        id: 'cancel',
        value: !isFormDisabled && !isDirty && hasInitialResourse,
      });
      setButtons({
        type: 'show',
        id: 'reset',
        value: !isFormDisabled && isDirty,
      });
      setButtons({
        type: 'show',
        id: 'save',
        value: !isFormDisabled && isDirty,
      });
    }
  }, [isFormDisabled, isDirty, isMobile]);

  useEffect(() => {
    if (setButtons) {
      setIsButtonsDisabled(!isValid);
    }
  }, [isValid]);

  useEffect(() => {
    if (!isResourceFetching) {
      if (isResourceSuccess || isResourceError) {
        if (isResourceError) {
          setIsButtonsDisabled(true);
        } else if (isMutationError || !isResourceError) {
          setIsButtonsDisabled(false);
        }

        if (setIsGoBackEnabled) {
          setIsGoBackEnabled(true);
        }

        if ((isResourceError && !!resourceData) || isMutationError) {
          setIsFormDisabled(false);
        }

        if (isResourceSuccess) {
          setButtons({
            type: 'update',
            id: 'reset',
            value: {
              action: onFormReset,
            },
          });
        }
      }
    }
  }, [isResourceFetching]);

  useEffect(() => {
    if (isMutationError) {
      if (mutationError?.response?.status !== 404) {
        if (hasInitialResourse) {
          refetchResource();
        } else {
          setIsGoBackEnabled(true);
        }
      } else {
        if (bypassUnsavedChangesRef) {
          bypassUnsavedChangesRef.current = true;
        }

        setIsGoBackEnabled(true);
      }
    }
  }, [isMutationError]);

  const handleOnSave = () => {
    if (setIsGoBackEnabled) {
      setIsGoBackEnabled(false);
    }

    setIsFormDisabled(true);
    setIsButtonsDisabled(true);
    submit();
  };

  return {
    isLoading: hasInitialResourse ? !resourseFetched : false,
    isFormDisabled: isFormDisabled,
    handleOnSave: handleOnSave,
  };
};
