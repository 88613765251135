import styled from 'styled-components/macro';
import { colors } from '../../../common/colors/colors';
import { pulse } from 'common/styles/animations';
import { fontFamilies } from 'common/fonts/fonts';
import { FlashMessage } from 'components/FlashMessage/FlashMessage.styled';

export const SelectionPanelContainer = styled.div`
  background-color: white;
  height: ${(props) => props.vh * 100 - 54}px;
  margin-top: 54px;
  overflow-y: auto;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999;

  ::-webkit-scrollbar {
    display: none;
  }

  .navigationHeader {
    align-items: center;
    background-color: ${(props) =>
      props.darkTheme ? colors.bg_Primary : colors.bg_grey_1} !important;

    box-shadow: ${(props) =>
      props.darkTheme
        ? '0px 2px 3px 0px rgb(0 0 0 / 50%)'
        : '2px 0 3px rgba(0, 0, 0, 0.3)'};

    display: flex;
    height: 54px;
    justify-content: space-between;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;

    @media screen and (min-width: 961px) {
      height: 70px;
      padding: 20px;
    }
  }

  .navigationHeader_nav {
    align-items: center;
    display: flex;
    gap: 10px;
    width: 100%;
    font-family: ${fontFamilies.family_Primary};
  }

  .getBack {
    display: inline-block;
    padding: 12px 0 12px 20px;
    /* padding: 12px 10px 20px 20px; */

    svg {
      fill: ${(props) =>
        props.darkTheme ? 'white' : colors.bg_Primary} !important;
      width: 20px;
    }

    @media screen and (min-width: 961px) {
      padding: 0;

      svg {
        width: 29.91px;
        height: 23.85px;
      }
    }
  }

  .navigationHeader .title {
    /* display: inline-block; */
    /* color: ${(props) =>
      props.darkTheme ? 'white' : colors.bg_Primary} !important; */
    font-family: ${fontFamilies.family_Primary};
    font-size: 1.125rem;
    font-weight: 600;

    @media screen and (min-width: 961px) {
      font-size: 1.875rem;
      padding-bottom: 2px;
      padding-left: 10px;
    }
  }

  .navigationHeader_buttonWrapper {
    align-items: center;
    display: flex;
    gap: 20px;
    height: 100%;

    @media screen and (min-width: 961px) {
      padding: 0;
    }
  }

  .saveButtonWrapper {
    align-items: center;
    display: flex;
    gap: 20px;
    padding: 0 20px;

    button {
      height: 30px;
      width: 30px;

      @media screen and (min-width: 961px) {
        min-width: 110px;
      }

      &.header__close-button {
        min-width: unset;
      }
    }
  }

  .navigationHeader_search {
    align-items: center;
    display: flex;
    /* height: 17.04px; */
    height: 100%;
    /* padding: 20px; */
    padding-inline: 20px;

    svg {
      fill: ${(props) => (props.darkTheme ? 'white' : colors.bg_Primary)};
      height: 17.04px;
      width: 17.04px;
    }
  }

  .searchHeader {
    align-items: center;
    background-color: white;
    box-shadow: 2px 0 3px rgba(0, 0, 0, 0.3);
    display: flex;
    gap: 10px;
    height: 54px;
    justify-content: space-between;
    padding: 15px 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
  }

  .goSearch {
    display: inline-block;
    height: 17.04px;

    svg {
      fill: ${colors.bg_grey_3};
      height: 17.04px;
      width: 17.04px;
    }
  }

  .searchTitle {
    border: none;
    color: ${colors.bg_Primary};
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    height: 100%;
    outline: none;
    padding-left: 10px;

    ::placeholder {
      color: ${colors.bg_grey_3};
    }
  }

  .searchHeader_search {
    height: 20px;
    /* margin-left: 10px; */
    padding: 0 5px;

    svg {
      fill: ${colors.bg_Primary};
    }
  }

  .mainContainer {
    height: 100%;
    overflow: auto;

    .flash-messages-wrapper {
      padding: 10px;

      ${FlashMessage} {
        line-height: 0.9;
      }
    }

    @media screen and (max-width: 960px) {
      ::-webkit-scrollbar {
        width: 0px;
      }
    }
  }
`;
