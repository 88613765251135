import * as S from './SVG.styled';

export default ({
  color = 'currentColor',
  width = 30,
  height = 30,
  bold,
  thin,
}) => {
  const content = bold ? (
    <path d="M 29.966,1.4209 C 29.94535,1.31493 29.91437,1.21282 29.87536,1.11458 29.87246,1.10727 29.87016,1.099809 29.86736,1.092531 29.82577,0.991691 29.77472,0.895951 29.7162,0.805511 29.7125,0.799648 29.7093,0.793589 29.7053,0.787783 29.57534,0.592433 29.40783,0.424653 29.21221,0.294653 29.20621,0.29062 29.19988,0.287375 29.19385,0.28343 29.10379,0.224952 29.00827,0.17411 28.90759,0.1327 28.90009,0.129616 28.89239,0.127393 28.88493,0.124435 28.78684,0.085606 28.68501,0.054537 28.57917,0.033742 28.57317,0.0326112 28.56741,0.0321824 28.56167,0.0311074 28.45641,0.0115134 28.34856,2.34e-5 28.23756,2.34e-5 h -11.705 c -0.97321,0 -1.7623,0.78907 -1.7623,1.7623 0,0.97321 0.78906,1.7623 1.7623,1.7623 h 7.451 L 0.51656,26.991623 c -0.68839,0.6881 -0.68839,1.8039 0,2.492 0.34391,0.3442 0.79509,0.51629 1.246,0.51629 0.45091,0 0.90208,-0.1721 1.246,-0.51629 l 23.467,-23.4669996 v 7.4509996 c 0,0.97321 0.78906,1.7623 1.7623,1.7623 0.97321,0 1.7623,-0.78906 1.7623,-1.7623 V 1.7626234 c 0,-0.11091 -0.01148,-0.21903 -0.03098,-0.32428 -0.0012,-0.0057 -0.0018,-0.01142 -0.0029,-0.017121 z" />
  ) : thin ? (
    <path
      d="M 0.63734364,30 C 0.80041459,30 0.96348553,29.93776 1.0879653,29.813277 L 9.5013485,21.399894 28.725311,2.1762254 V 13.337242 c 0,0.35197 0.285374,0.637344 0.637344,0.637344 C 29.714626,13.974586 30,13.689212 30,13.337242 V 0.63765355 c 0,-0.0418385 -0.0044,-0.0835973 -0.01245,-0.12477292 -0.004,-0.0191203 -0.0112,-0.0368003 -0.01649,-0.0552387 -0.0062,-0.0213383 -0.01121,-0.042906 -0.0196,-0.0636229 -0.0093,-0.0219024 -0.02178,-0.0417779 -0.03299,-0.0623769 -0.0087,-0.0157552 -0.01556,-0.03217 -0.02583,-0.0473037 -0.04668,-0.0699613 -0.106743,-0.130082 -0.176764,-0.17680253 -0.01494,-0.01011466 -0.03143,-0.01699798 -0.0473,-0.02567542 -0.02054,-0.01137979 -0.04046,-0.02378889 -0.06224,-0.03285192 -0.02085,-0.0085595 -0.04232,-0.01346072 -0.0638,-0.01974175 -0.01836,-0.0054652 -0.0361,-0.01258436 -0.05508,-0.01637656 -0.04108,-0.008209 -0.08309,-0.0125843647 -0.124792,-0.0125843647 L 16.662758,0 c -0.35197,0 -0.637344,0.28536778 -0.637344,0.63734444 0,0.35197665 0.285374,0.63734446 0.637344,0.63734446 l 11.161306,2.677e-4 -7.324792,7.3246363 c -3.12e-4,3.123e-4 -6.06e-4,5.099e-4 -9.24e-4,8.286e-4 L 0.186722,28.912034 c -0.24896267,0.248963 -0.24896267,0.652284 0,0.901243 C 0.31120174,29.937757 0.47427269,30 0.63734364,30 Z"
      strokeWidth="0.318672"
    />
  ) : (
    <path
      d="M 29.97582,0.98324736 C 29.96862,0.94720116 29.95522,0.91387856 29.94507,0.87904585 29.93283,0.83751565 29.92328,0.79544135 29.90656,0.75512162 29.88925,0.71366482 29.86566,0.67605042 29.84447,0.63705735 29.82746,0.60629025 29.81402,0.57429435 29.79432,0.54477755 29.704764,0.41068624 29.589537,0.29545872 29.455501,0.20586584 29.425052,0.18558372 29.392215,0.17164759 29.360571,0.154352 29.32236,0.13343711 29.285641,0.11037632 29.245043,0.09356677 29.204146,0.07668079 29.161757,0.06688668 29.119664,0.05461042 29.085336,0.04453508 29.052496,0.03123172 29.016973,0.02416125 28.937865,0.00843382 28.857565,0 28.777262,0 H 16.594984 c -0.675249,0 -1.222735,0.54748587 -1.222735,1.2227354 0,0.6752495 0.547486,1.2227354 1.222735,1.2227354 h 9.230518 L 19.843771,8.427352 c -5.81e-4,4.891e-4 -0.0012,8.254e-4 -0.0018,0.00135 L 0.35822325,27.912755 c -0.477631,0.477332 -0.477631,1.251693 0,1.729025 0.2388155,0.238815 0.55166457,0.358224 0.86451055,0.358224 0.312846,0 0.6259947,-0.119406 0.8645106,-0.358224 l 8.0698726,-8.069913 c 0,-2.05e-4 3e-4,-3.67e-4 6.12e-4,-5.5e-4 l 17.3968,-17.396822 v 9.230518 c 0,0.675249 0.547486,1.222735 1.222736,1.222735 C 29.452514,14.627748 30,14.080262 30,13.405013 V 1.2227354 C 30,1.1424534 29.9916,1.062282 29.97582,0.98324736 Z"
      strokeWidth="0.305684"
    />
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
    >
      {content}
    </S.StyledSVG>
  );
};
