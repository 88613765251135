import { StyledSVG } from './SVG.styled';

export default ({ color, width = '100%', height = '100%', ...rest }) => (
  <StyledSVG
    viewBox="0 0 30 30"
    width={width}
    height={height}
    color={color || 'currentColor'}
    {...rest}
  >
    <path
      d="M 29.814093,8.9762105 21.032276,0.18799319 a 0.63728675,0.63728675 0 0 0 -0.898572,0 L 0.1901191,20.122579 a 0.63728675,0.63728675 0 0 0 0,0.908131 l 8.7882173,8.778617 a 0.63728675,0.63728675 0 0 0 0.4492907,0.187996 0.63728675,0.63728675 0 0 0 0.4524705,-0.187996 L 29.814683,9.8777417 a 0.63728675,0.63728675 0 0 0 0,-0.8985713 z m -2.40895,1.5167685 -1.465769,-1.4529698 a 0.63728675,0.63728675 0 0 0 -0.901761,0.8985713 l 1.452969,1.4561695 -0.908131,0.908131 -3.619825,-3.6229244 a 0.6376415,0.6376415 0 1 0 -0.901761,0.9017613 l 3.619825,3.6229251 -0.908131,0.908131 -1.46577,-1.45297 a 0.63728675,0.63728675 0 0 0 -0.901761,0.898571 l 1.45297,1.45617 -0.895392,0.917691 -1.452969,-1.45297 a 0.63764167,0.63764167 0 0 0 -0.901762,0.901761 l 1.45297,1.45297 -0.908131,0.908131 -3.622925,-3.629324 a 0.64104667,0.64104667 0 0 0 -0.914501,0.898571 l 3.635725,3.622925 -0.908131,0.908131 -1.45297,-1.45297 a 0.63728675,0.63728675 0 1 0 -0.898571,0.901761 l 1.452969,1.45297 -0.911321,0.904941 -1.456169,-1.45297 a 0.63728675,0.63728675 0 0 0 -0.898572,0.901762 l 1.45297,1.452969 -0.908131,0.911322 -3.622925,-3.619825 a 0.6376415,0.6376415 0 0 0 -0.901761,0.901761 l 3.622925,3.619825 -0.908131,0.911321 -1.44027,-1.45617 a 0.63764167,0.63764167 0 1 0 -0.901762,0.901761 l 1.45617,1.45297 -0.908131,0.911321 -1.4561698,-1.456169 a 0.6376415,0.6376415 0 0 0 -0.9017613,0.901761 l 1.4370701,1.45297 -0.7743039,0.774303 -7.8832361,-7.886436 19.032604,-19.0326039 7.886436,7.886436 z"
      strokeWidth="0.318643"
    />
    <path
      d="m 3.8413332,18.704808 a 2.549147,2.549147 0 1 0 3.5879254,0 2.549147,2.549147 0 0 0 -3.5879254,0 z m 2.6861441,2.686144 a 1.2745735,1.2745735 0 1 1 0,-1.784363 1.2745735,1.2745735 0 0 1 0,1.784363 z"
      strokeWidth="0.318643"
    />
  </StyledSVG>
);
