import * as S from '../SVG.styled';

const OpticsSVG = ({ color = 'currentColor', width = 20, height = 20 }) => {
  const content = (
    <>
      <path d="m69.99,155.83c-3.31,0-6,2.69-6,6s2.69,6,6,6c19.29,0,34.99,15.7,34.99,34.99,0,3.31,2.69,6,6,6s6-2.69,6-6c0-25.91-21.08-46.99-46.99-46.99Z" />
      <path d="m309.72,186.4c-.04-.38-.11-.76-.22-1.14l-25.2-85.16c-.94-3.18-4.29-5-7.46-4.05-3.18.94-4.99,4.28-4.05,7.46l13.42,45.35c-12.11-10.01-27.62-16.03-44.52-16.03-31.77,0-58.64,21.28-67.16,50.33-5.66-1.45-11.5-2.2-17.42-2.2-6.76,0-13.39.96-19.79,2.84-8.31-29.38-35.34-50.97-67.34-50.97-16.83,0-32.29,5.97-44.38,15.91L61.6,27.13c1.68-5.38,6.37-9.38,11.95-10.19l25.88-3.75c3.28-.47,5.55-3.52,5.08-6.8-.48-3.28-3.52-5.55-6.8-5.08l-25.88,3.75c-10.13,1.47-18.64,8.72-21.71,18.58L3.91,179.79c-.74,2.12-1.38,4.28-1.92,6.48l-.07.25c-.08.28-.14.56-.18.84-1.13,4.98-1.74,10.15-1.74,15.47,0,38.59,31.4,69.99,69.99,69.99s69.99-31.4,69.99-69.99c0-2.42-.12-4.8-.36-7.16,5.64-1.78,11.5-2.7,17.5-2.7,5.12,0,10.16.66,15.04,1.97-.29,2.59-.46,5.22-.46,7.89,0,38.59,31.4,69.99,69.99,69.99s69.99-31.4,69.99-69.99c0-5.65-.69-11.15-1.96-16.42Zm-239.73,74.41c-31.98,0-57.99-26.01-57.99-57.99,0-4.64.56-9.15,1.6-13.47l1.77-5.97c8.01-22.44,29.47-38.55,54.63-38.55,31.98,0,57.99,26.01,57.99,57.99s-26.01,57.99-57.99,57.99Zm171.7,0c-31.98,0-57.99-26.01-57.99-57.99s26.01-57.99,57.99-57.99,57.99,26.01,57.99,57.99-26.01,57.99-57.99,57.99Z" />
      <path d="m241.69,155.83c-3.31,0-6,2.69-6,6s2.69,6,6,6c19.29,0,34.99,15.7,34.99,34.99,0,3.31,2.69,6,6,6s6-2.69,6-6c0-25.91-21.08-46.99-46.99-46.99Z" />
      <path d="m212,11.94l25.88,3.75c5.58.81,10.27,4.81,11.92,10.1l9.23,31.19c.77,2.61,3.16,4.3,5.75,4.3.56,0,1.14-.08,1.71-.25,3.18-.94,4.99-4.28,4.05-7.46l-9.25-31.27c-3.05-9.77-11.56-17.03-21.68-18.49L213.71.06c-3.27-.48-6.32,1.8-6.8,5.08-.47,3.28,1.8,6.32,5.08,6.8Z" />
      <path d="m268.48,67.77c-3.18.94-4.99,4.28-4.05,7.46l2.27,7.67c.77,2.61,3.16,4.3,5.75,4.3.56,0,1.14-.08,1.71-.25,3.18-.94,4.99-4.28,4.05-7.46l-2.27-7.67c-.94-3.18-4.28-4.99-7.46-4.05Z" />
    </>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 311.68 272.81"
      color={color}
    >
      {content}
    </S.StyledSVG>
  );
};

export default OpticsSVG;
