import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import { InputField } from 'components/InputField/InputField.styled';

export const OrderHeader = styled.div`
  .order-header {
    background-color: ${(props) => props.bg && props.bg};
    height: 46px;
    display: flex;
    align-items: center;
    gap: 20px;

    padding: 0px 10px;
    transition: 0.1s ease-in-out;
    font-family: ${fontFamilies.family_Secondary};
    font-size: 14px;

    &__status {
      font-weight: 500;
    }

    &__date-time {
      padding: 0 20px;
      display: flex;
      gap: 10px;

      ${InputField} {
        .select__control {
          height: 26px;

          .select__value-container {
            padding: 0 10px;
            min-height: unset;
          }

          .select__indicators {
            .select__indicator {
              padding: 0;
              height: unset;
            }
          }

          :not(&--is-focused) {
            border: 0;
          }
        }

        .select__menu {
          z-index: 2;
        }
      }

      .time {
        display: flex;
        align-items: center;
        gap: 5px;

        .dash {
          font-weight: 700;
        }
      }

      .input {
        white-space: nowrap;
        padding: 0 10px;
        background-color: #fff;
        border-radius: 5px;
        height: 26px;
        display: flex;
        align-items: center;
        font-size: 14px;
      }

      .warning-icon {
        background-color: #fff;
        width: 16px;
        height: 16px;
        border-radius: 100%;

        svg {
          width: 16px;
          height: 16px;
          color: ${colors.warningText};
        }
      }
    }

    .vacant-times-btn {
      width: auto;
      height: 26px;
      font-size: 14px;
      border-color: ${colors.text_Secondary};

      span {
        padding-bottom: 2px;
        color: ${colors.text_Secondary};
      }

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
`;

// export const AvailableTimes = styled.div`
//   height: ${(props) => (props.grow ? 185 : 0)}px;
//   transition: height 0.2s ease-in-out;

//   overflow: hidden;

//   background-color: ${(props) => props.bg && props.bg};
// `;
