import { useState, useEffect, useRef, useContext } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import { useLocation } from 'react-router-dom';

// style
import * as S from './OrderItemsMobile.styled';

// icons
import { Check, Clock } from 'common/icons';

// components
import SimpleSelectChild from 'components/DialogLayout/CustomSelect/children/SimpleSelectChild/SimpleSelectChild';
import OrderItemMobile from './partials/OrderItemMobile/OrderItemMobile';
import SelectionPanel from 'components/Overlay/SelectionPanel/SelectionPanel';
import SelectProfessionals from 'components/DialogLayout/SelectProfessionals/SelectProfessionals';
import SelectSMSDuration from 'components/DialogLayout/SelectSMSDuration/SelectSMSDuration';
import ServiceSelector from 'components/Cards/Services/ServiceSelector/ServiceSelector';
import DiscountModal from 'pages/Order/partials/Order/partials/OrderRowEntries/OrderRowEntry/DiscountColumn/DiscountModal/DiscountModal';
import TitleAndValueChild from 'components/DialogLayout/CustomSelect/children/TitleAndValueChild/TitleAndValueChild';
import InputFieldDuration from 'components/InputFieldDuration/InputFieldDuration';

// contexts
import { OrderContext } from 'context/OrderContext';

// hooks
import useObserver from 'hooks/useObserver';
import useMediaQuery from 'hooks/useMediaQuery';
import { getTimeFromMinutes } from 'common/helpers/timeCalculations';

const presetButtons = [
  {
    id: 'save',
    icon: <Check bold />,
    borderless: true,
    show: true,
  },
];

const OrderCard = ({
  deleteService,
  setDisplayModal,
  modalToggle,
  modalIsShowing,
}) => {
  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 600px)');
  const location = useLocation();
  const displayRef = useRef();
  const { width } = useObserver({
    ref: displayRef,
    debounceDelay: 10,
  });
  const {
    order,
    saveFunc,
    saveCond,
    products,
    addToOrder,
    updateServiceKey,
    professional,
    sectorId,
    setPlusFabAction,
  } = useContext(OrderContext);
  const [modalSwitch, setModalSwitch] = useState({
    content: '',
    flashMessages: '',
  });
  const [openedProduct, setOpenedProduct] = useState();
  const multiSelect = location.state?.multi === 'multi-selects';
  const searchParams = {
    ...(professional?.id && { employeeId: professional?.id }),
    ...(order?.state?.customer?.id && { customerId: order.state.customer.id }),
    sectorId,
    assignedToEmpOnly: 1,
  };
  const professionals = [];

  useEffect(() => {
    setPlusFabAction(() => () => {
      modalToggle();
      setModalSwitch({ content: 'select-service' });
    });
  }, []);

  useEffect(() => {
    switch (modalSwitch.content) {
      case 'select-service':
        setDisplayModal(
          <ServiceSelector
            handleAdd={addToOrder}
            data={{
              state: [],
              setState: () => {},
            }}
            close={handleCloseAction}
            searchParams={searchParams}
            showServiceAmountSelectionModal={false}
            toastTextOnAdd={tr['order-extended']}
            autoExpandCategories={true}
            includeUngroupedCategory={false}
          />,
        );
        break;
      case 'select-professionals':
        setDisplayModal(
          <SelectionPanel
            key={'select-professionals'}
            closeAction={() => setModalSwitch({ content: 'select-service' })}
            headerTitle={tr.professionals}
            searchTitle={tr.professionalSearch}
            array={professionals}
            arrayFilter={selectProfessionalsFilter}
            childProps={{
              selectedProf: professional.currSelectedProfessional,
              select: (prof) => selectProfessionals(prof),
            }}
            Child={SelectProfessionals}
          />,
        );
        break;
      case 'select-sms':
        setDisplayModal(
          <SelectionPanel
            key={'select-sms'}
            closeAction={() => handleCloseAction()}
            headerTitle="SMS"
            flashMessages={modalSwitch?.flashMessages}
          >
            <SelectSMSDuration
              service={openedProduct}
              select={(value) =>
                updateService(
                  { smsPeriodCustomer: value.value },
                  openedProduct.index,
                )
              }
            />
          </SelectionPanel>,
        );
        break;
      case 'select-discount': {
        setDisplayModal(
          <SelectionPanel
            key={'select-discount'}
            closeAction={() => handleCloseAction()}
            headerTitle={tr.discount}
            presetButtons={presetButtons}
          >
            <DiscountModal
              onSave={(value) =>
                updateService({ discountWithVat: value }, openedProduct.index)
              }
              price={openedProduct.unitPriceWithVat}
              discount={openedProduct.discountWithVat}
              quantity={openedProduct.quantity}
            />
          </SelectionPanel>,
        );
        break;
      }
      // case 'select-dateAndTime': {
      //   setDisplayModal(
      //     <SelectionPanel
      //       key={'select-dateAndTime'}
      //       closeAction={(cleanUp) => handleCloseAction(cleanUp)}
      //       Child={SelectDateAndTime}
      //       headerTitle={'Data | Laikas'}
      //     />,
      //   );
      //   break;
      // }
      default:
        setDisplayModal();
        return;
    }
  }, [modalSwitch, openedProduct, order.state.savedProducts]);

  useEffect(() => {
    if (modalIsShowing) {
      setModalSwitch({ content: null, flashMessages: null });
      modalToggle();
    }
  }, [width, isMobile]);

  useEffect(() => {
    let x = order.state?.savedProducts;
    products.setSelectedProducts([].concat.apply([], x));
  }, [modalIsShowing]);

  // Functions
  const handleCloseAction = (action) => {
    switch (action) {
      case 'select-professionals':
        setModalSwitch({ content: action });
        break;
      default:
        saveFunc.setContextSaveFunc(() => () => console.log('default'));
        saveCond.setContextSaveCond(() => () => false);
        setModalSwitch({ content: null, flashMessages: null });
        if (modalIsShowing) modalToggle();
        return;
    }
  };

  const selectProfessionals = (prof) => {
    const newArr = [];

    if (multiSelect) {
      prof.forEach((item) => newArr.push(item.id));
    } else {
      newArr.push(prof.id);
    }

    professional.setCurrSelectedProfessional(newArr);

    setModalSwitch({ content: 'select-service' });
  };

  const selectProfessionalsFilter = (input, array, setState) => {
    if (input.length < 3) {
      setState(array);
    } else {
      let filteredInput = array.filter((item) => {
        return item.fieldName.toLowerCase().includes(input.toLowerCase());
      });
      setState(filteredInput);
    }
  };

  const updateService = (keyValue, index) => {
    updateServiceKey(keyValue, index);
    handleCloseAction();
  };

  return (
    <S.OrderItemsMobile ref={displayRef}>
      {!!order.state.savedProducts?.length && (
        <>
          {order.state.savedProducts.map((item, i) =>
            item.serviceId === 1 ? (
              order.state.savedProducts.length - 1 !== i && (
                <TitleAndValueChild
                  title={tr['break']}
                  icon={<Clock />}
                  editable
                  bold
                  inputSize="S"
                  textType="primary"
                  justifyValue="flex-start"
                  className="break"
                >
                  <InputFieldDuration
                    id={'unitDurationInMinutes'}
                    state={{
                      ...(item?.unitDurationInMinutes
                        ? {
                            default: {
                              value: item?.unitDurationInMinutes,
                              label: getTimeFromMinutes(
                                item?.unitDurationInMinutes,
                              ),
                            },
                          }
                        : {}),
                      setValue: (id, value) =>
                        updateServiceKey(
                          { unitDurationInMinutes: value?.value },
                          i,
                        ),
                    }}
                  />
                </TitleAndValueChild>
              )
            ) : (
              <SimpleSelectChild
                key={i}
                placeholder={tr.select}
                child={
                  <OrderItemMobile
                    key={i}
                    service={{ ...item, index: i }}
                    deleteService={deleteService}
                    openModal={modalToggle}
                    setModalContent={setModalSwitch}
                    opened={{ openedProduct, setOpenedProduct }}
                    updateService={updateService}
                  />
                }
              />
            ),
          )}
        </>
      )}
    </S.OrderItemsMobile>
  );
};

export default OrderCard;
