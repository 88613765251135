import * as S from '../SVG.styled';

const OtherServicesSVG = ({
  color = 'currentColor',
  width = 20,
  height = 20,
}) => {
  const content = (
    <>
      <path d="m296.02,70.21h-13.24c-3.31,0-6,2.69-6,6s2.69,6,6,6h13.24c2.2,0,3.98,1.79,3.98,3.98v197.05c0,2.2-1.79,3.98-3.98,3.98H15.98c-2.2,0-3.98-1.79-3.98-3.98V86.2c0-2.2,1.79-3.98,3.98-3.98h142.86c3.31,0,6-2.69,6-6s-2.69-6-6-6h-33.81v-29.36c0-.36.29-.66.66-.66h60.62c.36,0,.66.29.66.66v35.36c0,3.31,2.69,6,6,6s6-2.69,6-6v-35.36c0-6.98-5.68-12.66-12.66-12.66h-60.62c-6.98,0-12.66,5.68-12.66,12.66v29.36h-20.98V12.66c0-.36.29-.66.66-.66h126.58c.36,0,.66.29.66.66v63.56c0,3.31,2.69,6,6,6s6-2.69,6-6V12.66c0-6.98-5.68-12.66-12.66-12.66h-126.58c-6.98,0-12.66,5.68-12.66,12.66v57.56H15.98c-8.81,0-15.98,7.17-15.98,15.98v197.05c0,8.81,7.17,15.98,15.98,15.98h280.03c8.81,0,15.98-7.17,15.98-15.98V86.2c0-8.81-7.17-15.98-15.98-15.98Z" />
      <path d="m227.02,121.53l-88.38,106.58-54.33-47.38c-2.5-2.18-6.29-1.92-8.47.58-2.18,2.5-1.92,6.29.58,8.47l58.97,51.42c1.1.96,2.5,1.48,3.94,1.48.16,0,.32,0,.48-.02,1.61-.13,3.1-.91,4.13-2.15l92.31-111.31c2.12-2.55,1.76-6.33-.79-8.45-2.55-2.11-6.33-1.76-8.45.79Z" />
    </>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 312 299.23"
      color={color}
    >
      {content}
    </S.StyledSVG>
  );
};

export default OtherServicesSVG;
