import { forwardRef, useCallback, useContext, useEffect, useMemo } from 'react';

// style
import * as S from './ScheduleWorkbench.styled';
import { colors } from 'common/colors/colors';

// components
import { Overlay } from 'components/Overlay/Overlay';
import { Loader } from 'components/Loader/Loader';
import { ScheduleDayCard } from '../ScheduleDayCard/ScheduleDayCard';
import { FlashMessage } from 'components/FlashMessage/FlashMessage';

// hooks
import { UserContext } from 'context/UserContext';
import { useTranslate } from 'hooks/useTranslate';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useOverflowShading } from 'hooks/useOverflowShading';
import { toSentenceCase } from 'common/helpers/stringOperations';
import { applyEndTime, applyStartTime } from 'common/helpers/dateOperations';
import { useScheduleHelpers } from 'hooks/employee-schedule/useScheduleHelpers';

export const ScheduleWorkbench = forwardRef((props, ref) => {
  const {
    state,
    period,
    isInSelectedRange, // probably should be moved here
    options,
    scheduleHeaderDimensions,
    activeStartDays,
    setActiveStartDays,
    isTransient,
    applyDayChanges,
    copyRange,
    pasteRange,
    clearRange,
    isRangeEmpty,
    hasChanged,
    getCacheRange,
    hasHourChanged,
    hasDateErrors,
    calendarType,
    sectorId,
  } = props;

  const { locale } = useContext(UserContext);
  const isMobile = useMediaQuery('(max-width: 960px)');
  const tr = useTranslate().use().global;

  const { getWeekDaysArray } = useScheduleHelpers();

  // overflow shading for schedule
  const { init } = useOverflowShading();

  // initialize shading and reflect to dynamic changes
  useEffect(() => {
    period &&
      init &&
      init('schedule', {
        top: scheduleHeaderDimensions?.height + (isMobile ? 10 : 40),
        ...(!isMobile && { scrollbarOffsetVertical: 8 }),
        ...(isMobile && {
          type: 'linear',
          containerStyle: `border-radius: 5px; border: 1px solid ${colors.table_borders_Primary};`,
        }),
      });
  }, [period, init, scheduleHeaderDimensions, isMobile]);

  // select a single day object from schedule
  const selectDay = (day) => {
    if (!isInSelectedRange(day.date)) {
      const value = {
        value: new Date(day.date),
        label: day.date.toLocaleDateString(locale),
      };

      state['date-from'].setValue('date-from', value);
      state['date-to'].setValue('date-to', value);
      setActiveStartDays({ start: value.value, end: value.value });
    }
  };

  const getStartDateOfTheMonth = useCallback((date) => {
    return date
      ? new Date(new Date(date).getFullYear(), new Date(date).getMonth(), 1)
      : undefined;
  }, []);

  // select range from scheduler window (left-click / shift + left-click)
  const selectRange = (e, day) => {
    if (e.shiftKey && state['date-from'].value) {
      const setDate = (id, value) => {
        state[id].setValue(id, {
          value,
          label: value.toLocaleDateString(locale),
        });
      };

      const preSelectedDateFrom = new Date(state['date-from'].value.value);
      const preSelectedDateTo = new Date(state['date-to'].value.value);
      const curSelectedDate = new Date(day.date);

      const activeStartDayFrom = getStartDateOfTheMonth(
        activeStartDays.start,
      )?.getTime();
      const activeStartDayTo = getStartDateOfTheMonth(
        activeStartDays.end,
      )?.getTime();
      const activeStartDayCurrent = getStartDateOfTheMonth(day?.date);

      if (curSelectedDate.getTime() < preSelectedDateFrom.getTime()) {
        setDate('date-from', curSelectedDate);
        activeStartDayFrom !== activeStartDayCurrent?.getTime() &&
          setActiveStartDays((old) => ({
            ...old,
            start: activeStartDayCurrent,
          }));
      } else if (curSelectedDate.getTime() > preSelectedDateTo.getTime()) {
        setDate('date-to', curSelectedDate);
        activeStartDayTo !== activeStartDayCurrent?.getTime() &&
          setActiveStartDays((old) => ({ ...old, end: activeStartDayCurrent }));
      }
    } else selectDay(day);
  };

  const pickerInfo = useMemo(() => {
    const cacheRange = getCacheRange();
    const from = cacheRange?.startDate;
    const to = cacheRange?.endDate;
    let response = '';

    if (from || to) {
      response += `${toSentenceCase(tr['ready-for-save'])}: `;

      if ((from && !to) || from === to) {
        response += from.toLocaleDateString(locale);
      } else if (from && to) {
        response += `${tr['from']} ${from?.toLocaleDateString(locale)} ${tr['to']} ${to?.toLocaleDateString(locale)}`;
      }
    }

    return response;
  }, [tr, getCacheRange, locale]);

  const setupRef = (day) => {
    const isStart =
      applyStartTime(day?.date)?.getTime() ===
      new Date(state['date-from']?.value?.value)?.getTime();
    const isEnd =
      applyEndTime(day?.date)?.getTime() ===
      applyEndTime(new Date(state['date-to']?.value?.value))?.getTime();

    // (isStart || isEnd) &&
    //   console.log({
    //     date: day.date.toLocaleDateString('lt-LT', { dateStyle: 'short' }),
    //     isStart,
    //     isEnd,
    //   });

    if (
      (isStart && isEnd) || // set startRef in case single day selected ...
      isStart // ... or in case range selected
    ) {
      return { ref: ref.start };
    } else if (
      isEnd && // set endRef in case range selected ...
      !isStart // ... but don't set in case single day selected
    ) {
      return { ref: ref.end };
    } else {
      return {};
    }
  };

  return (
    <S.ScheduleWorkbench
      id="schedule"
      stickTop={scheduleHeaderDimensions?.height}
    >
      <div className="section-header" id="schedule-header">
        <h2>{tr['work-schedule']}</h2>

        {pickerInfo && <FlashMessage>{pickerInfo}</FlashMessage>}
      </div>

      <Overlay isVisible={isTransient} htmlFor="schedule">
        <Loader />
      </Overlay>

      {(period && (
        <div className="schedule">
          {!isMobile && (
            <div className="schedule__header">
              {getWeekDaysArray({ sectorId })?.map((weekday, index) => (
                <h3 key={index}>{toSentenceCase(weekday)}</h3>
              ))}
            </div>
          )}

          <div className="schedule__data">
            {period.map((day, index) => {
              return (
                <ScheduleDayCard
                  key={day.date.getTime()}
                  date={day}
                  isSelected={isInSelectedRange(day.date)}
                  changeHandler={applyDayChanges}
                  options={{ 'office-number': options['office-number'] }}
                  copyRange={copyRange}
                  pasteRange={pasteRange}
                  clearRange={clearRange}
                  pasteable={() => !!state['schedule-template'].value}
                  clearable={isRangeEmpty}
                  selectRange={selectRange}
                  hasChanged={hasChanged}
                  {...setupRef(day)}
                  clearSelection={() => {
                    state['date-from']?.setValue('date-from', undefined);
                    state['date-to']?.setValue('date-to', undefined);
                  }}
                  hasHourChanged={hasHourChanged}
                  hasDateErrors={hasDateErrors}
                />
              );
            })}
          </div>
        </div>
      )) ||
        tr['select-period']}
    </S.ScheduleWorkbench>
  );
});

ScheduleWorkbench.displayName = 'ScheduleWorkbench';

export default ScheduleWorkbench;
