import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import StyledSVG from 'common/icons/SVG.styled';

export const ServiceCategories = styled.div`
  :not(:last-child) {
    margin-bottom: 18px;
  }

  :not(:first-child) {
    margin-top: 18px;
  }

  .category,
  .group {
    min-width: max-content;
    font-weight: 700;
  }

  .category {
    font-size: 1.125rem;
    display: flex;
    padding: 13px 0;
  }

  .group {
    border-radius: 20px;
    display: flex;
    font-size: 1rem;
    height: 40px;
    line-height: 1;
    padding: 13px 20px;

    :hover {
      background-color: ${colors.bg_grey_1};
    }

    :active,
    &.active {
      background-color: ${colors.bg_Primary};
      color: ${colors.text_Primary};
    }
  }
`;

export const Category = styled.div`
  color: ${({ isDisabledByParent }) =>
    isDisabledByParent && colors.disabledGrey};
`;

export const Group = styled.div`
  ${StyledSVG} {
    color: ${({ isDisabledByParent }) =>
      isDisabledByParent && colors.disabledGrey};
    display: ${({ isServiceItem }) => isServiceItem && 'none'};
  }
`;
