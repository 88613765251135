import styled, { css } from 'styled-components/macro';
import { fontFamilies } from 'common/fonts/fonts';
import { colors } from 'common/colors/colors';
import { dropdownRollIn } from 'common/styles/animations';

export const BaseInput = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${fontFamilies.family_Secondary};

  ${({ variation, hasValue, isInvalid }) => {
    switch (variation) {
      case 'primary':
        return css`
          .input-wrapper {
            border: 1px solid ${colors[isInvalid ? 'mandyRed' : 'filterGrey']};
            border-radius: 5px;

            input {
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;

              ${!hasValue &&
              css`
                border-radius: 5px;
              `}
            }
          }
        `;
      case 'secondary':
        return css`
          .input-wrapper {
            border: unset;
            border-bottom: 1px solid
              ${colors[isInvalid ? 'mandyRed' : 'filterGrey']};
          }
        `;
    }
  }}

  label {
    color: ${colors.bg_grey_3};
    display: block;
    font-size: 0.875rem;
    margin: ${(props) => (props.noLabel ? '0 10px' : '0 10px 5px')};
    white-space: nowrap;
  }

  .input-wrapper {
    display: flex;
    background-color: #fff;
    ${({ disabled }) =>
      disabled &&
      css`
        border-color: transparent;
        background-color: transparent;
      `}

    &:focus-within {
      border-color: ${({ isInvalid }) =>
        colors[isInvalid ? 'mandyRed' : 'bg_grey_3']};
    }

    input {
      border: unset;
      animation: ${dropdownRollIn} 0.2s ease-in-out;
      transform-origin: top;
      width: ${(props) => props.extendOnOpen && '100%'};
      color: ${colors.text_Secondary};
      /* -webkit-text-fill-color: ${colors.text_Secondary}; */
      flex: 1;
      min-height: 36px;
      padding: 2px 8px;
      padding-right: 0;
      outline: 0;
      width: 0;
      /* opacity: 1; */

      ::placeholder {
        color: ${({ disabled }) =>
          disabled ? colors.text_Secondary : colors.filterGrey};
      }

      :disabled {
        border-color: transparent;
        background-color: transparent;
        opacity: 1;
      }

      &:focus {
        font-weight: bold;
      }
    }
    .clear-icon {
      cursor: pointer;
      padding: 8px;

      svg {
        color: ${colors.filterGrey};
      }
    }
  }
`;
