import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { InputFieldDuration } from 'components/InputFieldDuration/InputFieldDuration.styled';
import { fontFamilies } from 'common/fonts/fonts';
import { TitleAndValue } from 'components/DialogLayout/CustomSelect/children/TitleAndValueChild/TitleAndValueChild.styled';

export const OrderItemsMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${TitleAndValue}.break {
    width: 180px;
    gap: 20px;

    .titleContainer {
      gap: 10px;

      span {
        font-family: ${fontFamilies.family_Secondary};
        padding: 0;
      }
      .iconContainer {
        margin: 0;

        svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    ${InputFieldDuration} {
      height: 24px;
      background-color: ${colors.bg_Secondary};
      display: flex;
      border-radius: 5px;

      .input-container {
        font-family: ${fontFamilies.family_Secondary};
        width: 100%;

        > div {
          align-items: center;
          border-radius: 5px;
          display: flex;
          font-weight: 900;
          height: 24px;
          justify-content: center;
          padding-top: 2px;
        }

        input {
          border: none;
          color: ${colors.bg_Primary};
          font-weight: 900;
          outline: none;
          text-align: center;

          ::-webkit-calendar-picker-indicator {
            display: none;
          }
        }
      }
    }
  }
`;
