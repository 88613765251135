import * as S from './SVG.styled';

export default ({ color, width = 30, height = 30, bold, ...rest }) => {
  const content = bold ? (
    <>
      <path
        d="M 17.8224,10.812979 C 17.60025,10.292644 17.08905,9.9548885 16.5231,9.9548885 H 1.4121225 c -0.56597551,0 -1.07718601,0.3377555 -1.29931711,0.8580905 -0.2099589,0.492945 -0.12172127,1.06197 0.2221251,1.46667 L 5.13051,18.374539 v 6.5331 c 0,0.4443 0.213,0.8703 0.569025,1.1319 l 4.85034,3.60885 c 0.24951,0.1857 0.54468,0.28005 0.839835,0.28005 0.216045,0 0.43212,-0.04875 0.635985,-0.15225 0.477735,-0.2403 0.77592,-0.7242 0.77592,-1.2597 v -10.14195 l 4.795635,-6.09489 c 0.3438,-0.4047 0.432,-0.97068 0.22215,-1.46667 m -7.187355,6.39006 c -0.170415,0.216 -0.26169,0.48075 -0.26169,0.75165 v 8.52915 l -2.805525,-2.0874 v -6.44175 c 0,-0.2739 -0.09129,-0.53865 -0.26169,-0.75165 l -3.78537,-4.8108 h 10.896585 l -3.78537,4.8108 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 18.47055,13.934974 c -0.1035,0 -0.21,-0.003 -0.3105,-0.01216 -0.67245,-0.04565 -1.25655,0.450345 -1.3083,1.11978 -0.05175,0.66945 0.4503,1.2567 1.11975,1.30845 0.16425,0.01215 0.33165,0.0183 0.49905,0.0183 3.95565,0 7.1721,-3.654555 7.1721,-8.1489005 0,-4.49433 -3.21645,-8.15187703 -7.1721,-8.15187703 -3.9558,0 -6.953055,3.38064703 -7.159965,7.69848703 -0.03348,0.67248 0.48687,1.241505 1.156305,1.27497 0.0213,0 0.03954,0 0.06084,0 0.64509,0 1.18368,-0.508155 1.214115,-1.159335 0.146055,-3.01854 2.224305,-5.382855 4.728705,-5.382855 2.50425,0 4.73775,2.56515 4.73775,5.71758 0,3.1524155 -2.124,5.7145355 -4.73775,5.7145355"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 24.5349,15.553789 -0.29505,-0.10035 c -0.38955,-0.13095 -0.81855,-0.0609 -1.1412,0.1947 -1.3662,1.068 -2.9667,1.63395 -4.6281,1.63395 -0.7881,0 -1.5672,-0.1278 -2.3157,-0.38025 -0.636,-0.213 -1.326675,0.1278 -1.54272,0.7668 -0.213,0.63585 0.12777,1.3266 0.76677,1.54275 0.9981,0.33465 2.0388,0.50505 3.09165,0.50505 1.9869,0 3.9009,-0.60255 5.57445,-1.7466 2.11785,0.86415 3.52065,2.9334 3.52065,5.2398 v 4.28745 H 14.931615 c -0.67248,0 -1.21716,0.54465 -1.21716,1.2171 0,0.6726 0.54468,1.21725 1.21716,1.21725 H 28.7829 c 0.67245,0 1.2171,-0.54465 1.2171,-1.21725 v -5.50455 c 0,-3.4536 -2.1939,-6.52995 -5.4651,-7.65585 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
    </>
  ) : (
    <>
      <path
        d="m 5.351535,18.29837 v 7.02795 c 0,0.2631 0.12684,0.51375 0.33615,0.6723 L 10.743,29.75987 c 0.149055,0.111 0.32346,0.16815 0.497895,0.16815 0.126855,0 0.256905,-0.0285 0.377415,-0.0888 0.285435,-0.14265 0.46302,-0.4281 0.46302,-0.7485 V 18.29837 l 5.14092,-6.536385 C 17.4315,11.52095 17.4855,11.181605 17.3586,10.883495 17.2254,10.57586 16.92405,10.37606 16.5879,10.37606 H 0.8385795 c -0.336174,0 -0.6374775,0.1998 -0.7706787,0.507435 -0.1268577,0.294945 -0.07292617,0.637455 0.1363887,0.87849 L 5.34519,18.29837 Z M 15.7032,11.64464 10.9491,17.68622 c -0.0888,0.111 -0.13635,0.2505 -0.13635,0.3933 v 10.1517 l -4.192635,-3.1206 v -7.0311 c 0,-0.1428 -0.047595,-0.27915 -0.136395,-0.3933 L 1.72974,11.64464 H 15.6999 Z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 12.392145,8.1559848 c 0.34251,0.01269 0.646965,-0.25371 0.66282,-0.602565 0.16809,-3.485415 2.610135,-6.212862 5.562735,-6.212862 2.9526,0 5.5722,2.958957 5.5722,6.593427 0,3.6344852 -2.49915,6.5902652 -5.5722,6.5902652 -0.1269,0 -0.2505,-0.0063 -0.37425,-0.01586 -0.3489,-0.0222 -0.65325,0.23469 -0.6819,0.583575 -0.02535,0.3489 0.23475,0.65325 0.58365,0.6819 0.1554,0.0126 0.31395,0.0189 0.4725,0.0189 3.77085,0 6.84075,-3.52659 6.84075,-7.8587852 0,-4.33218 -3.0699,-7.8620046 -6.84075,-7.8620046 -3.77085,0 -6.631515,3.2602446 -6.831315,7.4211846 -0.01585,0.348855 0.253725,0.646965 0.60258,0.66282"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 24.73545,16.17662 -0.30765,-0.1047 c -0.20295,-0.0666 -0.42495,-0.03165 -0.59625,0.10155 -1.5381,1.20195 -3.3396,1.83945 -5.21385,1.83945 -0.89115,0 -1.7697,-0.1428 -2.61645,-0.42825 -0.333,-0.111 -0.69135,0.0666 -0.80235,0.3996 -0.111,0.333 0.0666,0.6915 0.3996,0.8025 0.9768,0.32655 1.9917,0.4947 3.0192,0.4947 2.04555,0 4.0182,-0.65655 5.71815,-1.8966 2.62905,0.9102 4.3956,3.3903 4.3956,6.17175 v 5.10285 H 14.92929 c -0.352035,0 -0.63429,0.2853 -0.63429,0.6342 0,0.3489 0.282255,0.63435 0.63429,0.63435 H 29.36565 C 29.7177,29.92802 30,29.64257 30,29.29367 v -5.73705 c 0,-3.32685 -2.1153,-6.2922 -5.26455,-7.37685"
        fill="currentcolor"
        strokeWidth="1.5"
      />
    </>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
