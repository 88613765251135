import { useContext, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

// style
import * as S from './SideBar.styled';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { toggleSideBar } from 'redux/actions/sideBarActions';

// icons
import { Arrow } from 'common/icons';

// images
import { ServeProLogo } from 'common/images';

// components
import { Overlay } from 'components/Overlay/Overlay';
import { Avatar } from 'components/Avatar/Avatar';
import { SideBarMenu } from './SideBarMenu/SideBarMenu';

// context
import { UserContext } from 'context/UserContext';

// hooks
import { useSideBarMenu } from 'hooks/useSideBarMenu';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useScrollIntoView } from 'hooks/useScrollIntoView';

export const SideBar = ({ showBurgerMenu, setShowBurgerMenu }) => {
  const isMobile = useMediaQuery('(max-width: 960px)');
  const isSideBarOpen = useSelector((state) => state.sideBar.isSideBarOpen);
  const location = useLocation();
  const { user } = useContext(UserContext);
  const dispatch = useDispatch();

  const currRootMenuItem = location.pathname.split('/')[1];

  const menu = useSideBarMenu(currRootMenuItem);

  const sideBarToggleHandler = () => {
    dispatch(toggleSideBar());
  };

  const { ref, scroll } = useScrollIntoView({ vertical: 'start' }); // scroll into view currently selected item

  useEffect(() => {
    setShowBurgerMenu(false);
  }, [isMobile, setShowBurgerMenu]);

  return (
    <>
      <Overlay
        isVisible={showBurgerMenu}
        hide={() => setShowBurgerMenu(false)}
      />

      <S.Container show={isMobile ? showBurgerMenu : isSideBarOpen}>
        <S.LogoContainer>
          <NavLink to="/" replace={true}>
            <ServeProLogo />
          </NavLink>

          {isMobile && (
            <div className="avatar" onClick={scroll}>
              <Avatar imagePath={user?.photoUrl} raised />
            </div>
          )}
        </S.LogoContainer>
        <S.MenuContainer>
          <SideBarMenu menu={menu} showMenu={setShowBurgerMenu} ref={ref} />
        </S.MenuContainer>
        <S.HideButton show={isSideBarOpen} onClick={sideBarToggleHandler}>
          <Arrow black className={isSideBarOpen ? 'left' : ''} />
        </S.HideButton>
        <span className="project-version">
          {process.env.REACT_APP_RELEASE_VERSION}
        </span>
      </S.Container>
    </>
  );
};

export default SideBar;
