import * as S from './SVG.styled';

export default ({ color, width = 30, height = 30, bold, ...rest }) => {
  const content = bold ? (
    <>
      <path
        d="m 13.52658,11.33652 c 0.706125,0 1.39698,0.25983 1.99302,0.748935 0.52275,0.42795 1.29315,0.35154 1.7211,-0.168135 0.42795,-0.52272 0.35145,-1.293045 -0.16815,-1.72101 C 16.0332,9.343455 14.8074,8.89104 13.52658,8.89104 c -2.59833,0 -4.82373,1.88607 -5.71938,4.54248 H 7.235565 c -0.67557,0 -1.222755,0.547185 -1.222755,1.222755 0,0.675525 0.547185,1.222725 1.222755,1.222725 H 7.38534 c 0,0.05505 -0.00305,0.1101 -0.00305,0.16515 0,0.15885 0.0061,0.31485 0.015285,0.4707 h -0.16201 c -0.67557,0 -1.222755,0.5472 -1.222755,1.2228 0,0.67545 0.547185,1.22265 1.222755,1.22265 H 7.91724 c 0.95985,2.49435 3.111885,4.2369 5.60934,4.2369 1.268595,0 2.48517,-0.44325 3.51837,-1.28085 0.52275,-0.42495 0.60525,-1.1952 0.18045,-1.7211 -0.42495,-0.52275 -1.1952,-0.60525 -1.7211,-0.1803 -0.592965,0.47985 -1.274655,0.73365 -1.97772,0.73365 -1.17384,0 -2.21928,-0.70305 -2.89791,-1.7913 h 2.95293 c 0.675555,0 1.22274,-0.5472 1.22274,-1.2228 0,-0.6756 -0.547185,-1.22265 -1.22274,-1.22265 H 9.84612 C 9.833895,16.35585 9.827775,16.2 9.827775,16.041 c 0,-0.05505 0.00612,-0.10995 0.00612,-0.165 h 5.242455 c 0.6756,0 1.2228,-0.5472 1.2228,-1.222785 0,-0.67557 -0.5472,-1.22274 -1.2228,-1.22274 h -4.62498 c 0.663345,-1.26249 1.794375,-2.097015 3.078255,-2.097015 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 17.6259,1.5789 c -2.55555,0 -4.99497,0.770325 -7.079745,2.225385 C 4.58529,4.690785 0,9.84159 0,16.044 c 0,6.8229 5.55126,12.3771 12.37722,12.3771 2.62578,0 5.06208,-0.8253 7.06743,-2.2254 C 25.485,25.3062 30,20.10045 30,13.95612 30,7.133205 24.4488,1.5789 17.62275,1.5789 Z M 2.44548,16.044 c 0,-5.47488 4.453845,-9.931785 9.93174,-9.931785 5.47788,0 9.93168,4.453845 9.93168,9.931785 0,5.47785 -4.4538,9.93165 -9.93168,9.93165 -5.477895,0 -9.93174,-4.4538 -9.93174,-9.93165 z m 20.62467,6.21765 c 1.0668,-1.82805 1.6812,-3.9525 1.6812,-6.21765 0,-5.603265 -3.7446,-10.34751 -8.86185,-11.86371 0.56865,-0.100875 1.1463,-0.15591 1.73325,-0.15591 5.47485,0 9.9318,4.453845 9.9318,9.93174 0,3.44508 -1.76685,6.52023 -4.4844,8.30853 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
    </>
  ) : (
    <>
      <path
        d="m 12.26577,28.35405 c 2.654565,0 5.11473,-0.8508 7.12563,-2.29125 C 25.4493,25.24695 30,20.04945 30,13.91172 30,7.14627 24.49965,1.64595 17.73735,1.64595 c -2.58435,0 -5.05092,0.7935 -7.141425,2.29446 C 4.62078,4.756215 0,9.893235 0,16.08825 c 0,6.7623 5.50032,12.2658 12.26577,12.2658 z M 28.7253,13.91172 c 0,4.74183 -3.02745,8.85273 -7.3422,10.36653 1.95345,-2.17335 3.1452,-5.04465 3.1452,-8.19 0,-6.421275 -4.9617,-11.701695 -11.252325,-12.21795 1.3926,-0.62142 2.899875,-0.94965 4.461375,-0.94965 6.0612,0 10.9911,4.929885 10.9911,10.99107 z M 12.26577,5.097195 c 6.06123,0 10.99113,4.929885 10.99113,10.991055 0,6.0612 -4.9299,10.9911 -10.99113,10.9911 -6.061185,0 -10.991073,-4.9299 -10.991073,-10.9911 0,-6.06117 4.929888,-10.991055 10.991073,-10.991055 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 6.26832,17.8536 c 0,0.35055 0.286815,0.63735 0.63735,0.63735 h 1.166355 c 0.82536,2.5749 2.928615,4.4169 5.39196,4.4169 1.17273,0 2.304015,-0.41115 3.266415,-1.1919 0.27405,-0.22305 0.31545,-0.6246 0.0924,-0.8955 -0.2199,-0.27405 -0.6246,-0.31545 -0.8955,-0.0924 -0.7329,0.5928 -1.583775,0.90825 -2.463315,0.90825 -1.781385,0 -3.3174,-1.2843 -4.04397,-3.14205 h 4.10133 c 0.35055,0 0.63735,-0.28695 0.63735,-0.63735 0,-0.35055 -0.2868,-0.63735 -0.63735,-0.63735 H 9.06309 C 9.00255,16.85625 8.970675,16.4769 8.970675,16.0914 c 0,-0.27405 0.02232,-0.54495 0.05418,-0.8094 h 6.054795 c 0.35055,0 0.63735,-0.28686 0.63735,-0.63741 0,-0.350535 -0.2868,-0.63735 -0.63735,-0.63735 H 9.3021 c 0.669225,-2.02677 2.28171,-3.4608 4.161885,-3.4608 0.885915,0 1.746315,0.318675 2.482515,0.92415 0.2709,0.22308 0.6756,0.18483 0.8955,-0.08922 0.22305,-0.27087 0.1848,-0.672405 -0.08925,-0.895485 C 15.7839,9.6924 14.64627,9.27174 13.460805,9.27174 c -2.56533,0 -4.74507,1.991715 -5.490765,4.7355 H 6.90249 c -0.35055,0 -0.63735,0.286815 -0.63735,0.63735 0,0.35055 0.2868,0.63741 0.63735,0.63741 h 0.834915 c -0.025485,0.26445 -0.04461,0.53535 -0.04461,0.8094 0,0.3855 0.035055,0.76155 0.08604,1.12815 H 6.90249 c -0.35055,0 -0.63735,0.2868 -0.63735,0.63735 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
    </>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
