import { useMemo } from 'react';

// style
import * as S from './SetContents.styled';
import { colors } from 'common/colors/colors';

// components
import { ServiceSelector } from '../ServiceSelector/ServiceSelector';
import { SetService } from '../SetService/SetService';
import { ServiceStats } from '../ServiceStats/ServiceStats';

// icons
import { Minus, Plus } from 'common/icons';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useToggler } from 'hooks/useToggler';

export const SetContents = ({
  name,
  state,
  disabled,
  breadcrumbs,
  getUnitsById,
}) => {
  const tr = useTranslate().use().global;

  const {
    active: serviceSelectorVisible,
    on: showServiceSelector,
    off: hideServiceSelector,
  } = useToggler();

  const removeItem = (serviceId) => {
    // console.log(`remove service ${serviceId}`);

    let updatedValue = state.value.value;

    updatedValue = updatedValue.filter((service) => service.id !== serviceId);

    state.setValue('composition', { value: updatedValue, label: updatedValue });
  };

  const hasContents = state?.value?.value?.length > 0;

  const totalDuration = useMemo(
    () =>
      state?.value?.value?.reduce(
        (acc, item) =>
          item.empDurationInMinutes || item.baseDurationInMinutes
            ? acc +
              (item.empDurationInMinutes || item.baseDurationInMinutes) *
                item.compServiceCount
            : acc,
        0,
      ),
    [state?.value?.value],
  );

  const totalPrice = useMemo(
    () =>
      state?.value?.value?.reduce(
        (acc, item) =>
          item.avgUnitPriceWithVat
            ? acc + item.avgUnitPriceWithVat * item.compServiceCount
            : acc,
        0,
      ),
    [state?.value?.value],
  );

  return (
    <S.SetContents disabled={disabled}>
      <label>{tr['composition']}</label>

      <S.SetServiceContainer disabled={disabled} hasContents={hasContents}>
        {hasContents ? (
          state?.value?.value?.map((service, index) => (
            <SetService
              key={index}
              service={service}
              getUnitsById={getUnitsById}
              removeItem={removeItem}
              disabled={disabled}
              control={() =>
                !disabled && (
                  <button type="button" onClick={() => removeItem(service.id)}>
                    <Minus color={colors.bg_grey_3} bold inCircle />
                  </button>
                )
              }
            />
          ))
        ) : (
          <div className="empty">{tr['empty-fill-in']}</div>
        )}

        <div className="totals-control">
          {hasContents && (
            <ServiceStats
              totals={true}
              duration={totalDuration}
              price={totalPrice}
            />
          )}

          {!disabled && (
            <button type="button" onClick={showServiceSelector}>
              <Plus
                black
                inCircle
                width={20}
                height={20}
                color={colors.bg_Button_Primary}
              />
            </button>
          )}
        </div>

        {serviceSelectorVisible && (
          <ServiceSelector
            name={name}
            data={{ state: state?.value?.value, setState: state?.setValue }}
            close={hideServiceSelector}
            breadCrumbs={breadcrumbs}
            toastTextOnAdd={tr['composition-extended']}
          />
        )}
      </S.SetServiceContainer>
    </S.SetContents>
  );
};

export default SetContents;
