import * as S from './SVG.styled';

export default ({
  color,
  width = 30,
  height = 30,
  bold,
  black,
  specificTime,
  ...rest
}) => {
  let weight;

  if (bold) {
    weight = 700;
  } else if (black) {
    weight = 900;
  }

  let content;

  switch (weight) {
    case 900:
      content = specificTime ? (
        <>
          <path
            d="M 15,0 C 6.729,0 0,6.729 0,15 0,23.271 6.729,30 15,30 23.271,30 30,23.271 30,15 30,6.729 23.271,0 15,0 Z m 0,26.47545 C 8.67339,26.47545 3.52458,21.32655 3.52458,15 3.52458,8.67339 8.67339,3.52458 15,3.52458 c 6.32655,0 11.47545,5.14881 11.47545,11.47542 0,6.32655 -5.1489,11.47545 -11.47545,11.47545 z"
            fill="currentcolor"
            strokeWidth="1.5"
          />
          <path
            d="M 21.447,17.0178 16.5684,13.91028 14.891325,6.047535 C 14.68866,5.095905 13.751715,4.48791 12.800085,4.690575 11.84844,4.89324 11.24046,5.830185 11.44311,6.78183 l 1.82985,8.58522 c 0,0 0,0.003 0,0.0058 0.02057,0.09105 0.047,0.18225 0.08225,0.2673 0.0059,0.01185 0.0088,0.02655 0.01469,0.03825 0.03231,0.07935 0.07343,0.15855 0.11748,0.23205 0.0088,0.0147 0.01762,0.0294 0.02643,0.0441 0.047,0.07335 0.09693,0.14085 0.15567,0.2055 0.0088,0.0088 0.01762,0.02055 0.02643,0.0294 0.05875,0.06465 0.120435,0.1233 0.18798,0.1791 0.0029,0 0.0059,0.006 0.0088,0.0088 0.04992,0.03825 0.09985,0.07635 0.15273,0.10875 l 5.50122,3.504 c 0.2937,0.18795 0.62265,0.276 0.94575,0.276 0.58155,0 1.1514,-0.28785 1.4892,-0.81645 0.52275,-0.81945 0.282,-1.9092 -0.54045,-2.43195 z"
            fill="currentcolor"
            strokeWidth="1.5"
          />
        </>
      ) : (
        <>
          <path
            d="M 15,0 C 6.729,0 0,6.729 0,15 0,23.271 6.729,30 15,30 23.271,30 30,23.271 30,15 30,6.729 23.271,0 15,0 Z m 0,26.47545 C 8.67339,26.47545 3.52458,21.32655 3.52458,15 3.52458,8.67339 8.67339,3.52458 15,3.52458 c 6.32655,0 11.47545,5.14881 11.47545,11.47542 0,6.32655 -5.1489,11.47545 -11.47545,11.47545 z"
            fill="currentcolor"
            strokeWidth="1.5"
          />
          <path
            d="m 21.52335,13.23774 h -4.761 V 6.220905 c 0,-0.972195 -0.7902,-1.76229 -1.76235,-1.76229 -0.97218,0 -1.762275,0.790095 -1.762275,1.76229 V 15 c 0,0.9723 0.790095,1.76235 1.762275,1.76235 h 6.52335 c 0.9723,0 1.76235,-0.79005 1.76235,-1.76235 0,-0.972165 -0.79005,-1.76226 -1.76235,-1.76226 z"
            fill="currentcolor"
            strokeWidth="1.5"
          />
        </>
      );

      break;
    case 700:
      content = specificTime ? (
        <>
          <path
            d="M 15,30 C 23.2719,30 30,23.2719 30,15 30,6.728145 23.2719,0 15,0 6.728145,0 0,6.728145 0,15 0,23.2719 6.728145,30 15,30 Z M 15,2.44548 c 6.92385,0 12.55455,5.630745 12.55455,12.55452 0,6.92385 -5.6307,12.55455 -12.55455,12.55455 C 8.076225,27.55455 2.44548,21.92385 2.44548,15 2.44548,8.076225 8.076225,2.44548 15,2.44548 Z"
            fill="currentcolor"
            strokeWidth="1.5"
          />
          <path
            d="m 13.80477,15.2538 c 0,0 0,0.003 0,0.006 0.0031,0.01845 0.01223,0.03675 0.01835,0.05505 0.01529,0.05505 0.03057,0.1101 0.05196,0.159 0.0092,0.0213 0.0214,0.04275 0.03363,0.0642 0.02139,0.04575 0.04586,0.09165 0.07337,0.1314 0.01529,0.02445 0.03363,0.0459 0.04891,0.0672 0.02751,0.03675 0.05808,0.0735 0.0917,0.1071 0.0214,0.0213 0.04279,0.03975 0.0642,0.06105 0.03667,0.0336 0.07643,0.0642 0.11616,0.0918 0.01529,0.0091 0.02445,0.0213 0.03974,0.03045 L 20.0682,19.674 c 0.2049,0.1284 0.4311,0.1926 0.6573,0.1926 0.4035,0 0.80085,-0.19875 1.0332,-0.56565 0.36375,-0.5685 0.1956,-1.3266 -0.3729,-1.68735 L 16.0944,14.241915 14.29692,5.808045 C 14.15631,5.14776 13.505205,4.728975 12.84492,4.866525 12.184635,5.00715 11.76279,5.655195 11.9034,6.31854 l 1.90443,8.93526 z"
            fill="currentcolor"
            strokeWidth="1.5"
          />
        </>
      ) : (
        <>
          <path
            d="M 15,30 C 23.2719,30 30,23.2719 30,15 30,6.728145 23.2719,0 15,0 6.728145,0 0,6.728145 0,15 0,23.2719 6.728145,30 15,30 Z M 15,2.44548 c 6.92385,0 12.55455,5.630745 12.55455,12.55452 0,6.92385 -5.6307,12.55455 -12.55455,12.55455 C 8.076225,27.55455 2.44548,21.92385 2.44548,15 2.44548,8.076225 8.076225,2.44548 15,2.44548 Z"
            fill="currentcolor"
            strokeWidth="1.5"
          />
          <path
            d="m 15,16.2228 h 6.7893 c 0.6756,0 1.22265,-0.5472 1.22265,-1.2228 0,-0.675525 -0.54705,-1.22271 -1.22265,-1.22271 H 16.2228 V 5.863095 c 0,-0.67557 -0.5472,-1.22274 -1.2228,-1.22274 -0.67557,0 -1.22274,0.54717 -1.22274,1.22274 V 15 c 0,0.6756 0.54717,1.2228 1.22274,1.2228 z"
            fill="currentcolor"
            strokeWidth="1.5"
          />
        </>
      );

      break;
    default:
      content = specificTime ? (
        <>
          <path
            d="M 15,0 C 6.730395,0 0,6.730395 0,15 0,23.26965 6.730395,30 15,30 23.26965,30 30,23.26965 30,15 30,6.730395 23.2728,0 15,0 Z m 0,28.7253 C 7.43148,28.7253 1.274697,22.56855 1.274697,15 1.274697,7.43148 7.43148,1.274697 15,1.274697 22.56855,1.274697 28.7253,7.43148 28.7253,15 28.7253,22.56855 22.56855,28.7253 15,28.7253 Z"
            fill="currentcolor"
            strokeWidth="1.5"
          />
          <path
            d="M 21.30975,18.26325 15.5673,14.60487 13.636065,5.551335 C 13.562775,5.20716 13.224975,4.987275 12.880815,5.060565 12.53664,5.13387 12.316755,5.471655 12.39006,5.81583 l 1.98534,9.31482 c 0,0.0096 0.0064,0.0159 0.0064,0.0255 0.0064,0.02865 0.01593,0.0606 0.02868,0.0861 0.0032,0.01275 0.01273,0.0222 0.01593,0.0318 0.01275,0.0255 0.02548,0.04785 0.03824,0.0702 0.0064,0.01275 0.01593,0.0222 0.0255,0.03495 0.01593,0.0192 0.03186,0.03825 0.04779,0.05745 0.0096,0.0094 0.02232,0.01905 0.03187,0.0318 0.01913,0.0192 0.04143,0.0351 0.06056,0.04785 0.0064,0.0063 0.01273,0.01275 0.01911,0.0159 l 5.9688,3.80175 c 0.10515,0.0669 0.2262,0.09885 0.34095,0.09885 0.2103,0 0.41745,-0.10515 0.5385,-0.2964 0.1881,-0.2964 0.102,-0.6915 -0.1944,-0.8796 z"
            fill="currentcolor"
            strokeWidth="1.5"
          />
        </>
      ) : (
        <>
          <path
            d="M 15,30 C 23.2728,30 30,23.26965 30,15 30,6.730395 23.2728,0 15,0 6.727215,0 0,6.730395 0,15 0,23.26965 6.730395,30 15,30 Z M 15,1.274697 C 22.56855,1.274697 28.7253,7.43148 28.7253,15 28.7253,22.56855 22.56855,28.7253 15,28.7253 7.43148,28.7253 1.274697,22.56855 1.274697,15 1.274697,7.43148 7.43148,1.274697 15,1.274697 Z"
            fill="currentcolor"
            strokeWidth="1.5"
          />
          <path
            d="m 15,15.63735 h 7.07775 c 0.35055,0 0.63735,-0.2868 0.63735,-0.637365 0,-0.350535 -0.2868,-0.63735 -0.63735,-0.63735 h -6.4404 V 5.474805 C 15.63735,5.12427 15.35055,4.83747 15,4.83747 c -0.350535,0 -0.63735,0.2868 -0.63735,0.637335 v 9.52518 c 0,0.350565 0.286815,0.637365 0.63735,0.637365 z"
            fill="currentcolor"
            strokeWidth="1.5"
          />
        </>
      );

      break;
  }

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
