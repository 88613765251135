import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// style
import * as S from './Common.styled';

// icons
import { ArrowSVG } from 'common/icons';

// components
import { TheButton } from 'components/Buttons/TheButton';
import { FormProvider, Input, Select } from 'components/HookForm';
import { UnsavedChangesDialogBox } from 'components/UnsavedChangesDialogBox/UnsavedChangesDialogBox';
import { SectorStats } from '../../EmployeeGeneralInformation/partials/SectorStats/SectorStats';
import { FormContainer } from 'components/Form/FormContainer';
import { Loader } from 'components/Loader/Loader';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useFormStatus } from 'hooks/useFormStatus';
import {
  useEmployeesGetOneSectorSettings,
  useEmployeesSectorSettingsPost,
} from 'services/employeeService';

export const Common = ({ title }) => {
  const { employeeId } = useParams();
  const [searchParams] = useSearchParams();
  const sectorId = searchParams.get('sectorId');
  const isMobile = useMediaQuery('(max-width: 960px)');
  const navigate = useNavigate();
  const tr = useTranslate().use().global;

  const mutationContext = useEmployeesSectorSettingsPost({
    searchParams: {
      useVersioning: 1,
    },
  });

  const {
    mutate,
    isSuccess: mutationSuccess,
    isError: mutationError,
  } = mutationContext;

  const { setButtons, sectorStatsData, setIsGoBackEnabled } =
    useOutletContext();

  const bypassUnsavedChanges = useRef();

  const sectorSettingsContext = useEmployeesGetOneSectorSettings({
    queryParams: {
      retry: false,
      enabled: !!employeeId && !!sectorId,
    },
    searchParams: {
      sectorId,
      employeeId,
      useVersioning: 1,
    },
  });

  const { data, refetch } = sectorSettingsContext;

  const schema = yup.object().shape({
    sectorSettings: yup.object().shape({
      professionId: yup.number().nullable(),
      isShownInCalendar: yup.boolean(),
      priority: yup
        .number()
        .nullable()
        .positive(
          `${tr['priority-in-calendar']} ${tr['cant-be-negative-number'].toLowerCase()}`,
        ),
      allowOrdersOverlap: yup.boolean(),
      roleId: yup.number().nullable(),
      serveOnlineId: yup
        .string()
        .nullable()
        .max(
          40,
          `${tr['serve-online-system-id']} ${tr['can-not-exceed'].toLowerCase()} 40 ${tr['characters'].toLowerCase()}`,
        ),
    }),
  });

  const defaultValues = {
    employeeId: data?.employeeId ?? employeeId,
    sectorSettings: {
      sectorId,
      professionId: null,
      isShownInCalendar: data?.sectorSettings?.isShownInCalendar ?? null,
      roleId: data?.sectorSettings?.roleId ?? null,
      priority: data?.sectorSettings?.priority?.toString() ?? null,
      serveOnlineId: data?.sectorSettings?.serveOnlineId ?? null,
      allowOrdersOverlap: data?.sectorSettings?.allowOrdersOverlap ?? null,
      version: data?.sectorSettings?.version ?? null,
    },
  };

  const methods = useForm({
    mode: 'onChange',
    defaultValues: useMemo(() => defaultValues, [defaultValues]),
    resolver: yupResolver(schema),
  });

  const {
    reset,
    handleSubmit,
    formState: { isDirty, isValid, isSubmitting },
  } = methods;

  const onSubmit = useCallback((data) => {
    setIsGoBackEnabled(false);
    const postData = structuredClone(data);
    // Not implemented in BE yet
    postData.sectorSettings.professionId = null;
    postData.sectorSettings.roleId = null;
    mutate(postData);
  }, []);

  const { isLoading, isFormDisabled } = useFormStatus({
    resourceContext: sectorSettingsContext,
    mutationContext: mutationContext,
    setButtons: setButtons,
    methods: methods,
    resetForm: () => reset(defaultValues),
    setIsGoBackEnabled: setIsGoBackEnabled,
    submit: handleSubmit(onSubmit),
    bypassUnsavedChangesRef: bypassUnsavedChanges,
  });

  useEffect(() => {
    if (data?.sectorSettings) {
      reset(defaultValues);
    }
  }, [data]);

  useEffect(() => {
    if (mutationSuccess) {
      refetch();
    }
  }, [mutationSuccess, mutationError]);

  return (
    <S.Common>
      {isMobile && (
        <S.Controls>
          <TheButton
            secondary
            inverted
            raised
            icon={<ArrowSVG />}
            action={() => navigate(-1)}
          />
          <div className="back-button-text">{title}</div>
        </S.Controls>
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <FormProvider methods={methods}>
            <FormContainer disabled={isFormDisabled}>
              <div className="form">
                <div className="row">
                  <div className="input-container">
                    <Select
                      name="sectorSettings.professionId"
                      label={tr['occupation']}
                      disabled={isFormDisabled}
                      options={[
                        { label: 'Programuotojas', value: 0 },
                        { label: 'Dizaineris', value: 1 },
                      ]}
                    />
                  </div>

                  <div className="input-container">
                    <Select
                      name="sectorSettings.isShownInCalendar"
                      label={tr['visibilitySelf']}
                      disabled={isFormDisabled}
                      options={[
                        { label: tr['show'], value: true },
                        { label: tr['dont-show'], value: false },
                      ]}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="input-container">
                    <Input
                      name="sectorSettings.priority"
                      label={tr['priority-in-calendar']}
                      disabled={isFormDisabled}
                      type="number"
                      decimalPlaces={1}
                      roundingMethod="D"
                    />
                  </div>

                  <div className="input-container">
                    <Select
                      name="sectorSettings.allowOrdersOverlap"
                      label={tr['duplicate-orders']}
                      disabled={isFormDisabled}
                      options={[
                        { label: tr['allow'], value: true },
                        { label: tr['prohibit'], value: false },
                      ]}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="input-container">
                    <Select
                      name="sectorSettings.roleId"
                      label={tr['role-for-sector-permissions']}
                      disabled={isFormDisabled}
                      options={[
                        { label: 'Administratorius', value: 0 },
                        { label: 'Darbuotojas', value: 1 },
                      ]}
                    />
                  </div>

                  <div className="input-container">
                    <Input
                      name="sectorSettings.serveOnlineId"
                      label={tr['serve-online-system-id']}
                      disabled={isFormDisabled}
                    />
                  </div>
                </div>
              </div>
            </FormContainer>
          </FormProvider>

          <SectorStats data={sectorStatsData} />

          <UnsavedChangesDialogBox
            condition={isDirty}
            checkIfBypass={() => bypassUnsavedChanges.current}
          />
        </>
      )}
    </S.Common>
  );
};

export default Common;
