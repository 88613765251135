import { useContext } from 'react';

// style
import * as S from './OrderMobile.styled';

// icons
import { Check } from 'common/icons';

// components
import DiscountModal from 'pages/Order/partials/Order/partials/OrderRowEntries/OrderRowEntry/DiscountColumn/DiscountModal/DiscountModal';
import Overlay from 'components/Overlay/Overlay';
import SelectionPanel from 'components/Overlay/SelectionPanel/SelectionPanel';
import OrderItemsMobile from './partials/OrderItemsMobile/OrderItemsMobile';
import OrderFooterMobile from './partials/OrderFooterMobile/OrderFooterMobile';
import { EmployeesSelector } from '../EmployeesSelector/EmployeesSelector';
import OrderHeaderMobile from './partials/OrderHeaderMobile/OrderHeaderMobile';
import OrderNote from '../OrderNote/OrderNote';

// contexts
import { OrderContext } from 'context/OrderContext';

// hooks
import useTranslate from 'hooks/useTranslate';
import useModal from 'hooks/useModal';
import { useOrderFormStatus } from 'hooks/useOrderFormStatus';

const presetButtons = [
  {
    id: 'save',
    icon: <Check bold />,
    borderless: true,
    show: true,
  },
];

export const OrderMobile = ({
  setButtons,
  setDisplayModal,
  modalToggle,
  modalIsShowing,
}) => {
  const tr = useTranslate().use().global;
  const { isShowing, toggle } = useModal();
  useOrderFormStatus({ setButtons });
  const {
    order,
    deleteService,
    footerData,
    saveDiscount,
    professional,
    wantedEmployees,
    toggleWantedEmployeesSelection,
  } = useContext(OrderContext);

  return (
    <S.OrderMobile>
      <EmployeesSelector
        employee={professional}
        wantedEmployees={wantedEmployees}
        toggleWantedEmployeesSelection={toggleWantedEmployeesSelection}
      />
      {/* <OrderClientInfoMobile client={client} /> */}
      <OrderHeaderMobile id={order.state.id} status={order.state.status} />
      <div className="order-body">
        <OrderItemsMobile
          order={order.state}
          deleteService={deleteService}
          setDisplayModal={setDisplayModal}
          modalToggle={modalToggle}
          modalIsShowing={modalIsShowing}
        />
        {!!order.state.savedProducts?.length && <OrderNote />}
      </div>
      {!!order.state.savedProducts?.length && (
        <OrderFooterMobile footerData={footerData} setModal={{ toggle }} />
      )}
      <Overlay isVisible={isShowing} hide={toggle}>
        <SelectionPanel
          closeAction={() => toggle()}
          headerTitle={tr.discount}
          presetButtons={presetButtons}
        >
          <DiscountModal
            onSave={(value) => {
              saveDiscount(value);
              toggle();
            }}
            price={order.state.amountBeforeDiscountWithVat}
            discount={order.state.discountWithVat}
          />
        </SelectionPanel>
      </Overlay>
    </S.OrderMobile>
  );
};

export default OrderMobile;
