// style
import * as S from './TableHeadingControls.styled';

// icons
import { FilterSVG, SortArrowsSVG } from 'common/icons';

export const TableHeadingControls = ({ onSortClick, onFilterClick }) => (
  <S.TableHeadingControls className="controls">
    <div className="sort" onClick={onSortClick}>
      <SortArrowsSVG />
    </div>
    <div className="filter" onClick={onFilterClick}>
      <FilterSVG width={20} height={20} />
    </div>
  </S.TableHeadingControls>
);
