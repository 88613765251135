import { useState } from 'react';
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router';
import { useSearchParams } from 'react-router-dom';

// style
import * as S from './Employee.styled';

// components
import { FunctionBar } from 'components/FunctionBar/FunctionBar';
import { ContentLayout } from 'components/ContentLayout/ContentLayout';
import { DialogLayout } from 'components/DialogLayout/DialogLayout';
import { Footer } from 'components/Footer/Footer';
import { EmployeeGeneralInformation } from './partials/EmployeeGeneralInformation/EmployeeGeneralInformation';
import { WorkplaceSettings } from './partials/WorkplaceSettings/WorkplaceSettings';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { MainLayout } from 'layouts/MainLayout/MainLayout';
import { useEmployeesGet } from 'services/employeeService';
import { useSectorsTabs } from 'hooks/useSectorsTabs';

export const Employee = () => {
  const navigate = useNavigate();
  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 960px)');
  const { employeeId } = useParams();
  const isNewEmployee = employeeId === 'new';

  const tabs = useSectorsTabs({
    ...(employeeId !== 'new' && { employeeId }),
    prepend: {
      id: '',
      name: tr['general-information'],
      route: 'general-information',
      end: true,
    },
    route:
      'workplace-settings' + (!isMobile ? '/employee-common-settings' : ''),
    prevent: isNewEmployee,
  });

  const employeeContext = useEmployeesGet({
    id: employeeId,
    queryParams: {
      retry: false,
      enabled: !isNewEmployee,
    },
    searchParams: {
      withSectorStats: 1,
    },
  });
  const { data } = employeeContext;
  const [isGoBackEnabled, setIsGoBackEnabled] = useState(true);

  let dialogTitle = '';

  if (isNewEmployee) {
    dialogTitle = tr['new-employee'];
  } else {
    dialogTitle = data?.employee?.firstName || '';

    if (data?.employee?.lastName) {
      dialogTitle += ' ' + data.employee.lastName;
    }
  }

  return (
    <Routes>
      {tabs && <Route index element={<Navigate to="general-information" />} />}

      <Route
        element={
          <ContentLayout hideFab>
            {/* {isDataLoading && <Loader />} */}
            <DialogLayout
              headerText={dialogTitle}
              getBack={() =>
                isGoBackEnabled && navigate('/functions/tables/employees')
              }
              darkTheme
              footer={<Footer content={2} />}
              Child={EmployeeWrapper}
              childProps={{
                tabs: tabs,
                employeeContext: employeeContext,
                employeeData: data,
                setIsGoBackEnabled: setIsGoBackEnabled,
              }}
            />
          </ContentLayout>
        }
      >
        <Route
          path={'general-information/*'}
          element={<EmployeeGeneralInformation />}
        />
        <Route path={'workplace-settings/*'} element={<WorkplaceSettings />} />
      </Route>
    </Routes>
  );
};

const EmployeeWrapper = ({
  tabs,
  setButtons,
  employeeData,
  setIsGoBackEnabled,
  employeeContext,
}) => {
  const [functions, setFunctions] = useState([]);
  const [searchParams] = useSearchParams();
  const sectorId = searchParams.get('sectorId');

  let sectorStatsData = [];
  if (employeeData?.sectors?.length) {
    sectorStatsData = employeeData?.sectors
      ?.filter((sector) =>
        sectorId ? sector?.id === parseInt(sectorId) : true,
      )
      ?.map((sector) => ({
        id: sector?.id,
        name: sector?.name,
        scheduleTillDay: sector?.sectorStats?.scheduleTillDayLocal,
        serviceCount: sector?.sectorStats?.serviceCount,
      }));
  }

  return (
    <MainLayout>
      <S.Employee>
        <FunctionBar tabs={tabs} functions={functions} fixed local />
        <S.Container>
          <Outlet
            context={{
              setFunctions,
              setButtons,
              employeeData,
              sectorStatsData,
              setIsGoBackEnabled,
              employeeContext,
            }}
          />
        </S.Container>
      </S.Employee>
    </MainLayout>
  );
};
