import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

import { Controls } from 'pages/Employee/partials/WorkplaceSettings/WorkplaceSettings.styled';
import { FlashMessage } from 'components/FlashMessage/FlashMessage.styled';

export { Controls };

export const Schedule = styled.div`
  display: flex;
  height: calc(100dvh - 220px);
  overflow: auto hidden;

  @media screen and (max-width: 960px) {
    flex-direction: column;
    height: revert;
    width: revert;
  }
`;

export const Section = styled.section`
  overflow: auto;

  @media screen and (max-width: 960px) {
    margin: 10px 0;
  }

  :nth-child(1) {
    border-right: 1px solid ${colors.table_borders_Primary};
    flex: 0 0 auto;
    margin-right: 40px;
    max-width: min-content;
    padding-right: 40px;
  }

  :nth-child(2) {
    flex: 1 1 auto;
    padding-bottom: 20px;
    position: relative;
  }

  &::-webkit-scrollbar:vertical {
    display: none;
  }

  /* &::-webkit-scrollbar {
    display: none;
  } */

  > div:not(:last-child) {
    padding-bottom: 40px;

    @media screen and (max-width: 960px) {
      padding: 0 10px 20px;
    }
  }

  h2,
  h3 {
    font-family: ${fontFamilies.family_Primary};
    font-weight: 600;
  }

  h2 {
    font-size: 1.875rem;
    margin: 0;
    white-space: nowrap;

    @media screen and (max-width: 960px) {
      font-size: 1.125rem;
      font-weight: 900;
    }
  }

  h3 {
    font-size: 1.125rem;
    font-weight: 900;
    margin: 0 0 20px;

    @media screen and (max-width: 960px) {
      font-size: 1rem;
    }
  }

  .section-header {
    align-items: end;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    left: 0;
    line-height: 35px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: +1;

    @media screen and (max-width: 960px) {
      margin-top: 10px;
      z-index: revert;
      background-color: revert;
    }
  }

  #schedule-header ${FlashMessage} {
    line-height: 1.15;
  }

  @media screen and (max-width: 960px) {
    :last-child .section-header {
      padding: 0 10px;
    }
  }

  .range {
    line-height: 1.39;

    @media screen and (max-width: 960px) {
      line-height: revert;
    }
  }

  button {
    white-space: nowrap;
  }

  .schedule {
    display: grid;
    grid: auto / repeat(7, 1fr);
    width: 100%;

    @media screen and (max-width: 960px) {
      grid-gap: 20px;
      margin: 0;
      padding: 10px;
    }
  }

  .schedule,
  .schedule__header,
  .schedule__data {
    /* &::-webkit-scrollbar {
      display: none;
    } */
  }

  .schedule__header,
  .schedule__data {
    display: grid;
    grid-column: 1/8;
    grid-gap: 20px;
    grid-template-columns: subgrid;
    padding: 3px 7px 3px 3px;
  }

  .schedule__header {
    background-color: white;
    left: 0;
    padding-bottom: 20px;
    position: sticky;
    top: ${({ stickTop }) => stickTop && `${stickTop}px`};
    z-index: +1;
  }

  .schedule__data {
    position: relative;
    user-select: none;
  }

  .schedule h3 {
    line-height: 20px;
    margin: 0;
  }
`;

export const SetupAndControlSection = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 75px;

  @media screen and (max-width: 960px) {
    top: 55px;
  }
`;

export const CalendarPickerSection = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: min-content;
  padding-bottom: 20px;
  position: sticky;
  top: 75px;
  z-index: +1;

  @media screen and (max-width: 960px) {
    background-color: revert;
    max-width: revert;
    top: 55px;
  }
`;

export const ControlsSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 40px;

  @media screen and (max-width: 960px) {
    padding-inline: 10px;
  }
`;

export const ConfirmDialBoxContents = styled.div`
  font-family: ${fontFamilies.family_Secondary};
  line-height: 1.25;
`;
