import styled from 'styled-components';

import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const OrderFooterCellMobile = styled.div`
  width: 100%;
  height: 40px;

  color: white;

  text-align: center;
  border-radius: 5px;
  background-color: ${(props) => props.opacityBg && 'rgba(255, 255, 255, 0.1)'};
  background-color: ${(props) => props.background && colors.bg_Button_Primary};

  .footerCell_card {
    height: 100%;

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    font-size: 1rem;
    font-family: ${fontFamilies.family_Secondary};

    padding: 5px;

    .inputWrapper {
      display: flex;
      color: white;

      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background-color: rgba(255, 255, 255, 0.1);
      height: 20px;

      font-weight: 900;
    }

    .topInput {
      border: none;
      color: white;
      outline: none;
      text-align: center;
      background: none;

      min-width: 50px;
      width: 50px;

      padding: 0;

      font-weight: 900;

      ::placeholder {
        color: white;
        font-weight: 900;
      }
    }

    .topSpan {
      border-radius: 5px;
      font-weight: 700;
      height: 20px;
      font-size: 0.875rem;
      padding: 2px 0;
    }

    .bottomSpan {
      font-size: 0.75rem;
      font-weight: ${({ background }) => (background ? 700 : 400)};
    }
  }

  @media screen and (min-width: 961px) {
    background-color: ${(props) => props.opacityBg && 'unset'};
    max-width: 135px;
    height: 60px;

    cursor: ${(props) => props.clickable && 'pointer'};
    .footerCell_card {
      .topSpan {
        font-size: 1.125rem;
        padding: 4px 0;
        height: 26px;
        background-color: ${(props) =>
          props.opacityBg && 'rgba(255, 255, 255, 0.1)'};
        display: flex;
        width: 100%;
        justify-content: center;
      }

      .bottomSpan {
        font-size: ${(props) => props.background && 0.875}rem;
      }
    }
  }
`;
