export const getMinutesBetweenDates = (startTime, endTime) => {
  return Math.ceil((endTime.getTime() - startTime.getTime()) / 60 / 1000);
};

export const minutesToHoursAndMinutes = (totalMinutes, config = {}) => {
  const { string } = config;

  if (totalMinutes === undefined) return '';

  const minutes = Math.ceil(totalMinutes % 60);
  const hours = Math.floor(totalMinutes / 60);

  return string
    ? `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`
    : [hours, minutes];
};

export const durationToMinutes = (timeString) => {
  if (!timeString) return;
  const time = timeString.split(':');
  return Number(time[0]) * 60 + Number(time[1]);
};

export const getRandomTimePeriod = (startTime) => {
  const timeStart = new Date();
  const timeEnd = new Date();

  if (startTime) {
    const lastTime = new Date();
    lastTime.setHours(23, 59);
    const duration = new Date(lastTime - timeStart);
    timeStart.setTime(startTime.getTime() + Math.random() * duration.getTime());
  } else {
    timeStart.setHours((8 + Math.random() * (12 - 8)) | 0, Math.random() * 58);
  }

  const lastTime = new Date();
  lastTime.setHours(23, 59);

  const duration = new Date(lastTime - timeStart);

  timeEnd.setTime(timeStart.getTime() + Math.random() * duration.getTime());

  return [timeStart, timeEnd];
};

export const getRandomTimePeriodOrNot = (startTime) => {
  return Math.random() > 0.2 ? getRandomTimePeriod(startTime) : undefined;
};

export const getRandomHoursOrNot = () => {
  const period1 = getRandomTimePeriodOrNot();
  const period2 = period1 ? getRandomTimePeriodOrNot(period1[1]) : undefined;

  return [
    ...(period1 ? [{ start: period1[0], end: period1[1] }] : []),
    ...(period2 ? [{ start: period2[0], end: period2[1] }] : []),
  ];
};

export const getTimeFromMinutes = (minutes) => {
  const time = minutesToHoursAndMinutes(minutes || 0);

  const padToTwoDigits = (num) => num.toString().padStart(2, '0');

  return `${padToTwoDigits(time[0])}:${padToTwoDigits(time[1])}`;
};

export const addMinutes = (date, minutes) => {
  return new Date(date?.getTime() + minutes * 60000);
};
