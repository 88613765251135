import * as S from './SVG.styled';

export default ({ color, width = 30, height = 30, bold, ...rest }) => {
  const content = bold ? (
    <>
      <path
        d="M 28.7772,7.1228925 H 1.222731 C 0.5474775,7.1228925 0,6.5754225 0,5.9001675 0,5.2249125 0.5474775,4.6774425 1.222731,4.6774425 H 28.7772 c 0.6753,0 1.2228,0.54747 1.2228,1.222725 0,0.675255 -0.5475,1.222725 -1.2228,1.222725 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="M 28.7772,16.314757 H 1.222731 C 0.5474775,16.314757 0,15.767258 0,15.092108 0,14.416808 0.5474775,13.869337 1.222731,13.869337 H 28.7772 c 0.6753,0 1.2228,0.547471 1.2228,1.222771 0,0.67515 -0.5475,1.222649 -1.2228,1.222649 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="M 17.04915,25.322557 H 1.222731 C 0.5474775,25.322557 0,24.775057 0,24.099907 c 0,-0.6753 0.5474775,-1.2228 1.222731,-1.2228 H 17.04915 c 0.6753,0 1.22265,0.5475 1.22265,1.2228 0,0.67515 -0.54735,1.222651 -1.22265,1.22265 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
    </>
  ) : (
    <>
      <path
        d="M 29.36265,6.1507575 H 0.6373425 C 0.2855295,6.1507575 0,5.8652325 0,5.5134225 c 0,-0.35181 0.2855295,-0.63735 0.6373425,-0.63735 H 29.36265 c 0.35175,0 0.63735,0.28554 0.63735,0.63735 0,0.35181 -0.2856,0.637335 -0.63735,0.637335 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="M 29.36265,15.733178 H 0.6373425 C 0.2855295,15.733178 0,15.447728 0,15.095828 0,14.744063 0.2855295,14.458537 0.6373425,14.458537 H 29.36265 c 0.35175,0 0.63735,0.285526 0.63735,0.637291 0,0.3519 -0.2856,0.63735 -0.63735,0.63735 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="M 17.13615,25.123927 H 0.6373425 C 0.2855295,25.123927 0,24.838328 0,24.486578 0,24.134678 0.2855295,23.849227 0.6373425,23.849227 H 17.13615 c 0.3519,0 0.63735,0.285451 0.63735,0.637351 0,0.35175 -0.28545,0.637349 -0.63735,0.637349 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
    </>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
