import * as S from './SVG.styled';

export default ({ color, width = 30, height = 30, bold, ...rest }) => {
  const content = bold ? (
    <>
      <path
        d="M 27.0504,4.1110875 H 2.94957 C 1.3234845,4.1110875 0,5.4345675 0,7.0606575 V 22.939462 c 0,1.626001 1.3234845,2.94945 2.94957,2.94945 H 27.0504 c 1.62615,0 2.9496,-1.32345 2.9496,-2.94945 V 7.0606575 c 0,-1.62609 -1.32345,-2.94957 -2.9496,-2.94957 z m 0.5043,18.8283745 c 0,0.2781 -0.22605,0.504301 -0.5043,0.504301 H 2.94957 c -0.278145,0 -0.50433,-0.2262 -0.50433,-0.504301 V 7.0606575 c 0,-0.278145 0.226185,-0.50433 0.50433,-0.50433 H 27.0504 c 0.27825,0 0.5043,0.226185 0.5043,0.50433 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 21.5823,16.422862 c -0.49815,-0.45555 -1.27155,-0.4218 -1.72695,0.07635 -0.4554,0.49815 -0.4218,1.27155 0.0765,1.72695 l 4.74375,4.3434 c 0.23535,0.2139 0.53175,0.32085 0.8253,0.32085 0.33,0 0.66015,-0.1344 0.90165,-0.39735 0.4554,-0.498149 0.4218,-1.27155 -0.0765,-1.726949 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 8.417805,16.422862 -4.74375,4.343251 c -0.498225,0.4554 -0.53184,1.2288 -0.076425,1.726949 0.24147,0.26295 0.571575,0.39735 0.901695,0.39735 0.293415,0 0.589905,-0.10695 0.825255,-0.32085 l 4.743765,-4.3434 c 0.498225,-0.4554 0.53184,-1.2288 0.07641,-1.72695 -0.455415,-0.49815 -1.228725,-0.5319 -1.72695,-0.07635 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 26.1642,7.7270025 c -0.4554,-0.49821 -1.2288,-0.53184 -1.72695,-0.07641 L 15.00165,16.288463 5.566095,7.6505925 c -0.49821,-0.45543 -1.27152,-0.4218 -1.726935,0.07641 -0.45543,0.49821 -0.421815,1.27152 0.07641,1.72695 l 10.260825,9.3957605 c 0.23229,0.21405 0.52878,0.321 0.825255,0.321 0.29655,0 0.59295,-0.10695 0.8253,-0.321 L 26.0877,9.4539525 c 0.4983,-0.45543 0.5319,-1.22874 0.0765,-1.72695 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
    </>
  ) : (
    <>
      <path
        d="M 2.437605,25.711012 H 27.56235 c 1.34475,0 2.43765,-1.0929 2.43765,-2.4375 V 6.7232925 c 0,-1.344675 -1.0929,-2.437605 -2.43765,-2.437605 H 2.437605 C 1.0929375,4.2856875 0,5.3786175 0,6.7232925 V 23.276662 c 0,1.3446 1.0929375,2.437651 2.437605,2.437651 z M 1.274562,6.7232925 c 0,-0.64047 0.522573,-1.16304 1.163043,-1.16304 H 27.56235 c 0.6405,0 1.1631,0.52257 1.1631,1.16304 V 23.276662 c 0,0.6405 -0.5226,1.1631 -1.1631,1.1631 H 2.437605 c -0.64047,0 -1.163043,-0.5226 -1.163043,-1.1631 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 20.5302,16.990012 c -0.2391,0.258 -0.2199,0.6627 0.03825,0.90165 l 4.9452,4.5279 c 0.12105,0.1116 0.2772,0.16575 0.4302,0.16575 0.17205,0 0.3441,-0.0702 0.4716,-0.20715 0.23895,-0.25815 0.2199,-0.6627 -0.03825,-0.9018 l -4.94535,-4.5279 c -0.2613,-0.23895 -0.6627,-0.21975 -0.90165,0.03825 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 4.050015,22.588462 c 0.152955,0 0.30909,-0.05415 0.43017,-0.16575 l 4.94529,-4.5279 c 0.258105,-0.238949 0.27723,-0.6405 0.03825,-0.901799 -0.23898,-0.258001 -0.64047,-0.2772 -0.901755,-0.0381 l -4.945305,4.527749 c -0.258105,0.2391 -0.277215,0.6405 -0.038235,0.901801 0.124275,0.1371 0.29634,0.207149 0.471585,0.207149 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 14.56839,18.541762 c 0.12108,0.111451 0.277215,0.165601 0.430155,0.165601 0.152955,0 0.309105,-0.05415 0.430155,-0.165601 L 26.1255,8.7467325 c 0.258,-0.23898 0.2772,-0.64047 0.03825,-0.901755 -0.23895,-0.261285 -0.6405,-0.277215 -0.9018,-0.038235 L 14.995365,17.206613 4.731945,7.8067425 c -0.25809,-0.2358 -0.66276,-0.21987 -0.90174,0.038235 -0.23898,0.25809 -0.21987,0.662775 0.038235,0.901755 l 10.696755,9.7950295 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
    </>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
