import * as S from './SVG.styled';

export default ({ color, width = 30, height = 30, bold, ...rest }) => {
  const content = bold ? (
    <path
      d="M 28.488429,0.148078 H 1.514458 c -0.60836749,0 -1.15317196,0.36017171 -1.39227392,0.9201075 -0.23910196,0.5569171 -0.12106787,1.2016037 0.29964621,1.6404698 L 9.7138441,12.394047 v 10.330022 c 0,0.475185 0.2270081,0.93222 0.6113859,1.213698 l 7.545624,5.614546 c 0.266346,0.199764 0.584161,0.299646 0.901958,0.299646 0.233064,0 0.46611,-0.05449 0.681005,-0.163445 0.514542,-0.257271 0.832338,-0.777869 0.832338,-1.352936 V 12.394009 L 29.578169,2.7086178 C 29.998885,2.2697517 30.116917,1.6250651 29.877815,1.068148 29.638713,0.51123098 29.093909,0.1480405 28.485542,0.1480405 Z M 18.203782,11.068355 c -0.214895,0.227008 -0.335965,0.526655 -0.335965,0.838394 V 26.531682 L 12.138332,22.267147 V 11.906749 c 0,-0.311759 -0.121068,-0.611386 -0.335965,-0.838394 L 3.645488,2.5694729 h 22.712061 z"
      strokeWidth="0.302665"
    />
  ) : (
    <path
      d="m 10.124135,23.047332 c 0,0.29647 0.14193,0.58033 0.38163,0.76011 l 7.8629,5.8506 c 0.16716,0.123 0.36586,0.18608 0.56456,0.18608 0.14508,0 0.29017,-0.03469 0.42579,-0.10093 0.3217,-0.16085 0.52041,-0.48571 0.52041,-0.84526 v -16.867 l 9.8562,-10.2760003 c 0.26178,-0.2744 0.33748,-0.6781 0.18924,-1.02500001 -0.14824,-0.35009 -0.49202,-0.57402 -0.8705,-0.57402 H 0.94636487 c -0.37848,-0.0032 -0.72226,0.22393 -0.8705,0.57087 -0.14824,0.35009001 -0.0756999954,0.75380001 0.18924,1.02820001 L 10.124405,12.030982 Z M 1.6872349,1.4143317 H 28.313235 l -9.5218,9.9256003 c -0.11354,0.1167 -0.17662,0.2744 -0.17662,0.43525 v 16.495 l -7.2321,-5.3807 v -11.115 c 0,-0.16401 -0.06308,-0.31855 -0.17662,-0.43525 z"
      strokeWidth="0.3154"
    />
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
