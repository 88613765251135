import styled, { css } from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import { BaseInput } from 'components/Inputs/BaseInput/BaseInput.styled';
import { shadow } from 'common/styles/shadows';

export const OrderRowEntry = styled.div`
  background-color: ${colors.bg_grey_1};
  height: 100%;
  // but not first
  padding-top: ${(props) => !props.compItem && '5px'};

  .content {
    align-items: center;
    background-color: white;
    display: flex;
    height: 100%;
    justify-content: ${({ headerId }) =>
      headerId === 'delete' ? 'center' : 'unset'};
    min-height: 26px;
    padding: 6px 0;
  }

  .title-column {
    align-items: center;
    display: flex;
    font-family: ${fontFamilies.family_Secondary};
    font-size: 1rem;
    height: 100%;
    margin-left: -4px;
    min-width: 250px;

    &__logo {
      display: flex;
      margin-right: 10px;
    }

    &__logo svg {
      height: 16px;
      width: 16px;
    }

    &__title {
      margin-right: 10px;
    }
  }

  .compItem {
    font-weight: 400;
  }

  .select-column {
    align-items: center;
    display: flex;
    height: 100%;
    padding: 0 2px;
  }

  .amount-column {
    align-items: center;
    display: flex;
    height: 100%;
    padding: 0 2px;
  }

  .price-column {
    align-items: center;
    color: ${colors.bg_Primary};
    display: flex;
    font-size: 14px;
    font-family: ${fontFamilies.family_Secondary};
    height: 100%;
    padding: 0 5px;
  }

  .discount-column {
  }

  .total-price-column {
    align-items: center;
    color: ${colors.bg_Primary};
    display: flex;
    font-family: ${fontFamilies.family_Secondary};
    font-size: 14px;
    font-weight: 900;
    height: 100%;
    padding: 0 5px;
  }

  .red input {
    color: ${colors.mandyRed};
  }

  .danger {
    color: ${colors.warningText};
  }

  ${BaseInput} {
    &.danger {
      .input-wrapper {
        border: 1px solid ${colors.warningText};
      }
    }

    .input-wrapper {
      border: 0;
      border-radius: 5px;
      background-color: ${colors.bg_grey_1};
      border: 1px solid ${colors.bg_grey_1};

      .clear-icon {
        display: none;
      }

      input {
        animation: unset;
        background-color: ${colors.bg_grey_1};
        border: none;
        border-radius: 5px;
        color: ${colors.bg_Primary};
        font-size: 14px;
        font-family: ${fontFamilies.family_Secondary};
        height: 28px;
        min-height: unset;
        min-width: 100px;
        outline: none;
        padding: 0 5px;
        width: 100%;

        ::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
          margin: 0;
          -webkit-appearance: none;
        }

        :disabled {
          background-color: unset;
        }

        ::placeholder {
          color: ${colors.bg_Primary};
        }

        &.danger {
          color: ${colors.warningText};
        }
      }
    }

    &.vat .input-wrapper input {
      min-width: unset;
      width: 60px;
    }
  }
`;

export const CustomSelectStyles = {
  InputField: css`
    position: relative;
    min-width: 88px;
    z-index: ${({ menuIsOpen }) => menuIsOpen && 1};
    filter: ${({ menuIsOpen }) => menuIsOpen && `drop-shadow(${shadow.lg})`};

    .select {
      .select__control {
        cursor: pointer;
        border: 1px solid transparent;
        box-shadow: none;
        background-color: ${(props) =>
          props.bright ? 'white' : colors.bg_grey_1};
        height: 30px;

        .select__value-container {
          cursor: pointer;
          padding: 0 0 0 5px;
          height: 28px;
          min-height: unset;
          font-size: 0.875rem;

          .select__placeholder {
            color: ${colors.filterGrey};
          }

          .select__input-container {
            cursor: pointer;

            input {
              cursor: pointer;
            }
          }
        }

        .select__indicators {
          padding: 0 6px 0 2px;

          .select__indicator {
            cursor: pointer;
            height: 28px;
            width: 10px;
            padding: 0;

            svg {
              width: 10px;
            }
          }
        }
      }

      &.warning .select__control {
        border: 1px solid ${colors.warningText};
      }
    }
  `,
  Menu: css`
    &.select__menu {
      background-color: ${colors.bg_grey_1};
      border: 0;

      .select__menu-list {
        padding: 0;

        .select__option {
          margin: 0;
          padding: 0 5px;
          height: 25px;
          margin-bottom: 5px;
          display: flex;
          align-items: 'center';
          border-radius: 0;
          align-items: center;

          &--is-focused {
            background-color: #fff;
            cursor: pointer;
          }

          &--is-selected {
            background-color: ${colors.bg_grey_2};
            cursor: pointer;
          }
        }
      }
    }
  `,
};
