// hydrate data objects with types
const setItemType = (data) => {
  if ('baseDurationInMinutes' in data || 'unitId' in data) {
    data.type = 'service';
  } else if ('groupId' in data) {
    data.type = 'subgroup';
    data.assignedToSectorIds = data.assignedToSectorIds || [];
  } else if ('categoryId' in data) {
    data.type = 'group';
    data.assignedToSectorIds = data.assignedToSectorIds || [];
  } else {
    data.type = 'category';
    data.assignedToSectorIds = data.assignedToSectorIds || [];
  }

  if (data.hasComp) {
    data.type = 'set';
    data.explodeOnAdd = data.explodeOnAdd || undefined;
  }

  return data;
};

// apply changes to every tree item recursively
export const updateTreeDownstream = (data, callback, mutableCache) => {
  if (data instanceof Array) {
    for (let i = 0; i < data.length; i++) {
      updateTreeDownstream(data[i], callback, mutableCache);
    }
  } else if (data instanceof Object) {
    callback(data, mutableCache);

    for (const prop in data) {
      const value = data[prop];
      if (
        !['assignedToSectorIds', 'sectors', 'composition'].includes(prop) &&
        (value instanceof Object || value instanceof Array)
      ) {
        updateTreeDownstream(value, callback, mutableCache);
      }
    }
  }

  return data;
};

export const hydrateServiceData = (data) =>
  updateTreeDownstream(data, (item) => setItemType(item));

export const isNew = (activeItem) => activeItem?.id === 'new';

export const isCurrent = (context, activeItem) =>
  activeItem &&
  context.type === activeItem.type &&
  context.id === activeItem.id &&
  'active';

export const isInactive = (context) => !context.isActive && 'inactive';

const hasChildren = (context) => {
  switch (context.type) {
    case 'category':
      return 'groups' in context && context['groups'].length;
    case 'group':
      return (
        ('subgroups' in context && context['subgroups'].length) ||
        ('services' in context && context['services'].length)
      );
    case 'subgroup':
      return 'services' in context && context['services'].length;
    default:
      return false;
  }
};

export const hasSiblingsNamedEqually = (source, treeData) => {
  const parent = getParent(source, treeData);

  switch (source.type) {
    case 'category':
      return treeData.some(
        (category) => category.id !== 'new' && category.name === source.name,
      );
    case 'group':
      return (
        'groups' in parent &&
        parent['groups'].some(
          (group) => group.id !== 'new' && group.name === source.name,
        )
      );
    case 'subgroup':
      return (
        'subgroups' in parent &&
        parent['subgroups'].some(
          (subgroup) => subgroup.id !== 'new' && subgroup.name === source.name,
        )
      );
    default:
      return false;
  }
};

// export const emptyStatus = (context) => !hasChildren(context) && 'empty';
export const emptyStatus = (context) => context.isEmpty && 'empty';

export const getItem = (data, id, type) => {
  let result = null;

  if (data instanceof Array) {
    for (let i = 0; i < data.length; i++) {
      result = getItem(data[i], id, type);

      if (result) break;
    }
  } else {
    if (data?.type === type && data?.id === id) {
      return data;
    } else {
      for (let prop in data) {
        if (data[prop] instanceof Object || data[prop] instanceof Array) {
          result = getItem(data[prop], id, type);

          if (result) break;
        }
      }
    }
  }
  return result;
};

const addValueToObject = (arr, id, type, newValue, config) => {
  if (!arr) return;

  // if current context is an array
  if (arr instanceof Array) {
    // traverse all array elements
    arr.forEach((i, index) => {
      // if required object was found
      if (i.type === type && i.id === id) {
        arr[index] = { ...i, ...newValue };
      } else {
        // else traverse 'children' arrays
        if (i.type === 'category' && 'groups' in i)
          addValueToObject(i['groups'], id, type, newValue, config);
        else if (['group', 'subgroup'].includes(i.type)) {
          if ('subgroups' in i) {
            addValueToObject(i['subgroups'], id, type, newValue, config);
          }
          if ('services' in i) {
            addValueToObject(i['services'], id, type, newValue, config);
          }
        }
      }
    });
  } else {
    // if current context is an object
    if (arr.type === type && arr.id === id) {
      // if config options were supplied
      if (config?.action === 'insert') {
        arr = { ...arr, newValue };
      } else {
        arr = { ...arr, ...newValue };
      }
      return;
    }
    // traverse remaining props of the object
    for (let prop in arr) {
      if (prop instanceof Array || prop instanceof Object) {
        addValueToObject(prop, id, type, newValue, config);
      }
    }
  }
};

export const updateTree = (id, type, newValue, item, setSource) => {
  // const tr = useTranslate().use().global;

  const isNew = newValue?.id === 'new';

  if (isNew) {
    let newItem = { ...newValue };

    switch (newValue.type) {
      case 'category':
      case 'group':
        item?.groups?.push(newItem);
        break;
      case 'subgroup':
        item?.subgroups?.push(newItem);
        break;
      case 'service':
      case 'set':
      case 'material':
        if (!('services' in item)) {
          item.services = [];
        }
        item.services.push(newItem);
        break;
      default:
    }

    if (setSource) {
      setSource((prev) => {
        const newArray = [...prev];
        addValueToObject(newArray, id, type, item, { action: 'insert' });
        return newArray;
      });
    }
  } else {
    setSource((prev) => {
      const newArray = [...prev];
      addValueToObject(newArray, id, type, newValue);
      return newArray;
    });
  }
};

export const removeObjectById = (id, type, setResource, setActiveItem) => {
  let found = false;

  const checkContents = (resource) => {
    if (found) return;

    if (resource instanceof Array) {
      for (let index = 0; index < resource.length; index++) {
        // if the search criteria is met
        const item = resource[index];

        if (item.id === id && item.type === type) {
          resource.splice(index, 1);
          found = true;
          break;
        } else {
          // else traverse 'children' arrays
          checkContents(item);
        }
      }
    } else if (resource instanceof Object) {
      if (resource.type === 'category' && 'groups' in resource)
        checkContents(resource['groups']);
      else if (resource.type === 'group') {
        if ('subgroups' in resource) {
          checkContents(resource['subgroups']);
        }
        if ('services' in resource) {
          checkContents(resource['services']);
        }
      } else if (resource.type === 'subgroup' && 'services' in resource) {
        checkContents(resource['services']);
      }
    }
  };

  setResource((old) => {
    const newResource = old instanceof Array ? [...old] : { ...old };

    checkContents(newResource);

    return newResource;
  });

  setActiveItem && setActiveItem(undefined);
};

export const getPath = (data, item, path) => {
  if (!item) return;
  const { id, type } = item;

  const currentNode = getItem(data, id, type);
  if (!currentNode) return;

  let parentType;
  let parent;

  switch (type) {
    case 'service':
    case 'set':
    case 'material':
      parentType = 'subgroupId' in currentNode ? 'subgroup' : 'group';
      break;

    case 'subgroup':
      parentType = 'group';
      break;

    case 'group':
      parentType = 'category';
      break;

    default:
      return path;
  }

  parent = getItem(data, currentNode[`${parentType}Id`], parentType);

  if (parent) {
    path = [parent.name];

    return parentType === 'category'
      ? path
      : [...getPath(data, { id: parent.id, type: parentType }, path), ...path];
  }
};

// returns direct ancestor (parent) subgroup/group/category object
export const getParent = (source, treeData) =>
  'subgroupId' in source
    ? getItem(treeData, source.subgroupId, 'subgroup')
    : 'groupId' in source
      ? getItem(treeData, source.groupId, 'group')
      : 'categoryId' in source
        ? getItem(treeData, source.categoryId, 'category')
        : undefined;

// returns direct ancestor (parent) subgroup/group/category object
export const isParentEmpty = (parent) => {
  switch (parent.type) {
    case 'subgroup':
      return !parent?.services.length;
    case 'group':
      return !(parent?.subgroups?.length || parent?.services?.length);
    case 'category':
      return !parent?.groups?.length;
    default:
  }
};

// returns array of subgroup/group/category objects which are ancestors to source service
export const getAncestors = (source, treeData) => {
  if (
    source &&
    Object.keys(source).some((prop) => {
      return ['subgroupId', 'groupId', 'categoryId'].includes(prop);
    })
  ) {
    const parent = getParent(source, treeData);
    const ancestors = getAncestors(parent, treeData);
    return [...(ancestors ? ancestors : []), ...(parent ? [parent] : [])];
  }
};

// checks if service object is a special maintainance object (id === 0)
export const isServiceItem = (object) => {
  return (
    'id' in object &&
    object.id === 0 &&
    ('categoryId' in object ? object.categoryId === 0 : true)
  );
};
