import { useContext } from 'react';

// style
import * as S from './ScheduleHeader.styled';

// components
import PendingOrdersHeader from './PendingOrdersHeader/PendingOrdersHeader';
import EmployeesHeader from './EmployeesHeader/EmployeesHeader';
import TimelineHeader from './TimelineHeader/TimelineHeader';

// contexts
import { ScheduleContext } from 'context/ScheduleContext';
import { UserContext } from 'context/UserContext';

// hooks
import useMediaQuery from 'hooks/useMediaQuery';

export const ScheduleHeader = ({ showPendingOrders, elementRef }) => {
  const { numberOfDays, personal, getBackTo, searchParams, scheduleHasData } =
    useContext(ScheduleContext);
  const { locale } = useContext(UserContext);
  const isMobile = useMediaQuery('(max-width: 960px)');
  // const navigate = useNavigate();

  // const employeeId = searchParams.get('employeeId');

  // const navigateTo = () => {
  //   const endOfNewRoute = getBackTo.split('/').at(-1);
  //   const addEmployeeId = endOfNewRoute !== 'day' && endOfNewRoute !== 'week';

  //   const params = createSearchParams({
  //     ...Object.fromEntries(
  //       new URLSearchParams({
  //         sectorId,
  //         ...(addEmployeeId && { employeeId }),
  //       }),
  //     ),
  //   }).toString();

  //   navigate(getBackTo, { searchParams: params });
  // };

  return (
    <S.ScheduleHeader
      showPendingOrders={showPendingOrders}
      ref={elementRef}
      locale={locale}
      scheduleHasData={scheduleHasData}
    >
      {/* {personal && !isMobile && (
        <div className="filler__icon" onClick={navigateTo}>
          <ArrowSVG />
        </div>
      )} */}
      <div className="container">
        <EmployeesHeader />
        {scheduleHasData && numberOfDays.visible !== 1 && <TimelineHeader />}
      </div>
      {scheduleHasData && !isMobile && (
        <PendingOrdersHeader showPendingOrders={showPendingOrders} />
      )}
    </S.ScheduleHeader>
  );
};

export default ScheduleHeader;
