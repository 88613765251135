import { useCallback, useEffect, useMemo, useState } from 'react';

// style
import * as S from './SectorSelectorItem.styled';

// components
import { Checkbox } from 'components/Checkbox/Checkbox';
import { InputFieldGroup } from '../../ServiceDetails/ServiceGeneralData/ServiceGeneralData.styled';
import { InputFieldNumber } from 'components/InputFieldNumber/InputFieldNumber';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useNumber } from 'hooks/useNumber';

export const SectorSelectorItem = ({
  data,
  updateSectorProp,
  disabled,
  isService,
  disabledByParent,
  isParentNone,
}) => {
  const tr = useTranslate().use().global;

  const { currencySymbol, currencySymbolPlacement } = useNumber({
    price: true,
    sectorId: data.id,
  });

  const currencySettings = useMemo(
    () => ({
      currencySymbol,
      currencySymbolPlacement,
    }),
    [currencySymbol, currencySymbolPlacement],
  );

  const getVatAmount = (fullPrice, vatPercent) =>
    !(
      [undefined, null].includes(fullPrice) ||
      [undefined, null].includes(vatPercent)
    )
      ? fullPrice - fullPrice / (1 + vatPercent / 100)
      : undefined;

  const formState = useCallback(
    (propId, type) => {
      return {
        type: type,
        value: ![null, undefined].includes(data[propId])
          ? {
              value: data[propId],
              label: data[propId],
            }
          : undefined,
        setValue: (headerId, value) => {
          updateSectorProp(
            data.id,
            headerId,
            headerId === 'vatPercent' && value?.value === undefined
              ? null
              : value?.value,
          );
        },
      };
    },
    [data, updateSectorProp],
  );

  // set VAT amount field
  const [vatAmount, setVatAmount] = useState();

  // update VAT amount
  useEffect(
    () =>
      setVatAmount(getVatAmount(data['unitPriceWithVat'], data['vatPercent'])),
    [data, data.unitPriceWithVat, data.vatPercent],
  );

  return (
    <S.SectorSelectorItem>
      {disabled ? (
        (!disabledByParent || isParentNone) && data.name
      ) : (
        <Checkbox
          text={data.name}
          value={{
            state: data.isAssigned,
            action: () =>
              updateSectorProp(data.id, 'isAssigned', !data.isAssigned),
          }}
          disabled={disabledByParent}
        />
      )}

      {isService &&
        (!disabledByParent ||
          (disabledByParent && !disabled) ||
          isParentNone) && (
          <InputFieldGroup disabled={disabled}>
            <InputFieldNumber
              id={`unitPriceWithVat-${data.id}`}
              headerId={'unitPriceWithVat'}
              type="currency"
              label={tr['price-with-vat']}
              state={formState('unitPriceWithVat', 'search')}
              decimalPlaces={4}
              disabled={disabled}
              {...currencySettings}
            />

            <InputFieldNumber
              id={`vatPercent-${data.id}`}
              headerId={'vatPercent'}
              type="percentage"
              label={tr['vat']}
              state={formState('vatPercent', 'search')}
              decimalPlaces={2}
              disabled={disabled}
              placeholder="-"
              // TODO: implement select component with dynamic value addition function
            />

            <InputFieldNumber
              id={`totalVat-${data.id}`}
              type="currency"
              label={tr['total-vat']}
              state={{
                type: 'search',
                value:
                  vatAmount !== undefined
                    ? { value: vatAmount, label: vatAmount }
                    : undefined,
              }}
              decimalPlaces={4}
              disabled
              {...currencySettings}
            />
          </InputFieldGroup>
        )}
    </S.SectorSelectorItem>
  );
};
