import { Fragment } from 'react';
import { useEffect, useState, useRef } from 'react';

// style
import * as S from './AppointmentCard.styled';

// components
import Badge from 'components/Badge/Badge';

// hooks
import useStatusList from 'hooks/useStatusList';
import useObserver from 'hooks/useObserver';
import { useAppointment } from 'hooks/useAppointment';
import { useTranslate } from 'hooks/useTranslate';
import classNames from 'classnames';

const AppointmentCard = ({
  id,
  displaySettings,
  displayData,
  hoverDisplay,
  timeOff,
  onClick,
}) => {
  const tr = useTranslate().use().global;
  const statusObj = useStatusList(displayData?.status?.toLowerCase());
  let { icons } = useAppointment();
  const containerRef = useRef();
  const serviceListRef = useRef();
  const iconsListRef = useRef();
  const settingsObj = {
    name: { show: true },
    lastName: { show: true },
    phone: { show: false },
    // cabinet: { show: true },
    fullName: { show: true },
    sms: { show: true },
    note: { show: true },
    serviceName: { show: true },
    birthday: { show: true },
    newClient: { show: true },
    onlineRegistration: { show: true },
  };
  const [settings, setSettings] = useState(settingsObj);
  const [displayInfo, setDisplayInfo] = useState({});
  const [showServicesEllipsis, setShowServicesEllipsis] = useState(false);
  let container = useObserver({
    ref: containerRef,
    debounceDelay: 10,
  });
  let iconContainer = useObserver({
    ref: iconsListRef,
    debounceDelay: 10,
  });

  const iconsList = [
    {
      id: 'fullName',
      className: 'full-name',
      label: displayInfo?.firstName + ' ' + displayInfo?.lastName,
      icon: (
        <Badge
          color="#fff"
          customSizes={{
            size: 15,
            contentSize: 10,
          }}
          text={displayInfo?.firstName?.[0] + displayInfo?.lastName?.[0]}
        />
      ),
    },
    ...icons,
  ];

  useEffect(() => {
    if (!displaySettings) return;
    setSettings((prev) => {
      const newObj = { ...prev };
      displaySettings.forEach((item) => {
        if (!item.id) return;
        newObj[item?.id].show = item.value;
      });

      return newObj;
    });
  }, [displaySettings]);

  useEffect(() => {
    const displayInfoObj = {
      firstName: displayData?.firstName || tr['firstName'],
      lastName: displayData?.lastName || tr['lastName'],
      services: structuredClone(displayData?.services) || [
        { serviceName: tr['service-name'] },
        // { serviceName: tr['service-name'] },
      ],
    };

    const availableSpace = container?.height + 6 - 39;
    const serviceLength = displayInfoObj.services.length;

    if (serviceLength < availableSpace) {
      const availableLength =
        serviceLength < Math.floor(availableSpace / 12.65)
          ? serviceLength
          : Math.floor(availableSpace / 12.65);

      const newLength =
        serviceLength === availableLength
          ? availableLength
          : availableLength - 1;

      displayInfoObj.services.length = newLength >= 0 ? newLength : 0;

      setShowServicesEllipsis(serviceLength !== availableLength);
    } else if (!availableSpace || availableSpace < 0) {
      displayInfoObj.services.length = 0;
    }

    setDisplayInfo(displayInfoObj);
  }, [displayData, container]);

  useEffect(() => {
    if (!displayInfo.services || timeOff) return;
    if (!serviceListRef.current.getBoundingClientRect().height) return;
  }, [container.height]);

  const checkSettingsForIcon = ({ iconId, icon }) => {
    if (!settings[iconId].show) return;

    return <Fragment key={iconId}>{icon}</Fragment>;
  };

  const getServiceHeight = () => {
    const max = container?.height + 6 - 39;
    const content = displayInfo?.services?.length * 12.65;

    return max > content
      ? showServicesEllipsis
        ? content + 12.65
        : content
      : max;
  };

  const orderType = () => (
    <>
      <div className="service-card__content">
        {(settings.name.show || settings.lastName.show) && (
          <div className="service-card__name-wrapper">
            <div className="service-card__name">
              {settings.name.show && <span>{displayInfo?.firstName}</span>}{' '}
              {settings.lastName.show && <span>{displayInfo?.lastName}</span>}
            </div>
          </div>
        )}

        <div className="service-card__list-wrapper" ref={serviceListRef}>
          {settings.serviceName.show && (
            <ul className="service-card__list">
              {displayInfo?.services &&
                displayInfo.services.map((item, i) => (
                  <li key={i}>{item.serviceName}</li>
                ))}
              {showServicesEllipsis && <li>...</li>}
            </ul>
          )}
        </div>
        {settings.phone.show && (
          <div className="service-card__phone-wrapper">
            <span>+370 00 00 000</span>
          </div>
        )}
      </div>
      <div className="service-card__icons-list" ref={iconsListRef}>
        {iconsList.map(({ id, className, label, icon }, i) => (
          <div
            className={classNames('icon-wrapper', className)}
            key={i}
            data-tooltip-id="tooltip"
            data-tooltip-html={label}
          >
            {checkSettingsForIcon({ iconId: id, icon })}
          </div>
        ))}
      </div>
    </>
  );

  const timeOffType = () => (
    <S.TimeOffContainer>
      <span>{timeOff.title}</span>
      <span>{timeOff.duration}</span>
    </S.TimeOffContainer>
  );

  return (
    <S.AppointmentCard
      id={`order-${id}`}
      hoverDisplay={hoverDisplay}
      statusColor={statusObj?.background}
      containerHeight={container.height + 6}
      serviceListHeight={getServiceHeight()}
      ref={containerRef}
      onClick={onClick}
      timeOff={timeOff}
      width={container.width}
      iconContainerHeight={iconContainer.height || 0}
      isOneCellSize={container.height <= 20}
    >
      {timeOff ? timeOffType() : orderType()}
    </S.AppointmentCard>
  );
};

export default AppointmentCard;
