import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { pinEmployee } from '../../../redux/actions/calendarViewActions';

// style
import * as S from './WorkerFunctions.styled';

// icons
import { Calendar, PinSVG } from 'common/icons';

// components
import Selection from '../Selection/Selection';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { colors } from 'common/colors/colors';

export const WorkerFunctions = ({ id, prioritize }) => {
  // Hooks
  // -- translation
  const tr = useTranslate().use().global;

  // -- state
  // --- global
  const professionals = [];
  const dispatch = useDispatch();

  let navigate = useNavigate();

  return (
    <S.FunctionContainer>
      <Selection
        svg={<PinSVG color={colors.bg_Primary} />}
        text={
          prioritize ? tr.uncoverPinFromTheList : tr.pinAtTheBeginningOfTheList
        }
        action={() => {
          dispatch(pinEmployee(id));
        }}
      />
      <Selection
        svg={<Calendar color={colors.bg_Primary} width={16} height={16} />}
        text={tr.individualCalendar}
        action={(e) => {
          navigate(`/functions/daily-functions/calendar/personal/${id}`);
        }}
      />
    </S.FunctionContainer>
  );
};

export default WorkerFunctions;
