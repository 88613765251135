import { useContext, useEffect, useState } from 'react';

// contexts
import { OrderContext } from 'context/OrderContext';

// hooks
import { useFocus } from './useFocus';
import { useOrderNotePatch } from 'services/orderService';

export const useOrderNoteStatus = () => {
  const { order, isNewOrder, orderId } = useContext(OrderContext);
  const { mutate, isSuccess, isError } = useOrderNotePatch();
  const [inputRef, focusInput] = useFocus();
  const [isEditingNote, setIsEditingNote] = useState(false);
  const [isNoteDisabled, setNoteDisabled] = useState(false);
  const [inputValue, setInputValue] = useState(order?.state?.note || '');
  const [dbInputValue, setDbInputValue] = useState(order?.state?.note || '');

  useEffect(() => {
    setInputValue(order?.state?.note || '');
  }, [order?.state?.note]);

  useEffect(() => {
    if (isSuccess) {
      setDbInputValue(inputValue);
      order.setState((prev) => ({
        ...prev,
        note: inputValue.trim().length ? inputValue : null,
      }));
    }

    if (isError) {
      setIsEditingNote(true);
      focusInput();
    }

    if (isSuccess || isError) {
      setNoteDisabled(false);
    }
  }, [isSuccess, isError]);

  const handleClickAway = (e) => {
    if (isEditingNote) {
      order.setState((prev) => ({
        ...prev,
        note: inputValue.trim().length ? inputValue : null,
      }));

      if (isNewOrder || e.target.closest('button')?.id === 'save') {
        setIsEditingNote(false);
      } else {
        handleSubmit();
      }
    }
  };

  const handleReset = () => {
    order.setState((prev) => ({
      ...prev,
      note: dbInputValue,
    }));
    setInputValue(dbInputValue);
    setIsEditingNote(false);
  };

  const handleSubmit = () => {
    mutate({
      id: orderId,
      note: inputValue,
    });

    setNoteDisabled(true);
    setIsEditingNote(false);
  };

  return {
    inputRef,
    isNoteDisabled,
    isEditingNote,
    inputValue,
    setInputValue,
    setIsEditingNote,
    handleClickAway,
    handleSubmit,
    handleReset,
  };
};
