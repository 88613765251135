import { useEffect, useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useOutletContext,
  useParams,
} from 'react-router';
import { NavLink, useSearchParams } from 'react-router-dom';

// style
import * as S from './WorkplaceSettings.styled';

// icons
import { Arrow } from 'common/icons';

// components
import { TabTemplate } from 'pages/Settings/TabTemplate';
import { Common } from 'pages/Employee/partials/WorkplaceSettings/Common/Common';
import { Services } from 'pages/Employee/partials/WorkplaceSettings/Services/Services';
import { Schedule } from 'pages/Employee/partials/WorkplaceSettings/Schedule/Schedule';

// hooks and helpers
import { useTranslate } from 'hooks/useTranslate';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useFunctionBar } from 'hooks/useFunctionBar';

export const WorkplaceSettings = () => {
  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 960px)');

  // receive props through outlet context
  const { setFunctions, state, setValue } = useOutletContext();

  // setup foreign functions
  const functionBarFunctions = useFunctionBar([]);
  useEffect(() => setFunctions(functionBarFunctions), []);

  const location = useLocation();
  let [searchParams] = useSearchParams();
  const [sectorId, setSectorId] = useState(searchParams.get('sectorId'));

  useEffect(() => {
    setSectorId(searchParams.get('sectorId'));
  }, [searchParams]);

  // const parseString = location.pathname
  //   .replace(process.env.REACT_APP_APP_ROOT + '/', '')
  //   .split('/');

  // console.log({ parseString });

  // const currTab = parseString[parseString.length - 1];

  // console.log({ currTab });

  // tab array
  let tabs = [
    {
      id: 'employee-common-settings',
      name: tr['common-settings'],
      route: 'employee-common-settings',
      view: Common,
    },
    {
      id: 'employee-services',
      name: tr['services'],
      route: 'employee-services',
      view: Services,
    },
    {
      id: 'employee-schedule',
      name: tr['schedule'],
      route: 'employee-schedule',
      view: Schedule,
    },
  ];

  // tabs = undefined;

  const params = useParams();
  // const { branchId } = useParams();

  // console.log({ routeParams: params });

  return (
    <S.WorkplaceSettings>
      <S.Container>
        {((tabs && !isMobile) || (tabs && isMobile && !location.state)) && (
          <S.Links>
            {tabs.map((tab, key) => (
              <NavLink
                to={`${tab.route}?sectorId=${sectorId}`}
                key={key}
                state={tab.id}
                className="link"
              >
                {tab.name}
                {isMobile && (
                  <Arrow black width={16} height={16} className="chevron" />
                )}
              </NavLink>
            ))}
          </S.Links>
        )}

        {tabs && (
          <Routes>
            {!isMobile && tabs && (
              <Route
                index
                element={<Navigate to={tabs[0].route} state={tabs[0].id} />}
              />
            )}
            {tabs.map((tab, key) => (
              <Route
                key={key}
                path={tab.route}
                element={
                  <TabTemplate
                    name={tab.name}
                    view={tab.view}
                    renderInParent
                    state={state}
                    setValue={setValue}
                  />
                }
              />
            ))}
          </Routes>
        )}
      </S.Container>
    </S.WorkplaceSettings>
  );
};

export default WorkplaceSettings;
