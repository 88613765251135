import * as S from './SVG.styled';

export default ({ color, width = 30, height = 30, bold, ...rest }) => {
  const content = bold ? (
    <path
      d="M 26.3745,0 H 8.210685 C 7.03074,0 6.07089,0.959856 6.07089,2.13981 V 4.58529 H 3.625395 C 2.44545,4.58529 1.4856,5.54514 1.4856,6.725085 V 27.86025 C 1.4856,29.04015 2.44545,30 3.625395,30 H 21.7893 c 1.1799,0 2.13975,-0.95985 2.13975,-2.13975 V 6.725085 C 23.92905,5.54514 22.9692,4.58529 21.7893,4.58529 H 8.51637 V 2.445495 H 26.0688 V 23.2749 c 0,0.6756 0.5472,1.2228 1.2228,1.2228 0.6756,0 1.2228,-0.5472 1.2228,-1.2228 V 2.13981 C 28.5144,0.959856 27.55455,0 26.3745,0 Z M 21.4836,27.55455 H 3.93108 V 7.03077 H 21.4836 Z"
      fill="currentcolor"
      strokeWidth="1.5"
    />
  ) : (
    <path
      d="M 26.8578,0 H 7.922235 C 7.042695,0 6.32886,0.7138305 6.32886,1.593375 V 4.78011 H 3.142125 c -0.87954,0 -1.593375,0.713835 -1.593375,1.593375 V 28.4067 C 1.54875,29.28615 2.262585,30 3.142125,30 H 22.07775 c 0.8796,0 1.5933,-0.71385 1.5933,-1.5933 V 6.373485 c 0,-0.87954 -0.7137,-1.593375 -1.5933,-1.593375 H 7.60356 V 1.593375 c 0,-0.175275 0.1434,-0.318678 0.318675,-0.318678 H 26.8578 c 0.17535,0 0.31875,0.143403 0.31875,0.318678 V 23.6265 c 0,0.35055 0.2868,0.63735 0.63735,0.63735 0.35055,0 0.63735,-0.2868 0.63735,-0.63735 V 1.593375 C 28.45125,0.7138305 27.7374,0 26.8578,0 Z M 22.39635,6.373485 V 28.4067 c 0,0.1752 -0.1434,0.3186 -0.3186,0.3186 H 3.142125 C 2.96685,28.7253 2.82345,28.5819 2.82345,28.4067 V 6.373485 c 0,-0.175275 0.1434,-0.318675 0.318675,-0.318675 H 6.96303 22.07145 c 0.1752,0 0.3186,0.1434 0.3186,0.318675 z"
      fill="currentcolor"
      strokeWidth="1.5"
    />
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
