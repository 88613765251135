import { useEffect } from 'react';

// style
import * as S from './LoginDataSettings.styled';

// icons
import { Check } from 'common/icons';

// images
import { ServeProLogo } from 'common/images';

// components
import { TheButton } from 'components/Buttons/TheButton';
import { InputField } from 'components/InputField/InputField';
import InputFieldPhone from 'components/InputFieldPhone/InputFieldPhone';

// hooks
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useTranslate } from 'hooks/useTranslate';

export const LoginDataSettings = ({ setButtons }) => {
  const isMobile = useMediaQuery('(max-width: 960px)');
  const tr = useTranslate().use().global;

  // setup/cleanup header buttons
  useEffect(() => {
    setButtons &&
      setButtons({
        type: 'setup',
        value: [
          {
            id: 'save',
            icon: <Check bold />,
            action: () => console.log('Settings saved'),
            borderless: true,
            show: true,
          },
        ],
      });

    return () => setButtons && setButtons({ type: 'cleanup' });
  }, [setButtons]);

  return (
    <S.LoginDataSettings>
      <form className="login__logo-form">
        <div className="login__logo-container">
          <ServeProLogo color="#000" singleColor />
        </div>

        <div className="login__logo-add-button">
          <TheButton
            title="Įkelti savo logotipą"
            secondary
            action={() => console.log('Įkelti savo logotipą')}
          />
        </div>
      </form>

      <form className="login__branch-form">
        <div className="login__input-row">
          <InputField label={'Įmonės pavadinimas'} state={{ type: 'select' }} />
        </div>

        <div className="login__input-row">
          <InputField
            label={'Įstaigos pavadinimas (vykdoma veikla)'}
            state={{ type: 'select' }}
          />
        </div>

        <div className="login__input-row">
          <InputField label={'Direktorius)'} state={{ type: 'select' }} />
        </div>

        <div className="login__input-row double">
          <InputField label={'Įmonės kodas'} state={{ type: 'select' }} />
          <InputField label={'PVM kodas'} state={{ type: 'select' }} />
        </div>

        <div className="login__input-row double">
          <InputField label={'Šalis'} state={{ type: 'select' }} />
          <InputField label={'Miestas'} state={{ type: 'select' }} />
        </div>

        <div className="login__input-row double">
          <InputField label={'Gatvė'} state={{ type: 'select' }} />

          <div className="login__inner-multi-inputs">
            <InputField label={'Namo nr.'} state={{ type: 'select' }} />
            <InputField label={'Buto nr.'} state={{ type: 'select' }} />
          </div>
        </div>

        <div className="login__input-row double">
          {/* TODO: might crash - needs useFormState method */}
          <InputFieldPhone label={'Telefono numeris'} />
          <InputField label={'El. pašto adresas'} state={{ type: 'select' }} />
        </div>

        <div className="login__input-row double">
          <InputField label={'Banko pavadinimas'} state={{ type: 'select' }} />
          <InputField label={'Sąskaitos numeris'} state={{ type: 'select' }} />
        </div>
      </form>
    </S.LoginDataSettings>
  );
};

export default LoginDataSettings;
