import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const SetService = styled.div`
  align-items: center;
  background-color: ${colors.bg_Secondary};
  border: 1px solid ${colors.bg_grey_2};
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  font-family: ${fontFamilies.family_Secondary};
  padding: 10px 20px;
  gap: 10px;

  @media (max-width: 960px) {
    padding-inline: 10px;
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  > button > svg {
    background-color: ${colors.bg_Secondary};
    cursor: pointer;
    height: 20px;
    flex-shrink: 0;
    width: 20px;
  }

  .info__title {
    font-size: 1rem;
    display: flex;
    gap: 10px;
  }

  .info__title .name {
    /* font-weight: bold; */
  }

  .info__title svg {
    flex-shrink: 0;
    height: 16px;
    width: 16px;
  }
`;
