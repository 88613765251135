import { useEffect } from 'react';

// style
import * as S from './OnlineReservationsSettings.styled';

// icons
import { Check } from 'common/icons';

// hooks
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useTranslate } from 'hooks/useTranslate';

export const OnlineReservationsSettings = ({ setButtons }) => {
  const isMobile = useMediaQuery('(max-width: 960px)');
  const tr = useTranslate().use().global;

  // setup/cleanup header buttons
  useEffect(() => {
    setButtons &&
      setButtons({
        type: 'setup',
        value: [
          {
            id: 'save',
            icon: <Check bold />,
            action: () => console.log('Settings saved'),
            borderless: true,
            show: true,
          },
        ],
      });

    return () => setButtons && setButtons({ type: 'cleanup' });
  }, [setButtons]);

  return (
    <S.OnlineReservationsSettings>
      Online reservations settings contents
    </S.OnlineReservationsSettings>
  );
};

export default OnlineReservationsSettings;
