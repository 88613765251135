import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import TheButton from 'components/Buttons/TheButton';

export const FunctionWidget = styled.div``;

export const FunctionList = styled.div`
  display: flex;

  @media (max-width: 960px) {
    flex-direction: column;
    left: 50%;
    margin-left: unset;
    min-width: 85%;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);

    :not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

export const FloatingActionButton = styled(TheButton)`
  border: 1px solid ${colors.bg_grey_2};
  bottom: ${(props) => props.marginY}px;
  height: 50px;
  padding: 0;
  position: fixed;
  right: 20px;
  width: 50px;
  z-index: ${(props) => (props.isVisible ? 1001 : 4)};

  svg {
    transform: rotate(90deg);
    /* width: auto;
    height: ${({ isVisible }) => (isVisible ? 15 : 20)}px; */
  }

  &.plus {
    border: none;
    right: 80px;
    z-index: 4;
  }
`;
