import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';

export const ServiceStats = styled.div`
  color: ${colors.bg_grey_3};
  display: flex;
  flex-wrap: wrap;
  font-size: 0.75rem;
  gap: 20px;

  @media (max-width: 960px) {
    gap: 10px;
  }

  span {
    display: flex;
    flex-wrap: nowrap;
    gap: 5px;
    white-space: nowrap;
  }

  span > span {
    line-height: 1;
    margin-top:1px;
  }
`;
