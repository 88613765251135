import { Fragment, useCallback } from 'react';
import classNames from 'classnames';

// style
import * as S from './ServiceCategories.styled';

// components
import { BaseCheckbox } from 'components/Inputs';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { isServiceItem } from 'common/helpers/serviceFunctions';

export const ServiceCategories = ({
  categories,
  getItem,
  toggleActivity,
  activeGroup,
  setActiveGroup,
  isDisabledByParent,
}) => {
  const tr = useTranslate().use().global;

  const isCurrent = useCallback(
    (object) => {
      return (
        'categoryId' in object &&
        activeGroup &&
        object.id === activeGroup.id &&
        object.categoryId === activeGroup.categoryId
      );
    },
    [activeGroup],
  );

  return (
    <S.ServiceCategories>
      {categories?.map((category) => {
        return (
          <Fragment key={category.id}>
            <S.Category
              className="category"
              isDisabledByParent={isDisabledByParent('category', category.id)}
            >
              {isServiceItem(category) ? (
                <label>{tr['unassigned-to-category']}</label>
              ) : (
                <BaseCheckbox
                  label={category.name}
                  value={'isEmpAssigned' in category && category.isEmpAssigned}
                  onClick={() => toggleActivity('category', category.id)}
                  excludeLabel
                />
              )}
            </S.Category>

            {category.groups?.map((group) => {
              return (
                group.categoryId === category.id && (
                  <S.Group
                    className={classNames(
                      'group',
                      isCurrent(group) && 'active',
                    )}
                    key={group.id}
                    onClick={() =>
                      setActiveGroup({
                        categoryId: group.categoryId,
                        id: group.id,
                      })
                    }
                    isDisabledByParent={isDisabledByParent('group', group.id)}
                    isServiceItem={isServiceItem(group)}
                  >
                    <BaseCheckbox
                      label={
                        isServiceItem(group) ? tr['ungrouped'] : group.name
                      }
                      value={'isEmpAssigned' in group && group.isEmpAssigned}
                      onClick={() => toggleActivity('group', group.id)}
                      excludeLabel
                    />
                  </S.Group>
                )
              );
            })}
          </Fragment>
        );
      })}
    </S.ServiceCategories>
  );
};

export default ServiceCategories;
