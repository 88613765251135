import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

// style
import * as S from './Schedule.styled';

// icons
import { ArrowSVG, Check, TrashCan } from 'common/icons';

// components
import { ScheduleWorkbench } from './partials/ScheduleWorkbench/ScheduleWorkbench';
import { ScheduleTemplate } from './partials/ScheduleTemplate/ScheduleTemplate';
import { TheButton } from 'components/Buttons/TheButton';
import { SchedulerCalendarDouble } from 'components/Calendar/SchedulerCalendarDouble/SchedulerCalendarDouble';
import { Overlay } from 'components/Overlay/Overlay';
import { DialogBox } from 'components/DialogBox/DialogBox';
import { ScheduleTemplateCreationDialog } from './partials/ScheduleTemplateCreationDialog/ScheduleTemplateCreationDialog';
import { UnsavedChangesDialogBox } from 'components/UnsavedChangesDialogBox/UnsavedChangesDialogBox';
import { Loader } from 'components/Loader/Loader';

// contexts
import { UserContext } from 'context/UserContext';

// hooks
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useResizeObserver } from 'hooks/useResizeObserver';
import { useTranslate } from 'hooks/useTranslate';
import { useScrollIntoView } from 'hooks/useScrollIntoView';
import { useFormState } from 'hooks/useFormState';
import { useToggler } from 'hooks/useToggler';
import { toSentenceCase } from 'common/helpers/stringOperations';
import { useOverflowShading } from 'hooks/useOverflowShading';
import { useCacheManagement } from 'hooks/employee-schedule/useCacheManagement';
import { useChangesStatus } from 'hooks/useChangesStatus';
import { useScheduleHelpers } from 'hooks/employee-schedule/useScheduleHelpers';
import {
  useEmployeesDeleteSchedule,
  useEmployeesGetScheduleById,
  useEmployeesModifySchedule,
} from 'services/employeeService';
import {
  applyEndTime,
  applyStartTime,
  getCurrentMonthPeriodDates,
} from 'common/helpers/dateOperations';

export const Schedule = ({ title }) => {
  const { locale } = useContext(UserContext);
  const isMobile = useMediaQuery('(max-width: 960px)');
  const tr = useTranslate().use().global;
  const navigate = useNavigate();

  // get search params
  const { employeeId } = useParams();
  const [searchParams] = useSearchParams();
  const sectorId = searchParams.get('sectorId'); // current sector tab

  const { getFirstDayOfWeek, getFullPeriodDates, getFullPeriodDatesForAPI } =
    useScheduleHelpers();

  // refetch schedule data after sectors' data was processed and firstDayOfWeek extracted
  // useEffect(() => {
  //   sectorId && getFirstDayOfWeek({ sectorId }) && setShouldRefetch(true);
  // }, [getFirstDayOfWeek, sectorId]);

  // retrieve control button's handler
  const { setButtons, setIsGoBackEnabled } = useOutletContext();

  // track dynamic dimensions of flex'ible header
  const scheduleHeaderDimensions = useResizeObserver('schedule-header');

  // track dynamic dimensions of flex'ible calendar picker
  const calendarPickerDimensions = useResizeObserver('calendar-picker');

  // configure required fields
  const fieldsSetup = [
    {
      id: 'date-from',
      level: 1,
      type: 'calendar',
      options: [],
    },
    {
      id: 'date-to',
      level: 1,
      type: 'calendar',
      options: [],
    },
    {
      id: 'repetition',
      level: 1,
      type: 'switch',
      options: [
        { value: 'daily', label: tr['daily'] },
        { value: 'weekly', label: tr['weekly'] },
        { value: 'continuous', label: tr['continuous'] },
      ],
    },
    {
      id: 'repetition-daily',
      level: 1,
      type: 'switch',
      options: [
        { value: 'daily', label: tr['daily-detailed'] },
        { value: 'every-second-day', label: tr['every-second-day'] },
        { value: 'even-days', label: tr['even-days'] },
        { value: 'odd-days', label: tr['odd-days'] },
      ],
      default: { value: 'daily', label: tr['daily-detailed'] },
    },
    {
      id: 'repetition-weekly',
      level: 1,
      type: 'switch',
      options: [
        { value: 'weekly', label: tr['weekly-detailed'] },
        { value: 'every-second-week', label: tr['every-second-week'] },
        { value: 'even-weeks', label: tr['even-weeks'] },
        { value: 'odd-weeks', label: tr['odd-weeks'] },
      ],
      default: { value: 'weekly', label: tr['weekly-detailed'] },
    },
    {
      id: 'apply-workdays',
      level: 1,
      type: 'switch',
      options: [
        { value: true, label: tr['apply-workdays'] },
        { value: false, label: '' },
      ],
      default: { value: true, label: tr['apply-workdays'] },
    },
    {
      id: 'apply-weekends',
      level: 1,
      type: 'switch',
      options: [
        { value: true, label: tr['apply-weekends'] },
        { value: false, label: '' },
      ],
      default: { value: true, label: tr['apply-weekends'] },
    },
    {
      id: 'apply-holidays',
      level: 1,
      type: 'switch',
      options: [
        { value: true, label: tr['apply-holidays'] },
        { value: false, label: '' },
      ],
      default: { value: false, label: '' },
    },
    {
      id: 'office-number',
      level: 1,
      type: 'switch',
      options: [
        { value: undefined, label: '-' },
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '215', label: '215' },
        { value: '308', label: '308' },
      ],
      default: undefined,
    },
    {
      id: 'schedule-template',
      level: 1,
      type: 'object',
      template: [
        {
          hours0: undefined,
          hours1: undefined,
          hours2: undefined,
          hours3: undefined,
          office: undefined,
        },
      ],
    },
  ];

  const fieldsForEdit = (state) =>
    fieldsSetup
      .filter((field) =>
        [
          ...(templateType === 'continuous'
            ? []
            : [`repetition-${templateType}`]),
          'apply-workdays',
          'apply-weekends',
          'apply-holidays',
          'office-number',
          'schedule-template',
        ].includes(field.id),
      )
      .map((field) => ({ ...field, default: state[field.id].value }));

  // create form state
  const { options, state, setValue, resetFields, clearFields } =
    useFormState(fieldsSetup);

  const {
    setCurrentDatabaseArray,
    setDateRange,
    cacheArray: cache,
    liveArray: period,
    maintainCache: applyDayChanges,
    hasHourChanged,
    hasAnyChanges,
    getCacheRange,
    clearCache,
    clearTotalDatabase,
    processCacheForBackend,
    applyTemplateToRange,
    getLiveArraySlice,
    clearRange,
    hasValuesInRange,
    hasDateValues,
    hasDateErrors,
    hasAnyErrors,
    isDateVisible,
    setUpdateCurrent,
  } = useCacheManagement();

  // trigger for fetching B/E
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const [fullPeriodDatesForAPI, setFullPeriodDatesForAPI] = useState();

  useEffect(() => {
    const newFullPeriodDatesForAPI = getFullPeriodDatesForAPI(
      state?.['date-from']?.value?.value,
      state?.['date-to']?.value?.value,
      { sectorId },
    );

    const oldStartDate = fullPeriodDatesForAPI
      ? applyStartTime(new Date(fullPeriodDatesForAPI.start))
      : undefined;
    const oldEndDate = fullPeriodDatesForAPI
      ? applyStartTime(new Date(fullPeriodDatesForAPI.end))
      : undefined;
    const newStartDate = newFullPeriodDatesForAPI
      ? applyStartTime(new Date(newFullPeriodDatesForAPI.start))
      : undefined;
    const newEndDate = newFullPeriodDatesForAPI
      ? applyStartTime(new Date(newFullPeriodDatesForAPI.end))
      : undefined;
    const selectedStartDate = state['date-from']?.value
      ? applyStartTime(new Date(state['date-from'].value?.value))
      : undefined;
    const selectedEndDate = state['date-to']?.value
      ? applyStartTime(new Date(state['date-to'].value?.value))
      : undefined;

    if (
      getFirstDayOfWeek({ sectorId }) &&
      ((!fullPeriodDatesForAPI &&
        newFullPeriodDatesForAPI.start &&
        newFullPeriodDatesForAPI.end) ||
        ((selectedStartDate < oldStartDate || selectedEndDate > oldEndDate) &&
          shouldRefetch))
    ) {
      setFullPeriodDatesForAPI(newFullPeriodDatesForAPI);
      (selectedStartDate < oldStartDate || selectedEndDate > oldEndDate) &&
        shouldRefetch &&
        setUpdateCurrent(true);
    }
  }, [
    state,
    getFullPeriodDatesForAPI,
    sectorId,
    shouldRefetch,
    fullPeriodDatesForAPI,
    getFirstDayOfWeek,
    setUpdateCurrent,
  ]);

  // data fetch (GET) context
  const resourceContext = useEmployeesGetScheduleById({
    queryParams: {
      enabled: !!fullPeriodDatesForAPI?.start && !!fullPeriodDatesForAPI?.end,
      retry: false,
      // staleTime: 1000 * 60 * 60,
    },
    searchParams: {
      sectorId,
      employeeId,
      startDay: fullPeriodDatesForAPI?.start,
      endDay: fullPeriodDatesForAPI?.end,
    },
  });

  // destructure fetch context
  const {
    data,
    refetch,
    isFetching: isDbFetching,
    isFetched: isDbFetched,
    isError: isDbError,
    isSuccess: isDbSuccess,
  } = resourceContext;

  // data update (POST) context
  const mutationContext = useEmployeesModifySchedule();

  // destructure update context
  const {
    mutate: saveSchedule,
    isSuccess: mutationSuccess,
    isError: mutationError,
  } = mutationContext;

  // data removal (DELETE) context
  const removalContext = useEmployeesDeleteSchedule();

  // destructure removal context
  const { mutate: clearSchedule, isLoading: isRemovalLoading } = removalContext;

  // create selected template type state
  const [templateType, setTemplateType] = useState();

  const [valueInFocus, setValueInFocus] = useState('date-from');

  // required for view change control in SchedulerCalendarDouble
  const [activeStartDays, setActiveStartDays] = useState({
    start: applyStartTime(new Date()), // remove time
    end: applyStartTime(new Date()), // remove time
  });

  useEffect(() => {
    !!fullPeriodDatesForAPI &&
      setDateRange &&
      setDateRange({
        startDate: applyStartTime(new Date(fullPeriodDatesForAPI.start)),
        endDate: applyStartTime(new Date(fullPeriodDatesForAPI.end)),
      });
  }, [fullPeriodDatesForAPI, setDateRange, shouldRefetch]);

  useEffect(() => {
    isDbFetched && setCurrentDatabaseArray(data); // unpack the goods from B/E
    isDbFetched && setUpdateCurrent(true);
    shouldRefetch && setShouldRefetch(false); // always 'switch off the light' after fetching B/E
  }, [
    isDbFetched,
    data,
    setCurrentDatabaseArray,
    shouldRefetch,
    setUpdateCurrent,
  ]);

  // start/end day focus handlers
  const { ref: startRef, scroll: scrollStart } = useScrollIntoView();
  const { ref: endRef, scroll: scrollEnd } = useScrollIntoView();

  // scroll to currently focused values
  useEffect(() => {
    if (period && !isMobile) {
      if (valueInFocus === 'date-from' && startRef.current) {
        scrollStart();
        setValueInFocus(undefined);
      } else if (
        valueInFocus === 'date-to' &&
        (endRef.current || startRef.current)
      ) {
        scrollEnd();
        setValueInFocus(undefined);
      }
    }
  }, [
    period,
    isMobile,
    valueInFocus,
    startRef,
    endRef,
    scrollStart,
    scrollEnd,
  ]);

  // shift view to the erroneous date object if currently not visible
  const shiftViewTo = useCallback(
    (date) => {
      if (!isDateVisible(date)) {
        // get required date range...
        const requiredRange = getFullPeriodDates(date, date);
        // ...and set it
        setDateRange({
          startDate: applyStartTime(requiredRange.start),
          endDate: applyStartTime(requiredRange.end),
        });
      }
    },
    [isDateVisible, setDateRange],
  );

  // reset to default from/to values
  const resetToDefault = useCallback(
    (config = {}) => {
      const { refetch = false } = config;
      // e?.stopPropagation();

      const currentMonthPeriodDates = getCurrentMonthPeriodDates();

      shiftViewTo(currentMonthPeriodDates.start);

      setValue('date-from', {
        value: currentMonthPeriodDates?.start,
        label: currentMonthPeriodDates?.start?.toLocaleDateString(locale),
      });
      setValue('date-to', {
        value: currentMonthPeriodDates?.end,
        label: currentMonthPeriodDates?.end?.toLocaleDateString(locale),
      });
      setActiveStartDays({
        start: applyStartTime(new Date()), // remove time
        end: applyStartTime(new Date()), // remove time
      });
      refetch && setShouldRefetch(true); // only refetch if specified in config
      setValueInFocus('date-from'); // scroll to start date
    },
    [locale, setValue, shiftViewTo],
  );

  // // set default from/to values on page load
  // useEffect(() => {
  //   setTimeout(() => resetToDefault(), 1000); // messes up period if no timeout. Pending FIXME:
  //   // isDbSuccess && resetToDefault();
  // }, []);
  // // }, [resetToDefault, isDbSuccess]);

  const saveChanges = useCallback(() => {
    console.log('save changes start');

    const formatDate = (date) =>
      date?.toLocaleDateString('lt-LT', { dateStyle: 'short' });

    const formatTime = (date) =>
      date?.toLocaleTimeString('lt-LT', { timeStyle: 'short' });

    const formatDayScheduleForBackend = (day) => [
      ...(day.hours?.[0] || day.hours?.[1]
        ? [`${formatTime(day.hours?.[0])},${formatTime(day.hours?.[1])}`]
        : []),
      ...(day.hours?.[2] || day.hours?.[3]
        ? [`${formatTime(day.hours?.[2])},${formatTime(day.hours?.[3])}`]
        : []),
    ];

    const hasErrors = hasAnyErrors();

    if (hasErrors) {
      toast.error(tr[hasErrors[0].error]);
      const errorDateValue = {
        value: new Date(hasErrors[0].date),
        label: new Date(hasErrors[0].date).toLocaleDateString(locale),
      };

      shiftViewTo(errorDateValue.value);

      setValue('date-from', errorDateValue);
      setValue('date-to', errorDateValue);
      setActiveStartDays({
        start: applyStartTime(errorDateValue.value),
        end: applyStartTime(errorDateValue.value),
      });
      setValueInFocus('date-from');
    } else {
      saveSchedule(
        {
          employeeId,
          sectorId,
          days: processCacheForBackend()?.map((day) => {
            return {
              day: formatDate(day.date),
              schedule: formatDayScheduleForBackend(day),
            };
          }),
        },
        {
          onSuccess: (response) => {
            console.log('onSUCCESS');
            response.status === 207 && console.log('partial SUCCESS');
            response.status !== 207 && clearCache();
            clearTotalDatabase();
            setShouldRefetch(true);
            setUpdateCurrent(true);
            console.log(
              `changes saved${response.status === 207 ? ' partially' : ''}.`,
            );
          },
          onError: (err) => {
            console.log('onERROR', err);
            const errStatus = err.response?.status;

            const errMessage =
              err.response?.data?.errors?.Generic_InvalidInput?.find((error) =>
                error.includes(", day '"),
              );

            let errDate = errMessage?.split(", day '")?.[1];
            errDate = applyStartTime(
              new Date(errDate.substring(0, errDate.length - 1)),
            );

            const errDateObj = {
              value: errDate,
              label: errDate.toLocaleDateString(locale),
            };

            console.log(errStatus, errMessage, errDateObj);

            shiftViewTo(errDateObj.value);

            setValue('date-from', errDateObj);
            setValue('date-to', errDateObj);
            setActiveStartDays({
              start: applyStartTime(errDateObj.value),
              end: applyStartTime(errDateObj.value),
            });
            setValueInFocus('date-from'); // to trigger respective effect - scroll to error. For some reason 'date-from' doesn't work (???)
          },
          onSettled: () => console.log('onSETTLED'),
        },
      );
    }

    console.log('save changes end');
  }, [
    saveSchedule,
    setValue,
    sectorId,
    employeeId,
    tr,
    clearCache,
    clearTotalDatabase,
    locale,
    processCacheForBackend,
    setValueInFocus,
    hasAnyErrors,
    shiftViewTo,
    setUpdateCurrent,
  ]);

  useChangesStatus({
    setButtons,
    hasAnyChanges,
    hasAnyErrors,
    setIsGoBackEnabled,
    onReset: clearCache,
    onSubmit: saveChanges,
  });

  const focusOnDate = useMemo(
    () => ({
      rangeStart: () => {
        console.log('focus start');
        !isMobile && setValueInFocus('date-from');
      },
      rangeEnd: () => {
        console.log('focus end');
        !isMobile &&
          (new Date(state['date-from']?.value?.value)?.getTime() ===
          new Date(state['date-to']?.value?.value)?.getTime()
            ? setValueInFocus('date-from')
            : setValueInFocus('date-to'));
      },
    }),
    [isMobile, state],
  );

  // picker -->

  const maxLastQueryDate = useMemo(() => {
    let from = new Date(state?.['date-from']?.value?.value);
    let to = new Date(from);
    to.setFullYear(to.getFullYear() + 1); // A restriction of 1 year implemented in UI for the time being
    return to;
  }, [state]);

  // <-- picker

  // --> template

  // template setup dialog box visibility state
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const setTemplateDialog = (type) => {
    state['repetition'].setValue('repetition', type);
    setTemplateType(type);
    setIsDialogVisible(true);
  };

  // delete existing template
  const deleteTemplate = () => {
    resetFields([
      'repetition',
      'repetition-daily',
      'repetition-weekly',
      'apply-workdays',
      'apply-weekends',
      'apply-holidays',
      'office-number',
      'schedule-template',
    ]);
    setTemplateType(null);
  };

  // confirmation dialog box buttons
  const [confirmButtons, setConfirmButtons] = useState([]);

  // template setup dialog box visibility state
  const confirmDialog = useToggler();

  // confirm dialog handler
  const handleConfirmDialog = (type) => {
    const referenceDate = state['date-from']?.value
      ? new Date(state['date-from']?.value?.value)
      : new Date();

    switch (type) {
      case 'delete':
        setConfirmButtons([
          {
            id: 'delete',
            title: toSentenceCase(tr['remove']),
            message: `${tr['schedule-remove-confirm']} (${tr['from']} ${referenceDate?.toLocaleDateString(locale)})?`,
            icon: <TrashCan width={16} height={16} />,
            action: () => {
              console.log('delete future schedule started');
              clearSchedule(
                {
                  searchParams: {
                    employeeId,
                    sectorId,
                    startDay: state['date-from']?.value?.label,
                  },
                },
                {
                  onSuccess: () => {
                    clearCache(referenceDate);
                    clearTotalDatabase(referenceDate);
                    toast.success(tr['schedule-updated-successfully']);
                    setShouldRefetch(true);
                    setUpdateCurrent(true);
                    console.log('delete future schedule finished');
                  },
                },
              );
              confirmDialog.off();
            },
          },
        ]);
        break;
      case 'fill-safe':
      case 'fill-lossy':
        setConfirmButtons([
          {
            id: 'fill',
            title: tr['fill-schedule'],
            message: tr[`schedule-fill-confirm-${type.replace('fill-', '')}`],
            icon: <Check bold inCircle />,
            action: () => {
              handleTemplate();
              confirmDialog.off();
            },
          },
        ]);
        break;
      default:
    }
    confirmDialog.on();
  };

  const isRangeEmpty = useCallback(
    () =>
      !hasValuesInRange(
        state['date-from']?.value?.value,
        state['date-to']?.value?.value,
      ),
    [hasValuesInRange, state],
  );

  // choose message type according to range fill status
  const chooseUpdateOption = () => {
    if (isRangeEmpty()) {
      handleConfirmDialog('fill-safe');
    } else {
      handleConfirmDialog('fill-lossy');
    }
  };

  // close dialog if canceled
  const closeUnsaved = () => {
    resetFields(['repetition']);
    if (!state['schedule-template'].value) {
      setTemplateType(() => null);
    }
    setIsDialogVisible(false);
  };

  const handleTemplate = () => {
    const fromValue = state['date-from']?.value?.value;
    const toValue = state['date-to']?.value?.value;

    const processedTemplate = state['schedule-template'].value.map((day) => {
      return {
        hours: { 0: day.hours0, 1: day.hours1, 2: day.hours2, 3: day.hours3 },
        office: day.office,
      };
    });

    applyTemplateToRange(
      applyStartTime(fromValue),
      applyEndTime(toValue),
      processedTemplate,
      {
        repetition:
          state['repetition-daily']?.value?.value ??
          state[`repetition-weekly`]?.value?.value ??
          'continuous',
        application: {
          workdays: state['apply-workdays']?.value?.value,
          weekends: state['apply-weekends']?.value?.value,
          holidays: state['apply-holidays']?.value?.value,
        },
      },
    );
  };

  // <-- template

  // --> workbench

  // copy selected range into template
  const copyRange = () => {
    const dateFrom = state['date-from']?.value?.value;
    const dateTo = state['date-to']?.value?.value;
    const selectedRange = getLiveArraySlice(dateFrom, dateTo).map((day) => ({
      // date: day.date,
      hours0: day.hours?.[0],
      hours1: day.hours?.[1],
      hours2: day.hours?.[2],
      hours3: day.hours?.[3],
    }));

    state['repetition-weekly'].setValue('repetition-weekly', undefined);
    state['repetition-daily'].setValue('repetition-daily', undefined);

    state['apply-workdays'].setValue(
      'apply-workdays',
      options['apply-workdays'].find((option) => option.value),
    );

    state['apply-weekends'].setValue(
      'apply-weekends',
      options['apply-weekends'].find((option) => option.value),
    );

    state['apply-holidays'].setValue(
      'apply-holidays',
      options['apply-holidays'].find((option) => option.value),
    );

    state['schedule-template'].setValue('schedule-template', selectedRange);
    setTemplateType('continuous');
  };

  const handleClearRange = () => {
    const fromValue = applyStartTime(state['date-from']?.value?.value);
    const toValue = applyEndTime(state['date-to']?.value?.value);
    clearRange(fromValue, toValue);
  };

  // <-- workbench

  // if day object is in selected range
  const isInSelectedRange = useCallback(
    (date) => {
      const dateObj = new Date(date);
      const fromObj = applyStartTime(
        new Date(state['date-from']?.value?.value),
      );
      const toObj = applyEndTime(new Date(state['date-to']?.value?.value));

      return dateObj >= fromObj && dateObj <= toObj;
    },
    [state],
  );

  // overflow shading for schedule
  const { init } = useOverflowShading();

  // initialize shading and reflect to dynamic changes
  useEffect(() => {
    !isMobile &&
      init &&
      init('setup', { top: calendarPickerDimensions?.height + 75, right: 40 });
  }, [init, calendarPickerDimensions, isMobile, state]);

  // log states
  useEffect(
    () => console.log({ state, period, data, cache }),
    [state, period, data, cache],
  );

  return (
    <S.Schedule>
      {isMobile && (
        <S.Controls>
          <TheButton
            secondary
            inverted
            raised
            icon={<ArrowSVG />}
            action={() => navigate(-1)}
          />
          <div className="back-button-text">{title}</div>
        </S.Controls>
      )}

      <Overlay
        isVisible={
          resourceContext.isFetching ||
          mutationContext?.isLoading ||
          removalContext?.isLoading
        }
      >
        <Loader />
      </Overlay>

      <S.Section id="setup">
        <div className="section-header">
          <h2>{tr['work-schedule-settings']}</h2>
        </div>

        <S.SetupAndControlSection>
          <S.CalendarPickerSection id="calendar-picker">
            <SchedulerCalendarDouble
              state={{
                ['date-from']: { ...state['date-from'] },
                ['date-to']: { ...state['date-to'] },
              }}
              hasDateValues={hasDateValues}
              activeStartDays={activeStartDays}
              setActiveStartDays={setActiveStartDays}
              minDate={new Date(state['date-from']?.value?.value)}
              maxDate={maxLastQueryDate}
              focus={focusOnDate}
              resetToDefault={resetToDefault}
              setShouldRefetch={setShouldRefetch}
              calendarType={
                getFirstDayOfWeek({ sectorId }) === 7 ? 'gregory' : 'iso8601'
              }
            />
          </S.CalendarPickerSection>

          {period && state?.['date-from']?.value?.value && (
            <S.ControlsSection stickTop={calendarPickerDimensions?.height}>
              <TheButton
                title={`${toSentenceCase(tr['clear-from'])} ${state['date-from']?.value?.label}`}
                secondary
                outline
                height="35"
                width="fit-content"
                icon={<TrashCan bold width={16} height={16} />}
                action={() => handleConfirmDialog('delete')}
                flat
              />
            </S.ControlsSection>
          )}

          <ScheduleTemplate
            state={state}
            templateType={templateType}
            calendarPickerDimensions={calendarPickerDimensions}
            setTemplateDialog={setTemplateDialog}
            editTemplate={() => setIsDialogVisible(true)}
            deleteTemplate={deleteTemplate}
            chooseUpdateOption={chooseUpdateOption}
            sectorId={sectorId}
          />
        </S.SetupAndControlSection>
      </S.Section>

      <ScheduleWorkbench
        state={state}
        period={period}
        isInSelectedRange={isInSelectedRange}
        options={options}
        scheduleHeaderDimensions={scheduleHeaderDimensions}
        activeStartDays={activeStartDays}
        setActiveStartDays={setActiveStartDays}
        isTransient={period && isDbFetching && !isDbError}
        applyDayChanges={applyDayChanges}
        copyRange={copyRange}
        pasteRange={handleTemplate}
        clearRange={handleClearRange}
        isRangeEmpty={isRangeEmpty}
        hasChanged={hasAnyChanges}
        hasHourChanged={hasHourChanged}
        getCacheRange={getCacheRange}
        hasDateErrors={hasDateErrors}
        ref={{ start: startRef, end: endRef }}
        sectorId={sectorId}
      />

      <Overlay isVisible={isDialogVisible} hide={closeUnsaved}>
        <div className="modal-wrapper">
          <DialogBox
            closeIfRejected={closeUnsaved}
            // closeIfConfirmed={() => setIsDialogVisible(false)}
            headerText={tr['schedule-template']}
            buttons={[
              {
                id: 'save',
                title: tr['save'],
                icon: <Check bold inCircle />,
              },
            ]}
          >
            <ScheduleTemplateCreationDialog
              type={templateType}
              fields={fieldsForEdit(state)}
              setParentValue={state['repetition'].setValue}
              // hasChanged={hasChanged} // TODO: implement logic for template change tracking
              closeIfConfirmed={() => setIsDialogVisible(false)}
              sectorId={sectorId}
            />
          </DialogBox>
        </div>
      </Overlay>

      <Overlay isVisible={confirmDialog.active} hide={confirmDialog.off}>
        <div className="modal-wrapper confirm">
          <DialogBox
            close={confirmDialog.off}
            headerText={tr['warning']}
            buttons={confirmButtons}
          >
            <S.ConfirmDialBoxContents>
              {confirmButtons[0]?.message}
            </S.ConfirmDialBoxContents>
          </DialogBox>
        </div>
      </Overlay>

      <UnsavedChangesDialogBox
        condition={hasAnyChanges()}
        // checkIfBypass={() => bypassUnsavedChanges.current}
      />
    </S.Schedule>
  );
};

export default Schedule;
