import * as S from './SVG.styled';

export default ({ color, width = 30, height = 30, ...rest }) => (
  <S.StyledSVG
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    version="1.1"
    viewBox="0 0 30 30"
    color={color}
    {...rest}
  >
    <path
      d="m 23.91285,17.3865 c -1.7592,0 -3.34335,0.75645 -4.45575,1.95495 -0.75645,-0.81285 -1.7265,-1.41795 -2.82405,-1.7265 v -3.66954 c 2.2605,-0.69711 3.9069,-2.80629 3.9069,-5.292195 0,-2.485905 -3.33435,-6.324525 -4.3578,-7.404327 L 14.998515,0 13.814895,1.248888 c -1.020465,1.076832 -4.3548,4.796802 -4.3548,7.404327 0,2.60754 1.6464,4.595085 3.906855,5.292195 v 3.66954 c -1.097595,0.30555 -2.0706,0.91365 -2.82408,1.7265 C 9.43044,18.14295 7.846335,17.3865 6.08721,17.3865 2.72916,17.3865 0,20.1186 0,23.4738 v 5.63625 c 0,0.9018 0.729753,1.63155 1.631565,1.63155 H 28.36845 C 29.27025,30.7416 30,30.01185 30,29.11005 V 23.4738 c 0,-3.35805 -2.7321,-6.0873 -6.08715,-6.0873 z M 14.998515,4.844265 c 1.299285,1.593 2.275335,3.188955 2.275335,3.80895 0,1.254825 -1.02045,2.27529 -2.275335,2.27529 -1.254825,0 -2.27529,-1.020465 -2.27529,-2.27529 0,-0.62295 0.975975,-2.21595 2.27529,-3.80895 z M 26.7369,27.4815 H 3.26016 v -4.00485 c 0,-1.5573 1.26669,-2.82405 2.824095,-2.82405 1.55739,0 2.82408,1.26675 2.82408,2.82405 0,0.9018 0.72975,1.63155 1.631565,1.63155 0.901815,0 1.631565,-0.72975 1.631565,-1.63155 0,-1.5573 1.266675,-2.82405 2.82408,-2.82405 1.557405,0 2.824155,1.26675 2.824155,2.82405 0,0.9018 0.72975,1.63155 1.63155,1.63155 0.9018,0 1.63155,-0.72975 1.63155,-1.63155 0,-1.5573 1.2666,-2.82405 2.82405,-2.82405 1.55745,0 2.82405,1.26675 2.82405,2.82405 v 4.00485 z"
      fill="currentcolor"
      strokeWidth="1.5"
    />
  </S.StyledSVG>
);
