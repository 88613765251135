import * as S from './SVG.styled';
export default ({ color = 'currentColor', width = 30, height = 30, bold }) => {
  const content = bold ? (
    <>
      <path
        d="M 26.249236,2.6013858 14.767679,18.264724 9.5129409,14.046261 C 8.9871613,13.624414 8.2168332,13.706949 7.7949869,14.235786 7.3731405,14.761565 7.4556757,15.531893 7.9845121,15.95374 l 6.2512739,5.01936 c 0.217037,0.174241 0.489097,0.269003 0.764214,0.269003 0.05197,0 0.10699,-0.0031 0.158957,-0.0092 0.33014,-0.0428 0.629713,-0.220094 0.825351,-0.489097 L 28.223966,4.0472795 C 28.624415,3.5031588 28.505197,2.7358876 27.961076,2.3384961 27.416956,1.9411046 26.652741,2.0572652 26.252293,2.6013858 Z"
        strokeWidth="0.305686"
      />
      <path
        d="m 26.744447,7.6910537 c -0.602201,0.3056858 -0.843693,1.0423885 -0.538007,1.6445894 0.709191,1.4000409 1.137151,2.8917869 1.28388,4.4416139 h -1.198288 c -0.675565,0 -1.222743,0.547178 -1.222743,1.222743 0,0.675566 0.547178,1.222743 1.222743,1.222743 h 1.198288 c -0.577746,5.948645 -5.321989,10.692888 -11.270634,11.270634 v -1.198288 c 0,-0.675565 -0.547177,-1.222743 -1.222743,-1.222743 -0.675565,0 -1.222743,0.547178 -1.222743,1.222743 v 1.198288 C 7.8255555,26.915631 3.0813125,22.171388 2.5035664,16.222743 h 1.1982882 c 0.6755655,0 1.222743,-0.547177 1.222743,-1.222743 0,-0.675565 -0.5471775,-1.222743 -1.222743,-1.222743 H 2.5035664 C 3.0843693,7.8286123 7.8286123,3.0843693 13.777257,2.5066232 v 1.1982882 c 0,0.6755655 0.547178,1.2227431 1.222743,1.2227431 0.675566,0 1.222743,-0.5471776 1.222743,-1.2227431 V 2.5066232 c 2.124516,0.2048095 4.148156,0.9415122 5.91502,2.1642552 0.556348,0.3851641 1.317505,0.2445486 1.702669,-0.3087426 C 24.225596,3.8057877 24.084981,3.0446302 23.53169,2.6594661 21.015896,0.92011414 18.066028,0 15,0 6.7281436,0 0,6.7281436 0,15 0,23.271857 6.7281436,30 15,30 23.271857,30 30,23.271857 30,15 30,12.618708 29.458937,10.341349 28.389037,8.2290607 28.083351,7.6268597 27.346648,7.385368 26.744447,7.6910537 Z"
        strokeWidth="0.305686"
      />
    </>
  ) : (
    <>
      <path
        d="m 8.8846398,14.502868 c -0.2740599,-0.219885 -0.6755896,-0.175271 -0.8954748,0.09879 -0.2198853,0.27406 -0.1752709,0.675589 0.098789,0.895475 l 6.51689,5.232632 c 0.114722,0.09241 0.254939,0.140217 0.398343,0.140217 0.02868,0 0.05417,0 0.08286,-0.0064 0.172084,-0.02231 0.328234,-0.114723 0.43021,-0.254939 L 28.269598,3.2058636 C 28.476736,2.9222434 28.416188,2.5239005 28.132568,2.3135755 27.848948,2.1064372 27.450605,2.1669853 27.24028,2.4506054 L 14.878904,19.31485 8.881453,14.499681 Z"
        strokeWidth="0.318674"
      />
      <path
        d="M 15,30 C 23.272785,30 30,23.269598 30,15 30,12.619503 29.458253,10.340981 28.387508,8.2313574 28.22817,7.9190566 27.845761,7.7915869 27.530274,7.950924 27.214786,8.1102612 27.090503,8.4926704 27.24984,8.8081579 c 0.882728,1.7399621 1.367113,3.6073931 1.456342,5.5576801 h -1.931166 c -0.350542,0 -0.637349,0.286807 -0.637349,0.637349 0,0.350541 0.286807,0.637348 0.637349,0.637348 h 1.934353 c -0.325048,7.061823 -6.010198,12.746973 -13.072021,13.07202 v -1.934353 c 0,-0.350541 -0.286806,-0.637348 -0.637348,-0.637348 -0.350542,0 -0.637349,0.286807 -0.637349,0.637348 v 1.934353 C 7.3008284,28.387508 1.6156788,22.702358 1.290631,15.640535 h 1.934353 c 0.3505418,0 0.6373486,-0.286807 0.6373486,-0.637348 0,-0.350542 -0.2868068,-0.637349 -0.6373486,-0.637349 H 1.290631 C 1.6156788,7.3008284 7.3008284,1.6156788 14.362651,1.290631 v 1.934353 c 0,0.3505418 0.286807,0.6373486 0.637349,0.6373486 0.350542,0 0.637348,-0.2868068 0.637348,-0.6373486 V 1.290631 c 2.574889,0.1179095 5.041428,0.9464627 7.166986,2.4155512 0.289993,0.2007648 0.685149,0.1274697 0.885914,-0.1625239 C 23.891013,3.2536647 23.817718,2.8585086 23.527724,2.6577437 21.016571,0.91778201 18.065647,0 15,0 6.7304014,0 0,6.7304014 0,15 0,23.269598 6.7304014,30 15,30 Z"
        strokeWidth="0.318674"
      />
    </>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
    >
      {content}
    </S.StyledSVG>
  );
};
