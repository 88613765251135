import { cloneElement, useContext } from 'react';

// Context
import { ScheduleContext } from 'context/ScheduleContext';

export const useColumnLooper = () => {
  const { dataSource, days, scheduleHasData } = useContext(ScheduleContext);

  const loop = ({
    keyPrefix,
    skeleton,
    appendToEnd,
    noData = <></>,
    callback,
  }) => {
    const elements = [];
    let skeletonFunction = skeleton;

    if (typeof skeleton.props === 'object') {
      skeletonFunction = () => skeleton;
    }

    if (dataSource === null) {
      return [...Array(12)].map((item, i) =>
        cloneElement(skeletonFunction(i), {
          key: 'loading-' + keyPrefix + '-' + i,
        }),
      );
    }

    if (scheduleHasData && dataSource?.length) {
      dataSource.map((obj, i) => {
        if (!obj) null;

        days.map((day, index) => {
          let element = null;

          if (day.notInView || obj.notInView) {
            element = skeletonFunction(index);
          } else {
            element = callback(obj, day);
          }

          elements.push(
            cloneElement(element, { key: keyPrefix + '-' + i + '-' + index }),
          );
        });
      });
    } else {
      return noData;
    }

    appendToEnd &&
      [...Array(6)].map((item, i) => {
        elements.push(
          cloneElement(skeletonFunction(i), {
            key: 'loading-end-' + keyPrefix + '-' + i,
          }),
        );
      });

    return elements;
  };

  return { loop };
};
