import * as S from './SVG.styled';

export default ({ color, width = 30, height = 30, bold, ...rest }) => {
  const content = bold ? (
    <path
      d="M 26.3304,1.4829414 C 25.3737,0.52618438 24.102,4.27246e-4 22.74795,4.27246e-4 c -1.3542,0 -2.62575,0.525757134 -3.5826,1.482514154 l -1.947,1.947135 L 2.89443,17.756936 c -0.01833,0.01845 -0.033615,0.03975 -0.0489,0.0612 -0.02751,0.0336 -0.05808,0.0642 -0.08253,0.1008 -0.021405,0.0306 -0.03975,0.0642 -0.05808,0.0948 -0.021405,0.03675 -0.042795,0.07035 -0.05808,0.1101 -0.018345,0.03975 -0.03057,0.0795 -0.042795,0.1191 -0.00917,0.0276 -0.021405,0.05505 -0.03057,0.08265 l -2.53708545,10.1544 c -0.10392885,0.41565 0.01834035,0.8559 0.32095695,1.1616 0.232311,0.23235 0.544098,0.3576 0.8650545,0.3576 0.097816,0 0.1986885,-0.0123 0.296499,-0.03675 l 10.15446,-2.53695 c 0.02446,-0.0061 0.04891,-0.01845 0.07336,-0.0276 0.04279,-0.01215 0.08559,-0.02745 0.125325,-0.0459 0.03669,-0.01515 0.0703,-0.0366 0.106995,-0.0549 0.03362,-0.01845 0.06725,-0.03675 0.10086,-0.0612 0.03669,-0.02745 0.07336,-0.05505 0.106995,-0.08865 0.01833,-0.0153 0.03974,-0.0306 0.05808,-0.0459 L 28.51905,10.827371 c 1.9746,-1.9746446 1.9746,-5.1872696 0,-7.1619296 L 26.3334,1.4798859 Z m -8.247,4.5423 5.8902,5.8903196 -12.593685,12.593775 -5.89032,-5.89035 z M 4.422795,21.012386 8.9865,25.576136 2.90361,27.095336 Z M 26.7858,9.1033764 25.7007,10.188506 19.81035,4.2981864 20.8956,3.2130564 c 0.49515,-0.495195 1.1523,-0.76725 1.85235,-0.76725 0.6999,0 1.3572,0.272055 1.85235,0.76725 l 2.1855,2.18556 c 1.02105,1.020945 1.02105,2.683815 0,3.70476 z"
      fill="currentcolor"
      strokeWidth="1.5"
    />
  ) : (
    <path
      d="m 26.3667,1.356057 c -1.8102,-1.8102735 -4.75515,-1.8102735 -6.56535,0 L 17.76795,3.38943 2.833155,18.32415 c -0.01275,0.01275 -0.019125,0.0255 -0.02868,0.0351 -0.01275,0.0159 -0.0255,0.0318 -0.03825,0.04785 -0.01275,0.01905 -0.025485,0.03825 -0.035055,0.0573 -0.00956,0.0159 -0.019125,0.03195 -0.0255,0.051 -0.00956,0.02235 -0.01593,0.04455 -0.025485,0.0669 -0.00319,0.01275 -0.00957,0.0255 -0.01275,0.03825 l -2.6484849,10.5876 c -0.05418075,0.21675 0.0095613,0.4461 0.1689159,0.60555 0.12111,0.12105 0.283653,0.18795 0.449382,0.18795 0.0509925,0 0.1019865,-0.0063 0.1529805,-0.01905 L 11.37777,27.33735 c 0,0 0.0255,-0.0096 0.03825,-0.01275 0.02231,-0.0065 0.04462,-0.01275 0.06693,-0.02235 0.01912,-0.0096 0.03825,-0.0192 0.05418,-0.02865 0.01913,-0.0096 0.03505,-0.0192 0.05099,-0.03195 0.01912,-0.01275 0.03825,-0.02865 0.05738,-0.04455 0.0096,-0.0096 0.01912,-0.0159 0.02868,-0.0255 L 28.64235,10.20345 c 1.8102,-1.810275 1.8102,-4.75515 0,-6.565425 L 26.36355,1.359243 Z M 18.21735,4.74075 25.26075,11.78424 11.22798,25.8171 4.18449,18.7767 Z M 3.626745,20.01975 9.97863,26.37165 1.513695,28.48785 3.62994,20.0229 Z M 27.74355,9.298305 26.1627,10.87911 19.1193,3.83562 20.7,2.254815 c 0.6375,-0.63741 1.482,-0.9879975 2.3808,-0.9879975 0.8988,0 1.7466,0.3505875 2.3808,0.9879975 L 27.7404,4.5336 c 1.3131,1.313085 1.3131,3.45162 0,4.764705"
      fill="currentcolor"
      strokeWidth="1.5"
    />
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
