import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

// redux
import { setAppointments } from 'redux/actions/appointmentsAction';

// services
import { useServicesGetOders } from 'services/orderService';

// hooks
import {
  datesAreOnSameDay,
  getFirstDayOfWeek,
  toIsoString,
} from 'common/helpers/dateOperations';

export const useEmployeeOrders = (
  sectorId,
  startDate,
  numberOfDays,
  dataSource,
  past,
  page,
  showOnlyWorkingDays,
  dataSourceDate = startDate,
  dataSourceSector = sectorId,
) => {
  const dispatch = useDispatch();
  const [employeeIds, setEmployeeIds] = useState([]);
  // const querySearchParams = useRef();

  const sameDay = datesAreOnSameDay(startDate, dataSourceDate);
  const sameSector = sectorId === dataSourceSector;

  useEffect(() => {
    if (!sameDay || !sameSector) {
      setEmployeeIds([]);
      return;
    }

    const filtered = dataSource?.reduce((prev, curr) => {
      if (!curr.notInView) {
        prev.push(curr.employee.id);
      }
      return prev;
    }, []);

    setEmployeeIds(filtered);
  }, [dataSource, sameSector, sameDay]);

  const getStartDate = useCallback(() => {
    if (numberOfDays.visible === 1) {
      return toIsoString(startDate).split('T')[0];
    }

    if (numberOfDays.visible === 7) {
      const startOfTheWeek = getFirstDayOfWeek(startDate);

      return toIsoString(startOfTheWeek).split('T')[0];
    }

    let start = new Date(startDate);
    start.setDate(start.getDate() - past);
    start.setDate(start.getDate() + (page - 1) * 7);

    return toIsoString(start).split('T')[0];
  }, [numberOfDays.visible, page, past, startDate]);

  const getEndDate = useCallback(
    (startDay) => {
      if (numberOfDays.visible === 7) {
        const endDay = new Date(startDay);
        endDay.setDate(endDay.getDate() + numberOfDays.visible);
        return toIsoString(endDay).split('T')[0];
      }

      if (numberOfDays.visible === 1) {
        return toIsoString(startDate).split('T')[0];
      }

      let end = new Date(startDay);

      end.setDate(
        end.getDate() + (numberOfDays.visible - (numberOfDays.notInView || 0)),
      );

      return toIsoString(end).split('T')[0];
    },
    [numberOfDays.notInView, numberOfDays.visible, startDate],
  );

  const getParams = useCallback(() => {
    if (numberOfDays.visible === 7) {
      const startDay = getStartDate();

      const arrayOfCalls = (employeeIds || []).map((id) => {
        return {
          queryParams: {
            retry: true,
            refetchOnWindowFocus: false,
            enabled:
              !!employeeIds.length && !!sectorId && sameDay && sameSector,
          },
          searchParams: {
            sectorId,
            startDay,
            endDay: getEndDate(startDay),
            employeeIds: id,
            serviceListMode: 'both',
          },
        };
      });

      return arrayOfCalls;
    }
    if (numberOfDays.visible === 1 || !showOnlyWorkingDays) {
      const startDay = getStartDate();
      // const newQuerySearchParams = new URLSearchParams({
      //   sectorId,
      //   startDay,
      //   endDay: getEndDate(startDay),
      //   employeeIds: employeeIds?.toString(),
      //   serviceListMode: 'both',
      // }).toString();

      // const sameParams = querySearchParams.current === newQuerySearchParams;
      // querySearchParams.current = newQuerySearchParams;

      return {
        queryParams: {
          retry: true,
          refetchOnWindowFocus: false,
          enabled: !!employeeIds?.length && !!sectorId && sameDay && sameSector,
        },
        searchParams: {
          sectorId,
          startDay,
          endDay: getEndDate(startDay),
          employeeIds: employeeIds?.toString(),
          serviceListMode: 'both',
        },
      };
    }

    if (showOnlyWorkingDays) {
      const days = dataSource?.[0]?.days || [];
      const empId = dataSource?.[0]?.employee.id;

      const consecutiveDates = days.reduce(
        (acc, curr, index) => {
          const date = new Date(curr.day);

          if (
            index === 0 ||
            date -
              new Date(
                acc[acc.length - 1][acc[acc.length - 1].length - 1].day,
              ) ===
              24 * 60 * 60 * 1000
          ) {
            // Start a new group or add to the current group
            acc[acc.length - 1].push(curr);
          } else {
            // Start a new group
            acc.push([curr]);
          }
          return acc;
        },
        [[]],
      );

      const arrayOfCalls = consecutiveDates.map((date) => {
        return {
          queryParams: {
            retry: true,
            refetchOnWindowFocus: false,
            enabled: !!employeeIds.length && !!date.length,
          },
          searchParams: {
            sectorId,
            startDay: date.at(0)?.day,
            endDay: date.at(-1)?.day,
            employeeIds: empId,
          },
        };
      });

      return arrayOfCalls;
    }
    // }, [employeeIds, showOnlyWorkingDays]);
  }, [
    employeeIds,
    showOnlyWorkingDays,
    dataSource,
    getEndDate,
    getStartDate,
    numberOfDays.visible,
    sectorId,
  ]);

  const getType = useCallback(() => {
    return !showOnlyWorkingDays && numberOfDays.visible !== 7;
  }, [showOnlyWorkingDays, numberOfDays]);

  const employeeOrdersBE = useServicesGetOders(getType(), getParams());
  const employeeOrdersBEstringifyed = JSON.stringify(employeeOrdersBE);

  useEffect(() => {
    const single = numberOfDays.visible !== 7;

    if (!single || (showOnlyWorkingDays && dataSource?.[0]?.days?.length)) {
      employeeOrdersBE.forEach((obj) => {
        obj.refetch();
      });
    }
  }, [page]);

  // set orders
  useEffect(() => {
    // setting orders for single orders call
    const single = numberOfDays.visible !== 7;

    if (single && !showOnlyWorkingDays) {
      const data = employeeOrdersBE.data;

      if (!data) return;

      dispatch(setAppointments(data));
    }
  }, [employeeOrdersBE?.data]);

  useEffect(() => {
    // setting orders for multi orders calls
    const single = numberOfDays.visible !== 7;

    if (!single || showOnlyWorkingDays) {
      const dataBE = employeeOrdersBE;
      const complete = dataBE.every((el) => el.status === 'success');
      if (complete && dataBE.length) {
        let arr = [];

        dataBE.forEach((obj) => {
          const data = structuredClone(obj.data.employees[0]);

          if (data) {
            const foundEmployeeIndex = arr.findIndex(
              (emp) => emp.employee.id === data.employee.id,
            );

            if (foundEmployeeIndex < 0) {
              arr.push(data);
            } else {
              arr[foundEmployeeIndex].days.push(...data.days);
            }
          }
        });

        dispatch(setAppointments({ employees: arr }));
      }
    }
  }, [employeeOrdersBEstringifyed]);

  useEffect(() => {
    return () => {
      dispatch(setAppointments(null));
    };
  }, []);

  return employeeOrdersBE;
};

export default useEmployeeOrders;
