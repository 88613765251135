import { useContext } from 'react';

// style
import * as S from './GeneralInfo.styled';

// icons
import { Pencil, ArrowRevert, Check } from 'common/icons';

// components
import { HeaderByType } from './HeaderByType/HeaderByType';
import { Avatar } from 'components/Avatar/Avatar';
import { TheButton } from 'components/Buttons/TheButton';
import { InputField } from 'components/InputField/InputField';

// contexts
import { UserContext } from 'context/UserContext';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { isNew } from 'common/helpers/serviceFunctions';

export const GeneralInfo = ({
  activeItem,
  setActiveItem,
  state,
  disabled,
  changed,
  confirmChanges,
  resetChanges,
  reservedCategory,
}) => {
  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 960px)');
  const { locale } = useContext(UserContext);

  const getCurrentDateAndTime = () => {
    return new Date().toLocaleTimeString(locale, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  return (
    <S.GeneralInfo>
      <div className="header-container">
        <HeaderByType
          id={activeItem.id}
          type={activeItem.type}
          name={reservedCategory?.forcedTitle || state?.default?.value}
          readOnly={disabled.state}
        />
        {!isMobile && !reservedCategory && (
          <div className="button-container">
            {disabled.state ? (
              <TheButton
                title={tr['edit']}
                primary
                action={() => disabled.setState(false)}
                icon={<Pencil bold />}
              />
            ) : (
              <>
                <TheButton
                  title={tr[isNew(activeItem) || !changed ? 'cancel' : 'reset']}
                  primary
                  outline
                  // raised
                  action={() => {
                    resetChanges();
                    isNew(activeItem) && setActiveItem(undefined);
                  }}
                  icon={<ArrowRevert bold />}
                />
                {((!isNew(activeItem) && changed) || isNew(activeItem)) && (
                  <TheButton
                    title={tr['save']}
                    primary
                    // raised
                    action={confirmChanges}
                    icon={<Check bold />}
                  />
                )}
              </>
            )}
          </div>
        )}
      </div>

      {!reservedCategory && (
        <div className="modified-by-container">
          <div className="avatar-container">
            <Avatar raised />
          </div>

          <div className="additional-info-container">
            <span>Vardenis Pavardenis</span>
            <span>{getCurrentDateAndTime()}</span>
          </div>
        </div>
      )}

      {!disabled.state && (
        <div className="edit-title-container">
          <InputField
            id={'name'}
            label={tr['title']}
            state={state}
            autofocus={isNew(activeItem)}
          />
        </div>
      )}
    </S.GeneralInfo>
  );
};

export default GeneralInfo;
