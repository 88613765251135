import * as S from './SVG.styled';

export default ({ color, width = 30, height = 30, bold, ...rest }) => {
  const content = bold ? (
    <path
      d="M 26.904,2.37768 H 19.75845 10.24149 3.09597 C 1.390092,2.37768 0,3.767775 0,5.473665 V 24.52632 c 0,1.7058 1.390092,3.096 3.09597,3.096 h 7.142415 9.523215 7.1424 c 1.70595,0 3.096,-1.3902 3.096,-3.096 V 5.473665 C 30,3.767775 28.60995,2.37768 26.904,2.37768 Z M 2.47059,24.52632 V 5.473665 c 0,-0.34056 0.27864,-0.6192 0.6192,-0.6192 H 8.99691 V 25.14552 H 3.08979 c -0.34056,0 -0.6192,-0.2787 -0.6192,-0.6192 z M 11.47368,4.854465 h 7.04637 V 25.14552 H 11.47368 Z M 27.5232,24.52632 c 0,0.3405 -0.27855,0.6192 -0.6192,0.6192 H 20.99685 V 4.854465 H 26.904 c 0.34065,0 0.6192,0.27864 0.6192,0.6192 z"
      fill="currentcolor"
      strokeWidth="1.5"
    />
  ) : (
    <path
      d="m 27.41715,2.4827775 h -8.0973 -8.6397 -8.097285 C 1.159062,2.4827775 0,3.6418425 0,5.0656425 V 24.934372 c 0,1.4238 1.159062,2.58285 2.582865,2.58285 h 6.80586 11.222625 6.8058 c 1.4238,0 2.58285,-1.15905 2.58285,-2.58285 V 5.0656425 c 0,-1.4238 -1.15905,-2.582865 -2.58285,-2.582865 z M 9.388725,26.222572 h -6.80586 c -0.71352,0 -1.291431,-0.57795 -1.291431,-1.2915 V 5.0656425 c 0,-0.713505 0.577911,-1.291425 1.291431,-1.291425 h 6.80586 V 26.225722 Z M 10.68015,3.7742175 h 8.6397 V 26.225722 h -8.6397 z M 28.7085,24.931072 c 0,0.71355 -0.5778,1.2915 -1.29135,1.2915 h -6.8058 V 3.7742175 h 6.8058 c 0.71355,0 1.29135,0.57792 1.29135,1.291425 V 24.934372 Z"
      fill="currentcolor"
      strokeWidth="1.5"
    />
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
