// icons
import {
  Calendar,
  Coins,
  Ellipsis,
  InterchangeSVG,
  PinSVG,
  TrashCan,
} from 'common/icons';

// hooks
import useTranslate from './useTranslate';
import { colors } from 'common/colors/colors';

export const useContextMenuItems = (contents) => {
  const tr = useTranslate().use().global;

  const items = [
    {
      id: 'edit',
      label: 'Redaguoti',
      items: [],
    },
    {
      id: 'delete-timeOff',
      icon: <TrashCan />,
      label: 'Ištrinti nedarbo priežastį',
      color: colors.mandyRed,
      onClick: () => {},
    },
    {
      id: 'change-status',
      label: tr['change-order-status'],
      icon: <InterchangeSVG bold />,
      onClick: () => {},
      items: [],
    },
    {
      id: 'functions',
      label: tr['functions'],
      icon: <Ellipsis inCircle bold />,
      onClick: () => {},
      items: [],
    },
    {
      id: 'pin',
      label: 'Prisegti',
      icon: <PinSVG />,
      onClick: () => {},
    },
    {
      id: 'individual-scheduler',
      label: 'Individualus kalendorius',
      icon: <Calendar />,
      onClick: () => {},
    },
    {
      id: 'monthly-scheduler',
      label: 'Mėnesio kalendorius',
      icon: <Calendar />,
      onClick: () => {},
    },
    {
      id: 'balance-status',
      label: tr['change-balance-status'],
      icon: <Coins bold margin="2px 0 0 0" />,
      onClick: () => {},
    },
  ];

  const generateArray = () =>
    contents.reduce((acc, curr) => {
      const found = items.find((func) => func.id === curr);
      if (found) return [...acc, found];

      return acc;
    }, []);

  return Array.isArray(contents)
    ? generateArray()
    : items.find((func) => func?.id === contents);
};
