export const getFullName = (firstName, lastName) => firstName + ' ' + lastName;

export const toSentenceCase = (string) => {
  return string ? `${string.charAt(0).toUpperCase()}${string.slice(1)}` : '';
};

export const toCamelCase = (string) => {
  return string
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index == 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/[\s-]+/g, '');
};

export const toTitleCase = (string) => {
  return string.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const singularize = (word) => {
  const endings = {
    ves: 'fe',
    ies: 'y',
    i: 'us',
    zes: 'ze',
    ses: 's',
    es: 'e',
    s: '',
  };

  return word.replace(
    new RegExp(`(${Object.keys(endings).join('|')})$`),
    (r) => endings[r],
  );
};

export const pluralize = (object) => {
  switch (object) {
    case 'category':
      return 'categories';
    case 'set':
      return 'services';
    default:
      return object + 's';
  }
};
