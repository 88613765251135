import * as S from './SVG.styled';

export default ({ color, width = 30, height = 30, bold, ...rest }) => {
  const content = bold ? (
    <>
      <path
        d="m 11.326331,10.428323 -0.343892,2.006053 c -0.09955,0.579184 0.135745,1.149313 0.609353,1.493205 0.26848,0.193067 0.579185,0.292611 0.895927,0.292611 0.244349,0 0.48566,-0.05732 0.711915,-0.177978 l 1.800891,-0.947208 1.800891,0.947208 c 0.518847,0.271498 1.134238,0.226236 1.607842,-0.11463 0.473604,-0.343892 0.705878,-0.917039 0.609353,-1.496223 l -0.343892,-2.006053 1.456999,-1.4208115 c 0.419304,-0.410248 0.56711,-1.007545 0.386117,-1.5655984 C 20.336842,6.8808446 19.863238,6.4826529 19.284054,6.3981845 L 17.272001,6.1055735 16.370037,4.2805514 C 16.110613,3.7556665 15.585728,3.4298682 15.000506,3.4298682 c -0.585222,0 -1.110107,0.3257983 -1.36953,0.8506832 l -0.901965,1.8250221 -2.012052,0.292611 C 10.137774,6.4826491 9.6641705,6.8808446 9.4831777,7.4388981 9.3021848,7.9969515 9.4499959,8.5972672 9.8692945,9.0044965 L 11.326293,10.425308 Z M 13.537507,8.4312706 C 14.035242,8.3588734 14.463603,8.0451538 14.68682,7.595681 l 0.313724,-0.6334843 0.313723,0.6334843 c 0.223218,0.4494728 0.651578,0.7631962 1.149313,0.8355896 l 0.69984,0.1025638 -0.503772,0.4916976 c -0.358967,0.3499295 -0.524885,0.8536832 -0.440417,1.354437 l 0.120664,0.696821 -0.624429,-0.328798 c -0.443435,-0.235292 -0.977376,-0.235292 -1.420811,0 L 13.670227,11.07679 13.79089,10.382969 C 13.87535,9.8852339 13.71246,9.3814614 13.350473,9.028532 l -0.506791,-0.4916976 0.69984,-0.1025638 z"
        strokeWidth="0.301648"
      />
      <path
        d="M 25.013387,4.9673631e-8 H 4.9866127 C 4.319941,4.9673631e-8 3.7799812,0.53995984 3.7799812,1.2066315 V 28.793368 C 3.7799812,29.46004 4.319941,30 4.9866127,30 H 25.013387 c 0.666672,0 1.206632,-0.53996 1.206632,-1.206632 V 1.2066315 C 26.220019,0.53995984 25.680059,4.9673631e-8 25.013387,4.9673631e-8 Z M 23.806756,27.586737 H 6.1929067 V 2.4131129 H 23.806756 Z"
        strokeWidth="0.301648"
      />
      <path
        d="M 9.3324659,20.418648 H 20.674847 c 0.666671,0 1.206631,-0.53996 1.206631,-1.206631 0,-0.666672 -0.53996,-1.206632 -1.206631,-1.206632 H 9.3324659 c -0.6666716,0 -1.2066314,0.53996 -1.2066314,1.206632 0,0.666671 0.5399598,1.206631 1.2066314,1.206631 z"
        strokeWidth="0.301648"
      />
      <path
        d="m 9.3264659,23.801131 h 6.9290281 c 0.666671,0 1.206631,-0.53996 1.206631,-1.206631 0,-0.666672 -0.53996,-1.206632 -1.206631,-1.206632 H 9.3264659 c -0.6666716,0 -1.2066314,0.53996 -1.2066314,1.206632 0,0.666671 0.5399598,1.206631 1.2066314,1.206631 z"
        strokeWidth="0.301648"
      />
    </>
  ) : (
    <>
      <path
        d="m 11.8456,10.0175 -0.41488,2.4138 c -0.06286,0.36459 0.08486,0.72604 0.38345,0.9429 0.16972,0.12258 0.36773,0.18544 0.56574,0.18544 0.15401,0 0.30802,-0.03772 0.44945,-0.11315 l 2.1687,-1.1409 2.1687,1.1409 c 0.32687,0.17287 0.71661,0.14458 1.0152,-0.07229 0.29859,-0.21687 0.44631,-0.57831 0.38345,-0.9429 l -0.41488,-2.4138 1.7538,-1.7098 C 20.16834,8.04997 20.26263,7.67281 20.14949,7.3208 20.03634,6.96878 19.73776,6.71734 19.37317,6.66391 l -2.4264,-0.35202 -1.0843,-2.197 C 15.69903,3.78487 15.36902,3.57744 14.99814,3.57744 c -0.37088,0 -0.70089,0.2043 -0.86433,0.53745 l -1.0843,2.197 -2.4264,0.35202 C 10.25852,6.717341 9.95994,6.96878 9.84679,7.3208 9.73364,7.67282 9.824789,8.04998 10.09194,8.3077 Z m 1.5369,-2.483 c 0.3143,-0.044002 0.58774,-0.24201 0.72604,-0.52803 l 0.88947,-1.8009 0.88947,1.8009 c 0.14144,0.28601 0.41173,0.48088 0.72604,0.52803 l 1.9895,0.28916 -1.4395,1.4018 c -0.2263,0.22315 -0.33002,0.5406 -0.27658,0.85176 l 0.33944,1.9801 -1.7789,-0.93347 c -0.27973,-0.14772 -0.61603,-0.14772 -0.8989,0 l -1.7789,0.93347 0.33944,-1.9801 C 13.16255,9.76606 13.05883,9.44548 12.83254,9.22232 l -1.4395,-1.4018 1.9864,-0.28916 z"
        strokeWidth="0.3143"
      />
      <path
        d="M 26.0616,29.3715 V 0.6285 C 26.0616,0.28277 25.77873,-1e-4 25.433,-1e-4 H 4.567 c -0.34573,0 -0.6286,0.28287 -0.6286,0.6286 v 28.743 c 0,0.34573 0.28287,0.6286 0.6286,0.6286 h 20.866 c 0.34573,0 0.6286,-0.28287 0.6286,-0.6286 z M 24.8044,28.7429 H 5.1954 V 1.2569 h 19.609 z"
        strokeWidth="0.3143"
      />
      <path
        d="m 9.0955,20.0155 h 11.818 c 0.34573,0 0.6286,-0.28287 0.6286,-0.6286 0,-0.34573 -0.28287,-0.6286 -0.6286,-0.6286 H 9.0955 c -0.34573,0 -0.6286,0.28287 -0.6286,0.6286 0,0.34573 0.28287,0.6286 0.6286,0.6286 z"
        strokeWidth="0.3143"
      />
      <path
        d="m 9.0892,23.5415 h 7.2195 c 0.34573,0 0.6286,-0.28287 0.6286,-0.6286 0,-0.34573 -0.28287,-0.6286 -0.6286,-0.6286 H 9.0892 c -0.34573,0 -0.6286,0.28287 -0.6286,0.6286 0,0.34573 0.28287,0.6286 0.6286,0.6286 z"
        strokeWidth="0.3143"
      />
    </>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
