import PropTypes from 'prop-types';

// style
import * as S from './Avatar.styled';

// icons
import { Person } from 'common/icons';

export const Avatar = ({
  anonymous,
  raised,
  imagePath,
  size,
  fullName,
  className,
  ...props
}) => {
  const getInitials = (fullName) => {
    if (!fullName) return;

    const allNames = fullName.trim().split(' ');
    const initials = allNames.reduce((acc, curr, index) => {
      if (index === 0 || index === allNames.length - 1) {
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, '');
    return initials;
  };

  const initials = getInitials(fullName);

  return (
    <S.Avatar
      anonymous={anonymous}
      raised={raised}
      size={size}
      initials={!!initials}
      className={className}
    >
      {imagePath ? (
        <img
          src={imagePath}
          alt="avatar"
          referrerPolicy="no-referrer"
          {...props}
        ></img>
      ) : initials ? (
        <span className="initials" {...props}>
          {initials}
        </span>
      ) : (
        <Person black alt="no avatar" {...props} />
      )}
    </S.Avatar>
  );
};

Avatar.propTypes = {
  anonymous: PropTypes.bool,
  raised: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]), // can specify either default value (boolean) or a specific shadow value (string: ['sm', 'md', 'lg', etc.])
  imagePath: PropTypes.string,
  size: PropTypes.string,
  tooltip: PropTypes.string,
  fullName: PropTypes.string,
};

export default Avatar;
