import { useNavigate } from 'react-router';

// style
import * as S from './ClientCard.styled';

// icons
import { Arrow, TrashCan } from 'common/icons';

// components
import { RecordCell } from 'components/Tables/Table/partials/TableRecord/partials/RecordCell/RecordCell';

// hooks and helpers
import { useTable } from 'hooks/useTable';
import { getFullName } from 'common/helpers/stringOperations';

const exludedColumns = ['photoUrl'];

export const ClientCard = ({
  data,
  selectOne,
  columns,
  inactive,
  actions: { navigation },
  onRecordDelete,
}) => {
  const navigate = useNavigate();
  const { getColumnVisibility } = useTable();
  const handleNavigate = (e) => {
    e.stopPropagation();

    if (typeof navigation === 'boolean') {
      navigate(data.id);
    } else {
      navigation(data);
    }
  };

  return (
    <S.ClientCard inactive={inactive} selected={data.isSelected}>
      {/* inActive={data.inActive} */}
      <S.Header inactive={inactive}>
        <TrashCan
          bold
          onClick={() => onRecordDelete(data)}
          className="delete-button"
        />

        {/* <span className="mark" onClick={() => selectOne(data.id)}>
          {data.isSelected && <Check inCircle black width={16} height={16} />}
        </span> */}

        {/* <span className="avatar" onClick={handleNavigate}>
          <Avatar imagePath={fillImgPath(data.photoUrl)} raised="md" />
        </span> */}
        <span className="full-name" onClick={handleNavigate}>
          {getFullName(data.name, data.lastName)}
        </span>

        <span className="details" onClick={handleNavigate}>
          <Arrow black height="16" width="16" />
        </span>
      </S.Header>

      <S.Main>
        <tbody>
          {columns?.map(
            (column) =>
              !exludedColumns.includes(column.id) &&
              getColumnVisibility(column) && (
                <tr key={column.id + '-' + data.id}>
                  <td>{column?.label}:</td>
                  <RecordCell data={data} column={column} dashIfNoContent />
                </tr>
              ),
          )}
        </tbody>
      </S.Main>
    </S.ClientCard>
  );
};
