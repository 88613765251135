import { useCallback, useEffect, useMemo, useState } from 'react';

// style
import * as S from './ServiceTree.styled';

// icons
import { Arrow, ArrowRevert, Check } from 'common/icons';

// components
import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper';
import { ContentLayout } from 'components/ContentLayout/ContentLayout';
import { DialogLayout } from 'components/DialogLayout/DialogLayout';
import { ServiceGroup } from '../ServiceGroup/ServiceGroup';
import { BaseCheckbox } from 'components/Inputs';

// hooks
import { singularize } from 'common/helpers/stringOperations';
import { useTranslate } from 'hooks/useTranslate';
import {
  getItem as getTreeItem,
  isServiceItem,
} from 'common/helpers/serviceFunctions';

export const CategoryTree = ({
  data,
  close,
  modal = true,
  toggleActivity,
  updateValue,
  contentId,
  serviceActivity,
  cancelChanges,
  confirmChanges,
  getCachedItem,
  getItem,
  hasAnyChanges,
  hasValueChanged,
  getDbItem,
  updateCache,
  db,
  setActiveGroup,
  hydrateDbGroup,
  isDisabledByParent,
  updatePropDownstream,
}) => {
  const tr = useTranslate().use().global;

  const [activeOption, setActiveOption] = useState();

  const findItemById = (id, type) => getTreeItem(data, id, type);

  const type = singularize(contentId);

  const getServiceName = (object) =>
    'id' in object && object.id === 0
      ? tr['categoryId' in object ? 'ungrouped' : 'unassigned-to-category']
      : object.name;

  // useEffect(() => console.log({ activeOption }), [activeOption]);

  const Wrapper = (children) => {
    return (
      <ContentLayout hideFab>
        <DialogLayout headerText={getServiceName(data)} getBack={close} modal>
          {children}
        </DialogLayout>
      </ContentLayout>
    );
  };

  const initialButtons = [
    {
      id: 'cancel',
      secondary: true,
      outline: true,
      borderless: false,
      icon: <ArrowRevert bold borderless={false} />,
      action: () => {
        cancelChanges();
        // console.log(`${type}: changes reverted`);
      },
    },
    {
      id: 'save',
      borderless: true,
      icon: <Check bold />,
      action: () => {
        confirmChanges();
        // console.log(`${type}: changes saved`);
      },
    },
  ];

  const CategoryTreeItems = ({ setButtons }) => {
    // setup/cleanup header buttons
    useEffect(() => {
      setButtons && setButtons({ type: 'setup', value: initialButtons });
      return () => setButtons && setButtons({ type: 'cleanup' });
    }, [setButtons]);

    // show/hide header button according to changes
    useEffect(() => {
      if (setButtons) {
        const isChanged = hasAnyChanges();
        setButtons({ type: 'show', id: 'cancel', value: isChanged });
        setButtons({ type: 'show', id: 'save', value: isChanged });
      }
    }, [setButtons]);

    return data?.[contentId]?.map((child) => {
      const item = getItem(type, child.id);

      return (
        <S.ServiceTree
          key={child.id}
          type={type}
          isDisabledByParent={isDisabledByParent(item.type, item.id)}
          onClick={() => {
            child.type === 'group' &&
              setActiveGroup({
                categoryId: child.categoryId,
                id: child.id,
              });
            setActiveOption(() => findItemById(child.id, child.type));
          }}
        >
          <div className="item">
            {isServiceItem(item) ? (
              <label>{getServiceName(item)}</label>
            ) : (
              <BaseCheckbox
                label={child.name}
                value={item.isEmpAssigned}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleActivity(type, child.id);
                }}
                excludeLabel
              />
            )}

            <div className="arrow">
              <Arrow black height="16" width="16" />
            </div>
          </div>
        </S.ServiceTree>
      );
    });
  };

  return (
    <ConditionalWrapper condition={modal} wrapper={Wrapper}>
      <CategoryTreeItems />

      {activeOption &&
        (type === 'category' ? (
          <CategoryTree
            data={activeOption}
            contentId="groups"
            close={() => setActiveOption(undefined)}
            toggleActivity={toggleActivity}
            updateValue={updateValue}
            cancelChanges={cancelChanges}
            confirmChanges={confirmChanges}
            getDbItem={getDbItem}
            getCachedItem={getCachedItem}
            getItem={getItem}
            hasAnyChanges={hasAnyChanges}
            updateCache={updateCache}
            db={db}
            hasValueChanged={hasValueChanged}
            setActiveGroup={setActiveGroup}
            hydrateDbGroup={hydrateDbGroup}
            isDisabledByParent={isDisabledByParent}
            updatePropDownstream={updatePropDownstream}
          />
        ) : (
          <ServiceGroup
            group={getItem('group', activeOption.id, db)}
            serviceActivity={serviceActivity}
            activeGroup={activeOption}
            close={() => setActiveOption(undefined)}
            getDbItem={getDbItem}
            getCachedItem={getCachedItem}
            initialButtons={initialButtons}
            hasAnyChanges={hasAnyChanges}
            updateCache={updateCache}
            toggleActivity={toggleActivity}
            hasValueChanged={hasValueChanged}
            getItem={getItem}
            hydrateDbGroup={hydrateDbGroup}
            isDisabledByParent={isDisabledByParent}
            updatePropDownstream={updatePropDownstream}
          />
        ))}
    </ConditionalWrapper>
  );
};
