import styled from 'styled-components/macro';

import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import { shadow } from 'common/styles/shadows';
import { CustomSelect } from 'components/DialogLayout/CustomSelect/CustomSelect.styled';
import { BaseInput } from 'components/Inputs/BaseInput/BaseInput.styled';

export const OrderItemMobile = styled.div`
  background-color: ${colors.bg_Secondary};
  border-radius: 5px;
  box-shadow: ${shadow.md};
  color: ${colors.bg_Primary};
  display: flex;
  flex-direction: column;
  font-family: ${fontFamilies.family_Secondary};
  gap: 10px;
  padding: 10px;
  width: 100%;

  .serviceHeader {
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }

  .deleteAndPath {
    align-items: center;
    display: flex;
  }

  .deleteBtn {
    height: 16px;
    flex-shrink: 0;
    width: 16px;
  }

  .productPath {
    display: flex;
    flex-wrap: nowrap;
    font-size: 1rem;
    gap: 10px;
    height: 100%;

    .comp_icon svg {
      height: 16px;
      width: 16px;
    }

    .comp_arrow {
      align-items: center;
      margin-left: 10px;
      display: flex;
      transform: rotate(
        ${({ compositionIsOpened }) => (compositionIsOpened ? -90 : 90)}deg
      );
      transition: transform 0.2s ease-in-out;
    }
  }

  .comp_container {
    display: grid;
    grid-template-rows: ${({ compositionIsOpened }) =>
      compositionIsOpened ? 1 : 0}fr;
    transition: grid-template-rows 0.2s ease-out;
  }

  .inner {
    overflow: hidden;
  }

  .openDetailsBtn {
    display: flex;
    height: 13.676px;
    transform: rotate(90deg);

    svg {
      height: 7.806px;
      width: 13.676px;
    }
  }

  .serviceInfoForm {
    display: flex;
    flex-direction: column;
    gap: 2px;

    > div:first-child {
      margin-top: 0;
    }
  }

  .titleAndValueWrapper {
    display: flex;
    width: 100%;

    > div:nth-child(2) {
      margin-left: 15px;
    }

    &.half {
      width: calc(50% - 7.5px);
    }
  }

  .danger {
    color: ${colors.warningText};
  }

  ${CustomSelect} {
    .container {
      align-items: center;
      background-color: ${colors.bg_grey_1};
      border-radius: 3px;
      height: 35px;
      margin: 0;
      padding: 0 10px;

      .titleContainer {
        font-size: 0.875rem;
      }

      .valueContainer {
        &.editable {
          background-color: ${colors.bg_Secondary};
          padding: 0 5px;

          .arrow svg {
            height: 10px;
            width: auto;
          }
        }

        ${BaseInput} .input-wrapper {
          background-color: ${colors.bg_Secondary};

          input::placeholder {
            color: ${colors.bg_Primary};
          }
        }
      }
    }
  }

  .final-price {
    font-size: 1rem;
    font-weight: 700;
    padding-top: 6px;
    text-align: right;
    width: 100%;
  }
`;
