import * as S from './SVG.styled';

export default ({ color, width = 30, height = 30, bold, black, ...rest }) => {
  let weight;

  if (bold) {
    weight = 700;
  } else if (black) {
    weight = 900;
  }

  let content;

  switch (weight) {
    case 900:
      content = (
        <path
          d="M 29.484037,26.991465 23.48898,20.996558 c 1.676852,-2.199303 2.675253,-4.942057 2.675253,-7.91464 C 26.164233,5.8683484 20.295475,-1.9e-5 13.081936,-1.9e-5 5.8683672,-1.9e-5 -2.4997625e-7,5.8683484 -2.4997625e-7,13.081918 -2.4997625e-7,20.295457 5.8683672,26.163914 13.081936,26.163914 c 2.972584,0 5.715638,-0.998401 7.91494,-2.675253 l 5.994908,5.995057 c 0.34395,0.344251 0.795151,0.516301 1.246052,0.516301 0.45075,0 0.901951,-0.17205 1.245901,-0.516301 0.688351,-0.688201 0.688351,-1.804052 0,-2.491953 z M 3.5245392,13.081918 c 0,-5.2697771 4.2876204,-9.5573975 9.5573968,-9.5573975 5.269837,0 9.557742,4.2876204 9.557742,9.5573975 0,5.269836 -4.287605,9.557442 -9.557742,9.557442 -5.2700764,0 -9.5573968,-4.287606 -9.5573968,-9.557442 z"
          fill="currentColor"
          strokeWidth="1.5"
        />
      );

      break;
    case 700:
      content = (
        <path
          d="m 29.64221,27.912538 -6.623595,-6.623595 c 1.866013,-2.251366 2.98907,-5.139485 2.98907,-8.285217 0,-7.1704533 -5.833389,-13.003828 -13.003858,-13.003828 C 5.8333881,-1.02e-4 2.7093559e-8,5.8332727 2.7093559e-8,13.003726 2.7093559e-8,20.174135 5.8333881,26.007525 13.003827,26.007525 c 3.146091,0 6.034211,-1.123358 8.285576,-2.98922 l 6.623595,6.623595 c 0.238352,0.238651 0.551704,0.358202 0.864456,0.358202 0.312752,0 0.626104,-0.119551 0.864456,-0.358202 0.477453,-0.477604 0.477453,-1.251909 0,-1.729062 z M 2.4454801,13.003726 c 0,-5.8220642 4.7362822,-10.5583464 10.5583469,-10.5583464 5.822109,0 10.558392,4.7362822 10.558392,10.5583464 0,5.82205 -4.736283,10.558332 -10.558392,10.558332 -5.8220647,0 -10.5583469,-4.736282 -10.5583469,-10.558332 z"
          fill="currentColor"
          strokeWidth="1.50001"
        />
      );
      break;
    default:
      content = (
        <path
          d="m 12.918789,25.837961 c 3.333019,0 6.375365,-1.268869 8.669199,-3.34895 l 7.324159,7.324459 c 0.124651,0.124652 0.287404,0.186753 0.450606,0.186753 0.163203,0 0.326105,-0.0621 0.450607,-0.186753 0.248854,-0.248853 0.248854,-0.652359 0,-0.901213 l -7.324159,-7.324459 c 2.079781,-2.293834 3.34895,-5.335879 3.34895,-8.668914 0,-7.1233508 -5.795336,-12.919107 -12.919362,-12.919107 C 5.7947928,-2.23e-4 -1.9418784e-7,5.7955332 -1.9418784e-7,12.918884 -1.9418784e-7,20.042175 5.7954379,25.837961 12.918789,25.837961 Z m 0,-24.5634815 c 6.420665,0 11.644793,5.2237391 11.644793,11.6444045 0,6.420681 -5.224128,11.644358 -11.644793,11.644358 -6.4206657,0 -11.6440868,-5.223677 -11.6440868,-11.644358 0,-6.4206654 5.2237361,-11.6444045 11.6440868,-11.6444045 z"
          fill="currentColor"
          strokeWidth="1.50002"
        />
      );

      break;
  }

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
