import { StyledSVG } from './SVG.styled';

export default ({
  color = 'currentColor',
  width = 30,
  height = 30,
  ...rest
}) => (
  <StyledSVG
    width={width}
    height={height}
    viewBox="0 0 30 30"
    color={color}
    {...rest}
  >
    <path
      d="M1201.8,2719.813h-15.1a.637.637,0,1,0,0,1.275h15.1a.637.637,0,1,0,0-1.275Z"
      transform="translate(-1182.776 -2704.67)"
      // fill="#8d92a3"
    />
    <path
      d="M1201.8,2729.613h-15.1a.637.637,0,1,0,0,1.275h15.1a.637.637,0,1,0,0-1.275Z"
      transform="translate(-1182.776 -2711.346)"
      // fill="#8d92a3"
    />
    <path
      d="M1194.249,2739.413H1186.7a.637.637,0,1,0,0,1.274h7.546a.637.637,0,0,0,0-1.274Z"
      transform="translate(-1182.776 -2718.023)"
      // fill="#8d92a3"
    />
    <path
      d="M1205.107,2675.479h-2.887v-.956a.637.637,0,0,0-.637-.637h-2.894v-.957a.637.637,0,0,0-.637-.637h-12.142a.77.77,0,0,0-.146.042h-.032a.641.641,0,0,0-.137.086l-9.617,7.922a.654.654,0,0,0-.118.131l-.025.042a.568.568,0,0,0-.054.121v.042a.637.637,0,0,0-.035.156v20.822a.637.637,0,0,0,.637.637h28.725a.637.637,0,0,0,.637-.637v-25.539A.638.638,0,0,0,1205.107,2675.479Zm-19.758-1.2v5.914h-7.189Zm-8.33,7.189h8.964a.637.637,0,0,0,.637-.637v-7.266h10.794v27.451h-20.4Zm21.67-6.31h2.25v25.857h-2.25Zm5.781,25.857h-2.25v-24.264h2.25Z"
      transform="translate(-1175.745 -2672.292)"
      // fill="#8d92a3"
    />
  </StyledSVG>
);
