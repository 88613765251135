import { useTranslate } from 'hooks/useTranslate';
import { useContext, useEffect, useState } from 'react';
import useStatusList from 'hooks/useStatusList';

// style
import * as S from './AppointmentsDetailsMobile.styled';
import FunctionBar from 'components/FunctionBar/FunctionBar';

// icons
import { LinkUrlSVG, PhoneSVG } from 'common/icons';

// components
import DialogLayout from 'components/DialogLayout/DialogLayout';
import Overlay from 'components/Overlay/Overlay';
import Avatar from 'components/Avatar/Avatar';
import { Link } from 'components/Overrides';

// contexts
import { ScheduleContext } from 'context/ScheduleContext';
import { MainContext } from 'pages/Main/Main';
import { UserContext } from 'context/UserContext';

// hooks
import useNumber from 'hooks/useNumber';
import { useAppointment } from 'hooks/useAppointment';
import {
  getDurationString,
  getTimeString,
} from 'common/helpers/dateOperations';
import { fillImgPath } from 'common/helpers/imagesFunctions';
import { useEmployeesGet } from 'services/employeeService';

const AppointmentsDetailsMobile = ({
  status,
  data,
  functions,
  close,
  type,
}) => {
  const { id, uuid, employeeId, duration, customer, services, balance, note } =
    data;
  const { searchParams, numberOfDays } = useContext(ScheduleContext);
  const { sectorData } = useContext(MainContext);
  const { locale } = useContext(UserContext);
  const { formatPrice } = useNumber({ price: true });
  const tr = useTranslate().use().global;
  const currentStatus = useStatusList(status.toLowerCase());
  const { icons } = useAppointment();
  // TODO: duplicates requests
  const { data: employee } = useEmployeesGet({
    id: employeeId,
    queryParams: {
      retry: false,
      enabled: !!employeeId,
    },
  });
  const [functionBarItems, setFunctionBarItems] = useState([]);

  useEffect(() => {
    const btn = document.getElementById('service-fab');
    if (!btn) return;
    btn.style.zIndex = 1001;
  }, []);

  useEffect(() => {
    setFunctionBarItems(adaptFunctionItems(functions));
  }, [functions]);

  const adaptFunctionItems = (items) => {
    if (!items) return;

    const newForm = items.map((item) => ({
      id: item.id,
      acl: 1,
      icon: item.icon,
      iconTitle: item.label,
      tooltip: item.label,
      scope: 3,
      subItems: item?.items?.map((subItem) => ({
        id: subItem.id,
        acl: 1,
        hide: () => true,
        action: subItem.onClick,
        background: subItem.statusColor,
        color: subItem.color,
        title: subItem.label,
        closeOnClick: true,
        icon: subItem.icon,
        isDisabled: subItem?.disabled,
      })),
    }));

    return newForm;
  };
  const startTimeString = getTimeString(
    data?.time?.start,
    sectorData?.settings?.timezoneIanaId,
    locale,
  );

  const endTimeString = getTimeString(
    data?.time?.end,
    sectorData?.settings?.timezoneIanaId,
    locale,
  );
  const durationString =
    duration ||
    getDurationString(
      data?.time?.start,
      data?.time?.end,
      sectorData?.settings?.timezoneIanaId,
    );

  return (
    <Overlay isVisible={true}>
      <S.AppointmentsDetailsMobile statusColor={currentStatus.background}>
        <DialogLayout
          modal
          getBack={close}
          headerText={tr['order']}
          // buttons={[
          //   {
          //     id: 'edit',
          //     icon: <Pencil bold />,
          //     action: () => console.log('Edit btn'),
          //     show: true,
          //   },
          // ]}
        >
          <FunctionBar fixed functions={functionBarItems} id="service-fab" />
          <div className="details">
            <div className="details__customer-info">
              <Link
                to={`/functions/tables/clients/${customer.id}/general-information`}
                className="full-name"
              >
                {`${customer.name} ${customer.lastName}`}
                <LinkUrlSVG bold />
              </Link>
              <span className="phone">
                <PhoneSVG />
                <span>+37063328571</span>
              </span>
            </div>
            <Link
              to={'/functions/tables/orders/' + uuid}
              state={{
                toOrder: true,
                numberOfDays: numberOfDays.visible,
                searchParams: Object.fromEntries([...searchParams]),
              }}
              className="details__container order-number row"
            >
              <span className="wrapper flex-gap-row">
                <span className="label">{tr['order']}</span>
                <span>{id}</span>
              </span>
              <LinkUrlSVG bold />
            </Link>
            <div className="details__container row">
              <div className="details__status-bubble" />
              <span className="label">{currentStatus?.title}</span>
            </div>
            {!!employeeId && (
              <div className="details__container">
                <div className="details__specialist flex-gap-row">
                  <span className="label">{tr['specialist']}: </span>
                  <div className="details__avatar">
                    <Avatar
                      raised
                      imagePath={fillImgPath(employee?.employee?.photoUrl)}
                    />
                  </div>
                  <span>
                    {employee?.employee?.firstName}{' '}
                    {employee?.employee?.lastName}
                  </span>
                </div>
                <div className="flex-gap-row">
                  <span className="label">{tr['office']}: </span>
                  <span>2</span>
                </div>
              </div>
            )}
            <div className="details__container">
              <div className="flex-gap-row">
                {type !== 'pending' && (
                  <>
                    <span className="label">{tr['time']}: </span>
                    <span>
                      {startTimeString} - {endTimeString}
                    </span>
                  </>
                )}
                <span className="label">{tr['duration']}: </span>
                <span>{durationString}</span>
              </div>
            </div>
            {!!services.length && (
              <div className="details__container">
                <span className="label">{tr['services']}: </span>
                <ul>
                  {services.map((item, i) => (
                    <li key={i}>
                      <span>{item.serviceName}</span>
                    </li>
                  ))}
                </ul>
                <div className="font-bold">
                  {formatPrice(
                    services.reduce(
                      (prev, curr) => prev + +curr.finalAmountWithVat,
                      0,
                    ),
                  )}
                </div>
              </div>
            )}
            <div className="details__container balance">
              <div className="flex-gap-row">
                <div>
                  <div className="label">{tr['paid']}: </div>
                  <div className="debit">
                    {formatPrice(balance.amountCovered)}
                  </div>
                </div>
                <div>
                  <div className="label">{tr['pay']}: </div>
                  <div className="credit">{formatPrice(balance.toPay)}</div>
                </div>
              </div>
            </div>
            <div className="details__container">
              <span className="label">{tr['note']}: </span>
              <p>{note || tr['none']}</p>
            </div>
            <div className="details__container additional-info">
              <ul>
                {icons.map((item, i) => (
                  <li key={i}>
                    {item.icon}
                    <div>{item.label}</div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </DialogLayout>
      </S.AppointmentsDetailsMobile>
    </Overlay>
  );
};

export default AppointmentsDetailsMobile;
