import { useCallback, useState } from 'react';

export const useToggler = (initialState) => {
  const [active, setActive] = useState(initialState);

  const toggle = useCallback(() => setActive((oldState) => !oldState), []);

  const on = useCallback(() => setActive(() => true), []);

  const off = useCallback(() => setActive(() => false), []);

  return {
    active,
    toggle,
    on,
    off,
  };
};

export default useToggler;
