import { useContext, useEffect, useMemo, useState } from 'react';

// style
import * as S from './SetListItem.styled';

// icons
import { Minus } from 'common/icons';

// components
import { InputFieldNumber } from 'components/InputFieldNumber/InputFieldNumber';
import { SetService } from 'components/Cards/Services/SetService/SetService';

// contexts
import { UserContext } from 'context/UserContext';

// hooks
import { useSystemGetUnits } from 'services/systemService';

export const SetListItem = (props) => {
  const {
    id,
    name,
    parentName,
    durationInMinutes,
    price,
    count = 1,
    deleteAction,
    updateQuantity,
    hasComp,
    unit,
  } = props;

  const { locale } = useContext(UserContext);
  const { getUnitsById } = useSystemGetUnits();

  const [quantity] = useState(count); // local value

  const serviceObject = useMemo(
    () => ({
      subgroupName: parentName,
      name,
      hasComp,
      ...('durationInMinutes' in props && {
        baseDurationInMinutes: durationInMinutes,
      }),
      ...('unit' in props && { unitVolume: unit.volume, unitId: unit.id }),
      avgUnitPriceWithVat: price,
    }),
    [durationInMinutes, hasComp, name, parentName, price, unit, props],
  );

  return (
    <S.SetListItem>
      <SetService
        service={serviceObject}
        getUnitsById={getUnitsById}
        className={'setListItem__info-container'}
      />

      <div className="setListItem__action-container">
        <div className="setListItem__amount">
          <InputFieldNumber
            id={'serviceAmount'}
            state={{
              value: { value: quantity, label: quantity },
              setValue: (_, value) => updateQuantity(id, value?.value),
            }}
            placeholder={(1).toLocaleString(locale, {
              minimumFractionDigits: 3,
              maximumFractionDigits: 3,
            })}
            emptyInputBehavior="null"
          />
        </div>

        {deleteAction && (
          <div className="setListItem__trash-wrapper">
            <div className="setListItem__trash-icon" onClick={deleteAction}>
              <Minus inCircle bold />
            </div>
          </div>
        )}
      </div>
    </S.SetListItem>
  );
};

export default SetListItem;
