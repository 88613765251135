import * as S from './SVG.styled';

export default ({ color, width = 30, height = 30, ...rest }) => (
  <S.StyledSVG
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    version="1.1"
    viewBox="0 0 30 30"
    color={color}
    {...rest}
  >
    <>
      <path
        d="m 14.999985,5.99067 c 1.654215,0 2.995365,-1.34106 2.995365,-2.995335 C 17.99535,1.3410555 16.6542,0 14.999985,0 13.34571,0 12.00465,1.3410555 12.00465,2.995335 c 0,1.654275 1.34106,2.995335 2.995335,2.995335 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 14.999985,30 c 1.654215,0 2.995365,-1.341 2.995365,-2.99535 0,-1.6542 -1.34115,-2.99535 -2.995365,-2.99535 -1.654275,0 -2.995335,1.34115 -2.995335,2.99535 0,1.65435 1.34106,2.99535 2.995335,2.99535 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 14.999985,17.9955 c 1.654215,0 2.995365,-1.34115 2.995365,-2.99535 0,-1.654335 -1.34115,-2.995395 -2.995365,-2.995395 -1.654275,0 -2.995335,1.34106 -2.995335,2.995395 0,1.6542 1.34106,2.99535 2.995335,2.99535 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
    </>
  </S.StyledSVG>
);
