import * as S from './SVG.styled';

export default ({ color, width = 30, height = 30, bold, black, ...rest }) => {
  let weight;

  if (bold) {
    weight = 700;
  } else if (black) {
    weight = 900;
  }

  let content;

  switch (weight) {
    case 900:
      content = (
        <path
          d="M 15,0 C 6.729,0 0,6.729 0,15 0,23.271 6.729,30 15,30 23.271,30 30,23.271 30,15 30,6.729 23.271,0 15,0 Z m 0,26.47545 C 8.67339,26.47545 3.52458,21.32655 3.52458,15 3.52458,8.67339 8.67339,3.52458 15,3.52458 c 6.32655,0 11.47545,5.14881 11.47545,11.47542 0,6.32655 -5.1489,11.47545 -11.47545,11.47545 z"
          fill="currentcolor"
          strokeWidth="1.5"
        />
      );

      break;
    case 700:
      content = (
        <path
          d="M 15,30 C 23.2719,30 30,23.2719 30,15 30,6.728145 23.2719,0 15,0 6.728145,0 0,6.728145 0,15 0,23.2719 6.728145,30 15,30 Z M 15,2.44548 c 6.92385,0 12.55455,5.630745 12.55455,12.55452 0,6.92385 -5.6307,12.55455 -12.55455,12.55455 C 8.076225,27.55455 2.44548,21.92385 2.44548,15 2.44548,8.076225 8.076225,2.44548 15,2.44548 Z"
          fill="currentcolor"
          strokeWidth="1.5"
        />
      );

      break;
    default:
      content = (
        <path
          d="M 15,30 C 23.2728,30 30,23.26965 30,15 30,6.730395 23.2728,0 15,0 6.727215,0 0,6.730395 0,15 0,23.26965 6.730395,30 15,30 Z M 15,1.274697 C 22.56855,1.274697 28.7253,7.43148 28.7253,15 28.7253,22.56855 22.56855,28.7253 15,28.7253 7.43148,28.7253 1.274697,22.56855 1.274697,15 1.274697,7.43148 7.43148,1.274697 15,1.274697 Z"
          fill="currentcolor"
          strokeWidth="1.5"
        />
      );

      break;
  }

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
