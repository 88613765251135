import styled from 'styled-components/macro';
import { shadow } from 'common/styles/shadows';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import { SetListItem } from '../SetList/SetListItem/SetListItem.styled';

export const ServiceList = styled.section`
  box-shadow: ${shadow.lg};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 540px;
  padding: 40px;

  .list__header {
    padding-bottom: 40px;

    h2 {
      font-size: 1.875rem;
      font-weight: 600;
      margin: 0;
      padding: 0;
      padding-bottom: 40px;
    }
  }

  .list__body {
    font-family: ${fontFamilies.family_Secondary};
    overflow: auto;

    @media screen and (max-width: 960px) {
      margin-inline: -10px;
    }

    ::-webkit-scrollbar {
      height: 0;
      width: 0;
    }

    &-empty {
      border: 1px solid ${colors.bg_grey_2};
      border-radius: 5px;
      font-size: 1rem;
      padding: 10px;
      text-align: center;
    }
  }

  @media screen and (max-width: 960px) {
    box-shadow: none;
    margin: 20px;
    padding: 0;

    .list__header {
      display: none;
    }
  }
`;

export const ServiceModal = styled.div`
  margin: 10px 0;
  width: calc(100vw - 100px);

  ${SetListItem} {
    align-items: stretch;
    height: unset;
    min-height: 60px;

    .setListItem__info-container {
      padding: 10px;
    }

    .setListItem__action-container {
      padding: 0;

      .setListItem__amount {
        height: 100%;
        margin-right: 0;
      }
    }
  }
`;
