import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const TimeAndDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  transition: max-height 0.3s ease;
  width: 100%;
  max-height: ${({ openCalendor }) => (openCalendor ? '400' : '25')}px;

  .date-time-select-wrapper {
    column-gap: 10px;
    display: flex;
    flex-wrap: wrap;
    font-family: ${fontFamilies.family_Secondary};
    font-size: 0.875rem;
    justify-content: space-between;
    min-height: 25px;
    row-gap: 5px;
    width: 100%;
    align-items: center;

    .date-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;

      .date-select {
        align-items: center;
        background-color: ${(props) =>
          props.disabled
            ? colors.bg_grey_1
            : props.darkTheme
              ? 'white'
              : colors.bg_grey_1};
        border-radius: 5px;
        color: ${(props) =>
          props.disabled
            ? colors.bg_grey_3
            : props.openCalendor
              ? colors.bg_Button_Primary
              : colors.bg_Primary};
        display: flex;
        font-family: ${fontFamilies.family_Secondary};
        /* font-size: 16px; */
        min-height: 25px;
        padding: 0px 10px;

        .date-select__format {
          padding-right: 5px;
        }
      }
    }

    .time-select {
      align-items: center;
      display: flex;

      .time-input {
        align-items: center;
        background-color: ${(props) =>
          props.darkTheme ? 'white' : colors.bg_grey_1};
        border-radius: 5px;
        display: flex;
        min-height: 25px;
        /* min-width: 50%; */
        padding: 2px 10px;
      }

      .dash {
        padding: 2px 5px;
        font-weight: 700;
      }

      .time-picker-icon {
        align-items: center;
        display: flex;
        margin-right: -3px;
        padding-left: 15px;
      }
    }

    .warning-icon {
      background-color: #fff;
      width: 16px;
      height: 16px;
      border-radius: 100%;

      svg {
        width: 16px;
        height: 16px;
        color: ${colors.warningText};
      }
    }
  }

  .calendar-wrapper {
    border-radius: 3px;
    height: 100%;
    overflow: hidden;
    transition: height 0.3s ease;
    margin-bottom: 10px;

    .react-calendar__navigation {
      &__prev-button {
        right: 110px;
      }
      &__next-button {
        right: 0;
      }
    }

    .miniCalendarIcon {
      right: 54px;
    }
  }

  .date-select__open-calendar {
    background-color: white;
    margin-top: 10px;
    padding: 10px;
    padding-bottom: 30px;
    border-radius: 5px;
  }
`;
