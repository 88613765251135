import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import styled from 'styled-components/macro';

export const PageHeader = styled.div`
  align-items: flex-end;
  border-bottom: 1px solid ${colors.table_border};
  display: flex;
  flex-wrap: wrap;
  font-family: ${fontFamilies.family_Primary};
  justify-content: space-between;
  margin-bottom: 40px;

  @media (max-width: 960px) {
    margin-bottom: 20px;
    /* border-bottom: none; */
  }

  > h1 {
    color: ${colors.text_Secondary};
    display: flex;
    flex: 3;
    font-size: 1.875rem;
    font-weight: 600;
    justify-content: space-between;
    margin: 0 40px 20px 0;
    min-width: max-content;
    padding-bottom: 2px;

    @media (max-width: 960px) {
      font-size: 1.25rem;
      margin-bottom: 10px;
      padding-bottom: 3px;
      width: 100%;
    }

    span {
      font-weight: 400;
    }

    div {
      flex: 1;
    }

    // TODO: remove after refactoring clients table ~~>
    .sort,
    .filter {
      cursor: pointer;
      display: inline;
    }

    .controls {
      font-weight: 400;

      :last-child {
        display: none;

        *:not(:last-child) {
          margin-inline: 10px;
        }

        @media (max-width: 960px) {
          display: unset;
        }
      }
    }
    // <~~ [END]: remove after refactoring clients table
  }

  &.no-hr {
    border-bottom: unset;
    margin-bottom: unset;

    > h1 {
      @media (max-width: 960px) {
        margin: 10px 10px 20px;
        margin-inline: unset;
      }
    }
  }

  .control {
    margin-bottom: 20px;
    z-index: 1;

    @media (max-width: 960px) {
      background: linear-gradient(to bottom, transparent 0%, white 40%);
      bottom: 0;
      margin: 0;
      min-width: calc(100% - 20px);
      padding: 20px 10px;
      position: fixed;
    }
  }

  .control > button {
    height: 35px;
    max-width: fit-content;
    z-index: 1;

    @media (max-width: 960px) {
      bottom: 20px;
      height: 30px;
      max-width: calc(100% - 40px);
      position: fixed;
    }
  }

  .control > button > span {
    white-space: nowrap;
  }
`;
