import * as S from './SVG.styled';

export default ({ color, width = 30, height = 30, bold, black, ...rest }) => {
  let weight;

  if (bold) {
    weight = 700;
  } else if (black) {
    weight = 900;
  }

  let content;

  switch (weight) {
    case 900:
      content = (
        <>
          <path
            d="m 7.1745263,0.51765881 c -0.687285,-0.6902115 -1.80336,-0.6902115 -2.490645,0 -0.69021,0.68727449 -0.69021,1.80336599 0,2.49063599 L 16.675976,15.0003 4.6838813,26.9925 c -0.6872701,0.68715 -0.6872701,1.8033 0,2.4906 0.343635,0.34365 0.795945,0.5169 1.245315,0.5169 0.449385,0 0.901695,-0.17325 1.24533,-0.5169 L 20.411876,16.24575 c 0.6873,-0.6873 0.6873,-1.80342 0,-2.490705 z"
            fill="currentColor"
            strokeWidth="1.5"
          />
          <path
            d="m 24.071576,6.6209098 c -0.97215,0 -1.7622,0.79008 -1.7622,1.762245 V 21.62055 c 0,0.97215 0.79005,1.7622 1.7622,1.7622 0.97215,0 1.7622,-0.79005 1.7622,-1.7622 V 8.3831548 c 0,-0.972165 -0.79005,-1.762245 -1.7622,-1.762245 z"
            fill="currentColor"
            strokeWidth="1.5"
          />
        </>
      );

      break;
    case 700:
      content = (
        <>
          <path
            d="m 4.6938094,29.6424 c 0.23844,0.23835 0.55023,0.3576 0.865095,0.3576 0.31485,0 0.626655,-0.11925 0.865095,-0.3576 L 20.201289,15.86505 c 0.47685,-0.47685 0.47685,-1.25022 0,-1.730145 L 6.4239994,0.35765248 c -0.47688,-0.47686995 -1.25331,-0.47686995 -1.73019,0 -0.476865,0.4768695 -0.476865,1.25331752 0,1.73018252 L 17.605989,15 4.6938094,27.91215 c -0.476865,0.47685 -0.476865,1.25025 0,1.73025 z"
            fill="currentColor"
            strokeWidth="1.5"
          />
          <path
            d="m 24.441039,23.1129 c 0.6756,0 1.2228,-0.5472 1.2228,-1.2228 V 8.112915 c 0,-0.67557 -0.5472,-1.222755 -1.2228,-1.222755 -0.67545,0 -1.22265,0.547185 -1.22265,1.222755 V 21.8901 c 0,0.6756 0.5472,1.2228 1.22265,1.2228 z"
            fill="currentColor"
            strokeWidth="1.5"
          />
        </>
      );

      break;
    default:
      content = (
        <>
          <path
            d="m 4.7084738,29.81205 c 0.12429,0.1242 0.2868,0.18795 0.449325,0.18795 0.162525,0 0.325035,-0.06375 0.449325,-0.18795 L 19.969339,15.4497 c 0.24855,-0.24855 0.24855,-0.65325 0,-0.9018 L 5.6071238,0.18880952 c -0.248565,-0.25174605 -0.650085,-0.25174605 -0.89865,0 -0.24855,0.2517465 -0.251745,0.650079 0,0.89863798 L 18.621439,15.00045 4.7084738,28.9134 c -0.24855,0.24855 -0.24855,0.65325 0,0.9018 z"
            fill="currentColor"
            strokeWidth="1.5"
          />
          <path
            d="m 24.841789,22.8204 c 0.35055,0 0.63735,-0.2868 0.63735,-0.63735 V 7.820835 c 0,-0.350535 -0.2868,-0.637335 -0.63735,-0.637335 -0.35055,0 -0.63735,0.2868 -0.63735,0.637335 V 22.18305 c 0,0.35055 0.2868,0.63735 0.63735,0.63735 z"
            fill="currentColor"
            strokeWidth="1.5"
          />
        </>
      );

      break;
  }

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
