import * as S from '../SVG.styled';

const PhotographySVG = ({
  color = 'currentColor',
  width = 20,
  height = 20,
}) => {
  const content = (
    <>
      <path d="m97.04,183.3c-11.12,32.38,6.17,67.78,38.56,78.9h0c6.66,2.29,13.44,3.37,20.12,3.37,25.79,0,49.95-16.21,58.78-41.93,5.39-15.69,4.34-32.53-2.94-47.44-7.28-14.9-19.93-26.08-35.62-31.46s-32.53-4.35-47.44,2.94c-14.9,7.28-26.08,19.93-31.46,35.62Zm75.15-27.65c12.77,4.39,23.07,13.48,29,25.62,5.93,12.13,6.78,25.85,2.39,38.62-9.06,26.37-37.87,40.45-64.24,31.39-26.36-9.06-40.45-37.88-31.39-64.24,4.39-12.77,13.49-23.07,25.62-29,12.13-5.93,25.85-6.78,38.62-2.39Z" />
      <path d="m268.52,157.92c7.91,0,14.35-6.44,14.35-14.35s-6.44-14.35-14.35-14.35h-33.79c-7.91,0-14.35,6.44-14.35,14.35s6.44,14.35,14.35,14.35h33.79Zm-36.6-14.35c0-1.55,1.26-2.81,2.81-2.81h33.79c1.55,0,2.81,1.26,2.81,2.81s-1.26,2.81-2.81,2.81h-33.79c-1.55,0-2.81-1.26-2.81-2.81Z" />
      <path d="m174.88,179.38c6.66,5.28,10.87,13.18,11.54,21.66.24,3.02,2.77,5.31,5.74,5.31.15,0,.31,0,.47-.02,3.18-.25,5.54-3.03,5.29-6.21-.93-11.66-6.72-22.52-15.88-29.78-2.5-1.98-6.12-1.56-8.1.94-1.98,2.5-1.56,6.12.94,8.1Z" />
      <path d="m5.77,86.98c11.23,0,25.63,7.43,37.59,19.39,11.96,11.96,19.39,26.37,19.39,37.59,0,3.19,2.58,5.77,5.77,5.77s5.77-2.58,5.77-5.77c0-11.23,7.43-25.63,19.39-37.59,11.96-11.96,26.37-19.39,37.59-19.39,3.19,0,5.77-2.58,5.77-5.77s-2.58-5.77-5.77-5.77c-11.23,0-25.63-7.43-37.59-19.39s-19.39-26.37-19.39-37.59c0-3.19-2.58-5.77-5.77-5.77s-5.77,2.58-5.77,5.77c0,11.23-7.43,25.63-19.39,37.59-11.96,11.96-26.37,19.39-37.59,19.39C2.58,75.44,0,78.02,0,81.21s2.58,5.77,5.77,5.77Zm62.75-46.08c8.1,16.6,23.71,32.21,40.31,40.31-16.6,8.1-32.21,23.71-40.31,40.31-8.1-16.6-23.71-32.21-40.31-40.31,16.6-8.1,32.21-23.71,40.31-40.31Z" />
      <path d="m97.83,40.44c9.52,0,23.14,13.63,23.14,23.14,0,3.19,2.58,5.77,5.77,5.77s5.77-2.58,5.77-5.77c0-9.52,13.63-23.14,23.14-23.14,3.19,0,5.77-2.58,5.77-5.77s-2.58-5.77-5.77-5.77c-9.52,0-23.14-13.63-23.14-23.14,0-3.19-2.58-5.77-5.77-5.77s-5.77,2.58-5.77,5.77c0,9.52-13.63,23.14-23.14,23.14-3.19,0-5.77,2.58-5.77,5.77s2.58,5.77,5.77,5.77Zm28.91-18.17c3.24,4.8,7.6,9.16,12.4,12.4-4.8,3.24-9.16,7.6-12.4,12.4-3.24-4.8-7.6-9.16-12.4-12.4,4.8-3.24,9.16-7.6,12.4-12.4Z" />
      <path d="m304.01,291.93c-2.49-1.98-6.12-1.57-8.1.92-3.61,4.54-9,7.15-14.79,7.15H30.42c-10.41,0-18.88-8.47-18.88-18.88v-144.83c0-8.21,3.47-16.09,9.53-21.64,2.35-2.15,2.51-5.8.36-8.15-2.15-2.35-5.8-2.51-8.15-.36-8.43,7.73-13.27,18.71-13.27,30.14v144.83c0,16.77,13.65,30.42,30.42,30.42h250.7c9.33,0,18.01-4.19,23.82-11.5,1.98-2.49,1.57-6.12-.92-8.1Z" />
      <path d="m305.77,256.82c-3.19,0-5.77,2.58-5.77,5.77v14.17c0,3.19,2.58,5.77,5.77,5.77s5.77-2.58,5.77-5.77v-14.17c0-3.19-2.58-5.77-5.77-5.77Z" />
      <path d="m281.12,95.41h-38.13l-8.97-20.04c-5.21-11.63-16.81-19.15-29.56-19.15h-55.92c-3.19,0-5.77,2.58-5.77,5.77s2.58,5.77,5.77,5.77h55.92c8.21,0,15.68,4.84,19.03,12.33l10.5,23.45c.93,2.08,2.99,3.41,5.26,3.41h41.87c10.41,0,18.88,8.47,18.88,18.88v111.86c0,3.19,2.58,5.77,5.77,5.77s5.77-2.58,5.77-5.77v-111.86c0-16.77-13.65-30.42-30.42-30.42Z" />
    </>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 311.54 311.54"
      color={color}
    >
      {content}
    </S.StyledSVG>
  );
};

export default PhotographySVG;
