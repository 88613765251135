import { useCallback, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

// hooks
import useTranslate from 'hooks/useTranslate';
import { useServicesSellOrders } from 'services/orderService';

const useOrder = ({
  sectorId,
  selectedEmployees,
  order,
  setOrder,
  multiSelect,
  location,
  orderId,
  refetch,
  setIsFormDisabled,
  setIsWantedEmployeesDialogDisabled,
}) => {
  const navigate = useNavigate();
  const tr = useTranslate().use().global;

  const { mutate, isSuccess, data, isError, error } = useServicesSellOrders();

  const [isSubmiting, setIsSubmiting] = useState(false);

  const generateServiceList = (services) => {
    return services.map((service) => {
      const isBreak = service.unitPriceWithVat === undefined; // TOOD: change validation method

      if (isBreak) return service;

      const keyNeeded = [
        'serviceId',
        'unitDurationInMinutes',
        'unitPriceWithVat',
        'quantity',
        'discountWithVat',
        'unitId',
        'unitVolume',
        'vatPercent',
        'smsPeriodCustomer',
      ];

      return {
        ...Object.fromEntries(
          Object.entries(service).filter(([key]) => keyNeeded.includes(key)),
        ),
      };
    });
  };

  const saveOrder = useCallback(() => {
    if (isSubmiting) {
      return;
    }

    const orderCopy = structuredClone(order);
    const serviceList = generateServiceList(orderCopy.savedProducts);
    const employeeId = selectedEmployees?.[0]?.id;
    const orderToSend = {
      sectorId,
      employeeId: employeeId,
      wantedEmployeeIds: order.wantedEmployeeIds,
      startTimeUtc: DateTime.fromISO(orderCopy.startTimeUtc)
        .set({ millisecond: 0 })
        .toUTC()
        .toISO({ suppressMilliseconds: true }),
      sendVisitReminder: orderCopy.sendVisitReminder,
      customerId: orderCopy.customer.id,
      durationInMinutes: orderCopy.durationInMinutes,
      completionStatus: order.status,
      services: serviceList,
      note: orderCopy.note,
    };

    if (orderId !== 'new') {
      orderToSend.id = orderId;
    }

    setIsFormDisabled(true);
    setIsWantedEmployeesDialogDisabled(true);
    mutate(orderToSend);
    setIsSubmiting(true);
  }, [order, multiSelect, selectedEmployees, sectorId, isSubmiting]);

  useEffect(() => {
    if (isSuccess) {
      const responseOrderId = data?.data?.order?.id;

      if (orderId === 'new' && responseOrderId) {
        navigate('/functions/tables/orders/' + responseOrderId, {
          state: location.state,
        });
        toast.success(tr['order-created']);
      } else {
        refetch();
        toast.success(tr['order-updated']);
      }
      setIsSubmiting(false);
      setIsWantedEmployeesDialogDisabled(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      if (error?.response?.status === 412) {
        refetch();
      }
      if (error?.response?.status === 404) {
        navigate('/functions/daily-functions/calendar/');
      }

      setIsFormDisabled(false);
      setIsSubmiting(false);
      setIsWantedEmployeesDialogDisabled(false);
    }
  }, [isError]);

  const handleDateTimeInput = (type, value) => {
    if (type === 'date') {
      const startDateTime = DateTime.fromISO(order.startTimeUtc, {
        zone: order.timezoneIanaId,
      });
      const dateTimeObject = value.toObject();

      setOrder((prev) => ({
        ...prev,
        startTimeUtc: startDateTime
          .set({
            year: dateTimeObject.year,
            month: dateTimeObject.month,
            day: dateTimeObject.day,
          })
          .toISO(),
      }));
    } else if (type === 'startTime' || type === 'endTime') {
      const hour = Number(value.split(':')[0]);
      const minute = Number(value.split(':')[1]);

      setOrder((prev) => {
        let startDateTime = DateTime.fromISO(prev.startTimeUtc, {
          zone: order.timezoneIanaId,
        });
        let endDateTime = DateTime.fromISO(prev.endTimeUtc, {
          zone: order.timezoneIanaId,
        });

        if (type === 'startTime') {
          startDateTime = startDateTime.set({ hour, minute });
        } else if (type === 'endTime') {
          endDateTime = startDateTime.set({ hour, minute });
        }

        let durationInMinutes = endDateTime
          .diff(startDateTime, ['minutes'])
          .toObject().minutes;

        if (durationInMinutes < 0) {
          endDateTime = startDateTime;
          durationInMinutes = 0;
        }

        return {
          ...prev,
          startTimeUtc: startDateTime
            .toUTC()
            .toISO({ suppressMilliseconds: true }),
          endTimeUtc: endDateTime.toUTC().toISO({ suppressMilliseconds: true }),
          durationInMinutes,
        };
      });
    }
  };

  return {
    saveOrder,
    handleDateTimeInput,
  };
};

export default useOrder;
