import * as S from './SVG.styled';

export default ({ color, width = 30, height = 30, bold, ...rest }) => {
  const content = bold ? (
    <>
      <path
        d="M 22.611525,0 H 7.388415 c -1.28694,0 -2.33544,1.0485015 -2.33544,2.33544 v 25.33221 c 0,1.28685 1.0485,2.33235 2.33544,2.33235 h 15.22626 c 1.28685,0 2.33235,-1.0455 2.33235,-2.33235 V 2.33544 C 24.947025,1.0485015 23.901525,0 22.614675,0 Z m -0.10995,2.44548 V 21.17175 H 7.498455 V 2.44548 Z M 7.498455,27.55455 v -3.9372 h 15.00312 v 3.9372 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 12.169335,6.11676 h 5.66124 c 0.6756,0 1.2228,-0.547185 1.2228,-1.222755 0,-0.67557 -0.5472,-1.22274 -1.2228,-1.22274 h -5.66124 c -0.67557,0 -1.22274,0.54717 -1.22274,1.22274 0,0.67557 0.54717,1.222755 1.22274,1.222755 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
    </>
  ) : (
    <>
      <path
        d="M 7.061835,30 H 22.935 c 0.99105,0 1.7973,-0.80625 1.7973,-1.7973 V 1.794135 C 24.7323,0.8030595 23.92605,0 22.935,0 H 7.061835 C 6.07395,0 5.2677,0.8062455 5.2677,1.794135 V 28.2027 C 5.2677,29.19375 6.07395,30 7.061835,30 Z M 22.935,28.7253 H 7.061835 c -0.2868,0 -0.519435,-0.23265 -0.519435,-0.5226 v -4.85985 h 16.9152 v 4.85985 c 0,0.2868 -0.23265,0.5226 -0.5226,0.5226 z M 7.061835,1.274697 H 22.935 c 0.2868,0 0.5226,0.232638 0.5226,0.519438 V 22.06815 H 6.5424 V 1.794135 c 0,-0.2868 0.232635,-0.519438 0.519435,-0.519438 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 12.049155,5.101965 h 5.901795 c 0.35055,0 0.63735,-0.2868 0.63735,-0.63735 0,-0.350535 -0.2868,-0.63735 -0.63735,-0.63735 h -5.901795 c -0.35055,0 -0.63735,0.286815 -0.63735,0.63735 0,0.35055 0.2868,0.63735 0.63735,0.63735 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
    </>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
