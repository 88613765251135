import styled from 'styled-components/macro';
import { fontFamilies } from 'common/fonts/fonts';
import { TimeAndDateContainer } from 'components/DialogLayout/CustomSelect/children/TimeAndDateChild/TimeAndDateChild.styled';

export const OrderHeaderMobile = styled.div`
  padding: 10px 20px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  flex-direction: column;
  gap: 10px;

  .row {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 14px;
    font-family: ${fontFamilies.family_Secondary};

    .status-title {
      font-weight: 500;
    }

    ${TimeAndDateContainer} .date-time-select-wrapper {
      justify-content: start;
    }
  }
`;
