import styled, { css } from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { SCHEDULE_TYPE } from 'common/constants';

import { Cell } from 'components/ScheduleCustom/DailyView/ScheduleDay/ScheduleDay.styled';
export { Cell };

export const EmployeesHeader = styled.div`
  min-height: ${({ scheduleHasData }) => (scheduleHasData ? 64 : 54)}px;
  height: fit-content;
  display: flex;

  .employee-selection {
    position: sticky;
    left: 2px;
    z-index: 1;
  }

  @media screen and (max-width: 960px) {
    .employee-selection {
      left: 10px;
      width: ${({ pendingOrdersHeaderWidth }) =>
        `calc(100vw - 20px - ${pendingOrdersHeaderWidth}px)`};
    }
  }
`;

export const Employee = styled.div`
  .column-head {
    width: ${({ stickyHeader, hiddenHeader, numberOfDays }) =>
      stickyHeader && !hiddenHeader && numberOfDays * 100}%;
    height: ${({ stickyHeader }) => (stickyHeader ? 60 : 110)}px;

    .sticky-head {
      position: sticky;
      top: 0;
      left: ${(props) => (['en-US'].includes(props.locale) ? 87 : 57)}px;
      padding: 0 10px 0 5px;
      display: ${({ hiddenHeader }) =>
        hiddenHeader ? 'none' : 'inline-block'};
    }
  }

  @media screen and (max-width: 960px) {
    .column-head {
      height: ${({ stickyHeader }) => (stickyHeader ? 60 : 85)}px;

      .sticky-head {
        left: ${(props) => (['en-US'].includes(props.locale) ? 62 : 57)}px;
      }
    }
  }

  ${({ type }) => {
    switch (type) {
      case SCHEDULE_TYPE.WEEK:
        return css`
          .column-head {
            display: flex;
            align-items: center;
          }
        `;
    }
  }}
`;

export const EmployeeCellSkeleton = styled.div`
  height: 60px;
  min-width: ${({ defaultColumnWidth }) => defaultColumnWidth}px;
  z-index: 3;
  width: ${({ type }) => type === SCHEDULE_TYPE.WEEK && 700}%;

  .employee {
    position: relative;
    margin: 0 auto;
    width: 60px;
    height: 60px;
    background-color: ${colors.bg_grey_5};
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
    border-radius: 100%;

    .actions {
      position: absolute;
      left: -28px;
      top: 22px;
    }
  }

  .name {
    margin: 0 auto;
    background-color: ${colors.bg_grey_5};
    width: 80px;
    height: 14px;
    margin-top: 9px;
  }

  @media screen and (max-width: 960px) {
    .employee {
      width: 50px;
      height: 50px;

      .actions {
        top: 16px;
      }
    }
  }

  ${({ type }) => {
    switch (type) {
      case SCHEDULE_TYPE.WEEK:
        return css`
          display: flex;
          align-items: center;

          .employee {
            margin: unset;
            left: 34px;
          }

          .name {
            margin: unset;
            margin-left: 56px;
          }
        `;
    }
  }}

  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
`;
